import React, { ReactNode, useState } from 'react';
import styles from './Modal.module.scss'
import CLOSE_ICON from '../../assets/Icons/nav/x.png';

interface props {
  onClose?: () => void,
  onSave?: () => void,
  saveTitle?: string,
  cancelTitle?: string,
  children: ReactNode,
  showButtons?: boolean,
  onOkClick?: () => void,
  className?: string
  scroll?: string
  bgPopup?: string
  showOK?:boolean
}

const Modal = ({ onClose, scroll, onSave, children, saveTitle = 'Yes', cancelTitle = 'Cancel', showButtons = true, onOkClick, className,bgPopup,showOK=true }: props) => {
  return (
    <div className={`${styles.popupOuter} backdrop-blur-xl bg-white/30 z-[200] ${className} `}>
      <div className={styles.popupOverlay}></div>
      <div className={styles.popupContent}>
        <div className={`${styles.sideNav} ${styles.animateNav} ${bgPopup ? bgPopup :'bg-button-gray'} pb-6`}>
          {/* <div className='flex justify-end w-full px-3 mt-2'>
            <img src={CLOSE_ICON} alt='close' className='size-5 cursor-pointer' onClick={onClose}/>
          </div> */}
          <div className={`px-8 py-7 ${scroll}`}>
            {children}
          </div>
          {showButtons ? <div className='flex gap-8'>
            <button className='bg-secondary-gray text-primary-white min-w-20 h-8 rounded-md px-2' onClick={onClose}>{cancelTitle}</button>
            <button className='bg-primary-orange text-primary-white min-w-20 h-8 rounded-md px-2' onClick={onSave}>{saveTitle}</button>
          </div> :
            showOK && <button className='bg-secondary-gray text-primary-white min-w-20 h-8 rounded-md px-2' onClick={onOkClick}>Ok</button>
          }
        </div>
      </div>
    </div>);

};

export default Modal;