import React, { useEffect, useState } from 'react'
import BackArrow from "../../../assets/Icons/backArrow/backArrow.svg";
import RightArrow from '../../../assets/Icons/Analytics/blackRightArrow.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import Tick from '../../../assets/Icons/Analytics/greenTick.svg'
import Cross from '../../../assets/Icons/Analytics/cross.svg'
import Minus from '../../../assets/Icons/Analytics/minus.svg'
import { AnalyticsService } from '../../../services/analyticsService';
import ProfileIcon from '../../../assets/Icons/profileMenu/profile.svg';
import { useSpinner } from '../../../components/spinner/SpinnerContext';


export default function Guests() {

    const navigate = useNavigate();
  const [selectedRSVP, setSelectedRSVP] = useState('All');
  const [guests, setGuests] = useState<any>([]);
  const {setShowSpinner} = useSpinner();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const eventId: any = queryParams.get("eventid");
    const { eventName, goingGuestCount, notGoingGuestCount, maybeGuestCount,bannerImage } = location.state;
  
  useEffect(() => {
    if(eventId) {
      getGuests();
    }
  }, [eventId,selectedRSVP]);


  const getGuests = async () => {
    try {
      setShowSpinner(true);
      const response = await AnalyticsService.fetchGuests({id: eventId,pageSize: 100, pageNo: 1,status: getStatusForBackend(selectedRSVP)});
      if(response?.data) {
        setGuests(response?.data?.Users);
        setShowSpinner(false);
      }
    } catch (error) {
      console.log(error);
      setShowSpinner(false);
    }
  }
  
  const getStatusForBackend = (status: string) => {
    if(status == 'All') return 'All';
    if(status == 'Yes') return 'Going';
    if(status == 'No') return 'Not Going';
    if(status == 'Maybe') return 'Maybe';
    if(status == 'Attended') return 'Attending';
  }

  const getStatusForFrontend = (status: string) => {
    if(status == 'All') return 'All';
    if(status == 'Going') return 'Yes';
    if(status == 'Not Going') return 'No';
    if(status == 'Maybe') return 'Maybe';
    if(status == 'Attending') return 'Attended';
  }

  return (
    <div className="container mt-8 text-white flex flex-col gap-5">
      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <div className="flex items-center gap-4 font-bold text-base mb-6">
          <img
            src={BackArrow}
            alt="Back"
            className="w-8 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-text-gray font-medium text-2xl">Analytics</span>
          <img src={RightArrow} alt="Right Arrow" />
          <span className="font-medium text-2xl">Guests</span>
        </div>
        <div className="flex items-center gap-2 px-4 py-2 bg-input-box-gray rounded-2xl w-full md:w-[40%]">
          <img src={bannerImage || 'https://sondr-dev.s3.us-east-2.amazonaws.com/Socha/event_default_banner.png'} alt="Event" className="w-7 h-7 rounded-md" />
          <span className="truncate">
            {eventName}
          </span>
        </div>
      </div>
      
      <div className="flex flex-col md:flex-row md:items-center gap-3 md:justify-between">
        <div className="flex items-center gap-2 px-4 py-2 bg-input-box-gray rounded-2xl justify-between min-w-[25%]">
          <div className='flex items-center'>
            <img src={Tick} className='mr-1'/>
            <span>&nbsp;{goingGuestCount || '0'}</span>
            <span className="text-text-gray text-sm">&nbsp;Yes</span>
          </div>
          <div className='flex items-center' >
            <img src={Cross} className='mr-1'/>
            <span>&nbsp;{notGoingGuestCount || '0'}</span>
            <span className="text-text-gray text-sm">&nbsp;No</span>
          </div>
          <div className='flex items-center'>
            <img src={Minus} className='mr-1'/>
            <span>&nbsp;{maybeGuestCount || '0'}</span>
            <span className="text-text-gray text-sm">&nbsp;Maybe</span>
          </div>
        </div>
        <div className="flex items-center px-2 py-1 bg-input-box-gray rounded-2xl justify-between ">
        {["All", "Yes", "No","Maybe","Attended"].map((label, index) => (
                  <span
                key={index}
                className={`cursor-pointer ${selectedRSVP == label && 'bg-black'} px-3 py-1.5 rounded-xl`}
                onClick={() => setSelectedRSVP(label) }
                  >
                  {label}
                  </span>
              ))}
            </div>
        
      </div>
      <div className="overflow-x-auto">
      <table className="w-full text-left text-sm">
        <thead>
          <tr className='text-text-gray'>
            <th className="py-2 px-4">User name</th>
            <th className="py-2 px-4">Ticket purchased</th>
            <th className="py-2 px-4">RSVP</th>
          </tr>
        </thead>
        <tbody>
          {guests?.length > 0 ? guests?.map((row:any,index:any ) => (
            <tr key={index} className="hover:bg-input-box-gray ">
              <td className="py-2 px-4 flex items-center gap-2 my-1">
                <img src={row?.profilePhoto || ProfileIcon} className='rounded-full w-5 h-5'/>{row.username}
              </td>
                <td className="py-2 px-4">{row?.tickets}x</td>
                <td>
              <span className={`max-w-fit py-0.5 px-2 rounded-2xl text-black font-bold ${row.joiningStatus == 'Not Going' ? 'bg-[#DC2430]' :row.joiningStatus == 'Attending' ? 'bg-[#43AC12]' : row.joiningStatus == 'Maybe' ? 'bg-[#FF8A00]' : 'bg-[#AFFF8A]'}`}>{getStatusForFrontend(row.joiningStatus)}</span>
              </td>
            </tr>
          )) : <tr><td colSpan={3} className='py-2 px-4 '>No data found</td></tr>}
        </tbody>
      </table>
    </div>
    </div>
  );
}
