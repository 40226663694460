import React, { CSSProperties } from 'react';
import { PuffLoader } from 'react-spinners';
import styles from './spinner.module.scss'
import { useSpinner } from './SpinnerContext';

interface SpinnerProps {
  loading: boolean;
}

const override: CSSProperties = {
    'display': 'block',
    'margin':' 0 auto',
}

const Spinner: React.FC = () => {
    const { showSpinner } = useSpinner();
  return (
    <div className={styles.spinnerOverlay} style={{ display: showSpinner ? 'block' : 'none' }}>
      <div className={styles.spinnerContainer}>
        <PuffLoader cssOverride={override} size={100} color={'rgba(255, 138, 0, 1)'} loading={showSpinner} />
      </div>
    </div>
  );
};

export default Spinner;