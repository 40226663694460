import moment from 'moment';
import React, { useState, ChangeEvent, useEffect } from 'react';

interface TimePickerProps {
  onChange?: (time: string) => void;
  eventTime?: string;
  disabled?: boolean;
}

const TimePicker: React.FC<TimePickerProps> = ({ onChange, eventTime, disabled = false }) => {
  const currentHour = eventTime ? moment(eventTime, 'HH:mm:ss').format('hh') : moment().format('hh');
  const currentMinute = eventTime ? moment(eventTime, 'HH:mm:ss').format('mm') : moment().format('mm');

  let currentPeriod = null
  if (eventTime) {
    currentPeriod = eventTime.split(' ')[1];
  } else {

    currentPeriod = (parseInt(moment().format('HH')) >= 12 ? 'PM' : 'AM');
  }


  const [hour, setHour] = useState<string>(currentHour);
  const [minute, setMinute] = useState<string>(currentMinute);
  const [period, setPeriod] = useState<string>(currentPeriod);

  useEffect(() => {
    if (eventTime) {
      console.log(`Parsing eventTime: ${eventTime}`);
      const match = eventTime.match(/(\d{2}):(\d{2}):\d{2}/);
      if (match) {
        let [eventHour, eventMinute] = match.slice(1);
        const eventPeriod = parseInt(eventHour) >= 12 ? 'PM' : 'AM';
        eventHour = (parseInt(eventHour) % 12 || 12).toString().padStart(2, '0');
        setHour(eventHour);
        setMinute(eventMinute);
        setPeriod(eventPeriod);
        console.log(`Parsed time - Hour: ${eventHour}, Minute: ${eventMinute}, Period: ${eventPeriod}`);
      } else {
        console.error(`Failed to parse eventTime: ${eventTime}`);
      }
    }
  }, [eventTime]);



  const handleHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newHour = e.target.value;
    setHour(newHour);
    triggerOnChange(newHour, minute, period);
  };

  const handleMinuteChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newMinute = e.target.value;
    setMinute(newMinute);
    triggerOnChange(hour, newMinute, period);
  };

  const handlePeriodChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newPeriod = e.target.value;
    setPeriod(newPeriod);
    triggerOnChange(hour, minute, newPeriod);
  };

  const triggerOnChange = (h: string, m: string, p: string) => {
    if (onChange) {
      onChange(`${h}:${m} ${p}`);
    }
  };

  return (
    <div className={`flex justify-between items-center bg-input-box-gray text-primary-white rounded-[.875rem] px-3 w-full h-full ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}>
      <select
        value={hour}
        onChange={handleHourChange}
        disabled={disabled} // Disable when the prop is passed
        className='border-none bg-input-box-gray text-primary-white'
      >
        {Array.from({ length: 12 }, (_, i) => (
          <option key={i + 1} value={String(i + 1).padStart(2, '0')}>
            {String(i + 1).padStart(2, '0')}
          </option>
        ))}
      </select>
      :
      <select
        value={minute}
        onChange={handleMinuteChange}
        disabled={disabled} // Disable when the prop is passed
        className='border-none rounded-sm bg-input-box-gray text-primary-white'
      >
        {Array.from({ length: 60 }, (_, i) => (
          <option key={i} value={String(i).padStart(2, '0')}>
            {String(i).padStart(2, '0')}
          </option>
        ))}
      </select>
      <select
        value={period}
        onChange={handlePeriodChange}
        disabled={disabled} // Disable when the prop is passed
        className='border-none bg-input-box-gray text-primary-white'
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

export default TimePicker;
