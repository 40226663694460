import React, { ChangeEvent, useEffect, useState } from 'react'
import RadioInput from '../../../components/radioInput'
import Ticket from '../../../assets/Images/createEvent/ticket.svg'
import Promo from '../../../assets/Images/createEvent/promo.svg'
import PrimaryButton from '../../../components/primaryButton'
import ForwardButton from '../../../assets/Icons/forward/forward.svg'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { CreateEvent } from '../../../services/createEventService'
import TicketPromoModal from '../../../components/ticketModal'
import CancelIcon from "../../../assets/Icons/refine/cancel.svg";
import HostDatePicker from '../../../components/hostDatePicker/Index'
import TimePicker from '../../../components/timePicker'
import { eventDetailService } from '../../../services/eventDetailServices'
import { getLocalStorage } from '../../../utils/localStorage'
import { LOCAL_STORAGE } from '../../../utils/constants'
import { convertToUtcDateTime, convertUtcToLocal, decodeBase64, getCurrentDateTimeInUtc, getDayOfWeek, getMonthAndDay, getTime, utcToLocalDate, utcToLocalTime } from '../../../utils/common'
import SearchSelect from '../../../components/search'
import DeleteIcon from '../../../assets/Icons/delete/delete.svg'
import EditIcon from '../../../assets/Icons/edit/edit.svg'
import { toast } from "react-toastify";
import { useSpinner } from "../../../components/spinner/SpinnerContext";
import moment from 'moment-timezone'
import CustomRadio from '../../../components/custom-radio'



const TicketsAndPromo = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const eventId: any = queryParams.get("eventid");
    const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
    const [eventData, setEventData] = useState<any>()
    const [selectedOption, setSelectedOption] = useState('Free Event');
    const { setShowSpinner } = useSpinner();
    const [showTicketModal, setShowTicketModal] = useState(false)
    const [eventDetails, setEventDetails] = useState<Event | any>();
    const [showPromoModal, setShowPromoModal] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [ticketDetails, setTicketDetails] = useState([])
    const [promoDetails, setPromoDetails] = useState([])
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [ticketType, setTicketType] = useState([])
    const [selectedTicketTypesInPromo, setSelectedTicketTypesInPromo] = useState<any>([]);
    const [showWarning, setShowWarning] = useState(false)
    const [showAdvanceSettings, setShowAdvanceSettings] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isUpdatingTicket, setIsUpdatingTicket] = useState(false);
    const [ticketForm, setTicketForm] = useState<any>({
        ticketName: '',
        ticketPrice: '',
        totalTickets: '',
        description: '',
        MinorderQuantity: '',
        MaxorderQuantity: '',
        is_free: false,
        is_password_protected: false,
        password: ''
    })
    const [promoCodeForm, setPromoCodeForm] = useState<any>({
        promoCodeName: '',
        is_apply_for_all: false,
        ticketPercentage: '',
        usageLimit: ''
    })
    const [errors, setErrors] = useState<any>({});
    useEffect(() => {

        getHostEventDtls()
        getTicketsDetails()
        getPromoDetails()
    }, [selectedOption, showTicketModal, showPromoModal])

    useEffect(() => {
        fetchEvents()
    }, [])

    useEffect(() => {
        if (eventData) {
            setSelectedOption(eventData?.event_type)
        }
    }, [eventData])


    const fetchEvents = async () => {
        try {
            const res = await CreateEvent.fetchEventById({ id: eventId })
            if (res?.data) {
                setEventData(res?.data?.event)
            }
        } catch (error) {
            console.log(error, 'Error in fetching events')
        }
    }

    const getHostEventDtls = async () => {
        try {
            const res = await eventDetailService.getHostEventDetails({ eventId, userId });
            if (res?.data) {
                setEventDetails(res?.data?.events);
            }
        } catch (error: any) {
            console.log("error getHostEventDtls : ", error?.response?.data?.error || error?.message);
        }
    }

    const getTicketsDetails = async () => {
        try {
            const res = await CreateEvent.getEventTicket({ id: parseInt(eventId) })
            if (res?.data) {
                setTicketDetails(res?.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getPromoDetails = async () => {
        try {
            const res = await CreateEvent.getPromoCodes({ eventId: parseInt(eventId) })
            if (res?.data) {
                setPromoDetails(res?.data?.eventPromoCodeResponse)
            }
        }
        catch (error) {
            console.log(error)
        }
    }



    const handleTicketTypeChange = (selectedOptions: any) => {
        setSelectedTicketTypesInPromo(selectedOptions);
        setTicketType(selectedOptions);
    };

    const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Free Event" && ticketDetails?.length > 0) {
            setShowWarning(true)
        }
        setSelectedOption(event.target.value);
    };

    const handleWarning = async () => {
        const params = {
            eventId: parseInt(eventId),
            event_type: 'Free Event'
        }
        const res = await CreateEvent.updateEventType(params)
        if (res?.data) {
            setShowWarning(false)
        }
    }

    const handleTicketFormChange = (e: any) => {
        const { name, value } = e.target;
        setTicketForm({
            ...ticketForm,
            [name]: value,
        });
    }

    const handleIsFreeChange = () => {
        setTicketForm((prevForm: any) => ({
            ...prevForm,
            is_free: !prevForm.is_free,
        }));
    };

    const handleIsPasswordProtected = () => {
        setTicketForm((prevForm: any) => ({
            ...prevForm,
            is_password_protected: !prevForm.is_password_protected,
        }));
    };

    const handlePromoCodeFormChange = (e: any) => {
        const { name, value } = e.target;
        setPromoCodeForm({
            ...promoCodeForm,
            [name]: value,
        });
    }

    const validateTicketForm = () => {
        let errors: any = {};

        if (!ticketForm.ticketName) {
            errors.ticketName = 'Ticket name is required';
        }


        if (!ticketForm.is_free && !ticketForm.ticketPrice) {
            errors.ticketPrice = 'Ticket price is required';
        } else if (!ticketForm.is_free && !/^\d+$/.test(ticketForm.ticketPrice)) {
            errors.ticketPrice = 'Ticket price must be a number';
        }

        if (!ticketForm.totalTickets) {
            errors.totalTickets = 'Ticket limit is required';
        } else if (!/^\d+$/.test(ticketForm.totalTickets)) {
            errors.totalTickets = 'Ticket limit must be a number';
        }

        if (ticketForm.MinorderQuantity && !/^\d+$/.test(ticketForm.MinorderQuantity)) {
            errors.MinorderQuantity = 'Minimum order quantity must be a number';
        }

        if (ticketForm.MaxorderQuantity && !/^\d+$/.test(ticketForm.MaxorderQuantity)) {
            errors.MaxorderQuantity = 'Maximum order quantity must be a number';
        }

        if (ticketForm.is_password_protected) {
            if (!ticketForm.password) {
                errors.password = 'Password is required';
            } else if (ticketForm.password.length < 1 || ticketForm.password.length > 20) {
                errors.password = 'Password must be between 1 and 20 characters';
            }
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const validatePromoCodeForm = () => {
        let errors: any = {};

        if (!promoCodeForm.promoCodeName) {
            errors.promoCodeName = 'Promo code name is required';
        }
        if (ticketType.length <= 0 && !promoCodeForm.is_apply_for_all) {
            errors.ticketType = 'Select a ticket type';
        }


        if (!promoCodeForm.ticketPercentage) {
            errors.ticketPercentage = 'Discount percentage is required';
        } else if (!/^\d+$/.test(promoCodeForm.ticketPercentage)) {
            errors.ticketPercentage = 'Discount must be a number';
        }

        if (!promoCodeForm.usageLimit) {
            errors.usageLimit = 'Ticket limit is required';
        } else if (!/^\d+$/.test(promoCodeForm.usageLimit)) {
            errors.usageLimit = 'Ticket limit must be a number';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    console.log(promoCodeForm, "-------49")


    const submitTicketType = async (e: any) => {
        e.preventDefault()
        try {
            const params = {
                eventId: parseInt(eventId),
                event_type: selectedOption
            }
            const res = await CreateEvent.updateEventType(params)
            if (selectedOption === 'Ticketed Event' && ticketDetails?.length <= 0) {
                toast.error("Add atleast one ticket")
                return;
            }
            if (res?.data) {
                console.log(res.data.message)
                navigate({
                    pathname: "/create-event/general-settings",
                    search: createSearchParams({
                        eventid: eventId
                    }).toString()
                })
            }
        } catch (error) {
            console.log('error submitting date : ')
        }
    }

    console.log(ticketForm, "----------260")

    const onTicketSubmit = async (e: any) => {
        e.preventDefault();

        if (!validateTicketForm()) {
            return;
        }

        setIsUpdatingTicket(false);

        setShowSpinner(true)

        // Get the current date and time
        const currentDateTime = getCurrentDateTimeInUtc(eventDetails?.timezone)
        console.log(startDate, startTime, eventDetails?.startTime, "---------------182")
        // Convert start and end times to UTC for comparison

        const momentStartDate = moment.tz(startDate, eventDetails?.timezone);
        const momentEndDate = moment.tz(endDate, eventDetails?.timezone);
        // Ensure the start and end datetime strings are correctly formatted for the timezone
        const startDatetimeString = `${momentStartDate.format('YYYY-MM-DD')} ${startTime}`;
        const endDatetimeString = `${momentEndDate.format('YYYY-MM-DD')} ${endTime}`;

        const saleStartDate = moment.tz(startDatetimeString, "YYYY-MM-DD hh:mm A", eventDetails?.timezone);
        const saleEndDate = moment.tz(endDatetimeString, "YYYY-MM-DD hh:mm A", eventDetails?.timezone);
        const currentDatetime = moment.tz(eventDetails?.timezone).format("YYYY-MM-DD hh:mm A");

        const eventStartDate = moment(eventDetails?.startTime)
        const eventEndDate = moment(eventDetails?.endTime)


        // Validate event date and time

        if (saleStartDate.isBefore(currentDatetime)) {
            toast.error("Cannot create or update Tickets in the past");
            setShowSpinner(false)
            return;
        }

        if (saleEndDate.isAfter(eventEndDate)) {
            toast.error('You cant create ticket after event ends')
            setShowSpinner(false)
            return;
        }

        if (saleStartDate.isAfter(eventEndDate)) {
            toast.error('You cant create ticket after event ends')
            setShowSpinner(false)
            return;
        }


        // if (saleStartDate.isAfter(eventStartDate)) {
        //     toast.error('You cant create ticket after event starts')
        //     setShowSpinner(false)
        //     return;
        // }

        if (saleStartDate.isSameOrAfter(saleEndDate)) {
            console.log(saleStartDate, saleEndDate)
            toast.error("Start time cannot be after or the same as end time");
            setShowSpinner(false)
            return;
        }

        const commonParams = {
            ticketName: ticketForm.ticketName,
            ticketPrice: parseInt(ticketForm.ticketPrice),
            totalTickets: parseInt(ticketForm.totalTickets),
            description: ticketForm.description,
            saleStartTime: startDate ? convertToUtcDateTime(startDate, startTime, eventDetails?.timezone) : currentDateTime,
            saleEndTime: endDate && convertToUtcDateTime(endDate, endTime, eventDetails?.timezone),
            eventId: parseInt(eventId),
            is_free: ticketForm.is_free,
            is_password_protected: ticketForm.is_password_protected,
            password: ticketForm.password,
            MinorderQuantity: ticketForm.MinorderQuantity ? parseInt(ticketForm.MinorderQuantity) : 1,
            MaxorderQuantity: ticketForm.MaxorderQuantity ? parseInt(ticketForm.MaxorderQuantity) : 5
        };

        try {
            let res: any;
            if (ticketForm?.ID) {
                // Update existing ticket
                const updateParams = { ...commonParams, ID: ticketForm.ID };
                res = await CreateEvent.updateTicket(updateParams);

                if (res?.data) {
                    toast.success('Ticket Updated Sucessfully')
                    setShowSpinner(false)
                    setShowTicketModal(false);
                    getTicketsDetails();
                }
            } else {
                // Create new ticket
                res = await CreateEvent.createEventTicket(commonParams);

                if (res?.data) {
                    toast.success('Ticket Created Sucessfully')
                    setShowSpinner(false)
                    setShowTicketModal(false);
                    getTicketsDetails();
                }
            }
            setShowSpinner(false)

        } catch (error: any) {
            console.log(error);
            toast.error(error?.response?.data?.error || error?.message)
        }
    };

    console.log(ticketType, "---------332")

    // const calculatePrice = (selectedOptions:any) => {
    //     if (!selectedOptions.length) {
    //       setTotalAmount(0);
    //       return;
    //     }

    //     const selectedTicket = selectedOptions[0]; // Assuming single selection for simplicity
    //     const ticketDetail : any = ticketDetails.find((ticket:any) => ticket?.ID === selectedTicket.value);

    //     console.log(ticketDetail,selectedOptions, "---------347")

    //     if (!ticketDetail) {
    //       setTotalAmount(0);
    //       return;
    //     }

    //     const ticketPrice = ticketDetail?.ticketPrice;
    //     const ticketCount = promoCodeForm.ticketLimit
    //     const otherFee = 1;
    //     const handlingFee = 10;

    //     if (ticketPrice !== 0) {
    //       let basePrice = ticketPrice + otherFee;
    //       const handlingFeePercentage = (basePrice * handlingFee) / 100;
    //       basePrice += handlingFeePercentage;

    //       const calculatedTotalAmount = (basePrice * ticketCount).toFixed(2);
    //       setTotalAmount(+calculatedTotalAmount);
    //     } else {
    //       setTotalAmount(0);
    //     }
    //   };

    const onPromoSubmit = async (e: any) => {
        e.preventDefault()

        setIsUpdating(false);

        if (!validatePromoCodeForm()) {
            return;
        }

        try {
            const eventPromoCode: any[] = [];
            ticketType?.forEach((option: any) => {
                eventPromoCode.push({ ticketTypeId: option.value });
            });

            if (promoCodeForm?.ID) {
                const params = {
                    id: promoCodeForm?.ID,
                    eventId: parseInt(eventId),
                    eventPromoCode: promoCodeForm.is_apply_for_all ? [] : eventPromoCode,
                    is_apply_for_all: promoCodeForm.is_apply_for_all,
                    promoCodeName: promoCodeForm.promoCodeName,
                    ticketPercentage: parseInt(promoCodeForm.ticketPercentage),
                    usageLimit: parseInt(promoCodeForm.usageLimit)
                }
                const res = await CreateEvent.updatePromo(params)
                if (res?.data) {
                    toast.success('Promo Updated Sucessfully')
                    setShowPromoModal(false)
                }
            } else {
                const params = {
                    eventId: parseInt(eventId),
                    eventPromoCode: promoCodeForm.is_apply_for_all ? [] : eventPromoCode,
                    is_apply_for_all: promoCodeForm.is_apply_for_all,
                    promoCodeName: promoCodeForm.promoCodeName,
                    ticketPercentage: parseInt(promoCodeForm.ticketPercentage),
                    usageLimit: parseInt(promoCodeForm.usageLimit)
                }
                const res = await CreateEvent.createPromoCode(params)
                if (res?.data) {
                    toast.success('Promo Created Sucessfully')
                    setShowPromoModal(false)
                }
            }
        } catch (error: any) {
            console.log(error)
            toast.error(error?.response?.data?.error || error?.message)
        }
    }



    const deleteEventTicket = async (id: any) => {
        try {
            setShowSpinner(true)
            const res: any = await CreateEvent.deleteTicket({ ID: id })

            if (res?.data) {
                console.log(res?.data, "---170")
                getTicketsDetails()
                setShowSpinner(false)
            } else if (res?.code === 500) {
                toast.error(res?.error)
            }

        } catch (error: any) {
            console.log(error)
            toast.error(error?.response?.data?.error || error?.message)
        }
        setShowSpinner(false)
    }


    const deleteEventPromo = async (id: any) => {
        try {
            setShowSpinner(true)
            const res = await CreateEvent.deletePromo({ ID: id })
            if (res?.data) {
                console.log(res?.data, "---170")
                getPromoDetails()
                setShowSpinner(false)
            }
        } catch (error: any) {
            console.log(error)
            toast.error(error?.response?.data?.error || error?.message)
        }
        setShowSpinner(false)
    }

    const onEditTicketClick = (ticket: any) => {
        const decodedTicket = {
            ...ticket,
            password: ticket.password ? decodeBase64(ticket.password) : ''
        };
        // Log the ticket details to ensure they are correct


        const localStartDate = utcToLocalDate(ticket?.saleStartTime, eventDetails?.timezone);
        const localEndDate = utcToLocalDate(ticket?.saleEndTime, eventDetails?.timezone);

        console.log("Local Start Date:", localStartDate);
        console.log("Local End Date:", localEndDate);

        setStartDate(localStartDate);
        setStartTime(utcToLocalTime(ticket?.saleStartTime, eventDetails?.timezone));
        setEndDate(localEndDate);
        setEndTime(utcToLocalTime(ticket?.saleEndTime, eventDetails?.timezone));

        console.log("Decoded Ticket:", decodedTicket);
        setTicketForm(decodedTicket);
        setShowAdvanceSettings(false);
        setShowTicketModal(true);
        setIsUpdatingTicket(true);
    }

    const onEditPromoClick = (promo: any) => {
        setPromoCodeForm(promo);
        setSelectedTicketTypesInPromo(promo?.eventPromoCode?.map((item: any) => ({
            value: item?.ID,
            label: item?.ticketName,
        })));
        setIsUpdating(true);
        setShowPromoModal(true)
    }



    const options = ticketDetails
        ?.filter((item: any) => item?.ticketPrice > 0)
        .map((item: any) => ({
            value: item?.ID,
            label: item?.ticketName,
        }));




    return (
        <div>
            <form onSubmit={submitTicketType}>
                <div>
                    <label className='text-primary-white text-lg'>Select event ticket type</label>
                    <div className='flex gap-4 mt-2'>
                        <RadioInput heading='Ticketed' description='Tickets are required for entry' value='Ticketed Event' option={selectedOption} optionChange={handleOptionChange} />
                        <RadioInput heading='Free' description='No tickets required for entry' value='Free Event' option={selectedOption} optionChange={handleOptionChange} />
                    </div>
                </div>

                {selectedOption === 'Ticketed Event' &&
                    <div className='my-8'>
                        <span className='text-primary-white text-lg'>Select event ticket type</span>

                        {ticketDetails?.length <= 0 ? (
                            <div className='w-full bg-input-box-gray border-0 rounded-2xl flex flex-col items-center mt-2 py-8'>
                                <img src={Ticket} />
                                <p className='text-sm text-[#414141]'>Added tickets will appear here</p>
                            </div>
                        ) : (
                            <>
                                {ticketDetails.map((data: any) => (
                                    <div className='w-full bg-input-box-gray border-0 rounded-2xl my-4'>
                                        <div className='flex justify-between p-4'>
                                            <div className='flex items-center font-medium text-primary-white gap-2'>
                                                <img src={Ticket} />
                                                <span className=' text-base'>{data?.ticketName}</span>
                                                {/* <span className='bg-[#444141] text-[9px] p-1 rounded-md'>Free events</span> */}
                                                <span className='bg-[#444141] text-[9px] p-1 rounded-md'>
                                                    {data.is_free ? 'Free events' : 'Ticketed events'}
                                                </span>
                                            </div>
                                            <div className='flex justify-end gap-2 w-5'>
                                                <img className='cursor-pointer' src={EditIcon} onClick={() => onEditTicketClick(data)} />
                                                <img className='cursor-pointer' src={DeleteIcon} onClick={() => { deleteEventTicket(data?.ID) }} />
                                            </div>
                                        </div>

                                        <div className='border-t border-[#343434] mx-4'></div>

                                        <div className='flex items-center justify-around gap-1 text-primary-white font-medium text-sm p-4'>
                                            <div>
                                                <span>{getDayOfWeek(convertUtcToLocal(data?.saleStartTime, eventDetails?.timezone))} {getMonthAndDay(convertUtcToLocal(data?.saleStartTime, eventDetails?.timezone))}</span>
                                                <p className='text-text-gray'>{getTime(convertUtcToLocal(data?.saleStartTime, eventDetails?.timezone))}</p>
                                            </div>
                                            -
                                            <div>
                                                <span>{getDayOfWeek(convertUtcToLocal(data?.saleEndTime, eventDetails?.timezone))} {getMonthAndDay(convertUtcToLocal(data?.saleEndTime, eventDetails?.timezone))}</span>
                                                <p className='text-text-gray'>{getTime(convertUtcToLocal(data?.saleEndTime, eventDetails?.timezone))}</p>
                                            </div>
                                            <div className='border-r border-[#343434] h-9'></div>
                                            <span>{data?.totalTickets} tickets</span>
                                            <div className='border-r border-[#343434] h-9'></div>
                                            <div className='flex gap-1'>
                                                <span>{data?.MinorderQuantity}-{data?.MaxorderQuantity}</span>
                                                <p className='text-text-gray'>Max puchase</p>
                                            </div>
                                            <div className='border-r border-[#343434] h-9'></div>
                                            <span className='font-medium text-base'>${data?.ticketPrice}</span>
                                        </div>

                                        <div className='border-t border-[#343434] mx-4'></div>

                                        <p className='text-text-gray font-medium text-sm p-4'>{data?.description}</p>
                                    </div>))}
                            </>
                        )}

                        <div className='flex justify-end mt-4'>
                            <button className='text-sm text-primary-white border-2 rounded-xl border-primary-orange bg-third-orange p-2' onClick={() => { setShowTicketModal(true); setTicketForm({}); setErrors({}); setShowAdvanceSettings(false); setIsUpdatingTicket(false); setStartTime(''); setEndTime(''); setStartDate(''); setEndDate('') }} type='button'>Add ticket type</button>
                        </div>
                    </div>}
                {selectedOption === 'Ticketed Event' &&
                    <div className='my-8'>
                        <span className='text-primary-white text-lg'>Promo code</span>
                        {promoDetails === undefined ? (
                            <div className='w-full bg-input-box-gray border-0 rounded-2xl flex flex-col items-center mt-2 py-8'>
                                <img src={Promo} />
                                <p className='text-sm text-[#414141]'>Added promo code will appear here</p>
                            </div>
                        ) : (
                            <React.Fragment>
                                {promoDetails?.map((data: any) => (
                                    <div className='w-full bg-input-box-gray border-0 rounded-2xl my-4'>
                                        <div className='flex justify-between p-4'>
                                            <div className='flex items-center font-medium text-primary-white gap-2'>
                                                <img src={Promo} />
                                                <span className=' text-base'>{data?.promoCodeName}</span>
                                                {/* <span className='bg-[#444141] text-[9px] p-1 rounded-md'>
                                                    {ticketForm.is_free ? 'Free events' : 'Ticketed events'}
                                                </span> */}
                                            </div>
                                            <div className='flex justify-end gap-2 w-5'>
                                                <img src={EditIcon} onClick={() => { onEditPromoClick(data) }} />
                                                <img src={DeleteIcon} onClick={() => { deleteEventPromo(data?.ID) }} />
                                            </div>
                                        </div>

                                        <div className='border-t border-[#343434] mx-4'></div>
                                        {data?.eventPromoCode?.map((record: any) => (
                                            <div className='flex items-center justify-around gap-1 text-primary-white font-medium text-sm p-4'>
                                                <div>
                                                    <span className='text-text-gray'>Promo code applies to </span>
                                                    <span>{record?.ticketName}</span>
                                                </div>
                                                <div className='border-r border-[#343434] h-9'></div>
                                                <div>
                                                    <span>%{data?.ticketPercentage} </span>
                                                    <span className='text-text-gray'>Discount</span>
                                                </div>
                                                <div className='border-r border-[#343434] h-9'></div>
                                                <div>
                                                    <span>{data?.usageLimit}</span>
                                                    <span className='text-text-gray'> Max usage</span>
                                                </div>
                                            </div>))}
                                    </div>))}
                            </React.Fragment>
                        )}

                        <div className='flex justify-end mt-4'>
                            <button className='text-sm text-primary-white border-2 rounded-xl border-primary-orange bg-third-orange p-2' type='button' onClick={() => {
                                if (ticketDetails?.length <= 0) {
                                    setShowPromoModal(false)
                                    toast.error("Add at least one ticket");
                                } else {
                                    setShowPromoModal(true);
                                    setSelectedTicketTypesInPromo([])
                                    setPromoCodeForm({})
                                    setIsUpdating(false)
                                }
                            }}>Add promo code</button>
                        </div>
                    </div>}

                <div className='border-t border-text-gray my-8'></div>
                <div className='flex place-content-between items-center my-8'>
                    <PrimaryButton name='Previous' type='button' classNames='px-2 py-3' onClick={() => navigate({
                        pathname: "/create-event/event-info",
                        search: createSearchParams({
                            eventid: eventId
                        }).toString()
                    })} />
                    <PrimaryButton name='Save & Next' icon={ForwardButton} type='submit' classNames='px-2 py-3' />
                </div>
            </form>
            {showTicketModal && <TicketPromoModal >
                <div className='px-4 text-sm '>
                    <div className='flex justify-between'>
                        <h1 className='lg:text-2xl'> {isUpdatingTicket ? 'Edit Ticket' : 'New Ticket'}</h1>
                        <img src={CancelIcon} onClick={() => setShowTicketModal(false)} className='cursor-pointer w-2 lg:w-5' />
                    </div>
                    <div className='border-t border-text-gray my-8'></div>
                    <form onSubmit={onTicketSubmit}>
                        <div>
                            <label className='text-primary-white lg:text-lg'>Ticket name*</label>
                            <div className='mt-2 mb-8'>
                                <input className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg ' placeholder='Enter ticket name' name='ticketName' value={ticketForm.ticketName} onChange={handleTicketFormChange} />
                                {errors.ticketName && <p className='text-primary-red'>{errors.ticketName}</p>}
                            </div>
                        </div>

                        <div className='w-full flex justify-between items-center py-2 px-4 bg-tertiary-gray border-0 rounded-2xl text-primary-white text-lg'>
                            <p>Make ticket free</p>
                            <CustomRadio value={ticketForm.is_free} onChange={handleIsFreeChange} />
                        </div>


                        <div className='lg:flex gap-4 my-8'>
                            <div className='lg:w-1/2'>
                                <label className='text-primary-white lg:text-lg'>Ticket price($)*</label>
                                <div className='mt-2'>
                                    <input className='bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg' placeholder='Eg. $200' name='ticketPrice' value={ticketForm?.is_free === true ? 0 : ticketForm.ticketPrice} onChange={handleTicketFormChange} />
                                    {errors.ticketPrice && <p className='text-primary-red'>{errors.ticketPrice}</p>}
                                </div>
                            </div>
                            <div className='lg:w-1/2'>
                                <label className='text-primary-white lg:text-lg'>Ticket limit*</label>
                                <div className='mt-2'>
                                    <input className='bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg' placeholder='Eg. 120' name='totalTickets' value={ticketForm?.totalTickets} onChange={handleTicketFormChange} />
                                    {errors.totalTickets && <p className='text-primary-red'>{errors.totalTickets}</p>}
                                </div>
                            </div>
                        </div>
                        {showAdvanceSettings && <React.Fragment>

                            <div className='w-full flex justify-between mt-8 items-center py-2 px-4 bg-tertiary-gray border-0 rounded-2xl text-primary-white text-lg'>
                                <p>Make ticket password protected</p>
                                <CustomRadio value={ticketForm.is_password_protected} onChange={handleIsPasswordProtected} />
                            </div>

                            {ticketForm.is_password_protected && <div className='mt-8'>
                                <label className='text-primary-white lg:text-lg'>Enter password</label>
                                <div className='mt-2 mb-8'>
                                    <input className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg ' placeholder='Enter Password' name='password' value={ticketForm.password} onChange={handleTicketFormChange} />
                                    {errors.password && <p className='text-primary-red'>{errors.password}</p>}
                                </div>
                            </div>}

                            <div className='my-8'>
                                <label className='text-primary-white lg:text-lg'>Description</label>
                                <div className='mt-2'>
                                    <textarea className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg ' placeholder='Enter description of the ticket' name='description' value={ticketForm.description} onChange={handleTicketFormChange} />
                                </div>
                            </div>


                            <div className='lg:flex gap-4 my-8'>
                                <div className='lg:w-1/2'>
                                    <span className='text-primary-white lg:text-lg'>Ticket Start Date</span>
                                    <div className='mt-2 relative'>
                                        <HostDatePicker dateProp={startDate} onDateSelect={(date) => { setStartDate(date) }} />
                                    </div>
                                </div>
                                <div className='lg:w-1/2'>
                                    <label className='text-primary-white lg:text-lg'>Time</label>
                                    <div className='mt-2'>
                                        <TimePicker eventTime={startTime} onChange={(value) => setStartTime(value)} />
                                    </div>
                                </div>
                            </div>

                            <div className='lg:flex gap-4 my-8'>
                                <div className='lg:w-1/2'>
                                    <label className='text-primary-white lg:text-lg'>Ticket End Date</label>
                                    <div className='mt-2 relative'>
                                        <HostDatePicker dateProp={endDate} onDateSelect={(date) => { setEndDate(date) }} />
                                    </div>
                                </div>
                                <div className='lg:w-1/2'>
                                    <label className='text-primary-white lg:text-lg'>Time</label>
                                    <div className='mt-2'>
                                        <TimePicker eventTime={endTime} onChange={(value) => setEndTime(value)} />
                                    </div>
                                </div>
                            </div>

                            <div className='my-8'>
                                <label className='text-primary-white lg:text-lg'>Min max order</label>
                                <div className='lg:flex gap-4'>
                                    <div className='lg:w-1/2'>
                                        <div className='mt-2'>
                                            <input className='bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg' placeholder='1' name='MinorderQuantity' value={ticketForm.MinorderQuantity} onChange={handleTicketFormChange} />
                                            {errors.MinorderQuantity && <p className='text-primary-red'>{errors.MinorderQuantity}</p>}
                                        </div>
                                    </div>
                                    <div className='lg:w-1/2'>
                                        <div className='mt-2'>
                                            <input className='bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg' placeholder='10' name='MaxorderQuantity' value={ticketForm.MaxorderQuantity} onChange={handleTicketFormChange} />
                                            {errors.MaxorderQuantity && <p className='text-primary-red'>{errors.MaxorderQuantity}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}

                        <div className='border-t border-text-gray'></div>
                        <div className='flex justify-between items-center my-8' >
                            <PrimaryButton name='Advanced Settings' type='button' onClick={() => setShowAdvanceSettings(!showAdvanceSettings)} classNames='px-2 py-3' />
                            <PrimaryButton name='Save & Next' icon={ForwardButton} type='submit' classNames='px-2 py-3' />
                        </div>
                    </form>
                </div>
            </TicketPromoModal>}

            {showPromoModal && <TicketPromoModal>
                <div className='px-4 text-sm'>
                    <div className='flex justify-between'>
                        <h1 className='lg:text-2xl'>{isUpdating ? 'Edit Promocode' : 'New promocode'}</h1>
                        <img src={CancelIcon} onClick={() => setShowPromoModal(false)} className='cursor-pointer w-2 lg:w-5' />
                    </div>
                    <div className='border-t border-text-gray my-8'></div>
                    <form onSubmit={onPromoSubmit}>
                        <div>
                            <label className='text-primary-white lg:text-lg'>Promo code name*</label>
                            <div className='mt-2'>
                                <input className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg ' placeholder='Enter promo code name' name='promoCodeName' value={promoCodeForm.promoCodeName} onChange={handlePromoCodeFormChange} />
                                {errors.promoCodeName && <p className='text-primary-red'>{errors.promoCodeName}</p>}
                            </div>
                        </div>
                        <div className='my-4 w-full flex gap-4 items-center py-2 border-0 rounded-2xl text-primary-white text-lg'>
                            <p>Apply for all tickets</p>
                            <CustomRadio
                                value={promoCodeForm.is_apply_for_all}
                                onChange={() => {
                                    const newValue = !promoCodeForm.is_apply_for_all;
                                    setPromoCodeForm({ ...promoCodeForm, is_apply_for_all: newValue });

                                    if (!newValue) {
                                        setSelectedTicketTypesInPromo([]);
                                        setTicketType([]);
                                        validatePromoCodeForm();
                                    }
                                }}
                            />
                        </div>
                        {!promoCodeForm.is_apply_for_all && <div className='my-4'>
                            <label className='text-primary-white lg:text-lg'>Ticket type*</label>
                            <div className="w-full flex justify-between gap-1.5">
                                <SearchSelect
                                    className=" w-full"
                                    options={options}
                                    onChange={handleTicketTypeChange}
                                    isMulti={true}
                                    value={selectedTicketTypesInPromo}
                                />
                            </div>
                            {errors.ticketType && <p className='text-primary-red'>{errors.ticketType}</p>}
                        </div>}
                        <div className='lg:flex gap-4 my-4'>
                            <div className='lg:w-1/2'>
                                <label className='text-primary-white lg:text-lg'>Discount %*</label>
                                <div className='mt-2'>
                                    <input className='bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg' placeholder='Specify percentage' name='ticketPercentage' value={promoCodeForm.ticketPercentage} onChange={handlePromoCodeFormChange} />
                                    {errors.ticketPercentage && <p className='text-primary-red'>{errors.ticketPercentage}</p>}
                                </div>
                            </div>
                            <div className='lg:w-1/2'>
                                <label className='text-primary-white lg:text-lg'>Ticket limit*</label>
                                <div className='mt-2'>
                                    <input className='bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg' placeholder='Enter limit' name='usageLimit' value={promoCodeForm.usageLimit} onChange={handlePromoCodeFormChange} />
                                    {errors.usageLimit && <p className='text-primary-red'>{errors.usageLimit}</p>}
                                </div>
                            </div>
                        </div>
                        <div className='border-t border-text-gray'></div>
                        <div className='flex place-content-end items-center my-8' >
                            <PrimaryButton name={isUpdating ? 'Edit Promo Code' : 'Create Promo Code'} icon={ForwardButton} type='submit' classNames='px-2 py-3' />
                        </div>
                    </form>
                </div>
            </TicketPromoModal>}

            {showWarning && <TicketPromoModal>
                <div className='text-center'>
                    <span className='text-lg'>Are you sure you would like to switch to free event</span>
                    <p className='text-xs text-text-gray'>All tickets will be permanently removed from this event</p>
                    <p className='text-xs  text-text-gray'>This action cannot be undone.</p>
                    <div className='flex gap-2 justify-center mt-4'>
                        <PrimaryButton name='No' onClick={() => { setShowWarning(false); setSelectedOption('Ticketed Event') }} classNames='px-4 py-2' />
                        <PrimaryButton name='Yes' onClick={handleWarning} classNames='px-4 py-2' />
                    </div>
                </div>
            </TicketPromoModal>}

        </div>
    )
}

export default TicketsAndPromo