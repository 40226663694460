import Axios from '../utils/axiosInstance';
import qs from 'qs';

const getTicketsToBuy = (params: {}) => {
    return Axios.get(`getTicketsToBuy${params ? '?' + qs.stringify(params) : ''}`);
}

const checkPromCode = (params: {}) => {
    return Axios.get(`validatePromocode${params ? '?' + qs.stringify(params) : ''}`);
}

const purchaseTickets = (data: any) => {
    return Axios.post('purchaseTickets', data)
}

const updatePaymentStatus = (data: any) => {
    return Axios.post('update-payment-status', data)
}

const redeemTicket = (params: {}) => {
    return Axios.get(`redeemTicket${params ? '?' + qs.stringify(params) : ''}`);
}

const requestPayoutEmail = (data: any) => {
    return Axios.post('event/requestPayoutEmail', data)
}

const payoutRecords = (params: {}) => {
    return Axios.get(`event/records${params ? '?' + qs.stringify(params) : ''}`);
}

export const PayoutService = {
    checkPromCode,
    getTicketsToBuy,
    purchaseTickets,
    updatePaymentStatus,
    redeemTicket,
    requestPayoutEmail,
    payoutRecords
}