
 const getLocalStorage = (key:string) => {
    try {
        return localStorage.getItem(key) || '';
    } catch (err) {
        console.log(err);
    }
};

const setLocalStorage = (key: string, value: string) => {
    try {
        return localStorage.setItem(key, value);
    } catch (err) {
        console.log(err);
    }
}
const removeLocalStorage = (key: string) => {
    try {
        return localStorage.removeItem(key);
    } catch (err) {
        console.log(err);
    }
}


export  {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage
}