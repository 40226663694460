import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { getLocalStorage } from "./localStorage";
import { LOCAL_STORAGE } from "./constants";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  headers: any;
}


const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;

export const createInstance = (version: string) => {
  const instance = axios.create({
    baseURL: version === 'v2' ? BASE_URL_V2 : BASE_URL,
    headers: { 'Content-Type': 'application/json' }
  });
  instance.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {

    const token = getLocalStorage(LOCAL_STORAGE.USER_TOKEN);
    if (token) {
      if (config.headers)
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
    (error) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (error: AxiosError) => {
      // toast.error(error.message);
      return Promise.reject(error);
    }
  );
  return instance;
};

export default createInstance('default')