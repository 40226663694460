import React, { ReactNode, useState } from 'react';
import styles from  '../../components/modal/Modal.module.scss'
import CLOSE_ICON  from '../../assets/Icons/nav/x.png';
import PrimaryButton from '../primaryButton';
import CreateIcon from "../../assets/Icons/refine/create.svg";

interface props{
  children: ReactNode
  
}

const TicketPromoModal = ({children}: props) => {
  return (
      <div className={`${styles.popupOuter} backdrop-blur-xl bg-white/30 z-[500] `}>
      <div className={styles.popupOverlay}></div>
      <div className={styles.popupContent} >
        <div className={`${styles.sideNav} ${styles.animateNav} bg-button-gray pb-6 `}>
          {/* <div className='flex justify-end w-full px-3 mt-2'>
            <img src={CLOSE_ICON} alt='close' className='size-5 cursor-pointer' onClick={onClose}/>
          </div> */}
          <div className='px-8 py-7 my-4 overflow-y-auto max-h-[90vh] scrollableDiv' >
            {children}
          </div>
        </div>
      </div>
    </div>);
  
};

export default TicketPromoModal;