import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

type props = {
  onSliderChange?: (a: any) => void;
  value?: any;
};

function RangeSlider({ onSliderChange, value }: props) {
  const trackStyle = { backgroundColor: "rgba(255, 138, 0, 1)", height: 7 };
  const handleStyle = [
    {
      backgroundColor: "black",
      borderColor: "white",
      width: 20,
      height: 20,
      marginTop: -7,
      borderWidth: 3,
    },
    {
      backgroundColor: "black",
      borderColor: "white",
      width: 20,
      height: 20,
      marginTop: -7,
      borderWidth: 3,
    },
  ];

  return (
    <div className="w-full">
      <Slider
        range
        trackStyle={[trackStyle]}
        handleStyle={handleStyle}
        railStyle={{ backgroundColor: "#222222", height: 7 }}
        onChange={onSliderChange}
        min={0}
        max={1000}
        value={value}
      />
    </div>
  );
}

export default RangeSlider;
