import Axios from '../utils/axiosInstance'
import qs from 'qs';
import { LOCAL_STORAGE } from '../utils/constants';
import { getLocalStorage } from '../utils/localStorage';
const BASE_URL_V1 = process.env.REACT_APP_BASE_URL;

const fetchTextBlasts = (params: {}) => {
    return Axios.get(`fetchCampains${params ? '?'+ qs.stringify(params) : ''}`)
}

const hostedEvents = (params: {}) => {
    return Axios.get(`hostedEvents${params ? '?'+ qs.stringify(params) : ''}`)
}

const pastInvitedGuest = (params = {}) => {
    const queryParams = qs.stringify(params, { arrayFormat: 'repeat' });
    return Axios.get(`pastInvitedGuest${queryParams ? '?' + queryParams : ''}`);
  };

  const importContacts = async (data: FormData) => {
    const token = getLocalStorage(LOCAL_STORAGE.USER_TOKEN);
    try {
        const response = await fetch(`${BASE_URL_V1}importContacts`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}` // Replace `token` with your actual token variable
            },
            body: data
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Assuming the response is in JSON format
        const result = await response.json();
        return result;

    } catch (error) {
        console.error('Error importing contacts:', error);
        // Optionally handle the error further or return it
        throw error;
    }
}

const inviteGuests = async (data:any) => {
    return Axios.post('inviteGuests', data)
}

export const TextBlastService = {
    fetchTextBlasts,
    hostedEvents,
    pastInvitedGuest,
    importContacts,
    inviteGuests
}