import React, { useEffect, useState } from 'react';
import { Event } from '../../../utils/Interfaces';
import { CometChat } from '@cometchat-pro/chat';
import { getLocalStorage } from '../../../utils/localStorage';
import { LOCAL_STORAGE } from '../../../utils/constants';
import { CometChatMessages } from '../../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';

interface Props {
  event?: Event;
}

const GroupChat: React.FC<Props> = ({ event }) => {
  const AUTH_KEY = process.env.REACT_APP_COMETCHAT_AUTH_ID;
  const userID: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [GUID, setGUID] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (event) {
      fetchGroupChat();
    }
  }, [event]);

  const fetchGroupChat = async () => {
    try {
      const user = await CometChat.login(userID, AUTH_KEY);
      console.log('Login successful: ', user);
      setUser(user);
      if (event) {
        try {
          const group = await CometChat.getGroup(event.eventId.toString());
          setGUID(group);
          console.log("Group details fetched successfully:", group);
        } catch (error) {
          console.log("Group details fetching failed with exception:", error);
        }
      }
    } catch (err) {
      console.log('Login failed: ', err);
    }
  };

  return (
    <div className='w-full md:w-[50vw] lg:w-[35vw] h-[63vh] md:h-[80vh] xl:h-[70vh] overflow-y-auto scrollableDiv'>
      {GUID ? (
        <CometChatMessages chatWithGroup={GUID} hideChat={userID == event?.hostUserId ? false : event?.chat_settings == 'Host And Guest' ? false : true} />
      ) : (
        <p>Loading chat...</p>
      )}
    </div>
  );
};

export default GroupChat;
