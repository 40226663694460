import React, { useEffect } from "react";
import styles from './Eula.module.scss'

export default function Eula() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsHeader}>
        <h1>SOCHA™ MOBILE APPS</h1>
        <h1>MOBILE APPLICATION END USER LICENSE AGREEMENT</h1>
        <em>Last revised: June 7, 2023</em>
      </div>
      <div className={styles.termsContent}>
        <p>
          This Mobile Application End User License Agreement (<b>“License”</b>)
          is an agreement between you, as the end user, and Sondr, Inc. (
          <b>“Sondr,”</b> "we," "us," or "our"). This License governs your use
          of any and all of the mobile applications published by Sondr,
          including without limitation the Socha™ mobile application, and any
          and all related documentation, updates, patches and upgrades that
          replace or supplement any or all of the mobile applications in any
          respect and which are not distributed with a separate license
          (individually and/or collectively, the <b>“Mobile App”</b> or{" "}
          <b>“Mobile Apps”</b>). Except for Section 23 that applies only to the
          subscription fee-based services (<b>“Socha Premium”</b>) provided
          through the Mobile Apps, this License applies to any and all Socha™
          Mobile Apps.
        </p>
        <p>
          <b>
            BY DOWNLOADING THIS MOBILE APP FROM SONDR’S WEBSITE OR FROM AN
            MOBILEAPP STORE (AS DEFINED BELOW) OR CLICKING ON THE "ACCEPT"
            BUTTON BELOW YOU AGREE TO THE TERMS AND CONDITIONS OF THIS LICENSE,
            STATED BELOW, WHICH WILL BIND YOU. FURTHERMORE, THIS ALSO INCLUDES
            YOUR ACCEPTANCE OF SONDR’S PRIVACY POLICY AND TERMS OF SERVICE AS
            MADE AVAILABLE ON SONDR’S WEBSITE. IF YOU DO NOT AGREE TO THE TERMS
            AND CONDITIONS OF THIS LICENSE, SONDR WILL NOT LICENSE THE MOBILE
            APP TO YOU AND YOU MUST STOP THE DOWNLOADING OR STREAMING PROCESS
            (AS APPLICABLE) NOW.
          </b>
        </p>
        <p>
          Your use of the Mobile App requires that you have an account with
          Sondr and agree to Sondr’s Terms of Service (<b>“Terms of Service”</b>
          ) and Privacy Policy (<b>“Privacy Policy”</b>), each posted at:
          https://www.joinsocha.com/terms and https://www.joinsocha.com/privacy.
          To open an account, please go to https://joinsocha.com/registration on
          Sondr’s website.{" "}
        </p>
        <p>
          By consenting to this License, downloading and/or using the Mobile
          App, or allowing it to be downloaded and/or used, you acknowledge
          that:
        </p>
        <p>
          (a) You are at least 18 years of age or any other legal age required
          to form a contract in your jurisdiction;
        </p>
        <p>
          (b) You have the right, authority and legal capacity to enter into
          this License;
        </p>
        <p>
          (c) You have read, understood and agree to be bound by the terms and
          conditions of this License; and
        </p>
        <p>
          (d) You have read, understood and agree to be bound by the Terms of
          Service and Privacy Policy.
        </p>
        <p>
          If you do not agree with the terms and conditions of the License,
          Terms of Service and Privacy Policy, then do not download or use the
          Mobile App and delete any copies installed on your devices. Your
          agreement with regards to this License becomes effective immediately
          upon installation and/or use of the Mobile App, and shall end on the
          earliest date on which you dispose of the Mobile App or Sondr’s
          termination of this License. This License may be amended from time to
          time, therefore you agree to review it frequently in the{" "}
          <b>“Profile”</b> section of the Mobile App or on our website at
          https://www.joinsocha .com/EULA. Your continued use of the Mobile App
          will signify your acceptance of the changes to this License.
        </p>
        <p>
          The Mobile App consists of: (a) all software, code, text, graphics,
          logos, layouts, designs, interfaces, and other items included in or
          associated with the Mobile App; and (b) any files that are delivered
          to you by Sondr (via online transmission, through a third party
          distributor, or otherwise) to patch, update, or otherwise modify the
          Mobile App.
        </p>
        <p>
          The Mobile App may provide a means for you to access one or more
          websites, other Mobile Apps, or other products and services offered by
          Sondr (collectively, the <b>"Services"</b>), and audio, video and
          audio-video materials, photographs, text, graphics, logos, layouts,
          designs, interfaces, data and other content associated with the
          Services (<b>"Content"</b>). The Mobile App, Services and Content are
          copyrighted works of Sondr and may contain trademarks, service marks,
          trade names, and other intellectual property of Sondr.
        </p>
        <p>
          The Mobile App may use location-based services to locate you. If you
          choose to use the Mobile App, you consent to Sondr and its third party
          providers determining your location.
        </p>
        <ol>
          <li>
            <p>
              <b>1. Limited Use License Grant and Terms of Use</b>
            </p>
            <p>The Mobile App is licensed to you and not sold.</p>
            <p>
              Provided that you fully comply with the terms and conditions of
              this License, Sondr grants you, and you accept, the non-exclusive,
              limited, revocable, non-transferable, and non-sublicensable
              license, during the term, to install, access and use one copy of
              the Mobile App per Apple or Android OS device that you own or
              control, solely and exclusively for your personal use and as
              permitted by the Terms of Use for the Apple Mobile App Store
              and/or Google Play Mobile App Store and/or Amazon Appstore for
              Android or other app store through which it is made available
              (collectively, the <b>“Mobile App Stores”</b>).
            </p>
            <p>
              All rights not specifically granted under this License are
              reserved to Sondr and, as applicable, Sondr’s licensors.
            </p>
            <p>
              Your use of the Mobile App is limited to the rights expressly
              granted to you by this License. This License does not permit any
              commercial use which is strictly prohibited. You acknowledge and
              agree that you shall not use the Mobile App for any purpose other
              than for non-commercial personal enjoyment, entertainment
              purposes, and that you shall use the Mobile App in accordance with
              all applicable laws, rules and regulations.
            </p>
            <p>
              Except as expressly provided in this License, you shall not and
              shall not permit, encourage or support any third party to do the
              following:
            </p>
            <ul>
              <li>
                (a) Post, upload, publish, submit or transmit any content (“User
                Content”) that: (i) infringes, misappropriates or violates a
                third party’s patent, copyright, trademark, trade secret, moral
                rights or other intellectual property rights, or rights of
                publicity or privacy; (ii) violates, or encourages any conduct
                that would violate, any applicable law or regulation or would
                expose Sondr or third parties to civil liability; (iii) is
                fraudulent, false, misleading or deceptive; (iv) is defamatory,
                obscene, pornographic, vulgar or offensive; (v) promotes
                discrimination, bigotry, racism, hatred, harassment or harm
                against any individual or group; (vi) is violent or threatening
                or promotes violence or actions that are threatening to any
                person or entity; or (vii) promotes illegal or harmful
                activities or substances;
              </li>

              <li>
                (b) Send any unsolicited or unauthorized advertising,
                promotional materials, email, junk mail, spam, chain letters or
                other form of solicitation;
              </li>

              <li>
                (c) Collect or store any personally identifiable information
                from the Services from other users of the Services without their
                express permission;
              </li>

              <li>
                (d) Impersonate or misrepresent your affiliation with any person
                or entity;
              </li>

              <li>
                (e) Exploit the Mobile App or any parts of the Mobile App
                commercially or for the benefit of any third party;{" "}
              </li>

              <li>
                (f) Use the Mobile App, or permit it to be used, on more than
                one device simultaneously, unless otherwise authorized to do so
                by the Mobile App Stores in family sharing or other similar
                sharing features;
              </li>

              <li>
                (g) Make copies of the Mobile App or any part of the Mobile App,
                or make copies of any materials accompanying the Mobile App;{" "}
              </li>

              <li>
                (h) Sell, rent, lease, license, sub-license, distribute or
                otherwise transfer the Mobile App, or any copies of the Mobile
                App, or any rights to use the Mobile App, without the express
                prior written consent of Sondr;{" "}
              </li>

              <li>
                (i) Copy, decompile, reverse engineer, disassemble, attempt to
                derive the source code of, modify, or create derivative works of
                the Mobile App or of any component of the Mobile App, in whole
                or in part;{" "}
              </li>

              <li>
                (j) Modify, remove, disable or circumvent any proprietary
                notices or labels, product identification, copyright, logo,
                trademark, or any other intellectual property notices contained
                on or within the Mobile App;{" "}
              </li>

              <li>
                (k) Export the Mobile App or any copy or adaptation of the
                Mobile App in violation of any applicable laws or regulations;{" "}
              </li>

              <li>
                (l) Use portions of the Mobile App not made available to you or
                attempt to access and violate the security of the Mobile App;{" "}
              </li>

              <li>
                (m) Use, display, mirror or frame the Services, or any
                individual element within the Services, our name, any of our
                trademarks, logos or other proprietary information, or the
                layout and design of any page or form contained on a page or in
                the Mobile App, without our express written consent;
              </li>

              <li>
                (n) Access, tamper with, or use non-public areas of the
                Services, Sondr’s computer systems, or the technical delivery
                systems of Sondr’s partners and service providers;
              </li>

              <li>
                (o) Attempt to probe, scan, or test the vulnerability of any of
                Sondr’s systems or networks or breach any security or
                authentication measures;
              </li>

              <li>
                (p) Avoid, bypass, remove, deactivate, impair, descramble or
                otherwise circumvent any technological measure implemented by
                Sondr or any of Sondr’s providers or any other third party
                (including another user) to protect the Services or Content;
              </li>

              <li>
                (q) Attempt to access or search the Services or Content or
                download Content from the Services through the use of any
                engine, software, tool, agent, device or mechanism (including
                spiders, robots, crawlers, data mining tools or the like) other
                than the software and/or search agents provided by Sondr or
                other generally available third party web browsers;
              </li>

              <li>
                (r) Use any meta tags or other hidden text or metadata utilizing
                any of our trademarks, logos, URLs or product names without our
                express written consent; or
              </li>

              <li>
                (s) Forge any TCP/IP packet header or any part of the header
                information in any email or newsgroup posting, or in any way use
                the Services or Content to send altered, deceptive or false
                source-identifying information;
              </li>

              <li>
                (t) Attempt to decipher, decompile, disassemble or reverse
                engineer any of the software used to provide the Services or
                Content;
              </li>

              <li>
                (u) interfere with, or attempt to interfere with, the access of
                any user, host or network, including, without limitation,
                sending a virus, overloading, flooding, spamming, or
                mail-bombing the Services; or
              </li>

              <li>
                (v) Use the Mobile App, the Services or the Content in violation
                of any applicable laws and regulations.
              </li>
            </ul>
            <p>
              Any attempt to do any of the foregoing prohibited actions is a
              violation of the rights of Sondr and its licensors and you may be
              subject to prosecution and damages if you breach such
              restrictions. Although Sondr is not obligated to monitor access to
              or use of the Mobile App, the Services or the User Content or to
              review or edit any User Content, Sondr shall have the right to do
              so for the purpose of operating the Services, to ensure compliance
              with this License, or to comply with applicable law or other legal
              requirements. Sondr reserves the right, but is not obligated, to
              remove or disable access to any User Content, at any time and
              without notice, including, but not limited to, if Sondr, at its
              sole discretion, considers any User Content to be objectionable or
              in violation of this License. Sondr shall have the right to
              investigate violations of this License or conduct that affects the
              Mobile App or the Services. Sondr may also consult and cooperate
              with law enforcement authorities to prosecute users who violate
              the law.
            </p>
            <p>
              As part of the Mobile App, you may receive push notifications or
              other types of messages directly sent to you outside or inside the
              Mobile App (collectively, "Push Messages"). You may control the
              Push Messages in your device's or the Mobile App's settings on the
              Profile page. Some of the Push Messages may be related to your
              location or to your use of the Mobile App or the Content. Your
              carrier may charge standard messaging, data and other fees for use
              of Push Messages, and these fees may appear on your mobile bill or
              be deducted from your pre-paid balance. Your carrier may prohibit
              or restrict certain Push Messages and certain Push Messages may be
              incompatible with your carrier or mobile device. Contact your
              carrier with questions regarding these issues. You may discontinue
              Push Messages in your device's or the Mobile App's settings on the
              Profile page or by deleting the Mobile App. Sondr may collect
              information related to your use of Push Messages.
            </p>
          </li>
          <li>
            <p>
              <b>2. Ownership</b>
            </p>
            <p>
              All title, ownership rights and intellectual property rights in
              and to the Mobile App (including but not limited to any patches
              and updates), the Content and the Services and any and all copies
              thereof (including but not limited to any titles, computer code,
              themes, objects, characters, character names, stories, text,
              dialog, catch phrases, locations, concepts, artwork, animation,
              sounds, musical compositions, music, audio-visual effects, images,
              photographs, methods of operation, moral rights, any related
              documentation, and “applets” incorporated into the Mobile App) are
              owned by Sondr or licensors of Sondr. The Mobile App and the
              Content are protected by the copyright laws of the United States,
              by international copyright treaties, and by conventions and other
              laws. You acknowledge that Sondr owns all rights in and to the
              Mobile App and the Content, including, but not limited to
              worldwide statutory and common law rights associated with (a)
              patents and patent Mobile Apps; (b) works of authorship, including
              copyrights, copyright Mobile Apps, copyright registrations and
              “moral rights;” (c) the protection of trade and industrial secrets
              and confidential information; (d) trademarks; and (e) divisions,
              continuations, renewals, derivative works, and re-issuances of any
              of the foregoing, now existing or acquired in the future. You
              agree not to remove, alter or obscure any copyright, trademark,
              service mark or other proprietary rights notices incorporated in
              or accompanying the Mobile App, the Services and the Content.
            </p>
            <p>
              Sondr does not claim any ownership rights in any User Content that
              you make available through the Mobile App or the Services and
              nothing in this License will be deemed to restrict any rights that
              you may have to use and exploit your User Content. Subject to the
              foregoing, Sondr and its licensors exclusively own all right,
              title and interest in and to the Mobile App, the Services and the
              Content, including all associated intellectual property rights.{" "}
            </p>
          </li>
          <li>
            <p>
              <b>3. Rights in User Content Granted by You</b>
            </p>
            <p>
              By making any User Content available through the Mobile App and/or
              the Services you hereby grant to Sondr a non-exclusive, perpetual,
              transferable, sublicenseable, worldwide, royalty-free license to
              use, copy, modify, create derivative works based upon, distribute,
              publicly display, publicly perform and distribute your User
              Content in connection with marketing, promoting, operating and
              providing the Mobile App and the Services to you and to other
              users and potential customers of the Mobile App and the Services.
            </p>
            <p>
              You are solely responsible for all your User Content. You
              represent and warrant that you own all your User Content or you
              have all rights that are necessary to grant us the license rights
              in your User Content under this License. You also represent and
              warrant that neither your User Content, nor your use and provision
              of your User Content to be made available through the Services,
              nor any use of your User Content by Sondr on or through the Mobile
              App and/or the Services will infringe, misappropriate or violate a
              third party’s intellectual property rights, or rights of publicity
              or privacy, or result in the violation of any applicable law or
              regulation.
            </p>
            <p>
              You can remove some of your User Content by specifically deleting
              it. However, in certain instances, some of your User Content may
              not be completely removed and copies of your User Content may
              continue to exist on the Mobile App and the Services. Sondr is not
              responsible or liable for the removal or deletion of (or the
              failure to remove or delete) any of your User Content.
            </p>
          </li>
          <li>
            <p>
              <b>4. Mobile App Stores</b>
            </p>
            <p>
              Your use of the Mobile App is subject to the terms and conditions
              of the Mobile App Store on which you downloaded the Mobile App.
              For details, refer to the terms of use of the Mobile App Stores,
              as applicable.
            </p>
          </li>
          <li>
            <p>
              <b>5. Privacy</b>
            </p>
            <p>
              The collection, use and disclosure, if any, of information
              collected from you by Sondr is detailed in our Privacy Policy that
              can be found on the privacy policy section of our website at
              https://www.joinsocha.com/privacy, which is incorporated by
              reference and made an integral part of this License.
            </p>
          </li>
          <li>
            <p>
              <b>6. Consent to Use of Data</b>
            </p>
            <p>
              Your access and use of the Mobile App is not private or
              confidential. You agree that Sondr may collect and use technical
              data and related information, including but not limited to
              technical information about your device, system, Mobile App
              software and peripherals, and use of the Mobile App, that is
              gathered to facilitate the provision of software updates, product
              support and other services to you (if any) related to the Mobile
              App. Sondr may use and disclose this information, as long as it is
              in a form that does not personally identify you, to improve its
              products and services or to provide product, services and
              technologies to you and other users.
            </p>
          </li>
          <li>
            <p>
              <b>7. Updates</b>
            </p>
            <p>
              Sondr may, at its entire discretion, make updates or modifications
              to certain parameters of the Mobile App to fine-tune its features,
              functionality or for other purposes. These updates or
              modifications may result in loss of data or User Content. Sondr
              reserves the right to make these updates or modifications and will
              not be held liable to you for such updates or modifications and
              their resulting loss of data or User Data. Please note that you
              may need to update your version of the Mobile App to continue
              using it.
            </p>
          </li>
          <li>
            <p>
              <b>8. A/B Testing</b>
            </p>
            <p>
              You agree that Sondr may present you with different versions of
              the Mobile App in order to conduct user interface studies (also
              known as A/B testing). Sondr will have no obligation to inform you
              of the existence of other versions of the Mobile App, nor to
              provide you with a version you previously used.
            </p>
          </li>
          <li>
            <p>
              <b>9. Equipment and Access Fees</b>
            </p>
            <p>
              You must provide at your own expense the equipment, Internet
              connection, devices, service plans necessary to access and use the
              Mobile App. Sondr does not guarantee that the Mobile App can be
              accessed and used on all devices and wireless service plans, nor
              does it guarantee that the Mobile App is available in all
              geographic locations. When you use the Mobile App, your wireless
              service provider may subject you to fees for data and/or wireless
              access. You acknowledge and agree that you are solely responsible
              for any costs you may incur to access the Mobile App.
            </p>
          </li>
          <li>
            <p>
              <b>10. Charges and Billing Information</b>
            </p>
            <p>
              You agree that to the extent you provide Sondr any payment
              information, you represent that you are an authorized user of the
              chosen method of payment, and that all payment information you
              provide, including but not limited to your Mobile App Store
              account information, name, credit card or other payment account
              identifying number, expiration date, security codes, billing
              address, and any other payment information will be current,
              complete, true and accurate. All expenses and costs incurred by
              you, if any, are your sole responsibility. You are not entitled to
              reimbursement from Sondr for any expenses, and you will hold Sondr
              harmless therefrom.
            </p>
          </li>
          <li>
            <p>
              <b>11. No Warranty</b>
            </p>
            <p>
              You expressly acknowledge and agree that use of the Mobile App and
              the Services is at your sole risk and that the entire risk as to
              satisfactory quality, performance, accuracy and effort is with
              you. To the maximum extent permitted by applicable laws, the
              Mobile App and the Services are provided "as is" and “as
              available,” with all faults and without warranty of any kind, and
              Sondr hereby disclaims all warranties and conditions with respect
              to the Mobile App and any Services, either express, implied or
              statutory, including, but not limited to, the implied warranties
              and/or conditions of merchantability, of satisfactory quality, of
              fitness for a particular purpose, of accuracy, of quiet enjoyment,
              and non-infringement of third party rights. Sondr does not warrant
              against interference with your enjoyment of the Mobile App, that
              the functions contained in, or Services performed or provided by,
              the Mobile App will meet your requirements, that the operation of
              the Mobile App or Services will be uninterrupted or error-free, or
              that defects in the Mobile App or Services will be corrected. No
              oral or written information or advice given by Sondr or its
              authorized representative shall create a warranty. Should the
              Mobile App or Services prove defective, you assume the entire cost
              of all necessary servicing, repair or correction. Some
              jurisdictions do not allow the exclusion of implied warranties or
              limitations on applicable statutory rights of a consumer, so the
              above exclusion and limitations may not apply to you.
            </p>
            <p>
              In the event of any failure of the Mobile App to conform to any
              applicable warranty, if any, you may notify the Mobile App Store
              from which you downloaded the Mobile App and such Mobile App Store
              shall, if such option exist, refund the purchase price for the
              Mobile App to you, subject to the terms and conditions of each
              Mobile App Store. To the maximum extent permitted by applicable
              law, the Mobile App Stores will have no other warranty obligation
              whatsoever with respect to the Mobile App, and any other claims,
              losses, liabilities, damages, costs or expenses attributable to
              any failure to conform to any warranty will be Sondr’s
              responsibility.
            </p>
          </li>
          <li>
            <p>
              <b>12. Exculpation and Limitation of Liability</b>
            </p>
            <p>
              To the fullest extent permissible by law, you agree to release and
              hold harmless Sondr, its subsidiaries and, Affiliates, and their
              respective partners, service providers, licensors, advisors,
              representatives, agents, successors, assigns, employees, officers
              and directors (the <b>"Released Parties"</b>), from any and all
              liability, loss, harm, damage, injury, cost or expense whatsoever,
              or any incidental, special, indirect or consequential damages
              whatsoever, including, without limitation, property damage,
              personal injury (including emotional distress), and/or death,
              which may occur, in whole or in part, directly or indirectly, in
              connection with your use of the Mobile App, and for any claims or
              causes of action based on publicity rights, defamation or invasion
              of privacy and merchandise delivery, as well as for any damages
              for loss of profits, loss of data, business interruption or any
              other commercial damages or losses, arising out of or related to
              your use or inability to use the Mobile App, however caused,
              regardless of the theory of liability (contract, tort or
              otherwise) and even if Sondr has been advised of the possibility
              of such damages. Some jurisdictions do not allow the limitation of
              liability for personal injury, or of incidental or consequential
              damages, so this limitation may not apply to you. The Released
              Parties assume no responsibility for any injury or damage to your
              or to any other person's device, regardless of how caused,
              relating to or resulting from downloading materials or software in
              connection with the Mobile App. You acknowledge that Sondr has
              neither made nor is in any manner responsible or liable for any
              warranty, representations or guarantees, express or implied, in
              fact or in law, relative to the Mobile App or any component
              thereof.
            </p>
          </li>
          <li>
            <p>
              <b>13. Injunctive Relief</b>
            </p>
            <p>
              You agree that a breach of this License will cause irreparable
              injury to Sondr for which monetary damages would not be an
              adequate remedy. Sondr shall be entitled to seek equitable relief
              in addition to the other remedies it may have by this License or
              under the applicable law.
            </p>
          </li>
          <li>
            <p>
              <b>14. Indemnity</b>
            </p>
            <p>
              You agree to indemnify, defend and hold Sondr and other Release
              Parties harmless from all damages, losses and expenses, including
              legal fees and costs, arising directly or indirectly from you acts
              and omissions to act in using the Mobile App pursuant to the terms
              and conditions of this License and the Terms of Service.
            </p>
          </li>
          <li>
            <p>
              <b>15. Links</b>
            </p>
            <p>
              The Mobile App may contain links to third party websites or
              interact with third party mobile applications (including, without
              limitation, widgets, tools, software, or other software utilities)
              that are not owned or controlled by the Sondr. Our provision of a
              link to any other website or third party mobile application is for
              your convenience only and does not signify our endorsement of such
              other website or resource or its contents. You should read the
              terms of use and privacy policies that apply to such third party
              services, content and websites. SONDR WILL HAVE NO RESPONSIBILITY
              OR LIABILITY FOR ANY CONTENT, INFORMATION, SOFTWARE, SERVICES,
              MATERIALS OR PRACTICES OF ANY THIRD PARTY WEBSITE OR THIRD PARTY
              APP.
            </p>
          </li>
          <li>
            <p>
              <b>16. Third Party Mobile Apps and Devices</b>
            </p>
            <p>
              If you access the Mobile App using an Apple iOS or Android-powered
              device, then Apple Inc., Google, Inc. or Amazon Inc.,
              respectively, will be a third-party beneficiary to this License.
              However, these third-party beneficiaries are not a party to this
              contract and are not responsible for the provision or support of
              the Mobile App. You agree that your access to the Mobile App using
              these devices also shall be subject to the usage terms set forth
              in the applicable third-party beneficiary’s terms of service.
            </p>
          </li>
          <li>
            <p>
              <b>17. Term and Termination</b>
            </p>
            <p>
              This License shall commence on the date you download the Mobile
              App and shall continue to be in force and effect for as long as
              you do not dispose of the Mobile App and as you comply with this
              License. Your rights under this License will terminate
              automatically without any notice from Sondr in the event that you
              fail to comply with any of the terms and conditions of this
              License. Upon termination of this License, you shall cease all use
              of the Mobile App and destroy all copies (whether full or partial)
              of the Mobile App. Additionally, Sondr and/or its licensors,
              reserve the right to change, suspend, remove, or disable access to
              the Mobile App or the subscription options at any time without
              notice. In no event will Sondr be liable for the removal of or
              disabling of access to the Mobile App. You agree that Sondr shall
              not be liable for any loss or damage caused, directly or
              indirectly, by any such termination and/or suspension. Subscribers
              to the Mobile App may terminate their subscription in accordance
              with Section 23(f) of this License.
            </p>
          </li>
          <li>
            <p>
              <b>18. Severability</b>
            </p>
            <p>
              If for any reason any provision of this License is found to be
              illegal, unenforceable or invalid under applicable law, that
              provision shall be enforced to the maximum extent permissible so
              as to effect the intent of the parties as reflected in that
              provision, and the remainder of this License shall continue in
              full force and effect.
            </p>
          </li>
          <li>
            <p>
              <b>19. Assignment</b>
            </p>
            <p>
              This License is personal to you and is not assignable,
              transferable or sub-licensable by you. You acknowledge that you
              may not assign, transfer or sublicense any or all of your rights
              or obligations under this License without the express prior
              written consent of Sondr. Sondr may assign, transfer or delegate
              its rights and obligations under this License without consent from
              you, in whole or in part, at any time.
            </p>
          </li>
          <li>
            <p>
              <b>
                20. Governing Law and Forum; Waivers of Jury and Class Actions;
                Claims Limitations
              </b>
            </p>
            <p>
              The laws of the State of New York, excluding its conflict of law
              rules, govern this License and your use of the Mobile App. Your
              use of the Mobile App may also be subject to other local, state,
              national, or international laws. However, this License and the
              Mobile App will not be governed by the United Nations Convention
              on Contracts for the International Sale of Goods.
            </p>
            <p>
              The exclusive venue and jurisdiction for any action or proceeding
              arising out of this License shall be the state and federal courts
              located in New York County, State of New York. You hereby accept
              the personal jurisdiction of such courts. Notwithstanding this or
              any other provision of this License, Sondr may seek injunctive or
              other equitable relief from any court of competent jurisdiction.
            </p>
            <p>
              Class arbitrations and class actions are not permitted, and your
              claim may not be consolidated with any other person's claim. You
              and Sondr hereby agree that you and Sondr are each waiving the
              right to a trial by jury or to participate in a class action. This
              Section 20 shall survive termination of this License.
            </p>
            <p>
              Regardless of any statute or law to the contrary, you must file
              any claim or action related to use of the Mobile App or this
              License within one year after such claim or action accrued.
              Otherwise, you will waive the claim or action.
            </p>
          </li>
          <li>
            <p>
              <b>21. Export Laws</b>
            </p>
            <p>
              You agree to abide by the export laws applicable in the U.S. as
              well as any other applicable export laws and you agree not to
              transfer the Mobile App to any foreign national or national
              destination, which is prohibited by such laws. In particular, you
              will not ship, transfer, or export the Mobile App into any country
              or use the Mobile App in any manner prohibited by the United
              States Export Administration Act or any other export laws,
              restrictions, or regulations. You hereby certify that you are not
              a person with whom Sondr is prohibited from transacting business
              under applicable laws. Furthermore, you represent and warrant that
              (i) you are not located in a country that is subject to a U.S.
              Government embargo, or that has been designated by the U.S.
              Government as a “terrorist supporting country”; that (ii) you are
              not listed on any U.S. Government list of prohibited or restricted
              parties, and that (iii) you are not otherwise prohibited under the
              applicable laws from receiving the Mobile App.
            </p>
          </li>
          <li>
            <p>
              <b>22. Terms For Apple iPhone, iPod Touch and iPad End Users</b>
            </p>
            <p>
              These terms supplement and are in addition to the terms of the
              License for users who purchase and/or install the Mobile App on
              Apple, Inc. (<b>“Apple”</b>) iPhone, iPod Touch, Apple TV and/or
              iPad products (collectively the <b>“Apple Devices”</b>).
            </p>
            <p>
              (a) You acknowledge and agree that this License is concluded
              between you and Sondr only, not Apple.
            </p>
            <p>
              (b) By installing and/or purchasing the Mobile App, provided that
              you fully comply with the terms and conditions of this License,
              you are acquiring and Sondr grants you a personal, limited,
              non-exclusive and non-transferable license to use the Mobile App
              on authorized Apple Devices for personal, non-commercial use, and
              subject to the Apple Terms and Conditions (also referred to as the
              Usage Rules) set forth in the Mobile App Store Terms of Service.
            </p>
            <p>
              (c) You acknowledge and agree that Apple has no obligation
              whatsoever to furnish any maintenance and support services with
              respect to the Mobile App.{" "}
            </p>
            <p>
              (d) In the event of any failure of the Mobile App to conform to
              any applicable warranty, you may notify Apple and Apple will
              refund the purchase price for the Mobile App to you. To the
              maximum extent permitted by applicable law, Apple will have no
              other warranty obligation whatsoever with respect to the Mobile
              App, and any other claims, losses, liabilities, damages, costs or
              expenses attributable to any failure to conform to any warranty
              will be Sondr’s responsibility.{" "}
            </p>
            <p>
              (e) You acknowledge and agree that Apple shall not be responsible
              for addressing any claims by you or any third party relating to
              your possession and/or use of the Mobile App, including but not
              limited to: (i) product liability claims; (ii) any claims that the
              Mobile App fails to conform to any applicable legal or regulatory
              requirement; (iii) claims arising under consumer protection or
              similar legislation; and (iv) claims by any third party that the
              Mobile App or your possession and use of the Mobile App infringes
              on the intellectual property rights of the third party.
            </p>
            <p>
              (f) You represent and warrant that (i) you are not located in a
              country that is subject to a U.S. Government embargo, or that has
              been designated by the U.S. Government as a “terrorist supporting
              country”; and that (ii) you are not listed on any U.S. Government
              list of prohibited or restricted parties.
            </p>
            <p>
              (g) You acknowledge and agree that Apple and Apple’s subsidiaries
              are third party beneficiaries of this License, and that, upon your
              acceptance of the terms and conditions of this License, Apple will
              have the right (and will be deemed to have accepted the right) to
              enforce the License against you as a third party beneficiary
              thereof.
            </p>
          </li>
          <li>
            <p>
              <b>23. Additional Terms specifically for Socha Premium</b>
            </p>
            <p>
              (a) General: In addition to all other terms and conditions of this
              License, the specific terms of this Section 23 will apply only and
              specifically to Socha Premium, the subscription-only fee-based
              services which may be made available by us through the Mobile App.
            </p>
            <p>
              (b) Subscription Service: use of Socha Premium is a subscription
              service. Although certain features, activities and/or content of
              the Socha Premium may be available at no charge to you, other
              features, activities and/or content may only be available to you
              if you agree to pay for a subscription plan. Your subscription to
              Socha Premium gives full access to all the content and features of
              Socha Premium as long as your subscription is active subject to
              Section 17 of this License. At any time, Sondr may make some or
              all of the content, features or services available only to paying
              subscribers.
            </p>
            <p>
              (c) Payment: You can subscribe to one of the subscription plans
              for Socha Premium as may be made available by Sondr from time to
              time, featuring one-time fees and/or periodic fees as in-app
              purchases and subscriptions, through an Mobile App Store of your
              choice. Billing cycle, term, renewal, cancellation, reactivation
              and other terms of the subscription depends on the subscription
              plan you chose. For example, monthly subscription is billed
              monthly and yearly subscription is billed once annually, on the
              anniversary of the start of subscription date. A full description
              of currently available subscription plans and their payment terms
              is available on Sondr’s website.
            </p>
            <p>
              (e) Renewal: Your account will be charged for renewal within
              24-hours prior to the end of the current period. Your subscription
              automatically renews for the same period as your initial
              subscription (excluding any free trial period or any promotional
              or discount price) unless auto-renew is turned off at least
              24-hours before the end of the current period.
            </p>
            <p>
              (f) Subscription Cancellation: At any time, you can cancel the
              auto-renewal of your subscription by going to your account
              settings on the Profile page after your purchase. If you cancel
              the auto-renewal of your subscription, it shall remain active
              until your option plan has lapsed. Please note that you will not
              get a refund for any remaining period of a subscription. When your
              subscription has lapsed, you may lose access to Socha Premium and
              all content you obtained while your subscription was active.
            </p>
          </li>
          <li>
            <p>
              <b>24. Modifications</b>
            </p>
            <p>
              Sondr may, in its sole and absolute discretion, change the
              provisions of this License from time to time. Therefore, you agree
              to review it frequently either in the “Profile” section of the
              Mobile App or by visiting the link on our website:
              https://www.joinsocha .com/EULA. Your continued use of the Mobile
              App will signify your acceptance of the changes to this License as
              changed. If you object to any such changes, your sole recourse
              will be to stop using the Mobile App.
            </p>
          </li>
          <li>
            <p>
              <b>25. Entire Agreement</b>
            </p>
            <p>
              This License, together with the Terms of Service and Privacy
              Policy any other rules or guidelines posted in connection with the
              Mobile App, including applicable Mobile App Stores’ licenses and
              policies, where applicable, constitute the entire and exclusive,
              final statement of the agreement between you and Sondr for the use
              of the Mobile App. These documents replace and supersede all prior
              understandings (written or oral) concerning the within subject
              matter. In the event of a conflict between this License and any
              applicable purchase terms, service terms or other terms, the terms
              of these documents shall prevail.
            </p>
          </li>
          <li>
            <p>
              <b>26. No Waiver</b>
            </p>
            <p>
              No delay or failure on the part of Sondr to exercise any right or
              provision hereunder shall operate as a waiver thereof, nor shall
              any single or partial exercise of any right or power hereunder
              preclude further exercise of any other right hereunder.
            </p>
          </li>
          <li>
            <p>
              <b>27. Contact Information</b>
            </p>
            <p>
              For any questions, please do not hesitate to contact us by email
              at: support@joinsocha.com.
            </p>
          </li>
          <li>
            <p>
              <b>28. Claims of Infringement</b>
            </p>
            <p>
              For any claims of infringement or any other legal inquiries,
              please contact legal@joinsocha.com. Our copyright policy is stated
              in the Terms of Service where you will also find instructions for
              sending us copyright infringement notices.
            </p>
          </li>
          <li>
            <p>
              <b>29. Survival</b>
            </p>
            <p>
              The terms of this License which by their nature should survive the
              termination of this License shall survive such termination
            </p>
          </li>
          <li>
            <p>
              <b>30. Section Titles</b>
            </p>
            <p>
              The section titles in this License are for convenience only and
              have no legal or contractual effect.
            </p>
          </li>
          <li>
            <p>
              <b>31. Legal</b>
            </p>
            <p>Socha™ is a trademark of Sondr, Inc.</p>
            <p>
              Apple, the Apple iPhone, iPod Touch and iPad are trademarks of
              Apple Inc., registered in the U.S. and other countries. Mobile App
              Store is a service mark of Apple Inc.
            </p>
            <p>
              Google and Google Play are registered trademarks of Google LLC.
            </p>
            <p>
              Amazon and Amazon Mobile App Store are trademarks of Amazon.com,
              Inc. or its Affiliates.
            </p>
          </li>
        </ol>
      </div>
      <div className={styles.termsFooter}>*****</div>
    </div>
  );
}
