import React, { useEffect, useState } from 'react'
import BackArrow from '../../assets/Icons/backArrow/backArrow.svg'
import DummyImage from '../../assets/Images/socha_banner.png'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/primaryButton';
import ExploreIcon from "../../assets/Icons/eventDetails/explore.svg";
import { TextBlastService } from '../../services/textBlast';
import { convertUtcToLocal, getDayOfWeek, getMonthAndDay, getTime } from '../../utils/common';
import RightSideDrawer from '../../components/rightDrawer';
import CreateIcon from "../../assets/Icons/refine/create.svg";

const TextBlast = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id: any = queryParams.get("eventid");
    const [hostedEventDetails, setHostedEventDetails] = useState<any[]>([])
    const [selectedTextBlast, setSelectedTextBlast] = useState<any>(null)
    const[opendrawer, setOpenDrawer] = useState(false)
    

    useEffect(() => {
        getTextBlasts()
    },[])

    const getTextBlasts = async () => {
        try {
          const res = await TextBlastService.fetchTextBlasts({ id });
          if (res?.data) {
            setHostedEventDetails(res?.data?.campaignDetails)
            if(selectedTextBlast===null){
                setSelectedTextBlast(res?.data?.campaignDetails[0])
            }
          }
        } catch (error:any) {
          console.log("error hostedeventDtls : ", error?.response?.data?.error || error?.message);
         
        }
    }

    const handleTextBlastClick = (data:any) => {
        setSelectedTextBlast(data)
        setOpenDrawer(true)
    }


  return (
    <React.Fragment>
    <div className='container'>
           <div className='grid grid-cols-10  my-2'>
            <div className='col-start-1 col-span-10'>
                <div className='flex gap-4 items-center font-bold text-base mb-6'>
                    <img src={BackArrow} className='w-8 cursor-pointer' onClick={() => navigate(-1)}/>   
                </div>
                <div className='flex items-center justify-between font-medium text-2xl overflow-x-auto lg:overflow-x-hidden w-auto'>
                    <span className="text-primary-white">Text Blast</span>
                    <div
                        className="py-3 px-4 rounded-xl md:hidden bg-primary-orange"
                        onClick={() => navigate({
                            pathname: '/recipient-selection',
                            search: createSearchParams({
                                eventid: id
                            }).toString()
                        })}
                        >
                        <img
                            src={CreateIcon}
                            alt={""}
                            width={20}
                            height={20}
                            className="cursor-pointer"
                        />
                        </div>
                    <PrimaryButton type='button' icon={CreateIcon} classNames='hidden md:block px-2 py-3' name='Create text blast' 
                    onClick={() => navigate({
                    pathname: '/recipient-selection',
                    search: createSearchParams({
                        eventid: id
                    }).toString()
                })}/>
                </div>
            </div> 
            <div className='col-start-1 col-span-10 md:col-span-4 '>
                {hostedEventDetails?.map((data:any) => (
                <div className={`bg-input-box-gray flex justify-between my-8 rounded-2xl p-4 cursor-pointer ${selectedTextBlast?.ID === data?.ID ? 'border border-primary-orange' : 'border-0'}`} onClick={() => handleTextBlastClick(data)}>
                <div>
                    <p className='text-primary-white'>{data?.campaignName}</p>
                    <span className='text-primary-white'>{data?.guestCount} </span>
                    <span className='text-text-gray'>Guests </span>
                    <span className='text-text-gray'>- </span>
                    <span className='text-primary-white'>{getDayOfWeek(convertUtcToLocal(data?.CreatedAt,"America/New_York"), 'long')} {getMonthAndDay(convertUtcToLocal(data?.CreatedAt,"America/New_York"))} </span>
                    <span className='text-text-gray'>{getTime(convertUtcToLocal(data?.CreatedAt,"America/New_York"))}</span>
                </div>
                <img src={ExploreIcon} alt="explore" width={20} height={20} />
            </div>
            ))}
            </div>
            <div className='col-end-11 col-span-5 hidden md:block bg-[#0B0B0B] my-8 text-primary-white h-screen p-8 rounded-3xl'>
                <span className='text-2xl'>{selectedTextBlast?.campaignName}</span>
                <p className='text-base my-8'>{selectedTextBlast?.campaignContent}</p>

                <span className='text-lg'>Recipients ({selectedTextBlast?.guestInvitedDetails?.length})</span>
                {selectedTextBlast?.guestInvitedDetails?.map((recipients:any) => (
                <div className='flex gap-3 items-center my-4'>
                    <img src={DummyImage} className='w-10 rounded-full'/>
                    <span className='text-bold text-sm'>{recipients?.firstName} {recipients?.lastName}</span>
                </div>))}
            </div>  
        </div>
    </div>
    <div className='md:hidden'>
    <RightSideDrawer
        open={opendrawer}
        onClose={() => setOpenDrawer(false)}
    >
        <div className='text-primary-white px-3'> <span className='text-2xl'>{selectedTextBlast?.campaignName}</span>
                <p className='text-base my-8'>{selectedTextBlast?.campaignContent}</p>

                <span className='text-lg'>Recipients ({selectedTextBlast?.guestInvitedDetails?.length})</span>
                {selectedTextBlast?.guestInvitedDetails?.map((recipients:any) => (
                <div className='flex gap-3 items-center my-4'>
                    <img src={DummyImage} className='w-10 rounded-full'/>
                    <span className='text-bold text-sm'>{recipients?.firstName} {recipients?.lastName}</span>
                </div>))}</div>
    </RightSideDrawer>
    </div>
    </React.Fragment>
  )
}

export default TextBlast