import Axios, { createInstance } from '../utils/axiosInstance'
import qs from 'qs';
import { API_VERSION } from '../utils/constants';

const discoverEvents = (params: {}) => {
    return Axios.get(`discoverEvents${params ? '?' + qs.stringify(params) : ''}`);
}

const upcomingEvents = (params: {}, version?: string) => {
    if (version == API_VERSION.V2) {
        const axiosInstanceV2 = createInstance('v2');
        return axiosInstanceV2.get(`upComingEvents${params ? '?' + qs.stringify(params) : ''}`);
    } else
        return Axios.get(`upComingEvents${params ? '?' + qs.stringify(params) : ''}`)
}

const invitedEvents = (params: {}) => {
    return Axios.get(`invitedEvents${params ? '?' + qs.stringify(params) : ''}`);
}

const attendedEvents = (params: {}) => {
    return Axios.get(`attendedEvents${params ? '?' + qs.stringify(params) : ''}`);
}

const pastEvents = (params: {}, version?: string) => {
    if (version == API_VERSION.V2) {
        const axiosInstanceV2 = createInstance('v2');
        return axiosInstanceV2.get(`pastEvents${params ? '?' + qs.stringify(params) : ''}`);
    } else
        return Axios.get(`pastEvents${params ? '?' + qs.stringify(params) : ''}`);
}

const draftEvents = (params: {}) => {
    return Axios.get(`hostedDraftEvents${params ? '?' + qs.stringify(params) : ''}`);
}

export const EventService = {
    discoverEvents,
    upcomingEvents,
    pastEvents,
    invitedEvents,
    attendedEvents,
    draftEvents
}