import React from 'react'
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import AuthScreenLayout from '../screens/auth/layout/AuthScreenLayout'
import Login from '../screens/auth/login'
import OtpScreen from '../screens/auth/otp'
import SignUp from '../screens/auth/signUp'
import TermsOfService from '../screens/help/terms-of-service'
import PrivacyPolicy from '../screens/help/privacy-policy'
import Eula from '../screens/help/eula'
import HostAgreement from '../screens/help/host-agreement'
import SafetyNotice from '../screens/help/safety-notice'
import FAQPage from '../screens/help/faq'
import HomeLayout from '../screens/homeLayout'
import Discover from '../screens/discover'
import LandingPage from '../screens/landingPage'
import Events from '../screens/events'
import HostCenter from '../screens/hostCenter'
import EventDetails from '../screens/eventDetails'
import ProtectedRoute from './ProtectedRoute'
import CreateEventLayout from '../screens/hostCenter/layout/createEventLayout'
import BasicDetails from '../screens/hostCenter/basicDetails'
import EventInfo from '../screens/hostCenter/eventInfo'
import TicketsAndPromo from '../screens/hostCenter/tickets&promo'
import GeneralSettings from '../screens/hostCenter/generalSettings'
import Payouts from '../screens/hostCenter/payouts'
import HostEventDetails from '../screens/eventDetails/hostEventDetails'
import TextBlastDetails from '../screens/textBlast/textBlastDetails'
import RecipientSelection from '../screens/recipientSelection'
import TextBlast from '../screens/text-blast'
import Analytics from '../screens/Analytics'
import RefundRequest from '../screens/refundRequest'
import Registration from '../screens/auth/registration'
import Profile from '../screens/profile'
import Revenue from '../screens/Analytics/revenue'
import Guests from '../screens/Analytics/guests'


const routes = createBrowserRouter([
  {
    path: '',
    element: <HomeLayout />,
    children: [
      {
        index: true,
        path: '',
        element: <LandingPage />
      },
      {
        path: 'discover',
        element: (<ProtectedRoute>
                    <Discover />
                  </ProtectedRoute> )
      },
      {
        path: 'events',
        element: (<ProtectedRoute>
                    <Events />
                  </ProtectedRoute> )
      },
      {
        path: 'host-center',
        element: (<ProtectedRoute>
                    <HostCenter />
                  </ProtectedRoute> )     
      },
      {
        path: 'refund-requests',
        element: (<ProtectedRoute>
                    <RefundRequest/>
                   </ProtectedRoute>)
      },
      {
        path: 'host-center/event-details',
        element: (<ProtectedRoute>
                  <HostEventDetails />
                </ProtectedRoute>)
      },
      {
        path: 'host-center/analytics',
        element: (<ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>)
      },
      {
        path: 'host-center/analytics/revenue',
        element: (<ProtectedRoute>
                  <Revenue />
                </ProtectedRoute>)
      },
      {
        path: 'host-center/analytics/guests',
        element: (<ProtectedRoute>
                  <Guests />
                </ProtectedRoute>)
      },
      {
        path: 'event-details',
        element: <EventDetails />
      },
      {
        path: 'create-event',
        element: <CreateEventLayout />,
        children: [
          {
            path: 'basic-details',
            element: (<ProtectedRoute>
                      <BasicDetails />
                    </ProtectedRoute>)
          },
          {
            path: 'event-info',
            element: (<ProtectedRoute>
                      <EventInfo />
                    </ProtectedRoute>)
          },
          {
            path: 'tickets&promo',
            element: (<ProtectedRoute>
                      < TicketsAndPromo/>
                    </ProtectedRoute>)
          },
          {
            path: 'general-settings',
            element: (<ProtectedRoute>
                        <GeneralSettings />
                    </ProtectedRoute>)
          },
          {
            path: 'payouts',
            element: (<ProtectedRoute>
                      <Payouts />
                    </ProtectedRoute>)
          }
        ]
      },
      {
        path: 'text-blast',
        element: (
                <ProtectedRoute>
                  <TextBlast />
                </ProtectedRoute>
              )
      },
      {
        path: 'recipient-selection',
        element: (<ProtectedRoute>
                  <RecipientSelection />
                </ProtectedRoute>)
      },
        {
          path: 'textBlast-details',
          element: (<ProtectedRoute>
                    <TextBlastDetails />
                  </ProtectedRoute>)
        },
        {
          path: 'profile',
          element: (<ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>)
        }
    ]
  },
  
  {
    path: '/help',
    children: [
      {
        path: 'terms-of-service',
        element: <TermsOfService />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: 'eula',
        element: <Eula />
      },
      {
        path: 'host-agreement',
        element: <HostAgreement />
      },
      {
        path: 'safety-notice',
        element: <SafetyNotice />
      },
      {
        path: 'faq',
        element: <FAQPage />
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthScreenLayout />,
    children: [
      {
        index: true,
        element: <Login />
      },
      {
        path: 'otp',
        element: <OtpScreen />
      },
      {
        path: 'signup',
        element: <SignUp />
      },
      {
        path: 'registration',
        element: <Registration />
      }
    ]
  }
]);

export default routes;