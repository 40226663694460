import React, { useEffect, useRef, useState } from "react";
import { PHONE_2_IMAGE_URL } from "../../assets/assets";
import AppStore from "../../assets/Icons/payout/appStore.svg";
import PlayStore from "../../assets/Icons/payout/playStore.svg";
import Camera from "../../assets/Icons/camera/camera.svg";
import ProfilePhoto from "../../assets/Icons/profile/Proifle.svg";
import ImageCropper from "../../components/ImageCropper/ImageCropper";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import { toast } from "react-toastify";
import { UserService } from "../../services/userServices";
import { AuthService } from "../../services/authService";
import { getLocalStorage } from "../../utils/localStorage";
import { LOCAL_STORAGE } from "../../utils/constants";

const userInfoFields = [
  { key: "firstName", placeholder: "Enter your first name *" },
  { key: "lastName", placeholder: "Enter your last name *" },
  { key: "email", placeholder: "Enter email address *", type: "email" },
];

export default function Profile() {
  const emailInputRef = useRef<any>();
  const [errors, setErrors] = useState(
    Object.fromEntries(userInfoFields.map((field) => [field.key, ""]))
  );
  const [userInfo, setUserInfo] = useState(
    Object.fromEntries(userInfoFields.map((field) => [field.key, ""]))
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const { setShowSpinner } = useSpinner();
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const userId = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [userDetails, setUserDetails] = useState<any>();
  
  
  useEffect(() => {
    getUserProfileDetails()
  }, [])
  

  const getUserProfileDetails = async () => {
    try {
      setShowSpinner(true)
      const res = await UserService.getUserProfileDetails();
      if (res?.data) {  
        setUserDetails(res?.data);
        setUserInfo((prevState) => ({
          ...prevState,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          email: res?.data?.email,
        }));
        setImageURL(res?.data?.profilePhoto);
        setShowSpinner(false)
      }
    } catch (error: any) {
      console.log("error getGuestEventDtls : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false)
    }
  }

  const handleChange = (key: string, value: string) => {
    setUserInfo((prevState) => ({ ...prevState, [key]: value }));
    setShowUpdateButton(true)
    if (errors[key]) setErrors((prevState) => ({ ...prevState, [key]: "" }));
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsCropperOpen(true);
    if (file) {
      const url = URL.createObjectURL(file);
      setImageURL(url);
      setShowUpdateButton(true);
    }
  };

  const handleUpload = async (userId: any, croppedImage: string) => {
    try {
      setShowSpinner(true);
      const croppedImageBlob = await fetch(croppedImage).then(res => res.blob());
      console.log(croppedImageBlob, 'croppedImageBlob');
      const formData = new FormData();
      formData.append('photo1', croppedImageBlob);
      formData.append('ID', userId);

      const response = await UserService.uploadProfilePic(formData);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result?.data) {

        console.log(result.data.message);

      } else {
        throw new Error('No data in response');
      }
    } catch (error) {
      console.error("Error in profile upload:", error);
      toast.error("Unable to upload profile picture.");

    } finally {
      setShowSpinner(false);
    }
  };

  const onUpdateClick = async () => {
    try {
      if (!userInfo.firstName)
        setErrors(prevState => ({ ...prevState, firstName: 'First name is required.' }));
      if (!userInfo.lastName)
        setErrors(prevState => ({ ...prevState, lastName: 'Last name is required.' }));
      if (!userInfo.email)
        setErrors(prevState => ({ ...prevState, email: 'Email address is required.' }));
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userInfo.email))
        setErrors(prevState => ({ ...prevState, email: 'Please enter a valid email address.' }));
      console.log("selectedFile", selectedFile)

      if (!imageURL && selectedFile == null)
        setErrors(prevState => ({ ...prevState, profile: 'Profile photo is required.' }));

      if (!userInfo.firstName || !userInfo.lastName || (!userInfo.email) || (!imageURL && selectedFile == null))
        return;
      setShowSpinner(true);
      const payload = {
        id:userId ? parseInt(userId) : undefined,
        email:  userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        gender:userDetails.gender
      }
      const res = await AuthService.updateUserDetails(payload);
      if (res?.data && res?.status) { 
        if(selectedFile)
         await handleUpload(userId, imageURL);
        toast.success("Profile updated successfully.")
        setShowSpinner(false);
        window.history.back();
        setTimeout(() => {
          window.location.reload(); 
        }, 100);
      }
    } catch (error:any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      toast.error(errorMessage);
      setShowSpinner(false);
    }
  }

  return (
      <div className="flex items-start 2xl:container justify-between h-[85vh]">   
     <div className="m-auto flex flex-col gap-5 my-5">
  <div className="flex h-full flex-col justify-center items-center gap-4">
    {/* Profile Image Section */}
    <div>
      <input
        type="file"
        id="fileInput"
        onChange={handleFileChange}
        accept=".jpg,.png,.jpeg"
        hidden
      />
      <label htmlFor="fileInput" className="cursor-pointer relative">
        <img
          src={imageURL ? imageURL : ProfilePhoto}
          alt="profile"
          className="w-32 h-32 rounded-full"
        />
        <div className="absolute bottom-0 right-0 w-9 h-9 bg-primary-orange rounded-full flex justify-center items-center">
          <img src={Camera} alt="camera" />
        </div>
      </label>
      {isCropperOpen && (
        <div className="fixed inset-0 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <ImageCropper
              imageFile={selectedFile}
              onClose={() => setIsCropperOpen(false)}
              onCropComplete={(croppedImage) => {
                console.log(croppedImage, "croppedImage");
                setImageURL(croppedImage);
                setIsCropperOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </div>

    {/* Error for Profile */}
    <div className="w-full">
      {errors["profile"] && (
        <div className="text-primary-red text-sm  text-center mt-1">
          {errors["profile"]}
        </div>
      )}
    </div>

    {/* First and Last Name Section */}
    <div className="flex flex-col md:flex-row justify-center items-center gap-4 w-full">
      <div className="w-full">
        <input
          type="text"
          placeholder="First Name*"
          className="w-full rounded-xl placeholder:font-light border border-[#424242] text-primary-white bg-[#222222]"
          name="firstName"
          value={userInfo["firstName"]}
          onChange={(e) => handleChange("firstName", e.target.value)}
          required
        />
        {errors["firstName"] && (
          <div className="text-primary-red text-sm text-left mt-1">
            {errors["firstName"]}
          </div>
        )}
      </div>
      <div className="w-full">
        <input
          type="text"
          placeholder="Last Name*"
          className="w-full rounded-xl placeholder:font-light border border-[#424242] text-primary-white bg-[#222222]"
          name="lastName"
          value={userInfo["lastName"]}
          onChange={(e) => handleChange("lastName", e.target.value)}
          required
        />
        {errors["lastName"] && (
          <div className="text-primary-red text-sm text-left mt-1">
            {errors["lastName"]}
          </div>
        )}
      </div>
    </div>

    {/* Email Section */}
    <div className="w-full">
      <input
        ref={emailInputRef}
        type="email"
        placeholder="Email Address*"
        className="w-full rounded-xl placeholder:font-light border border-[#424242] text-primary-white bg-[#222222]"
        name="email"
        value={userInfo["email"]}
        onChange={(e) => handleChange("email", e.target.value)}
        required
      />
      {errors["email"] && (
        <div className="text-primary-red text-sm text-left mt-1">
          {errors["email"]}
        </div>
      )}
    </div>
  </div>

  {/* Update Button */}
  {showUpdateButton && <div
    className="w-fit border border-primary-orange text-white rounded-xl p-3 bg-[#FF8A0021] cursor-pointer"
    onClick={onUpdateClick}
  >
    Update
  </div>}
</div>

      <div className="hidden lg:flex w-1/2 text-primary-white h-full flex-col bg-[#181818] justify-between rounded-lg">
        <div className="w-[70%] flex flex-col items-start gap-8 p-6">
          <span className="text-3xl font-bold text-start">
            Download the socha app to level up your profile!
          </span>
          <div className="flex items-center gap-6">
          <a href="https://apps.apple.com/in/app/socha-social/id6444107828" target="_blank"><img src={AppStore} alt="app store" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.app.socha&pcampaignid=web_share" target="_blank"><img src={PlayStore} alt="google play" /></a>
          </div>
              </div>
              <div className="relative flex items-center justify-center">
                    <div className="bottom-0 absolute h-64 w-64 rounded-full bg-orange-400 bg-opacity-10 blur-2xl"></div>
                    <img src={PHONE_2_IMAGE_URL} className="relative h-64 w-36 z-10" />
            </div>
      </div>
    </div>
  );
}
