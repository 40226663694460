import { RouterProvider } from 'react-router-dom';
import AuthRoutes from './routes/router';
import { SpinnerProvider } from './components/spinner/SpinnerContext';
import Spinner from './components/spinner/Spinner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CometChat } from '@cometchat-pro/chat';
import AppBanner from './components/AppBanner';
import AppSwitchToggle from './components/appSwitchToggle';

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region =process.env.REACT_APP_COMETCHAT_REGION;
const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully");
    // You can now call login function.
  },
  error => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);

function App() {
  return (
    <SpinnerProvider>
      <AppBanner />
      {/* <AppSwitchToggle/> */}
      <RouterProvider router={AuthRoutes} />
      <Spinner />
      <ToastContainer />
    </SpinnerProvider>
    );
}

export default App;
