import React, { useEffect, useState } from "react";
import {API_VERSION, LOCAL_STORAGE, MY_EVENTS_TYPES } from "../../utils/constants";
import EventCard from "../../components/eventCard";
import SearchIcon from "../../assets/Icons/refine/search.svg";
import { getLocalStorage } from "../../utils/localStorage";
import { EventService } from "../../services/eventService";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Event } from "../../utils/Interfaces";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import SearchInput from "../../components/searchInput";
import PrimaryButton from "../../components/primaryButton";

export default function Events() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab:any = queryParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(tab);
  const [showSearchInMobile, setShowSearchInMobile] = useState(false);
  const [allEvents, setAllEvents] = useState([])
  const userId:any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const { setShowSpinner } = useSpinner();
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    setSelectedTab(tab);
    FetchEvents(tab);
  }, [tab, searchFilter]);

  const FetchEvents = async (tab: string) => {

    try {
      let res;
      setShowSpinner(true);
      const params = {
        userId: userId,
        pageNo: 1,
        pageSize: 20,
        search: searchFilter ? searchFilter :undefined
      };
  
      if (tab === MY_EVENTS_TYPES.UPCOMING) {
        res = await EventService.upcomingEvents(params, API_VERSION.V2);
      } else if (tab === MY_EVENTS_TYPES.INVITED) {
        res = await EventService.invitedEvents(params);
      } else if (tab === MY_EVENTS_TYPES.ATTENDED) {
        res = await EventService.attendedEvents(params);
      }
      if (res?.data) setAllEvents(res?.data?.events);
      setShowSpinner(false)
    } catch (error:any) {
      console.log("error fetch events : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false)
    }
  };
  
  const onEventClick = (eventId: number, hostId: number) => {
    if(hostId == userId)
      navigate({
        pathname: '/host-center/event-details',
        search: createSearchParams({
          eventid: eventId.toString(),
          hostid: hostId?.toString()
        }).toString()
      });
      else
      navigate({
        pathname: '/event-details',
        search: createSearchParams({
          eventid: eventId.toString(),
          hostid: hostId.toString()
        }).toString()
      });
}

  const handleClick = (item: string) => {
  queryParams.set('tab', item);
  navigate({
    pathname: location.pathname,
    search: queryParams.toString()
  });
  };
  
  return (
    <div className="mx-8 text-primary-white ">
      <h1 className="text-primary-white text-[2.35rem] font-medium flex justify-between items-center">
        <span> Invited Events</span>
        <img
          src={SearchIcon}
          alt={""}
          width={25}
          height={25}
          className="cursor-pointer md:hidden"
          onClick={() => setShowSearchInMobile(!showSearchInMobile)}
        />
      </h1>
      <div className="flex flex-col gap-4 md:flex-row md:justify-between md:items-center my-3.5">
        <div className="flex text-text-gray bg-input-box-gray gap-1 lg:gap-2 items-center p-1 rounded-xl">
          {Object.values(MY_EVENTS_TYPES).map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer rounded-xl p-2 md:px-4 text-[.9rem] md:text-[1rem] ${
                selectedTab === item ? "text-primary-white bg-primary-black" : ""
              }`}
              onClick={() => handleClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
        <div
          className={`w-full md:w-[30%] md:block ${
            showSearchInMobile ? "block " : "hidden"
          }`}
        >
          <SearchInput onInputChange={(input) => setSearchFilter(input)}/>
        </div>
      </div>
      {(!allEvents || allEvents?.length == 0) ?
        <div className="flex flex-col justify-center items-center gap-3 mt-32 " >
        <span className="text-xl font-mono">KEEP AN EYE OUT</span>
          <span className="text-xs text-text-gray">We haven't found any {tab.toLowerCase()} events, but stay tuned!</span>
        <PrimaryButton name="Refresh" classNames="px-3 py-1.5" onClick={() => FetchEvents(tab)}/>
      </div>
        : <div className="py-8 md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 justify-items-center items-center ">
        {allEvents?.map((event:Event, idx) => <EventCard event={event} onCardClick={() => onEventClick(event?.eventId, event?.hostUserId)} />)}
      </div>}
    </div>
  );
}
