import React, { ChangeEvent } from 'react';


interface props {
    icon? : any,
    onChange?: (input: ChangeEvent<HTMLInputElement>) =>void,
    placeHolder?: string,
    value?: string
}

const CustomInput = ({ icon, onChange, placeHolder, value }: props ) => {
  return (
    <div className=" w-full flex items-center bg-gray-800 text-gray-400 rounded-xl p-2 bg-input-box-gray">
   {icon && <img src={icon}/>}
      <input
        type="text"
        placeholder={placeHolder}
        className="bg-transparent outline-none flex-grow bg-input-box-gray border-none text-text-gray w-full"
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default CustomInput;