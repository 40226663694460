import React, { useCallback, useEffect, useState } from 'react'
import CountryCodePicker from '../../../components/countryCodePicker'
import { validatePhoneNumber } from '../../../utils/common';
import { AuthService } from '../../../services/authService';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSpinner } from '../../../components/spinner/SpinnerContext';
import { getLocalStorage } from '../../../utils/localStorage';
import { LOCAL_STORAGE } from '../../../utils/constants';
import { toast } from 'react-toastify';
import PrimaryButton from '../../../components/primaryButton';
import Logo from '../../../assets/Images/login/logo.png'
import OTP_FAILED from '../../../assets/Images/login/otpFailed.png';
import ModalButton from '../../../assets/Images/login/modalButton.svg';
export default function Login() {

    const [selectedCountryCode, setSelectedCountryCode] = useState('+1');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [countryCodeError, setCountryCodeError] = useState('');
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    let location = useLocation();
    const { useEmail }: { useEmail?: boolean } = location?.state ?? {};
    const [loginWithEmail, setLoginWithEmail] = useState(useEmail ?? false);
    const [openEmailPopup, setOpenEmailPopup] = useState(false);

    const { setShowSpinner } = useSpinner();

    const navigate = useNavigate();

    useEffect(() => {
        if (getLocalStorage(LOCAL_STORAGE.USER_ID))
            navigate('/discover');
    }, [])

    const onCountryChange = (selectedValue: string) => {
        setSelectedCountryCode(selectedValue);
    }

    const onPhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/\D/g, '');
        setPhone(value || '');
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event?.target?.value)
    }

    const onContinue = async () => {
        try {
            if (!loginWithEmail) {
                if (!phone) {
                    setMobileNumberError(' * Please enter phone number.');
                    return;
                }
                if (validatePhoneNumber(phone)) {
                    setShowSpinner(true);
                    await AuthService.createOTP(selectedCountryCode + phone);
                    navigate("otp", { state: { mobileNumber: `${selectedCountryCode}${phone}` } });
                }
            } else {
                if (!email) {
                    setEmailError('* Please enter a valid email address.');
                    return;
                }
                else {
                    setShowSpinner(true);
                    await AuthService.createEmailOTP(email);
                    navigate("otp", { state: { email: email, mobileNumber: `${selectedCountryCode}${phone}` } });
                }
            }
        } catch (error: any) {
            setOpenEmailPopup(true);
            if (!loginWithEmail) {
                setMobileNumberError('Unable to send OTP. Check mobile number or try again.');
                toast.error('Unable to send OTP. Check mobile number or try again.');
            } else {
                setEmailError("Unable to send OTP. Check email or try again.");
                toast.error('Unable to send OTP. Check email or try again.')
            }
        } finally {
            setShowSpinner(false);
        }
    };

    return (
        <div className='w-full px-2 xl:px-0 md:w-[60%] xl:w-[40%] flex flex-col gap-16 md:justify-between items-start text-white '>
            <img src={Logo} alt="logo" className='w-20 h-10' />
            <div className='text-primary-white text-4xl font-bold'>
                Events happen.<br />Connections stay.
            </div>

            {!loginWithEmail ? <div className='w-full flex flex-col gap-3'>
                <span className='text-lg font-medium text-text-gray'>Enter your phone number</span>
                <div className='flex gap-5'>
                    <div className='w-1/3 lg:w-1/4 items-center flex '>
                        <CountryCodePicker onCountryChange={onCountryChange} />
                        {countryCodeError && <div className='text-primary-red text-sm w-full text-left'>{countryCodeError}</div>}
                    </div>

                    <div className='w-full'>
                        <input
                            key='1'
                            type="tel"
                            inputMode="numeric"
                            className=' h-full w-full rounded-xl placeholder:font-light border border-[#424242] text-white'
                            style={{ backgroundColor: '#222222' }}
                            placeholder="Mobile Number"
                            name="phone"
                            onChange={onPhoneNumberChange}
                            value={phone}
                        />
                        {mobileNumberError && <div className='text-primary-red text-sm w-full text-left mt-1'>{mobileNumberError}</div>}
                    </div>
                </div>
            </div> :
                <div className='flex flex-col gap-3 w-full'>
                    <span className='text-lg font-medium text-text-gray'>Enter your email ID</span>
                    <input
                        key='2'
                        type="text"
                        className='input placeholder:font-light border border-[#424242] text-white text-xs'
                        style={{ backgroundColor: '#222222' }}
                        placeholder="Email address *" name="email"
                        required
                        onChange={onEmailChange} />

                    {emailError && <div className=" text-primary-red text-sm w-full text-left" >{emailError}</div>}
                </div>}

            <div className='flex flex-col gap-3'>
                <div className='text-sm text-text-gray z-10'>
                    By clicking ‘Continue’, you confirm you’re 18 or older and agree to our <Link to="/help/terms-of-service" target="_blank">
                        <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange underline">
                            Terms of Service
                        </span>
                    </Link> and  <Link to="/help/eula" target="_blank">
                        <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange underline">
                            EULA
                        </span>
                    </Link>. Review how we handle your data in our <Link to="/help/privacy-policy" target="_blank"><span className='text-primary-white text-xs font-medium mb-4 hover:text-primary-orange underline'>Privacy Policy</span></Link>.
                </div>
                <PrimaryButton name='Continue' onClick={onContinue} classNames='p-3 z-20' />
            </div>

            {openEmailPopup && <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50`}>
                <div className="bg-[#181818] rounded-lg w-[90%] max-w-lg p-6 relative">
                    {/* Modal Body */}
                    <div className="py-4 flex flex-col justify-center items-center gap-5">
                        <img src={OTP_FAILED} alt="otp-failed" className='w-44 h-44' />
                        <span className='text-xl font-semibold'>OTP Delivery Failed</span>
                        <span className='text-sm text-text-gray text-center'>{loginWithEmail ? `Please double check your email address ${email} or use your mobile number instead` : `Please double check your mobile number ${selectedCountryCode + phone} or use your email instead`}</span>
                        <div className='w-full px-5 py-4 flex justify-between items-center border border-[#424242] rounded-xl p-2 cursor-pointer' onClick={() => setOpenEmailPopup(false)}>
                            <span className='text-sm '>{loginWithEmail ? 'Edit email address' : 'Edit phone number'}</span>
                            <img src={ModalButton} alt="modal-button" className='w-4 h-4' />
                        </div>
                        <div
                            className='w-full px-5 py-4 flex justify-between items-center border border-[#424242] rounded-xl p-2 cursor-pointer'
                            onClick={() => {
                                if (loginWithEmail)
                                    setLoginWithEmail(false)
                                else
                                    setLoginWithEmail(true);
                                setOpenEmailPopup(false)
                            }}>
                            <span className='text-sm '>{loginWithEmail ? 'Use mobile number to login' : 'Use email to login'}</span>
                            <img src={ModalButton} alt="modal-button" className='w-4 h-4' />
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
