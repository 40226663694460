import { useState, useRef, useCallback, useEffect } from "react";
import Cropper, { Area } from "react-easy-crop";

type Crop = {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface ImageCropperProps {
    imageFile: File | null;
    onClose: () => void;
    onCropComplete: (croppedImage: string) => void;
}

const ImageCropper = ({ imageFile, onClose, onCropComplete }: ImageCropperProps) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>({ x: 0, y: 0, width: 500, height: 500 });
    const [cropFinal, setCropFinal] = useState<Crop>({ x: 0, y: 0, width: 0, height: 0 });
    const [zoom, setZoom] = useState<number>(1.0);

    // Load the image when the file changes
    useEffect(() => {
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target?.result as string);
            };
            reader.readAsDataURL(imageFile);
        }
    }, [imageFile]);

    const onCropChange = useCallback((location: { x: number; y: number }) => {
        setCrop({ ...crop, x: location.x, y: location.y });
    }, [crop]);

    const onHandleCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCropFinal(croppedAreaPixels);
    }, []);

    const getCroppedImg = async () => {
        setLoading(true);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx && imageSrc) {
            const img = new Image();
            img.src = imageSrc;

            // Wait for the image to load
            img.onload = () => {
                const pixelRatio = window.devicePixelRatio;
                canvas.width = cropFinal.width * pixelRatio;
                canvas.height = cropFinal.height * pixelRatio;
                ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                ctx.drawImage(
                    img,
                    cropFinal.x,
                    cropFinal.y,
                    cropFinal.width,
                    cropFinal.height,
                    0,
                    0,
                    cropFinal.width,
                    cropFinal.height
                );

                // Create a blob from the canvas
                canvas.toBlob((file) => {
                    if (file) {
                        const croppedImageUrl = URL.createObjectURL(file);
                        onCropComplete(croppedImageUrl);
                    }
                    setLoading(false);
                }, 'image/jpeg');
            };
        } else {
            setLoading(false);
            onClose(); // Close if there's an error
        }
    };

    return (
        <div className="absolute top-0 left-0 w-full h-full">
            <Cropper
                image={imageSrc || ''}
                crop={crop}
                onCropChange={onCropChange}
                onCropComplete={onHandleCropComplete}
                zoom={zoom}
                aspect={1}
                onZoomChange={setZoom}
            />
            <button className="bg-primary-orange text-primary-white px-4 py-2 rounded-md absolute top-4 right-4" onClick={getCroppedImg} disabled={isLoading}>Crop Image</button>
            <button className="bg-primary-orange text-primary-white px-4 py-2 rounded-md absolute top-4 left-4" onClick={onClose}>Cancel</button>
        </div>
    );
};

export default ImageCropper;