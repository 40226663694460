import React, { ChangeEvent } from 'react'


interface props {
    heading: string;
    description?: string
    value?:string
    option?:any
    optionChange?: (event:ChangeEvent<HTMLInputElement>)=> void
}

function RadioInput({heading, description,value, option, optionChange} : props) {



  return (
    <div className='w-1/2 bg-input-box-gray rounded-2xl p-4'>
      <div className='flex justify-between'>
        <label className='text-primary-white text-lg'>{heading}</label>
        <input type='radio' value={value} checked={option === value} onChange={optionChange} className='text-primary-orange checked:border-transparent focus:ring-0'/>
      </div>
      <p className='text-[#535353] text-sm mt-4'>{description}</p>
    </div>
  )
}

export default RadioInput