import React, { useEffect } from "react";
import styles from './PrivacyPolicy.module.scss'

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsHeader}>
        <h1>SONDR, INC.</h1>
        <h1>PRIVACY POLICY</h1>
      </div>
      <div className={styles.termsContent}>
        <p>
          This Privacy Policy describes the privacy practices of Sondr, Inc. (
          <b>“Sondr,” “us,” “our,”</b> or <b>“we”</b>) with respect to your
          information collected, stored, disclosed and used by Sondr, through
          our Platform, App or Services, (as each term is defined in the Terms
          of Service posted at https:// www.joinsocha.com/terms).
        </p>
        <p>
          <b>
            YOUR USE OF OUR PLATFORM, APP AND SERVICES IS SUBJECT TO THIS
            PRIVACY POLICY, END USER LICENSE AGREEMENT, THE TERMS OF SERVICE
            AND, IF APPLICABLE, THE HOST AGREEMENT. BY USING OUR PLATFORM, APP
            AND SERVICES, YOU ARE ACCEPTING THE PRACTICES SET OUT IN THIS
            PRIVACY POLICY.
          </b>
        </p>
        <p>
          If we decide to change our Privacy Policy, we will post those changes
          to this page and any other places we deem Appropriate so that you are
          aware of what personal information we collect, how we use it, and
          under what circumstances, if any, we disclose it.{" "}
        </p>
        <p>
          The effective date of this Privacy Policy is noted below. We reserve
          the right to modify this Privacy Policy at any time, so please review
          it frequently. If we make material changes to this Privacy Policy, we
          will notify you here, or by other means, such as e-mail, at our
          discretion. Your continued use of any portion of our Platform, App or
          Services following the posting of the updated Privacy Policy will
          constitute your acceptance of the changes.{" "}
        </p>
        <ol>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                1. What Personal Information We Collect and How We Collect It{" "}
              </b>
            </p>
            <p>
              In order to provide our Services, we collect information about you
              that, alone or in combination with other information, can be used
              to identify you as an individual <b>(“personal information”)</b>.
              What personal information we collect and process depends on how
              and why you interact with us.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (a) Personal Information You Provide.
              </span>{" "}
              When you register for an account or subscribe to any of our
              Services to become a registered user or member <b>(“Member”)</b>,
              we collect a variety of information about you, including your
              contact information such as your legal name, email address,
              contact telephone number, mailing address, date of birth, social
              networking and going out and entertainment preferences, and so on.
              When you sign up for our Services and become a Member, you may
              provide us with answers to our proprietary personal profile
              building tool, which we use to build a personality profile for you
              to introduce yourself to other Members, for Hosts of Events to
              invite you to attend their Events, and for us to provide certain
              of our Services to you. You may also choose to provide us with
              your photo(s) which may contain personal information. By providing
              us photo(s) you agree that we may make them available to other
              Members. We may also collect your name, contact information (such
              as your email), and a copy of your correspondences with us, if you
              contact us. You may also be able to communicate with other Members
              via messaging, posting and other interactive features. If you use
              such features, you acknowledge and agree that the Members with
              whom you choose to communicate will have access to certain of your
              information, including text, photos, video and audio recordings of
              you and that we and our agents and service providers will have
              access to such communications and recordings of you as well as
              your Member profile. Furthermore, we may use a third-party service
              provider to enable similar features, and you agree that we will
              transfer your IP address and profile ID to this service provider
              strictly for the purpose of providing Services. To process
              purchases, we may require your name, address, phone number, email
              address and credit card information or other payment methods. If
              you would like us to delete such payment information from our
              systems, you may contact us as described under the “Contacting Us”
              section below.{" "}
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (b) Sensitive Information.
              </span>{" "}
              During the registration process and while updating your Member
              profile, you may provide us with personal information, including
              but not limited to, date of birth, place of birth, gender
              identity, marital status, sexual preferences, religious beliefs,
              ethnicity and political views. This information is only used by us
              to provide the Services to you. By providing this information, you
              agree to our storing, processing, and use of such data for the
              purposes stated above. You may change update your information by
              accessing your profile at any time or select the “prefer not to
              specify” option in response to any questions requesting such
              information.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (c) Passwords; Member Account.
              </span>{" "}
              You will also be required to create a password in connection with
              the registration of your account. Once you register, you will be
              able to review and change your password and certain other account
              information at any time by accessing your “Profile” page on the
              App. You control your Member account and are able to correct or
              update (other than your email address, mobile phone number, date
              of birth, gender and location (which is automatically updated))
              your registration information at any time through the App. Certain
              registration information such as your Member name may be visible
              to the public.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>(d) Surveys.</span>{" "}
              We may periodically conduct voluntary member surveys. We encourage
              our members to participate in such surveys because they provide us
              with important information regarding the improvement of our
              Services. You may also volunteer for certain surveys that we may
              offer to our users, and any additional rules regarding the conduct
              of such surveys will be disclosed to you prior to your
              participation. We do not link the survey responses to any personal
              information, and all responses are anonymous.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (e) Personal Information
              </span>{" "}
              We Automatically Collect. When you access or use our Services, we
              automatically collect information about how you access and use our
              Platform, App and Services. The information we collect includes
              your IP address, browser type, Internet service provider (ISP),
              referring/exit pages, platform type, date/time stamp, and number
              of clicks. If you use a mobile device to access or download any of
              our Services, we may also collect device information (such as your
              mobile device ID, model and manufacturer), operating system, and
              version information. While this information taken together could
              be considered personal information, we do not use this information
              to personally identify you. We use this information in the
              aggregate to analyze trends, administer our Services, prevent
              fraud, understand how users interact with our Services, and gather
              demographic information to tailor our visitors’ experience on our
              Services, show them content that we think they might be interested
              in, and display the content according to their preferences. We do
              not share this information with third parties.
            </p>
            <p>
              The technologies we use to automatically collect data from you may
              include:
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (i) Cookies & Web Tracking.
              </span>{" "}
              We use “cookies” to keep track of some types of information while
              you are accessing or using our Services. Cookies are very small
              files placed on your computer, and they allow us to count the
              number of visitors to our Platform and App and distinguish repeat
              visitors from new visitors. They also allow us to save user
              preferences and track user trends. We rely on cookies for the
              proper operation of our Platform and App; therefore if your
              browser is set to reject all cookies, our Platform and App will
              not function properly. Some cookies are automatically erased after
              the end of the browser session (these are “session cookies”),
              whereas other cookies are stored for a predetermined amount time
              or permanently in your browser, before they erase themselves
              (these are “temporary" or "permanent" cookies).While a cookie may
              distinguish your device, it will not name you. Virtually every
              website uses some form of cookie technology.{" "}
            </p>
            <p style={{ textDecoration: "underline" }}>
              What kind of cookies does Sondr use?
            </p>
            <p>
              <b>Strictly necessary cookies</b>
            </p>
            <p>
              We use certain cookies to make sure that our Platform and our
              Service is easy, secure and safe to use. Without these cookies,
              services that you have asked for (such as a secure website and
              service), would not be possible. This category of cookies
              includes:
            </p>
            <ul className={`${styles.ml1} ${styles.revert}`}>
              <li>
                <p>
                  Cookies that identify or authenticate our users to ensure that
                  our service is secure;
                </p>
              </li>
              <li>
                <p>Cookies that temporarily store certain user entries ;</p>
              </li>
              <li>
                <p>
                  Cookies that store certain user preferences (such as your
                  language choices).
                </p>
              </li>
            </ul>
            <p>
              <b>Analytics cookies</b>
            </p>
            <p>
              We use analytics cookies to record usage data about our users
              (e.g. which users have visited which of our subpages, etc.) and to
              evaluate this information statistically.
            </p>
            <p>
              <b>
                Third Party Advertising Cookies / Third Party Pixel / Third
                Party Tracking
              </b>
            </p>
            <p>
              We also allow third parties to collect our users' data through
              advertising cookies or pixels placed on our Platform. These
              cookies allow us and other third parties to provide you with
              interest-based advertising that is based on an analysis of your
              usage behavior (e.g., which banner ads you have clicked on, which
              subpages you have visited, etc.) on the internet and our Services.
            </p>
            <p style={{ textDecoration: "underline" }}>
              {" "}
              How can you deactivate or erase our cookies?{" "}
            </p>
            <p>
              ou can deactivate cookies on your device via your browser
              settings; and you can erase any cookies already stored on your
              device at any time in your browser. Please note, however, that if
              you choose to deactivate or remove cookies, doing so may prevent
              certain features on our Platform from working properly and
              therefore affect your experience on our Platform. In addition, you
              may not be able to use all the features of our Service if you
              deactivate or remove cookies.
            </p>
            <p>
              For additional information and opt-out instructions for
              interest-based advertising, please review our Third Party
              Advertising section below.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (ii) Web Beacons.
              </span>{" "}
              “Web beacons” (also known as “clear gifs” and “pixel tags”) are
              small transparent graphic images that are often used in
              conjunction with cookies in order to further personalize our
              Platform for our users and to collect a limited set of information
              about our visitors. We may also use web beacons in email
              communications in order to understand the behavior of our
              customers.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (iii) Web Analytics.
              </span>{" "}
              We collect and use statistical information about your use of our
              Services to make it more user-friendly, to understand our market
              share, to conduct other market research and to make sure that we
              display relevant advertisements for you. To this end, we work with
              various authorized service providers; and we use analytics tools
              in our Platform. However, these tools will not use your IP
              address, or (if they do), they will shorten it immediately after
              collection (which means that it will be less clearly be linked to
              your device). These tools will also generate Member profiles by
              using analytic cookies or by evaluating log files; however these
              Member profiles will not be linked to your real world information
              and will not name you.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (iv) Promotional e-mails.
              </span>{" "}
              Promotional e-mail messages we send you may contain code that
              enables our database to track your usage of the e-mails, including
              whether the e-mail was opened and what links (if any) were
              clicked. If you would rather not receive promotional e-mails from
              us, please see the section below labeled “Choice/Opt-Out.”
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                (f) Personal Information We Receive from Others.
              </span>{" "}
              In addition to information you provide us directly, and that we
              collect automatically, we also may receive personal information
              from:
            </p>
            <ul className={`${styles.ml1} ${styles.revert}`}>
              <li>
                <p>
                  Fraud-detection and credit-reference agencies to correct our
                  records and help prevent and detect fraud;
                </p>
              </li>
              <li>
                <p>
                  Sources that are available to the public, which we might
                  combine with information that we collect from you directly;
                  and
                </p>
              </li>
              <li>
                <p>A family member or someone else acting on your behalf.</p>
              </li>
            </ul>
            <p>
              We encourage Members to refer a friend to our Services by sending
              us a friend’s name and email address. We will keep this
              information in our database, and send that person a one-time
              e-mail containing your name and inviting them to visit our
              Platform. This e-mail will also include instructions on how to
              remove their information from our database. If you refer a friend,
              you agree that you will not abuse this feature by entering names
              and addresses of those who would not be interested in our
              Services.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                2. How and Why We Use Your Personal Information
              </b>
            </p>
            <p>
              We use information that we collect about you or that you provide
              to us, including any personal information, to:
            </p>
            <ul className={`${styles.ml1} ${styles.disc}`}>
              <li>Provide, secure and improve our Services;</li>
              <li>Establish and manage Member accounts;</li>
              <li>
                Display the public fields of your Member profile to Hosts (as
                defined in the Terms of Service) of Events and other Members,
                including recent login date, user name, photos, videos, and
                other profile content;
              </li>
              <li>
                Provide customer support, troubleshoot issues, manage our
                Services, and respond to requests, questions, and comments;
              </li>
              <li>Process transactions;</li>
              <li>
                Communicate with you about our Services, including order
                confirmation, refund and renewal processing, notifying of
                expressions of interests from other Members (including Hosts of
                Events), notifications of future Events, and notices for our
                Services, service announcements and administrative messages;
              </li>
              <li>
                Communicate with you regarding promotions, including
                newsletters, new product offerings, special discounts, event
                notifications, special third-party offers;
              </li>
              <li>Conduct market and consumer research and trend analyses;</li>
              <li>
                Enable posting on our blogs, forums, and other public
                communications;
              </li>
              <li>Present webinars and other interactive events;</li>
              <li>
                Perform accounting, auditing, billing, reconciliation, and
                collection activities;
              </li>
              <li>
                Prevent, detect, identify, investigate, and respond to potential
                or actual claims, liabilities, prohibited behavior, and criminal
                activity;
              </li>
              <li>
                Comply with and enforce legal requirements, agreements, and
                policies;
              </li>
              <li>
                Achieve purposes for which we provide specific notice at the
                time of collection;
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
            <p>
              One of the methods we use to communicate with you in connection
              with our Services may be by telephone or text message (including
              to any wireless number you may provide to us). For example, you
              may sign up for a text messaging service (
              <b>“SMS Verification”</b>) which enables Sondr to verify your
              phone number via a confirmed text message and display such
              verification in your public account (as a security measure for the
              Members’ benefit). If you would rather not receive telephone calls
              or text messages from us, you may change or delete your number
              from your account preferences page(s), or ask to be removed from
              our contact list if you receive a call or text message from us.
              You may also opt-out of the SMS Verification service at any time
            </p>
            <p>
              In addition to the uses outlined above, by accessing or using our
              Services, you agree to allow us to anonymously use the information
              from you and your experiences to continue our research into social
              networking and successful relationships. This research may be
              published. However, all of your responses will be kept anonymous,
              and no personal information will be published.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                3. Mobile Push Notifications
              </b>
            </p>
            <p>
              We will send you push notifications if you choose to receive them
              for purposes of receiving notice of Events which may be of
              interest to you, other Members (including Hosts of Events) who
              would like to introduce themselves to you, whether at an Event or
              at any other location where you and such other Members are
              physically present, letting you know when someone has sent you a
              message, or for other Service-related matters. If you wish to
              opt-out from receiving these types of communications (other than
              system notifications, which may not be turned off), you may turn
              them off at the device level.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                4. If Information is Not Provided
              </b>
            </p>
            <p>
              If you do not provide us with some or all of the personal
              information we request, this may limit our ability to fulfill the
              applicable purpose for collection and the services we are able to
              provide you. For example, we may not be able to consider or
              process your payment, present prospective Events to you, introduce
              you to Hosts or other Members or provide a particular service you
              have requested.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                5. Disclosure of Your Personal Information
              </b>
            </p>
            <p>In general, we may share personal information we collect:</p>
            <ul className={`${styles.ml1} ${styles.disc}`}>
              <li>
                With current and future entities that are part of our network
                for the purposes described in this Privacy Policy, including
                with your consent;
              </li>
              <li>
                With affiliated and unaffiliated service providers who help us
                perform and deliver our Services, subject to agreements,
                including: customer care agents; technology assistants; service
                fulfillment; form processing; website management and hosting;
                information technology and security; email and newsletter
                delivery; advertisement partners and corporate sponsors;
                auditing; and credit card processing;
              </li>
              <li>
                With the appropriate authorities if we believe disclosure is
                necessary and appropriate to prevent physical, financial, or
                other harm, injury, or loss, including to protect against fraud
                or credit risk;
              </li>
              <li>
                With legal, governmental, or judicial authorities as instructed
                or required by those authorities and applicable laws, or in
                relation to a legal activity, such as in response to a subpoena
                or investigation of suspected illicit or illegal activities, or
                where we believe in good faith that users may be engaged in
                illicit or illegal activities, or where we are bound by contract
                or law to enable a network partner to comply with applicable
                laws;
              </li>
              <li>
                With necessary third parties in connection with, or during
                negotiations for, an acquisition, merger, asset sale, or other
                similar business transfer that involves all or substantially all
                of our assets or functions where personal information is
                transferred or shared as part of the business assets;
              </li>
              <li>
                With your consent or at your direction, such as when you choose
                to share information or publicly post content and reviews (for
                example, social media posts);
              </li>
              <li>
                With other Members of your choosing and at your discretion,
                should the Services you are subscribed to allow that
                functionality.
              </li>
            </ul>
            <p>
              <b>
                In addition, Sondr may disclose personal information as follows.
              </b>
            </p>
            <ul className={`${styles.ml1} ${styles.disc}`}>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  To Your Hosts and Prospective Hosts.
                </span>{" "}
                As an integral part of providing some of our Services, we may
                disclose your profile information to the Hosts of Events you
                register for or purchase tickets for and potential Hosts of
                Events you may be interested in attending you would like to
                connect with, including photos and other profile information
                posted by you. We never share your private contact information
                with other Members (other than the Hosts referred to above).
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  To Comply with Valid Legal Requests.
                </span>{" "}
                You acknowledge and agree that we may disclose information you
                provide if required to do so by law, or if we, in our sole
                discretion, believe that disclosure is reasonable to (1) comply
                with the law, requests or orders from law enforcement, or any
                legal process (whether or not such disclosure is required by
                applicable law); (2) protect or defend Sondr’s, or a third
                party’s, rights or property; or (3) protect someone’s health or
                safety, such as when harm or violence against any person
                (including you) is threatened.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  To Protect Abuse Victims.
                </span>{" "}
                Notwithstanding any other provision of this Privacy Policy or
                our Terms of Service, we reserve the right, but have no
                obligation, to disclose any information that you submit to the
                Services, if in our sole opinion, we suspect or have reason to
                suspect, that the information involves a party who may be the
                victim of abuse in any form. Abuse may include, without
                limitation, elder abuse, child abuse, spousal abuse, neglect, or
                domestic violence. Information may be disclosed to authorities
                that we, in our sole discretion, deem Appropriate to handle such
                disclosure. Appropriate authorities may include, without
                limitation, law enforcement agencies, child protection agencies,
                or court officials. You hereby acknowledge and agree that we are
                permitted to make such disclosure.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  To Trusted Third Parties By Us.
                </span>{" "}
                We may share your information with third parties, but not in a
                manner that would reveal your identity. We may share your
                personal information, sometimes in conjunction with
                non-identifying information, with service providers that may be
                associated with us to perform functions on our behalf. For
                example, outsourced customer care agents or technology
                assistants may need access to your information to perform
                services for you. Your information will be treated as private
                and confidential by such service providers and not used for any
                other purpose than we authorize.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  To Transfer As a Result of Sale of Business.
                </span>{" "}
                As we develop our business, we may buy or sell assets and,
                depending on the transaction, your personal information may be
                one of the transferred assets. In the event that we are acquired
                by another company, your personal information may be part of the
                assets transferred to the acquiring party. If this occurs, you
                will be notified if there are any material changes to the way
                your personal information is collected or used.
              </li>
            </ul>
            <p>
              We process all information, including your personal information,
              via our servers and networks located in the United States.
              However, some of our service providers may be located in other
              jurisdictions. By using our Services and expressly assenting to
              this privacy policy, you have consented to the transfer of your
              personal information solely for the purpose of providing you the
              Services for which you have registered or to which you have
              subscribed. We take all reasonable steps to ensure that all
              overseas recipients will not hold, use or disclose your personal
              information inconsistently with applicable law.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                6. Third-Party Advertising
              </b>
            </p>
            <p>
              We may use third-party advertising agencies or other service
              providers to serve ads on our Platform on behalf of Sondr. These
              companies may employ cookies or pixels to measure advertising
              effectiveness. Any information that these third parties collect
              via cookies and pixels is anonymous. Some of these third-party
              advertising agencies may be members of the Digital Advertising
              Alliance, which offers you the option to opt out of ad targeting
              from its member agencies by following the procedures listed here
              at https://optout.aboutads.info/ or for Applications, access the
              App Choices Platform here at https://youradchoices.com/choices.
            </p>
            <p>
              We will only share your information with third parties in the ways
              defined in this Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>7. Age Restrictions</b>
            </p>
            <p>
              We only accept registrations to our Services from users who are 18
              years or older (or the age of majority in applicable
              jurisdictions, if higher), and any information we may receive from
              Members we believe to be under the age of 18 will be purged from
              our database. If you believe that we may have collected any such
              personal information through our Services, please notify us as
              specified below.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>8. Custom Audience</b>
            </p>
            <p>
              We may also use services provided by third-party websites (such as
              social networking and other websites) to serve targeted
              advertisements on such websites to you or others, and we may
              provide a hashed version of your email address or other
              information to the website operator or provider for such purposes.
              To opt-out of the use of your information for such purposes,
              please email us at support@joinsocha.com.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>9. Security</b>
            </p>
            <p>
              We store information in a combination of electronic storage
              facilities, paper-based files and other records. While we cannot
              guarantee your personal information is completely secure at all
              times, we have administrative, organizational, technical, and
              physical security measures in place to help protect against the
              loss, misuse and alteration of the information collected and
              processed. These measures include the use of firewalls, digital
              certificates, Security Socket Layer (SSL) and encryption
              technology during credit card transactions and administrative
              access to website data, as well as other proprietary security
              measures which are applied to all repositories and transfers of
              Member information.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                10. “Do Not Track” Signals
              </b>
            </p>
            <p>
              We do not process or respond to web browsers’ “do not track”
              signals or other similar transmissions that indicate a request to
              disable online tracking of users who use or visit our Platform and
              use our App. You may, however, disable certain tracking as
              discussed above (e.g., by disabling cookies). Please consult the
              “Help” section of your internet browser for more information.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                11. Links to or Access from Other Website or Platforms
              </b>
            </p>
            <p>
              You may be able to access your account or content of your account
              from third party websites or platforms, such as social networking
              websites, by way of various applications. These services will
              authenticate your identity and provide you with the option to
              share certain personal information with us such as your name and
              email address to pre-populate our registration form. The privacy
              policies and practices of such websites in connection with
              information you disclose on such websites may differ from the
              practices of Sondr as set forth in this Privacy Policy. We are not
              responsible for the privacy policies or practices or the content
              of any other Platforms that may provide access to, or be linked to
              or from, our Services, including that of any social networking
              websites.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>12. Choice/Opt-Out</b>
            </p>
            <p>
              You may use the following options for removing your information
              from our e-mail database if you wish to opt out of receiving
              promotional emails.
            </p>
            <ul className={styles.ml2}>
              <li>
                1. Click on the "unsubscribe" link on the bottom of the e-mail;
              </li>
              <li>
                2. Send mail to the following postal address or email to the
                following email address letting us know which promotional
                e-mails you wish to opt-out of:
              </li>
              <li>
                <ul className={styles.ml3}>
                  <li style={{ marginBottom: "0 !important" }}>Sondr, Inc.</li>
                  <li style={{ marginBottom: "0 !important" }}>
                    442 Fifth Avenue, #1625
                  </li>
                  <li style={{ marginBottom: "0 !important" }}>
                    New York, NY 10018
                  </li>
                  <li style={{ marginBottom: "0 !important" }}>
                    Email: support@joinsocha.com
                  </li>
                </ul>
              </li>
              <li>
                For our Services, select our Help Center link from the Profile
                page of the App and search our FAQs to find the answer you are
                looking for, or send us an email and our Customer Service
                representatives will be happy to assist you; or
              </li>
              <li>
                For any Services that allow you to control which e-mails you
                receive (other than system notifications from us), go to the
                Profile page of the App, and uncheck the undesired promotions.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                13. Updating / Deleting Your Personal Information
              </b>
            </p>
            <p>
              We provide Members the following options to access, change,
              correct, update or delete personal information previously
              submitted to us.
            </p>
            <ul className={styles.ml2}>
              <li>
                (a) Certain information (such as profile settings, credit card,
                and email address updates) may be updated by logging in to your
                account and updating directly.
              </li>
              <li>
                (b) Sondr requires that some personal information updates (such
                as date of birth changes) be processed by our customer care
                group for verification purposes and to protect other members. In
                order to make such personal information updates, please contact
                Customer Service at support@joinsocha.com for further
                assistance.
              </li>
              <li>
                (c) To request deletion of your personal account information,
                please access our FAQ located on the Profile page within the App
                and follow the process under the relevant account deletion FAQ.
                Requests for personal information deletion will be processed
                promptly.
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                14. Retention and Storage of Your Personal Information
              </b>
            </p>
            <p>
              We retain your personal information for as long as necessary to
              fulfill the purpose(s) for which it was collected and to comply
              with applicable laws, and your consent to such purpose(s) remains
              valid after termination of your relationship with us. Except for
              information required for accounting or record-keeping purposes, we
              will automatically delete your personal information after 12
              months of inactivity as a Member (as defined in the Terms of
              Service) unless we have a valid reason to retain such information.
            </p>
            <p>
              Your personal information may be processed and stored outside of
              the jurisdiction or country in which you reside by us, an
              Affiliate or an unaffiliated service provider for the purposes set
              out in this Privacy Policy and, under the laws of these other
              jurisdictions, in certain circumstances foreign courts, law
              enforcement agencies or regulatory agencies may be entitled to
              access your personal information.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>15. Contacting Us</b>
            </p>
            <p>
              If you have any questions about this Privacy Policy or our privacy
              practices, you can contact us by writing to:
            </p>
            <ul className={styles.ml2}>
              <li style={{ marginBottom: "0 !important" }}>Sondr, Inc.</li>
              <li style={{ marginBottom: "0 !important" }}>
                442 Fifth Avenue, #1625
              </li>
              <li style={{ marginBottom: "0 !important" }}>
                New York, NY 10018
              </li>
              <li style={{ marginBottom: "0 !important" }}>
                Email: legal@joinsocha.com
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                16. Dispute Resolution
              </b>
            </p>
            <p>
              If you have an unresolved privacy or data use concern that we have
              not addressed satisfactorily, please contact us at the address or
              email address listed above.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>
                17. Residents of California - California Privacy Policy
              </b>
            </p>
            <p>
              This Section 17 of this Privacy Policy is applicable only to
              California residents.
            </p>
            <p style={{ textDecoration: "underline" }}>
              California Consumer Rights
            </p>
            <p>
              Effective January 1, 2020, under the California Consumer Privacy
              Act (“CCPA”), California residents have the right to know what
              categories of personal information we generally collect, use,
              disclose, and/or sell about them within the past 12 months.
              California residents have the right to know where that information
              came from, the business or commercial purposes for the collection,
              and the categories of third parties with and/or to whom that
              information was shared and/or sold within the past 12 months.
              Throughout this Privacy Policy, and specifically in this section,
              we have set out our policies and processes as it relates to the
              collection, use and disclosure of your personal information.
            </p>
            <p>
              California residents also have the right to request access to that
              information on an individual level, to remove their personal
              information in accordance with Applicable laws. Consumers who have
              a visual disability may be able to use a screen reader or other
              text-to-speech or text-to-Braille tool to review the contents of
              this notice.
            </p>
            <p style={{ textDecoration: "underline" }}>Data Access Request</p>
            <p>
              If you are a California consumer and you would like to submit a
              request to exercise your right to know what personal information
              Sondr has collected, used or disclosed to third parties in the
              12-month period prior to your request, you can make a request by
              sending an email to legal@joinsocha.com with "CCPA Request" in the
              subject line, along with your email address and phone number used
              to sign up to the App. Please be sure specify in your request the
              details you would like to know, including any specific pieces of
              personal information you would like to access.
            </p>
            <p>
              Please note that your right to know can be exercised without
              justification and free of charge. However, we may request a fee,
              notably when requests are unfounded, excessive or have a
              repetitive character. We are not required to provide access to
              personal information more than twice in 12 months.
            </p>
            <p style={{ textDecoration: "underline" }}>Data Deletion Request</p>
            <p>
              If you are a California consumer and a Member (as defined in our
              Terms of Service) and would like to exercise your personal data
              deletion rights, you can log into your account in the App, visit
              the Profile page of the App, and click Delete Account. Please note
              that this deletion on your own is only possible on our App and not
              in our website. If you cannot log into the App, you need to submit
              a data deletion request as described below.
            </p>
            <p>
              To submit a data deletion request, please send an email to
              legal@joinsocha.com with "CCPA Deletion Request" in the subject
              line, along with your email address and phone number used to sign
              up to the App.
            </p>
            <p>
              Please note that your right to deletion can be exercised without
              justification and free of charge. However, we may request a fee,
              notably when requests are unfounded, excessive or have a
              repetitive character. We are not required to comply with your
              request to deletion in the following circumstances: (i) to perform
              our contract with you, (ii) to detect security incidents, protect
              against malicious, deceptive, fraudulent, or illegal activity, or
              prosecute those responsible for the activity, (iii) to debug to
              identify and repair errors that impair existing intended
              functionality, and/or (iv) to comply with our obligation to
              maintain records of consumer requests made pursuant to the CCPA.
            </p>
            <p style={{ textDecoration: "underline" }}>Verification Process</p>
            <p>
              To protect your privacy, we must first verify your identity before
              we can respond to and process your request for access to specific
              data or to delete data. To do that and if you are a Member, please
              login and send us your request through one of the contact links
              above that we can easily identify you. If you are not able to log
              in or access your Member profile through the App before sending us
              your request, we will request certain account information that may
              include the following: the email address associated with your
              Member profile, your Member ID and your service password (that is
              not your personal password) in response to your request. Your
              Member ID and service password are assigned to you when you
              register for our Services. If you cannot provide us with such
              information, we will ask you to provide other account information
              to verify your identity. Requests to opt out of selling of
              personal information, if applicable, do not need to be verified
              and will be honored in accordance with applicable laws.
            </p>
            <p>
              We will make good faith efforts to provide you with access to your
              data when you request it, but there may be circumstances in which
              we may not provide access or delete data as allowed by law. For
              example, we are not required to delete personal information needed
              to provide a Service you have asked us to provide, to detect
              fraudulent or illegal activity, required for bookkeeping or tax
              purposes (e.g., transaction data), or required for legal purposes.
              Also, we are not required to provide access to information that
              contains legal privilege, where your identity could not be
              verified, or where the information would compromise others’
              privacy or other legitimate rights, like intellectual property
              rights. If we determine that your request for access or deletion
              should be denied or restricted in any particular instance, we will
              provide you with an explanation of why that determination has been
              made and a contact point for any further inquiries.
            </p>
            <p style={{ textDecoration: "underline" }}>Authorized Agent</p>
            <p>
              If you wish to designate an authorized agent to make a request on
              your behalf, please let us know when you make your request. You
              can designate an authorized agent to make a request under the CCPA
              on your behalf if: (i) the authorized agent is a natural person or
              a business entity registered with the Secretary of State of
              California; and (ii) you sign a written declaration that you
              authorize the authorized agent to act on your behalf.
            </p>
            <p>
              If you use an authorized agent to submit a request to exercise
              your right to know or your right to request deletion, please take
              the following steps:{" "}
            </p>
            <ul className={`${styles.ml2} ${styles.disc}`}>
              <li>
                Mail your written declaration authorizing the authorized agent
                to act on your behalf, certified by a California notary public,
                to this address: Sondr, Inc., 442 Fifth Avenue, #1625, New York,
                NY 10018, Attn: CCPA Request; and
              </li>
              <li>
                Provide any information we request as described above and/or in
                our response to verify your identity.
              </li>
            </ul>
            <p style={{ textDecoration: "underline" }}>Non-Member / Visitor</p>
            <p>
              If you are a California consumer and a non-Member or visitor to
              our Platform and you would like to exercise your right to know or
              your right to deletion: please note that we only collect, use, and
              disclose non-name identifying information. For this reason, we do
              not have a reasonable method by which we could verify your
              identity, or individually verify all the members of a household,
              to the degree of certainty required under CCPA. Therefore, we are
              not able to comply with your request.
            </p>
            <p style={{ textDecoration: "underline" }}>Data Retention</p>
            <p>
              Personal data of Members will be stored for the duration of your
              membership. However, we will erase your data at your request,
              provided that there is no statutory storage obligation that
              Applies to that information. If your data is subject to a
              mandatory storage period, we will ensure that this information is
              isolated and stored until the expiration of the mandatory
              retention period. Once your membership is over, Sondr
              automatically erases profile data of Members who are inactive for
              12 months.
            </p>
            <p>
              Sondr will also store any personal data which is required to
              demonstrate that Sondr has lawfully complied with valid requests
              under the CCPA for the required period.
            </p>
            <p style={{ textDecoration: "underline" }}>
              Additional Information
            </p>
            <p>
              Any access or deletion requests that can be processed by us will
              be done so within 45 days from the date we receive your request
              unless we notify you that an extension is required. In case of an
              extended completion period, we will process your request within 90
              days from the date of your original request. If we are unable to
              satisfactorily verify your identity, we will not be able to
              process your request.
            </p>
            <p>
              We will not discriminate against you if you choose to exercise any
              of your privacy rights under California law.
            </p>
            <p style={{ textDecoration: "underline" }}>
              Right to Opt-Out of the Sale of Personal Information
            </p>
            <p>
              California residents also have the right to request that we limit
              or stop the “selling” (a broad word used to also include some
              types of sharing) of their personal information under certain
              circumstances. However, we do not engage in selling personal
              information.
            </p>
            <p style={{ textDecoration: "underline" }}>
              Contact for More Information or Questions?
            </p>
            <p>
              If you have any questions about this section or about exercising
              your rights described above, you can contact us by mailing us at:
            </p>
            <ul className={`${styles.ml1} ${styles.disc}`}>
              <li style={{ marginBottom: "0 !important" }}>Sondr, Inc.</li>
              <li style={{ marginBottom: "0 !important" }}>
                442 Fifth Avenue, #1625
              </li>
              <li style={{ marginBottom: "0 !important" }}>
                New York, NY 10018
              </li>
              <li style={{ marginBottom: "0 !important" }}>
                Email: legal@joinsocha.com
              </li>
            </ul>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>18. Effective Date</b>
            </p>
            <p>This Privacy Policy was last revised on June 7, 2023.</p>
          </li>
        </ol>
      </div>
      <div className={styles.termsFooter}>*****</div>
    </div>
  );
}
