import React, { useEffect, useState } from "react";
import GuestIcon from "../../../assets/Icons/eventDetails/guestList.svg";
import ExploreIcon from "../../../assets/Icons/eventDetails/explore.svg";
import ProfileIcon from "../../../assets/Icons/eventDetails/ProfIcon.svg";
import ChatIcon from "../../../assets/Icons/eventDetails/eventChat.svg";
import AnalyticsIcon from "../../../assets/Icons/eventDetails/analytics.svg";
import TextBlastIcon from "../../../assets/Icons/eventDetails/textBlast.svg";
import TicketIcon from "../../../assets/Icons/payout/whiteTicket.svg";
import RedCancel from "../../../assets/Icons/eventDetails/redCancel.svg";
import { DRAWERS, LOCAL_STORAGE } from "../../../utils/constants";
import GoogleMap from "../../../components/googleMap";
import RightSideDrawer from "../../../components/rightDrawer";
import GuestList from "../guestList";
import GroupChat from "../groupChat";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  convertUtcToLocal,
  getDateTimeFromString,
  getDayOfWeek,
  getMonthAndDay,
  getTime,
} from "../../../utils/common";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStorage";
import { Event } from "../../../utils/Interfaces";
import { useSpinner } from "../../../components/spinner/SpinnerContext";
import { eventDetailService } from "../../../services/eventDetailServices";
import { toast } from "react-toastify";
import Payout from "../payout";
import PrimaryButton from "../../../components/primaryButton";
import Modal from "../../../components/modal";
import moment from 'moment-timezone';
import ShareIcon from "../../../assets/Icons/eventDetails/share.svg";
import { UserService } from "../../../services/userServices";
import Girl from '../../../assets/Images/connection.png'
import AboutIcon from '../../../assets/Icons/connections/about.svg'
import AddressIcon from '../../../assets/Icons/connections/address.svg'
import WorkIcon from '../../../assets/Icons/connections/work.svg'
import DatingIcon from '../../../assets/Icons/connections/dating.svg'
import ConnectionIcon from '../../../assets/Icons/connections/Connection.svg'
import ConnectionIcon2 from '../../../assets/Icons/connections/ConnectionOpen.svg'
import FriendshipIcon from '../../../assets/Icons/connections/friendship.svg'
import OpentoAnythingIcon from '../../../assets/Icons/connections/openToAnything.svg'

export default function HostEventDetails() {
  const [eventDetails, setEventDetails] = useState<Event | any>();
  const [guestList, setGuestList] = useState<any>();
  const [eventStartDateTime, setEventStartDateTime] = useState("");
  const [eventEndDateTime, setEventEndDateTime] = useState('');
  const [isHost, setIsHost] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId: any = queryParams.get("eventid");
  const hostId = queryParams.get("hostid");
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [userDetails, setUserDetails] = useState<any>('');
  const [tickets, setTickets] = useState();
  const [showCancel, setShowCancel] = useState(false)
  const [isAllFreeTickets, setIsAllFreeTickets] = useState(true);
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDrawer, setSelectedDrawer] = useState("");
  const [connectionExpanded, setConnectionExpanded] = useState(false);
  const [guestDetails, setGuestDetails] = useState<any>();

  const navigate = useNavigate();
  const { setShowSpinner } = useSpinner();

  const [isExpanded, setIsExpanded] = useState(false);
  const [words, setWords] = useState("");
  const [truncatedText, setTruncatedText] = useState("");

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleConnectionExpanded = () => {
    setConnectionExpanded(!connectionExpanded);
  };

  useEffect(() => {
    setEventStartDateTime(
      convertUtcToLocal(eventDetails?.startTime, eventDetails?.timezone)
    );
    setEventEndDateTime(convertUtcToLocal(eventDetails?.endTime, eventDetails?.timezone))
    const totalWords = eventDetails?.description?.split(" ");
    setWords(totalWords);
    setTruncatedText(totalWords?.slice(0, 31)?.join(" "));
  }, [eventDetails]);

  useEffect(() => {
    if (hostId === userId) {
      getHostEventDtls();
      setIsHost(true);
    }
    if (userId)
      getUserProfileDetails()

  }, [eventId]);

  const getUserProfileDetails = async () => {
    try {
      const res = await UserService.getUserProfileDetails();
      if (res?.data) {
        setUserDetails(res?.data);
      }
    } catch (error: any) {
      console.log("error getGuestEventDtls : ", error?.response?.data?.error || error?.message);
    }
  }

  const fetchGuestDetails = async (reqUserId: number) => {
    try {
      const res = await eventDetailService.getGuestDetails({ userId, req_user_id: reqUserId });
      if (res?.data) {
        setGuestDetails(res?.data?.current_user?.userProfileResponse);

      }
    } catch (error: any) {
      console.log("error fetchGuestDetails : ", error?.response?.data?.error || error?.message);
    }
  }

  const getHostEventDtls = async () => {
    try {
      setShowSpinner(true);
      const res = await eventDetailService.getHostEventDetails({
        eventId,
        userId,
      });
      if (res?.status) {
        setEventDetails(res?.data?.events);
        setTickets(res?.data?.eventTicketTypes);
        res?.data?.eventTicketTypes?.forEach((element: any) => {
          if (!element?.is_free)
            setIsAllFreeTickets(false);
        });
        setGuestList(res?.data?.InvitedGuest);
        setShowSpinner(false);
      }
    } catch (error: any) {
      console.log(
        "error getHostEventDtls : ",
        error?.response?.data?.error || error?.message
      );
      navigate('/host-center?tab=Upcoming');
      setShowSpinner(false);
    }
  };

  const MapLink = () => {
    const latitude = eventDetails?.coordinates?.split(",")?.[0];
    const longitude = eventDetails?.coordinates?.split(",")?.[1];
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.open(`maps://maps.apple.com/?q=${latitude},${longitude}`);
    } else
      window.open(`https://www.google.com/maps?q=${latitude},${longitude}`);
  };

  const deleteEvent = async () => {
    try {
      setShowCancel(false);
      const data = {
        "id": eventDetails.eventId,
      }
      const res: any = await eventDetailService.deleteEvent(data);
      if (res?.status == true) {
        if (guestList?.length <= 1 || isAllFreeTickets || eventDetails?.event_type == 'Free Event')
          toast.success("The event has been cancelled successfully.");
        else
          toast.success("The request to cancel the event has been successfully initiated.");
        navigate(-1);
      }
    } catch (error: any) {
      console.log(
        "error getHostEventDtls : ",
        error?.response?.data?.error || error?.message
      );
    }
  }

  const currentDateTime = moment().tz(eventDetails?.timezone)?.format('YYYY-MM-DD')

  const ShareUrl = async () => {
    await navigator.clipboard.writeText(`${userDetails?.firstName ? (userDetails?.firstName) : 'I have'} invited you to event, ${eventDetails?.eventName} ${process.env.REACT_APP_BASE_URL?.split('api/')?.[0]}eventLinkPreview?eventid=${eventDetails?.eventId}&hostid=${eventDetails?.hostUserId}&share=true`);
    toast.success("Event Link Copied!");
  }

  const handleEditEvent = () => {
    if (eventDetails?.status == 'Completed') {
      navigate({
        pathname: '/create-event/general-settings',
        search: createSearchParams({
          eventid: eventId
        }).toString()
      })
    }
    else {
      navigate({
        pathname: '/create-event/basic-details',
        search: createSearchParams({
          eventid: eventId
        }).toString()
      })
    }

  }

  const handleConnection = (connection: boolean, reqUserId: number) => {
    setIsConnectionModalOpen(connection);
    if (reqUserId) {
      fetchGuestDetails(reqUserId);
    }
  }

  const publishEvent = async () => {
    setShowSpinner(true);
    const res = await eventDetailService.publishEvent({ eventId: parseInt(eventId), hostUserId: parseInt(userId), isPublished: true });
    if (res?.status) {
      setShowSpinner(false);
      toast.success("Event published successfully!");
      navigate('/host-center?tab=Drafts');
    }
  }
  return (
    <div className={`${!eventDetails && 'hidden'} relative text-primary-white`}>
      <div
        className="px-8 bg-cover bg-top inset-0 h-[15vh] text-primary-white rounded-b-full z-0 mx-28 "
        style={{
          backgroundImage: `url(${eventDetails?.bannerImage})`,
          filter: "blur(100px)",
        }}
      ></div>

      <div className="absolute top-24 py-8 z-100 px-7 xl:px-[7rem] container left-1/2 transform -translate-x-1/2 pb-28">
        <div className="flex flex-col gap-6 md:gap-14 md:flex-row">
          {/* left side */}
          <div className="flex-1 flex flex-col gap-6">
            <div className="flex-1 relative self-center flex justify-center">
              <img
                src={
                  eventDetails?.bannerImage ||
                  "https://sondr-dev.s3.us-east-2.amazonaws.com/Socha/event_default_banner.png"
                }
                className="w-[25.18rem] md:w-[20rem] aspect-square border-t-20 border-l-20 rounded-3xl shadow-lg object-cover"
              />

              <div className=" md:hidden absolute bottom-0 left-0 px-2 pb-2 w-full">
                <div className="flex justify-between items-center">
                  <div className=" px-2 pb-2 w-fit py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 text-sm border border-[#FFFFFF26]">
                    {eventDetails?.eventMode == "Open"
                      ? "Public Event"
                      : "Private Event"}
                  </div>
                  <div
                    className="px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 border border-[#FFFFFF26] cursor-pointer"
                    onClick={() => ShareUrl()}
                  >
                    <img src={ShareIcon} alt="back" width={14} height={14} />
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden md:flex justify-between items-center">
              <div className="w-fit px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 text-sm border border-[#FFFFFF26]">
                {eventDetails?.eventMode == "Open"
                  ? "Public Event"
                  : "Private Event"}
              </div>
              <div
                className="px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 border border-[#FFFFFF26] cursor-pointer"
                onClick={() => ShareUrl()}
              >
                <img src={ShareIcon} alt="back" width={14} height={14} />
              </div>
            </div>

            <div className="flex flex-col gap-1 md:gap-6">
              <div className="font-bold text-4xl tracking-tighter text-left ">
                {eventDetails?.eventName}
              </div>

              <div className="flex gap-2.5">
                <div className=" flex gap-2">
                  <span>
                    {getDayOfWeek(eventStartDateTime) +
                      " " +
                      getMonthAndDay(eventStartDateTime)}
                  </span>
                  <span className=" text-[#F1F1F1B2]">
                    {getTime(eventStartDateTime)}
                  </span>
                </div>
                -
                <div className="flex gap-2">
                  <span>
                    {getDayOfWeek(eventEndDateTime) +
                      " " +
                      getMonthAndDay(eventEndDateTime)}
                  </span>
                  <span className=" text-[#F1F1F1B2]">
                    {getTime(eventEndDateTime)}
                  </span>
                </div>
              </div>
            </div>

            {/* about */}
            <div className="flex flex-col gap-2">
              <span className="text-lg font-bold">About</span>
              <div className="text-[#c5c3c3]">
                <div>
                  {isExpanded ? (
                    <>
                      {eventDetails?.description}
                      <button
                        onClick={toggleExpanded}
                        className="text-primary-white"
                      >
                        &nbsp;read less
                      </button>
                    </>
                  ) : (
                    <>
                      {truncatedText}
                      <button
                        onClick={toggleExpanded}
                        className="text-primary-white"
                      >
                        {words?.length >= 31 && "...\u00A0read more"}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Map */}
            <div className="flex flex-col gap-2">
              <span className="text-lg font-bold">Where</span>
              <span
                className="text-[#c5c3c3] cursor-pointer"
                onClick={() => MapLink()}
              >
                {eventDetails?.location}
              </span>
              <div className="rounded-xl h-56 w-full bg-[#1D1D1D] border border-[#1D1D1D]">
                {eventDetails?.coordinates ? (
                  <GoogleMap
                    styles={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "0.75rem",
                    }}
                    lat={Number(eventDetails?.coordinates?.split(",")?.[0])}
                    lng={Number(eventDetails?.coordinates?.split(",")?.[1])}
                  />
                ) : (
                  <span className="m-3">No location found!</span>
                )}
              </div>
            </div>
          </div>

          {/* right side */}
          <div className="flex-1 mt-20">
            <div className="flex justify-between items-center">
              <span className="text-xl">Event options</span>
              <div className="flex gap-2">
                {eventDetails?.isPublished == false && <PrimaryButton name="Publish event" classNames='px-2 py-3' darkMode={true} onClick={publishEvent} />}
                <PrimaryButton name="Edit event" classNames='px-2 py-3' darkMode={true} onClick={handleEditEvent} />
              </div>

            </div>
            <div className="flex flex-col gap-2 items-center mt-5">
              {/* guest list box */}
              <div
                className="w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer "
                onClick={() => {
                  setSelectedDrawer(DRAWERS.GUEST_LIST);
                  setIsModalOpen(!isModalOpen);
                }}
              >
                <div className="flex gap-2 items-center">
                  <img src={GuestIcon} alt="back" width={20} height={20} />

                  {/* guests profile */}
                  <div className="flex justify-center items-center ">
                    {guestList?.slice(0, 4).map((item: any, idx: number) =>
                      item?.profilePic ? (
                        <div
                          key={idx}
                          className={`w-7 h-7 ${idx !== 0 && "ml-[-0.5rem]"}`}
                        >
                          <img
                            src={item.profilePic}
                            alt="back"
                            className="rounded-full border-[2px] border-[#1D1D1D] border-solid w-full h-full object-cover object-center"
                          />
                        </div>
                      ) : (
                        <div
                          key={idx}
                          className={`w-7 h-7 bg-text-gray rounded-full border-[2px] border-[#1D1D1D] border-solid flex justify-center ${idx !== 0 && "ml-[-0.5rem]"
                            }`}
                        >
                          <img
                            src={ProfileIcon}
                            alt="back"
                            width={17}
                            height={17}
                          />
                        </div>
                      )
                    )}
                  </div>

                  <span className="text-sm font-medium ">See guest list</span>
                </div>
                <img src={ExploreIcon} alt="back" width={20} height={20} />
              </div>

              {/* event chat */}
              <div
                className="w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer"
                onClick={() => {
                  setSelectedDrawer(DRAWERS.GROUP_CHAT);
                  setIsModalOpen(!isModalOpen);
                }}
              >
                <div className="flex gap-2">
                  <img src={ChatIcon} alt="back" width={20} height={20} />
                  <span>Event chat</span>
                </div>
                <img src={ExploreIcon} alt="explore" width={20} height={20} />
              </div>

              {/* Tickets */}
              <div
                className={`${eventDetails?.event_type == "Ticketed Event"
                  ? "block"
                  : "hidden"
                  } w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer`}
                onClick={() => {
                  setSelectedDrawer(DRAWERS.BUY_TICKETS);
                  setIsModalOpen(!isModalOpen);
                }}
              >
                <div className="flex gap-2">
                  <img src={TicketIcon} alt="back" width={20} height={20} />
                  <span>Tickets</span>
                </div>
                <img src={ExploreIcon} alt="explore" width={20} height={20} />
              </div>

              {/* Analytics */}
              <div
                className="w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer"
                onClick={() => {
                  navigate({
                    pathname: '/host-center/analytics',
                    search: createSearchParams({
                      eventid: eventId
                    }).toString()
                  })
                }}
              >
                <div className="flex gap-2">
                  <img src={AnalyticsIcon} alt="back" width={20} height={20} />
                  <span>Analytics</span>
                </div>
                <img src={ExploreIcon} alt="explore" width={20} height={20} />
              </div>

              {/* Text Blast */}
              <div
                className="w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer"
                onClick={() =>
                  navigate({
                    pathname: "/text-blast",
                    search: createSearchParams({
                      eventid: eventId,
                    }).toString(),
                  })
                }
              >
                <div className="flex gap-2">
                  <img src={TextBlastIcon} alt="back" width={20} height={20} />
                  <span>Text blast</span>
                </div>
                <img src={ExploreIcon} alt="explore" width={20} height={20} />
              </div>
            </div>
            {/* Delete event */}
            {eventDetails?.status?.toLowerCase() == "planned" && (
              <div
                className={`w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex gap-2 items-center mt-10 ${eventDetails?.eventCancellationStatus != 'pending' ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'}`}
                onClick={() => {
                  if (eventDetails?.eventCancellationStatus == 'pending')
                    setShowCancel(true);
                }}
              >
                <img src={RedCancel} alt="back" width={20} height={20} />
                <span className="text-primary-red">Delete event</span>
              </div>
            )}
            {eventDetails?.eventCancellationStatus != 'pending' && <span className="text-red-500 text-sm m-2">*The request to cancel the event has been submitted successfully.</span>}
          </div>
        </div>
      </div>
      <RightSideDrawer
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          selectedDrawer == DRAWERS.GUEST_LIST
            ? DRAWERS.GUEST_LIST
            : selectedDrawer == DRAWERS.GROUP_CHAT
              ? DRAWERS.GROUP_CHAT
              : DRAWERS.BUY_TICKETS
        }
        showTitle={
          selectedDrawer == DRAWERS.PAYMENT || selectedDrawer == DRAWERS.TICKET
            ? false
            : true
        }
        showCloseIcon={selectedDrawer == DRAWERS.PAYMENT ? false : true}
      >
        {selectedDrawer == DRAWERS.GUEST_LIST ? (
          <GuestList eventId={eventId} handleConnection={handleConnection} />
        ) : selectedDrawer == DRAWERS.GROUP_CHAT ? (
          <GroupChat event={eventDetails} />
        ) : (
          <Payout
            onCompChange={(value) => setSelectedDrawer(value)}
            selectedDrawer={selectedDrawer}
            eventId={eventId}
            isHost={true}
          />
        )}
      </RightSideDrawer>

      <RightSideDrawer
        open={isConnectionModalOpen}
        onClose={() => setIsConnectionModalOpen(false)}
        title={DRAWERS.CONNECTION}
      >
        <div className="overflow-y-auto h-[75vh] scrollableDiv relative">

          <div className="flex flex-col justify-start items-center">
            <img src={guestDetails?.userPhotos?.[0]} className="w-full md:w-2/3 object-cover" />
            <div className="flex flex-col w-full md:w-2/3 absolute top-[50%] ">
              <div className="font-bold text-3xl px-6">
                <span className="text-primary-white">{guestDetails?.firstName} {guestDetails?.lastName} </span>
                <span className="text-text-gray">{guestDetails?.age}</span>
              </div>

              <div className="flex flex-col gap-6 bg-[#121212] w-full py-8">
                <div className="flex flex-col gap-2 px-6">
                  {guestDetails?.occupationVisblit &&
                    <div className="flex gap-2 bg-[#1D1D1D] p-2 rounded-lg ">
                      <img src={WorkIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.job_title} at {guestDetails?.workplace}</span>
                    </div>}
                  {guestDetails?.information_visible &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AddressIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.university}</span>
                    </div>}
                  {guestDetails?.information_visible &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AddressIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.city}, {guestDetails?.country}</span>
                    </div>}
                  {guestDetails?.heightVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.height}</span>
                    </div>}
                  {guestDetails?.genderCategoryVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.groupCategory}</span>
                    </div>}
                  {guestDetails?.genderVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.gender}</span>
                    </div>}
                  {guestDetails?.lookingForVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.groupBy}</span>
                    </div>}
                </div>
                {guestDetails?.information_visible && guestDetails?.interests?.length > 0 && <div className="px-6">
                  <span className="text-primary-white text-base">Interests</span>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {guestDetails?.interests?.map((item: any, idx: number) => (
                      <span key={idx} className="bg-[#1D1D1D] p-2 rounded-lg text-text-gray text-sm font-semibold">
                        {item}
                      </span>
                    ))}
                  </div>
                </div>}


                {guestDetails?.profileStatus && guestDetails?.promptQuestions?.length > 0 && guestDetails?.promptQuestions.map((item: any, idx: number) => (
                  <div className="px-6">
                    <div className="my-2 text-primary-white text-base">{idx == 0 && guestDetails?.profileStatus}</div>
                    <span className="text-text-gray text-base">{item?.question}</span>
                    <p className="text-sm text-primary-gray">{item?.answer}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </RightSideDrawer>

      {showCancel && (
        <Modal
          onClose={() => setShowCancel(false)}
          onSave={() => deleteEvent()}
        >
          Are you sure you want to delete this event?
        </Modal>
      )}
    </div>
  );
}
