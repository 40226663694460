import React, { ReactNode } from 'react'
import styles from './WelcomeModal.module.scss'

interface props {
    children: ReactNode,
    className?: string
}

const WelcomeModal = ({children, className}: props) => {
  return (
    <div className={`${styles.popupOuter} backdrop-blur-xl bg-white/30 z-[200] ${className} `}>
    <div className={styles.popupOverlay}></div>
    <div className={styles.popupContent}>
      <div className={`${styles.sideNav} ${styles.animateNav} bg-[#181818]`}>
        {/* <div className='flex justify-end w-full px-3 mt-2'>
          <img src={CLOSE_ICON} alt='close' className='size-5 cursor-pointer' onClick={onClose}/>
        </div> */}
        <div className={`px-8 md:pt-4 md:pb-0 pb-6`}>
          {children}
        </div>
   
      </div>
    </div>
  </div>
  )
}

export default WelcomeModal