import React, { useEffect, useMemo, useState } from "react";
import SeparateLine from "../../assets/Icons/eventDetails/separaterLine.svg";
import CreditCard from "../../assets/Icons/payout/credit_card.svg";
import ShareIcon from "../../assets/Icons/eventDetails/share.svg";
import RefundIcon from "../../assets/Icons/eventDetails/RefundIcon.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";

import {
  convertUtcToLocal,
  getDayOfWeek,
  getMonthAndDay,
  getTime,
} from "../../utils/common";
import { toast } from "react-toastify";
import Modal from "../modal";
import { RefundService } from "../../services/refundService";
import { getLocalStorage } from "../../utils/localStorage";
import { LOCAL_STORAGE } from "../../utils/constants";

interface Props {
  ticketPurchased: Array<any>;
    eventDetail?: any;
    userDetails?:any
}

export default function UserTickets({ ticketPurchased, eventDetail,userDetails }: Props) {
  const [selectedTicket, setSelectedTicket] = useState(ticketPurchased?.[0]);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [showRefund, setShowRefund] = useState(false);
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);

  useEffect(() => {
    setSelectedTicket(ticketPurchased?.[selectedSlide]);
  }, [selectedSlide]);

  const canShareTicket = useMemo(() => {
    if (!selectedTicket) return false;

    const balance = selectedTicket.totalPurchasedTickets - (selectedTicket.redeemedTicketsCount || 0);
    const refundStatus = selectedTicket.refundRequestStatus;

    return balance > 1 && (refundStatus === "pending" || refundStatus === "declined");
  }, [selectedTicket]);
    
    const canRefundTickets = useMemo(() => {
        if (selectedTicket) {
            if (selectedTicket?.totalPurchasedTickets > 0) {
                if (selectedTicket?.refundRequestStatus == "pending" && selectedTicket?.totalAmount != "0") {
                    return true;
                }
            }
        }
        return false;
    }, [selectedTicket]);

    const ShareTicket = async () => {
            await navigator.clipboard.writeText(`${userDetails?.firstName ? (userDetails?.firstName + ' has') : 'I have'} purchased a ticket for you to attend an event, ${eventDetail?.eventName} ${window.location.href}&purchaseid=${selectedTicket?.id}&share=true`);
            toast.success("Ticket link copied for redemption.");
    }
    
  
    const refundTicket = async () => {
      try {
        setShowRefund(false);
        const response = await RefundService.requestRefundEmail({
          "eventId": eventDetail?.eventId,
          "purchaseTicketId": selectedTicket?.id,
          "purchasedUserId": parseInt(selectedTicket?.purchasedUserId || userId),
          "ticketTypeId": selectedTicket?.ticketTypeId,
          "allTickets": true
        });
        if (response) {
          toast.success(response?.data?.message || 'The refund request has been submitted successfully.');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {  
          toast.error("Failed to send refund request email.");
        }
      } catch (error) {
        toast.error("Failed to send refund request email.");
      }

  }
  
    const getRefundMessage = () => {
      if (!selectedTicket) return "";

      const isRedeemed = selectedTicket.redeemedTicketsCount > 0;
      const refundStatus = selectedTicket.refundRequestStatus?.toLowerCase();
      const ticketName = selectedTicket.ticketName?.toLowerCase() || 'ticket';

      if (!isRedeemed) {
        switch (refundStatus) {
          case 'requested':
            return `Your refund request for the ${ticketName} has been submitted successfully.`;
          case 'declined':
            return `Unfortunately, your refund request for the ${ticketName} has been denied. Please check your email for further details.`;
          case 'approved':
            return `Your refund request for the ${ticketName} has been approved successfully.`;
          default:
            return "";
        }
      } else {
        switch (refundStatus) {
          case 'requested':
            return `Refund requested for this ticket. This ticket will expire if approved.`;
          case 'declined':
            return `Unfortunately, refund request for this ticket has been denied.`;
          default:
            return "";
        }
      }
    };
  
  return (
    <>
    <Swiper
      pagination={{
        type: "fraction",
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper w-full text-center"
      onNavigationPrev={() => setSelectedSlide(selectedSlide - 1)}
      onNavigationNext={() => setSelectedSlide(selectedSlide + 1)}
    >
      {ticketPurchased.map((ticket, index) => (
        <SwiperSlide key={index}>
          <div className="mx-14 w-72 whitespace-nowrap overflow-hidden text-ellipsis mb-2">
            
           {(eventDetail.status?.toLowerCase() !== 'cancelled' && eventDetail?.status?.toLowerCase() !== 'completed') && <div className="flex justify-between">
             {canRefundTickets ? <div className="flex gap-2 items-center text-xs rounded-md bg-input-box-gray px-2 py-1.5 cursor-pointer" onClick={() =>setShowRefund(!showRefund)}>
                <img src={RefundIcon} width={20} height={20}/>
                Request refund
              </div> : <div></div>}
              {canShareTicket ? <div className="flex gap-2 items-center text-xs rounded-md bg-input-box-gray px-2 py-1.5 cursor-pointer" onClick={() => ShareTicket()}>
                <img src={ShareIcon} width={15}/>
                Share ticket
              </div>: <div></div>}
            </div>}
            {canShareTicket && <span className="text-xs italic text-success-500 text-wrap ">There is an additional ticket up for grabs. Feel free to share it with your friends and family to redeem.</span>}
            <div className="bg-[#121314] p-4 flex flex-col gap-3 justify-center mt-4 rounded-xl">
              <div className="bg-[#161718] text-primary-white py-1.5 px-2 rounded-md text-xs self-center">
                {eventDetail?.status?.toLowerCase() == 'planned' ? "upcoming" : eventDetail?.status}
              </div>
              <div className="flex flex-col items-center gap-1">
                <span className="text-base font-medium ">
                  {eventDetail.eventName}
                </span>
                <div className="flex gap-1 text-xs text-text-gray">
                  <div className=" flex gap-2">
                    <span>
                      {getDayOfWeek(
                        convertUtcToLocal(
                          eventDetail?.startTime,
                          eventDetail?.timezone
                        )
                      ) +
                        " " +
                        getMonthAndDay(
                          convertUtcToLocal(
                            eventDetail?.startTime,
                            eventDetail?.timezone
                          )
                        )}
                    </span>
                    <span className=" text-[#F1F1F1B2]">
                      {getTime(
                        convertUtcToLocal(
                          eventDetail?.startTime,
                          eventDetail?.timezone
                        )
                      )}
                    </span>
                  </div>
                  <span>-</span>
                  <div className=" flex gap-2">
                    <span>
                      {getDayOfWeek(
                        convertUtcToLocal(
                          eventDetail?.endTime,
                          eventDetail?.timezone
                        )
                      ) +
                        " " +
                        getMonthAndDay(
                          convertUtcToLocal(
                            eventDetail?.endTime,
                            eventDetail?.timezone
                          )
                        )}
                    </span>
                    <span className=" text-[#F1F1F1B2]">
                      {getTime(
                        convertUtcToLocal(
                          eventDetail?.endTime,
                          eventDetail?.timezone
                        )
                      )}
                    </span>
                  </div>
                </div>
                <span className="text-xs font-medium text-text-gray text-wrap">
                  {eventDetail.location}
                </span>
              </div>

              <img src={SeparateLine} alt="Separator" />
              <div className="self-center">
                <img src={ticket.qrCode} alt="QR Code" height={95} width={95} />
              </div>
              <img src={SeparateLine} alt="Separator" />

              <div className="flex flex-col items-end">
                <div className="flex justify-between w-full">
                  <div className="bg-[#161718] text-primary-white py-1.5 px-2 rounded-md text-xs">
                    {ticket.ticketName}
                  </div>
                  <span className="text-base">${ticket.totalAmount}</span>
                </div>
                <span className="text-text-gray">
                  x {ticket.totalPurchasedTickets || 1}
                </span>
              </div>

              <div className="flex justify-between items-center">
                <div className="border border-dashed border-text-gray px-1.5 rounded text-sm">
                  {ticket.code}
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-xs text-text-gray">Payment thru</span>
                  <img src={CreditCard} alt="Credit Card" width={20} />
                </div>
              </div>
              <span
              className={`${selectedTicket?.refundRequestStatus == 'declined' ? 'text-primary-red' : 'text-success-green'} text-wrap text-sm italic`}
              >{getRefundMessage()}</span>
            </div>
          </div>
        </SwiperSlide>
      ))}
      </Swiper>
      {showRefund && <Modal onSave={() => refundTicket()} onClose={() => setShowRefund(false)}>
        <div className="max-w-[20rem]">
          <p className="font-semibold mb-2 text-center">
            Are you sure you want to request tickets refund?
          </p>
        </div>
      </Modal>}
    </>
  );
}
