import React from 'react'
import SearchIcon from "../../assets/Icons/refine/search.svg";

interface props{
    onInputChange?: (input: string) => void,
    value?: string,
    
}

export default function SearchInput({ onInputChange = () => { } }: props) {
  return (
    <div className="flex items-center rounded-2xl px-4 py-1 w-full bg-input-box-gray">
    <img src={SearchIcon} />
    <input
      type="text"
      placeholder="Find an event"
      className="bg-transparent border-none placeholder-text-gray focus:ring-0 focus:outline-none w-full ml-2 bg-input-box-gray"
        onChange={(e) => onInputChange(e.target.value)}
        />
  </div>
  )
}
