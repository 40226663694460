import React, { useEffect, useState } from "react";
import BackArrow from "../../assets/Icons/backArrow/backArrow.svg";
import Wallet from "../../assets/Icons/wallet/Wallet--Streamline-Tabler.svg";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import PrimaryButton from "../../components/primaryButton";
import Cards from "../../components/cards";
import Tick from '../../assets/Icons/Analytics/greenTick.svg'
import Cross from '../../assets/Icons/Analytics/cross.svg'
import Minus from '../../assets/Icons/Analytics/minus.svg'
import RightArrow from '../../assets/Icons/Analytics/blackRightArrow.svg'
import Connection from '../../assets/Icons/Analytics/connections.svg'
import Friends from '../../assets/Icons/Analytics/friends.svg'
import Heart from '../../assets/Icons/Analytics/heart.svg'
import { AnalyticsService } from "../../services/analyticsService";
import Modal from "../../components/modal";

const Analytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { setShowPayoutDrawer }: any = useOutletContext();
  const id: any = queryParams.get("eventid");
  const [analytics, setAnalytics] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getAnalytics();
  }, []);


  const getAnalytics = async () => {
    try {
      const response = await AnalyticsService.fetchAnalytics({ 'filterBy': 'all', eventId: id, from: '', to: '' });
      if (response?.data) {
        setAnalytics(response?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  }


  return (
    <React.Fragment>
      <div className="container mt-8">
        <div className="grid grid-cols-10">
          <div className="col-start-1 col-span-3">
            <div className="flex gap-4 items-center font-bold text-base mb-6">
              <img
                src={BackArrow}
                className="w-8 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <span className="text-primary-white font-medium text-4xl">
                Analytics
              </span>
            </div>
          </div>
          <div className="col-start-7 col-span-11 flex items-center justify-between cursor-pointer"  >
            <div className=" items-center justify-between gap-20 bg-input-box-gray rounded-2xl px-4 py-2 hidden md:flex" onClick={() => navigate('/refund-requests')}>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2 bg-[#DC2430] rounded-2xl px-2 py-1">
                  <img src={Wallet} />
                  <span className="text-primary-white font-medium text-base">
                    {analytics?.totalRefundRequestsCount}
                  </span>
                </div>
                <span className="text-primary-white font-medium text-base">
                  Refund request
                </span>
              </div>
              <img src={RightArrow} />
            </div>
            <PrimaryButton
              name="Request payout"
              type="button"
              classNames="px-4 py-3"
              onClick={() => setShowPayoutDrawer(true)}
            />
          </div>
          <div className="col-start-1 col-span-11 flex items-center justify-between md:hidden">
            <div className="flex items-center justify-between gap-32 bg-input-box-gray rounded-2xl px-4 py-2">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2 bg-[#DC2430] rounded-2xl px-2 py-1">
                  <img src={Wallet} />
                  <span className="text-primary-white font-medium text-base">
                    {analytics?.totalRefundRequestsCount}
                  </span>
                </div>
                <span className="text-primary-white font-medium text-base">
                  Refund request
                </span>
              </div>
              <img src={RightArrow} />
            </div>
          </div>
          <div className="col-start-1 col-span-10 mt-4">
            <div className="grid grid-cols-11 gap-4">
              <div className="col-start-1 col-span-11 md:col-span-3">
                <Cards heading="Revenue" onClick={() => navigate('/host-center/analytics/revenue?eventid=' + id)}>
                  <span className="text-primary-white font-medium text-3xl">
                    ${analytics?.revenue}
                  </span>
                  <p className="text-text-gray font-medium text-base">Earned</p>
                  <div className="border-t border-[#474747] my-2"></div>
                  <span className="text-primary-white font-medium text-lg">
                    {analytics?.ticketPurchased} &nbsp;
                  </span>
                  <span className="text-text-gray font-medium text-base">
                    Tickets Sold
                  </span>
                </Cards>
              </div>
              <div className="col-start-1 col-span-11 md:col-start-4 md:col-span-4">
                <Cards heading="Guests"
                  onClick={() =>
                    navigate('/host-center/analytics/guests?eventid=' + id,
                      {
                        state: {
                          eventName: analytics?.event?.eventName,
                          goingGuestCount: analytics?.goingGuestCount,
                          notGoingGuestCount: analytics?.notGoingGuestCount,
                          maybeGuestCount: analytics?.maybeGuestCount,
                          bannerImage: analytics?.event?.bannerImage
                        }
                      })}>
                  <div className="flex md:gap-28 gap-4">
                    <div>
                      <span className="text-primary-white font-medium text-3xl">
                        {analytics?.goingGuestCount + analytics?.notGoingGuestCount + analytics?.maybeGuestCount}
                      </span>
                      <p className="text-text-gray font-medium text-base">
                        Guest RSVP received
                      </p>
                    </div>
                    <div>
                      <span className="text-primary-white font-medium text-3xl">
                        {analytics?.attendees}
                      </span>
                      <p className="text-text-gray font-medium text-base">
                        Attendees
                      </p>
                    </div>
                  </div>
                  <div className="border-t border-[#474747] my-2"></div>
                  <div className="flex justify-between text-primary-white font-medium text-lg px-2">
                    <div className="flex items-center gap-2">
                      <img src={Tick} />
                      <span>{analytics?.goingGuestCount}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={Cross} />
                      <span>{analytics?.notGoingGuestCount}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={Minus} />
                      <span>{analytics?.maybeGuestCount}</span>
                    </div>
                  </div>
                </Cards>
              </div>
              <div className="col-start-1 col-span-11 md:col-start-8 md:col-span-4">
                <Cards heading="Gender ratio" onClick={() => setShowModal(true)}>
                  <div className="flex justify-between my-9 gap-1">
                    {analytics?.genderRatio.map((item: any, index: number) => (
                      <div
                        key={index}
                        className={`h-6 rounded`}
                        style={{
                          width: `${item.ratio.toFixed(2)}%`,
                          backgroundColor: item.gender === 'Men' ? '#1E90FF' :
                            item.gender === 'Women' ? '#FF69B4' :
                              ['#FFD700', '#FFA500', '#9370DB'][index % 3]
                        }}
                      ></div>
                    ))}
                    {/* <div className="w-[20%] h-6 bg-[#FF69B4] rounded"></div>
                    <div className="w-[50%] h-6 bg-[#FFD700] rounded"></div> */}
                  </div>
                  <div className="border-t border-[#474747] my-2"></div>
                  <div className="flex justify-between text-primary-white font-medium text-base px-2">
                    {analytics?.genderRatio.map((item: any, index: number) => (
                      <div key={index} className="flex items-center gap-2">
                        <span
                          className="w-3 h-3 rounded-full"
                          style={{
                            backgroundColor: item.gender === 'Men' ? '#1E90FF' :
                              item.gender === 'Women' ? '#FF69B4' :
                                ['#FFD700', '#FFA500', '#9370DB'][index % 3]
                          }}
                        ></span>
                        <span>{item.ratio.toFixed(2)}%</span>
                        <span className="text-text-gray font-medium text-base">{item.gender === "" ? 'Others' : item.gender}</span>
                      </div>
                    ))}
                  </div>
                </Cards>
              </div>
              <div className="col-start-1 col-span-11 md:col-start-1 md:col-span-3">
                <Cards heading="Connection requests" onClick={() => setShowModal(true)}>
                  <span className="text-primary-white font-medium text-3xl">
                    {analytics?.connectionRequestsCount}
                  </span>
                  <p className="text-text-gray font-medium text-base">
                    Connection <br /> requests sent
                  </p>
                </Cards>
              </div>
              <div className="col-start-1 col-span-11 md:col-start-4 md:col-span-8">
                <Cards heading="Matches" onClick={() => setShowModal(true)}>
                  <span className="text-primary-white font-medium text-3xl">
                    {analytics?.matchCount}
                  </span>
                  <p className="text-text-gray font-medium text-base">
                    Matches made
                  </p>
                  <div className="border-t border-[#474747] my-2"></div>
                  <div className="flex md:flex-row flex-col justify-between text-primary-white font-medium text-base md:px-2">
                    <div className="flex items-center">
                      <img src={Connection} className="w-10" />
                      <span>{analytics?.anyConnectionCount} &nbsp;</span>
                      <span className="text-text-gray font-medium text-base">Any Connection</span>
                    </div>
                    <div className="flex items-center">
                      <img src={Friends} className="w-10" />
                      <span>{analytics?.friendShipCount} &nbsp;</span>
                      <span className="text-text-gray font-medium text-base">Friendship/Professional</span>
                    </div>
                    <div className="flex items-center">
                      <img src={Heart} className="w-10" />
                      <span>{analytics?.datingCount} &nbsp;</span>
                      <span className="text-text-gray font-medium text-base">Dating</span>
                    </div>
                  </div>
                </Cards>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && <Modal showButtons={false} onOkClick={() => setShowModal(false)}>Coming Soon</Modal>}
    </React.Fragment>
  );
};

export default Analytics;
