import React from 'react'

interface props {
  value: boolean,
  onChange?: () => void,
  className?: string
}

export default function CustomRadio({ value = false, onChange, className }: props) {
  return (
    <div className={`relative w-16 h-8 ${!value ? 'bg-input-box-gray' : 'bg-primary-orange'} rounded-full cursor-pointer ${className}`} onClick={onChange}>
      <div className={`absolute top-0 left-0  ${className ? 'w-[18px] h-[18px] m-[0.09rem]' : 'w-6 h-6 m-1'}  rounded-full bg-primary-white  transition-transform duration-500 ${value && 'transform translate-x-full left-1'}`}>
      </div>
    </div>
  )
}
