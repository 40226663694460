import React from 'react'
import GrayCal from '../../assets/Icons/refine/gray-calender.svg'
import { Event } from '../../utils/Interfaces';
import { convertUtcToLocal, getDayOfWeek, getTime, getMonthAndDay } from '../../utils/common';
import banner from '../../assets/Images/socha_banner.png'


interface props {
    event: Event,
    onCardClick?: () => void
}
export default function EventCard({ event, onCardClick = () => { } }: props) {
    const eventStartDateTime = convertUtcToLocal(event?.startTime, event?.timezone);

    return (
        <div className='w-[17.75rem] mb-12 lg:mb-0 cursor-pointer' onClick={() => onCardClick()}>
            <img src={event?.bannerImage || banner} className='rounded-3xl w-full h-[17.75rem] object-cover' />

            <div className='text-[18px] truncate mt-2.5 '>
                {event?.eventName}
            </div>
            <div className='text-[#999999] truncate text-[.875rem]'>
                {event?.location}
            </div>

            <div className='flex justify-between mt-2.5 text-[.775rem]' >
                <div className='rounded-2xl py-1 px-2 bg-[#2E2E2E] gap-1.5 flex items-center'>
                    <img src={GrayCal} />
                    <span className='font-medium'><span className='text-text-gray '>{getDayOfWeek(eventStartDateTime)}</span> {getMonthAndDay(eventStartDateTime)} - {getTime(eventStartDateTime)}</span>
                </div>
                <div className='rounded-2xl py-1 px-2 bg-[#2E2E2E] gap-1.5 flex items-center'>
                    <span className='text-text-gray '>From</span>
                    <span className=' font-medium'>{event?.isFree ? 'Free Event' : `$${event?.minimumTicketPrice}` || 'Free Event'}</span>
                </div>
            </div>

        </div>
    )
}
