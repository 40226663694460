import React, { useEffect, useRef, useState } from 'react';
import DropdownIcon from '../../assets/Icons/refine/dropdown.svg';
import ReactDOM from 'react-dom';
import ProfileIcon from '../../assets/Icons/profileMenu/profile.svg';
import PrefIcon from '../../assets/Icons/profileMenu/pref.svg';
import HelpIcon from '../../assets/Icons/profileMenu/help.svg';
import LogoutIcon from '../../assets/Icons/profileMenu/logout.svg';
import { useNavigate } from 'react-router-dom';
import AppStore from "../../assets/Icons/payout/appStore.svg";
import PlayStore from "../../assets/Icons/payout/playStore.svg";

interface Props {
    profileImage: string;
    onLogoutClick: () => void;
    onPefClick: (tab: string) => void;
}

const menuItems = [
    { label: 'Profile', icon: ProfileIcon, action: 'profile' },
    { label: 'Connections', icon: ProfileIcon, action: 'connections' },
    { label: 'Preference', icon: PrefIcon, action: 'preference' },
    { label: 'Help center', icon: HelpIcon, action: 'help center' },
    { label: 'Logout', icon: LogoutIcon, action: 'logout' },
];

const menuItemClass = "hover:bg-black hover:text-white flex items-center gap-2 font-semibold p-2 w-full cursor-pointer rounded-xl";

const ProfileMenu: React.FC<Props> = ({ profileImage, onLogoutClick,onPefClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const handleOpen = (tab: string) => {
        if (tab === 'logout') {
            onLogoutClick();
        } else if(tab == 'profile') {
            navigate('/profile');
        } else if (tab == 'connections') {
            onPefClick('connections')
        } else if (tab == 'preference') {
            onPefClick('preference')
        } else if (tab == 'help center') {
            window.location.href = "mailto:support@joinsocha.com";
        } else {
            console.log("clicked")
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    return (
        <div
            ref={divRef}
            className="flex gap-2 cursor-pointer items-center relative z-20"
            onClick={() => setIsOpen(!isOpen)}
        >
            <img src={profileImage} className="h-7 w-7 rounded-full object-cover" alt="Profile" />
            <img 
                src={DropdownIcon}
                className={`bg-primary-black py-2.5 rounded-full cursor-pointer transition-transform duration-300 transform ${isOpen ? 'rotate-180' : ''}`}
                alt="Dropdown"
            />
            {isOpen && <div className="px-1 py-3 bg-[#353535] rounded-[1.2rem] absolute w-56 top-[6vh] 2xl:top-[4vh] -right-3 text-text-gray z-50">
                <div className="w-full flex flex-col items-start">
                    {menuItems.map((item) => (
                        <div 
                            key={item.action}
                            className={menuItemClass} 
                            onClick={() => handleOpen(item.action)}
                        >
                            <img src={item.icon} alt={item.label} />
                            {item.label}
                        </div>
                    ))}
                </div>
                <hr className='border-[#474747] my-1'/>
                <div className="flex items-center gap-2 p-1.5">
                <a href="https://apps.apple.com/in/app/socha-social/id6444107828" target="_blank"><img src={AppStore} alt="app store" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.app.socha&pcampaignid=web_share" target="_blank"><img src={PlayStore} alt="google play" /></a>
              </div>
            </div>}
        </div>
    );
};

export default ProfileMenu;
