import React, { ChangeEvent, useEffect, useState } from 'react'
import RadioInput from '../../../components/radioInput'
import PrimaryButton from '../../../components/primaryButton'
import ForwardButton from '../../../assets/Icons/forward/forward.svg'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import HostDatePicker from '../../../components/hostDatePicker/Index';
import { LocationService } from '../../../services/locationService';
import { CreateEvent } from '../../../services/createEventService';
import LocationSearch from '../../../components/PlacesSearch/indexx';
import TimePicker from '../../../components/timePicker';
import { getLocalStorage } from '../../../utils/localStorage';
import { LOCAL_STORAGE } from '../../../utils/constants';
import moment from 'moment-timezone';
import { convertToUtcDateTime, utcToLocalDate, utcToLocalTime } from '../../../utils/common';
import { toast } from "react-toastify";

function BasicDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentTime = moment().format('hh:mm A');
  const queryParams = new URLSearchParams(location.search);
  const id: any = queryParams.get("eventid");
  const [title, setTitle] = useState('')
  const [selectedOption, setSelectedOption] = useState('Private');
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [startTime, setStartTime] = useState(currentTime)
  const [endTime, setEndTime] = useState(currentTime)
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [eventLocation, setEventLocation] = useState<any>('')
  const [coordinates, setCoordinates] = useState<any>('');
  const [timezone, setTimezone] = useState<string | null | any>(null);
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [description, setDescription] = useState('')
  const [eventData, setEventData] = useState<any>()
  const [invitedUser, setInvitedUser] = useState<number>()
  const [errors, setErrors] = useState<any>({});




  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await LocationService.getCurrentLocation();
        setCurrentLocation(res);
      } catch (error) {
        console.error("Error while fetching location:", error);
      }
    };



    fetchData();
    if (id) {
      fetchEvents()
    }
  }, []);

  useEffect(() => {
    if (eventData) {
      setTitle(eventData.eventName);
      setSelectedOption(eventData.eventMode);
      setDescription(eventData.description);
      setStartDate(utcToLocalDate(eventData?.startTime, eventData?.timezone));
      setEndDate(utcToLocalDate(eventData?.endTime, eventData?.timezone));
      setStartTime(utcToLocalTime(eventData.startTime, eventData?.timezone));
      setEndTime(utcToLocalTime(eventData.endTime, eventData?.timezone));
      setEventLocation({ label: eventData.location, value: { place_id: eventData.coordinates } });
      setCoordinates(eventData.coordinates.split(',').map((coord: any) => parseFloat(coord)));
      setTimezone(eventData.timezone);
    }
  }, [eventData]);





  const handleSelect = async (place: any) => {
    setEventLocation(place);
    const placeId = place.value.place_id;
    try {
      const result: any = await LocationService.geocodeByPlaceId(placeId);
      const { lat, lng } = result.geometry.location;
      const coords = { lat: lat(), lng: lng() };
      setCoordinates(coords);

      const timestamp = moment().unix();
      const timezoneId = await LocationService.fetchTimezone(coords.lat, coords.lng, timestamp);
      setTimezone(timezoneId);
    } catch (error) {
      console.error('Geocoder or Timezone API failed due to:', error);
    }
  };


  const fetchEvents = async () => {
    try {
      const res = await CreateEvent.fetchEventById({ id })
      if (res?.data) {
        setInvitedUser(res?.data?.invitedGuestCount)
        setEventData(res?.data?.event)
      }
    } catch (error) {
      console.log(error, 'Error in fetching events')
    }
  }


  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
  }

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {

    setSelectedOption(event.target.value);
  };


  const setStatusWhenEdit = () => {
    const currentDateTime = moment().tz(timezone).format('YYYY-MM-DD')
    const startDateTime = convertToUtcDateTime(startDate, startTime, timezone)
    const endDateTime = convertToUtcDateTime(endDate, endTime, timezone)
    if (endDateTime < currentDateTime) {
      return 'Completed'
    } else {
      if (startDateTime > currentDateTime) {
        return 'Planned'
      } else {
        return 'On-Going'
      }
    }
  }




  const validateForm = () => {
    let errors: any = {};

    if (!title) {
      errors.title = 'Title is required.'
    }
    if (!startDate) {
      errors.startDate = 'Start date is required.'
    }


    if (!eventLocation) {
      errors.eventLocation = 'Location is required.'
    }
    if (!description) {
      errors.description = 'Description is required.'
    }
    // Validation checks

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitBasicDetails = async (e: any) => {
    e.preventDefault()

    if (!validateForm()) {
      return;
    }

    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

    // Combine start date and time, and end date and time into valid moment strings
    const startDatetimeString = `${formattedStartDate} ${startTime}`;
    const endDatetimeString = `${formattedEndDate} ${endTime}`;

    const startDatetime = moment.tz(startDatetimeString, "YYYY-MM-DD hh:mm A", timezone);
    const endDatetime = moment.tz(endDatetimeString, "YYYY-MM-DD hh:mm A", timezone);
    const currentDatetime = moment.tz(timezone);



    // Validation: StartDatetime should not be before currentDatetime
    if (startDatetime.isBefore(currentDatetime)) {
      toast.error('Start time cannot be before the current time');
      return;
    }

    // Validation: StartDatetime should not be after endDatetime
    if (startDatetime.isAfter(endDatetime)) {
      toast.error('Start time cannot be after the end time');
      return;
    }




    try {
      let params = {}
      let selectedCoordinates: string = Object.values(coordinates).toString()

      if (!id) {
        params = {
          coordinates: selectedCoordinates,
          date: moment().tz(timezone).format('YYYY-MM-DD'),
          description: description,
          endTime: convertToUtcDateTime(endDate, endTime, timezone),
          eventMode: selectedOption,
          eventName: title,
          hostUserId: parseInt(userId),
          invitedUserId: [],
          location: eventLocation?.label,
          startTime: convertToUtcDateTime(startDate, startTime, timezone),
          timeZone: timezone
        }
        const res = await CreateEvent.basicDetails(params)
        if (res?.data) {
          toast.success(res.data.message)
          navigate({
            pathname: "/create-event/event-info",
            search: createSearchParams({
              eventid: res.data.eventID
            }).toString()
          })
        }
        else {
          console.log("Error")
        }
      }
      else {
        params = {
          description: description,
          hostUserId: parseInt(userId),
          invitedUserId: invitedUser,
          eventmode: selectedOption,
          location: eventLocation?.label,
          endTime: convertToUtcDateTime(endDate, endTime, timezone),
          startTime: convertToUtcDateTime(startDate, startTime, timezone),
          date: moment().tz(timezone).format('YYYY-MM-DD'),
          coordinates: selectedCoordinates,
          eventName: title,
          eventId: eventData?.id,
          status: setStatusWhenEdit(),
          timeZone: timezone
        }
        const res = await CreateEvent.updateEvent(params)
        if (res?.data) {
          toast.success(res.data.message)
          console.log(res.data.message)
          navigate({
            pathname: "/create-event/event-info",
            search: createSearchParams({
              eventid: eventData?.id
            }).toString()
          })
        }
        else {
          console.log("Error")
        }
      }
    }
    catch (error: any) {
      console.log('error submitting date : ', error)
      toast.error(error?.response?.data?.error || error?.message)

    }
  }

  const handleStartDateSelect = (date: any) => {
    setStartDate(date);
    if (!endDate) {
      setEndDate(date);
    }
  };



  return (
    <form onSubmit={submitBasicDetails}>
      <div>
        <label className='text-primary-white text-lg'>Title*</label>
        <div className='mt-2'>
          <input className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg ' placeholder='Enter event title here' value={title} onChange={handleTitleChange} />
          {errors.title && <p className='text-primary-red'>{errors.title}</p>}
        </div>
      </div>

      <div className='my-8'>
        <span className='text-primary-white text-lg'>Event Mode</span>
        <div className='flex gap-4 mt-2'>
          <RadioInput heading='Private' description='This event will be invite only' value="Private" option={selectedOption} optionChange={handleOptionChange} />
          <RadioInput heading='Public' description='This event will be open to everyone' value="Open" option={selectedOption} optionChange={handleOptionChange} />
        </div>
      </div>

      <div>
        <label className='text-primary-white text-lg'>Select Location*</label>
        <div className='mt-2'>

          <LocationSearch handleLocationSelect={(place) => { handleSelect(place) }} location={eventLocation} />
          {errors.eventLocation && <p className='text-primary-red'>{errors.eventLocation}</p>}
        </div>
      </div>

      <div className='md:flex gap-4 my-8'>
        <div className='md:w-1/2'>
          <span className='text-primary-white text-lg'>Event Start Date*</span>
          <div className='relative mt-2'>
            {eventData?.status === "On-Going" ? (
              <>
                <HostDatePicker
                  dateProp={startDate}
                  onDateSelect={handleStartDateSelect}
                  disabled={true}
                />
                {errors.startDate && <p className='text-primary-red'>{errors.startDate}</p>}
              </>
            ) : (
              <>
                <HostDatePicker
                  dateProp={startDate}
                  onDateSelect={handleStartDateSelect}
                  disabled={false}
                  timezone={timezone}
                />
                {errors.startDate && <p className='text-primary-red'>{errors.startDate}</p>}
              </>
            )}
          </div>

        </div>
        <div className='md:w-1/2'>
          <label className='text-primary-white text-lg'>Time*</label>
          <div className='mt-2'>
            <TimePicker
              eventTime={startTime}
              onChange={(value) => setStartTime(value)}
              disabled={eventData?.status === "On-Going"}
            />
            {errors.startTime && <p className='text-primary-red'>{errors.startTime}</p>}
          </div>

        </div>
      </div>

      <div className='md:flex gap-4 my-8'>
        <div className='md:w-1/2'>
          <label className='text-primary-white text-lg'>Event End Date*</label>
          <div className='relative mt-2'>
            <HostDatePicker dateProp={endDate ? endDate : startDate} onDateSelect={(date) => { setEndDate(date) }} disabled={eventData?.status === "On-Going"} timezone={timezone} />
          </div>
        </div>
        <div className='md:w-1/2'>
          <label className='text-primary-white text-lg'>Time*</label>
          <div className='mt-2'>
            <TimePicker eventTime={endTime} onChange={(value) => setEndTime(value)} disabled={eventData?.status === "On-Going"} />
            {errors.startTime && <p className='text-primary-red'>{errors.startTime}</p>}
          </div>
        </div>
      </div>

      <div className='my-8'>
        <label className='text-primary-white text-lg'>Description*</label>
        <div className='mt-2'>
          <textarea className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white overflow-y-auto scrollableDiv placeholder: text-lg ' placeholder='Enter description of the event' value={description} onChange={handleDescriptionChange} />
          {errors.description && <p className='text-primary-red'>{errors.description}</p>}
        </div>
      </div>

      <div className='border-t border-text-gray'></div>
      <div className='flex place-content-end items-center my-8'>
        <PrimaryButton name='Save & Next' icon={ForwardButton} type='submit' classNames='px-2 py-3' />
      </div>
    </form>
  )
}

export default BasicDetails