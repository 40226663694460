import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalenderIcon from "../../assets/Icons/refine/calender.svg";
import CancelIcon from "../../assets/Icons/refine/cancel.svg";
import './CustomCalender.css'
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const monthNamesAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SELECTED_RANGE = {
  START: "Start",
  END: "End",
};

interface props{
  onDateSelect: (start: Value | any, end: Value | any) => void;
  startDateProp?: any;
  endDateProp?:any
}
export default function DatePicker({ onDateSelect = () => { }, startDateProp, endDateProp }: props) {
  const location = useLocation();
  const [value, setValue] = useState<Value | any>(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState<Value | any>(startDateProp || "");
  const [endDate, setEndDate] = useState<Value | any>(endDateProp || "");
  const divRef = useRef<HTMLDivElement>(null);
  const [minDate, setMinDate] = useState(new Date())

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if(startDateProp)
      setStartDate(new Date(startDateProp));
    if(endDateProp)
    setEndDate(new Date(endDateProp));
  }, [startDateProp, endDateProp]);

  

  const onChange = (newValue: any) => {
    if (selectedDate == SELECTED_RANGE.START) {
      setStartDate(newValue);
      setMinDate(newValue)
      setSelectedDate(SELECTED_RANGE.END);
      return;
    } else {
      if(newValue < startDate)
        {
          toast.error("End date must be later than start date.");
          return;
        }
      setEndDate(newValue);
      setSelectedDate('');
      onDateSelect(startDate, newValue);
      setShowCalendar(false);
    }
  };

  return (
    <div ref={divRef}>
      <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center ">
        <img
          src={CalenderIcon}
          className="p-2 cursor-pointer"
          onClick={() => {
            setSelectedDate(SELECTED_RANGE.START);
            setShowCalendar(!showCalendar);
          }}
        />
        <div className="flex justify-between items-center bg-primary-black p-2 gap-2.5 rounded-lg px-3 w-full min-w-[10rem] ">
          <div className="flex gap-2 items-center cursor-pointer">
            <div
              className={`cursor-pointer ${
                selectedDate == SELECTED_RANGE.START ? "bg-input-box-gray" : ""
                }`}
                onClick={() => {
                  setMinDate(new Date())
                  setSelectedDate(SELECTED_RANGE.START);
                  setShowCalendar(!showCalendar);
                }}
            >
              {startDate ? (
                <>
                  <span className="text-text-gray ">
                    {monthNamesAbbreviated[startDate?.getMonth()] + " "}
                  </span>
                  {startDate?.getDate()}
                </>
              ) : (
                <span className="text-text-gray">Start</span>
              )}
            </div>
            <span>-</span>
            <div
              className={`cursor-pointer ${
                selectedDate == SELECTED_RANGE.END ? "bg-input-box-gray" : ""
                }`}
              onClick={() => {
                if (!startDate) {
                  setMinDate(new Date())
                  setSelectedDate(SELECTED_RANGE.START);
                } else
                setSelectedDate(SELECTED_RANGE.END);
                  setShowCalendar(!showCalendar);
                }}
            >
              {endDate ? (
                <>
                  <span className="text-text-gray ">
                    {monthNamesAbbreviated[endDate?.getMonth()] + " "}
                  </span>
                  {endDate?.getDate()}
                </>
              ) : (
                <span className="text-text-gray">End</span>
              )}
            </div>
          </div>
          <img src={CancelIcon} className="cursor-pointer " 
          onClick={ () => {
                  setStartDate("");
                  setEndDate("");
                  onDateSelect('','');
                } }
          />
        </div>
      </div>
      {showCalendar && (
        <div className="absolute z-10 mt-2">
          <Calendar
            onChange={onChange}
            value={selectedDate == SELECTED_RANGE.START ? startDate : endDate}
            className={`font-[DM-sans] custom-calendar`}
            minDate={location.search.includes('Past') ? undefined : minDate}
          />
        </div>
      )}
    </div>
  );
}
