import React from 'react'
import styles from './BannerSection.module.scss'
import Header from '../../../../components/header'
import PhoneImage from '../../../../assets/Images/landingpage/phone.png'
import QrCode from '../../../../assets/Images/landingpage/qrcode.svg'
import Celebration from '../../../../assets/Images/landingpage/celebration.svg'
import {motion, AnimatePresence, stagger } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

type PLATFORM = 'android' | 'ios'
export default function BannerSection() {

  const navigate = useNavigate();

  const appUrls = {
    android: 'com.app.sondr.sondrapp://',
  };

  const handleAppDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    const isWindows = /Windows/i.test(userAgent);
    const isMac = /Macintosh/i.test(userAgent);
    
    const appUrls = {
      android: 'com.app.sondr.sondrapp://',
      ios: 'sondrapp://'
    };
    
    const storeUrls = {
      android: 'https://play.google.com/store/apps/details?id=com.app.socha',
      ios: 'https://apps.apple.com/app/id6444107828'
    };
  
    let platform: PLATFORM |''  = '';
  
    if (isAndroid || isIOS) {
      platform = isAndroid ? 'android' : 'ios';
    } else if (isWindows) {
      platform = 'android'; // Use Android logic for Windows
    } else if (isMac) {
      platform = 'ios'; // Use iOS logic for Mac
    }
  
    if (platform) {
      const appUrl = appUrls[platform];
      const storeUrl = storeUrls[platform];
  
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = appUrl;
      document.body.appendChild(iframe);
  
      setTimeout(() => {
        window.location.href = storeUrl; // Redirect to the store if app not installed
        document.body.removeChild(iframe);
      }, 1500);
    } else {
      // Handle unsupported devices
      console.log('Please visit our website or use a supported device to download the app.');
    }
  };
  

  return (
      <div className={`${styles.content} relative lg:h-screen bg-no-repeat bg-cover md:bg-contain md:mx-auto `}>
      <Header/>
      <div className='w-full flex justify-between items-center h-[60vh] mt-[6rem] lg:px-20 px-2'>
        <div className='text-primary-orange'>
          <AnimatePresence>
            <motion.h1
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              key="child1"
              transition={{ duration: 2.5, ease: "linear" }}
              className='font-bold text-[2rem] md:text-3xl z-9 lg:text-5xl xl:text-6xl text-primary-white'>
              <span className='text-primary-orange z-9'>Socha</span><span>: where events</span><br />
              <span className={`${styles.arrow} relative`}>come alive</span>
            </motion.h1>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key="child2"
              transition={{ duration: 1, ease: "linear", delay: 2.5 }}
              className="text-primary-white font-normal text-sm md:text-base lg:text-lg mt-">
                A ticketing and event management social platform that allows guest to connect with each other!
            </motion.p>
          </AnimatePresence>
          <div className='flex gap-5 mt-8'>
            <button className='flex justify-between items-center gap-2 bg-tertiary-gray p-[.5rem] rounded-[10px] md:px-4  max-w-fit h-14' onClick={handleAppDownload}>
              <img src={QrCode} alt='qrCode' className='w-[2.2rem]' /><span className='text-base text-primary-white' >Download Socha</span>
            </button>
            <button className='flex justify-between items-center gap-2 bg-primary-orange  p-[.5rem] rounded-[10px] md:px-4  max-w-fit h-14' onClick={() => navigate('/create-event/basic-details')}>
              <img src={Celebration} alt='Celebration' className='w-[1.6rem]'/><span className=' text-[.9rem] font-medium text-primary-white'>Create Event</span>
            </button>
          </div>
        </div>
        <img className='transform scale-90 w-[45%] hidden md:block' src={PhoneImage} />
      </div>
    </div>
  )
}
