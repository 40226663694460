import React, { useState, useEffect } from "react";
import PrimaryButton from "../primaryButton";
import CancelIcon from "../../assets/Icons/refine/cancel.svg";
import Logo from "../../assets/Images/login/logo.png";

const AppBanner = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [appLabel, setAppLabel] = useState('GET - On the Play Store');
  const [buttonLabel, setButtonLabel] = useState('View');
  const appUrls = {
    android: 'com.app.sondr.sondrapp://',
  };

  const checkIfAppInstalled = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isAndroid = userAgent.toLocaleLowerCase().includes('android');
    
    if (isAndroid) {
      setIsMobile(true); // Show the banner on mobile
      const appUrl = appUrls.android;

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = appUrl;
      document.body.appendChild(iframe);

      const fallbackTimeout = setTimeout(() => {
        setIsAppInstalled(false); // Assume app is not installed after timeout
        document.body.removeChild(iframe);
      }, 2000); // Increased time to avoid early disappearance

      iframe.onload = () => {
        clearTimeout(fallbackTimeout); // If app opens, clear the fallback
        setIsAppInstalled(true); // App is installed
        document.body.removeChild(iframe);
      };
    }
  };

  const handleAppDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isAndroid = userAgent.toLocaleLowerCase().includes('android');
    const appUrl = appUrls.android;
    const storeUrl = 'https://play.google.com/store/apps/details?id=com.app.socha';

    if (isAndroid) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = appUrl;
      document.body.appendChild(iframe);

      setTimeout(() => {
        if (!isAppInstalled) {
          window.location.href = storeUrl; // Redirect to Play Store if app not installed
        }
        document.body.removeChild(iframe);
      }, 1500);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    const isAndroid = userAgent.toLocaleLowerCase().includes('android');
    if (isAndroid) {
      checkIfAppInstalled(); // Check if the app is installed

      if (isAppInstalled) {
        setButtonLabel('Open');
        setAppLabel('Social Networking');
      }
    }
  }, [isAppInstalled]);

  return (
    <div className={isMobile ? "block" : "hidden"}>
      <div className="w-full p-4 bg-tertiary-gray flex items-center gap-2 md:hidden">
        <div className="close-btn shrink-0">
          <button
            className="bg-primary-black rounded-[50px]"
            onClick={() => setIsMobile(!isMobile)}
          >
            <img className="w-3" src={CancelIcon} alt="close button" onClick={() => setIsMobile(false)} />
          </button>
        </div>
        <div className="icon shrink-0 grow-[1]">
          <img className="w-14" src="/favicon.png" alt="logo" />
        </div>
        <div className="content grow-[6]">
          <h3 className="text-primary-white text-lg">Socha Social</h3>
          <h6 className="text-primary-white text-sm">{appLabel}</h6>
        </div>
        <PrimaryButton onClick={handleAppDownload} name={buttonLabel} classNames="px-4 py-2" />
      </div>
    </div>
  );
};

export default AppBanner;
