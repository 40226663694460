import React, { useCallback, useState } from 'react'
import styles from './CountryCodePicker.module.scss';

export interface Country {
    name: string
    code: string,
    country_code:string
  }
  const countries: Country[] = [
    {
      "code": "+1",
      "name": "United States",
      "country_code": "US"
    },
    {
      "code": "+7 840",
      "name": "Abkhazia",
      "country_code": "GE"
    },
    {
      "code": "+93",
      "name": "Afghanistan",
      "country_code": "AF"
    },
    {
      "code": "+355",
      "name": "Albania",
      "country_code": "AL"
    },
    {
      "code": "+213",
      "name": "Algeria",
      "country_code": "DZ"
    },
    {
      "code": "+1 684",
      "name": "American Samoa",
      "country_code": "AS"
    },
    {
      "code": "+376",
      "name": "Andorra",
      "country_code": "AD"
    },
    {
      "code": "+244",
      "name": "Angola",
      "country_code": "AO"
    },
    {
      "code": "+1 264",
      "name": "Anguilla",
      "country_code": "AI"
    },
    {
      "code": "+1 268",
      "name": "Antigua and Barbuda",
      "country_code": "AG"
    },
    {
      "code": "+54",
      "name": "Argentina",
      "country_code": "AR"
    },
    {
      "code": "+374",
      "name": "Armenia",
      "country_code": "AM"
    },
    {
      "code": "+297",
      "name": "Aruba",
      "country_code": "AW"
    },
    {
      "code": "+247",
      "name": "Ascension",
      "country_code": "AC"
    },
    {
      "code": "+61",
      "name": "Australia",
      "country_code": "AU"
    },
    {
      "code": "+672",
      "name": "Australian External Territories",
      "country_code": "AU"
    },
    {
      "code": "+43",
      "name": "Austria",
      "country_code": "AT"
    },
    {
      "code": "+994",
      "name": "Azerbaijan",
      "country_code": "AZ"
    },
    {
      "code": "+1 242",
      "name": "Bahamas",
      "country_code": "BS"
    },
    {
      "code": "+973",
      "name": "Bahrain",
      "country_code": "BH"
    },
    {
      "code": "+880",
      "name": "Bangladesh",
      "country_code": "BD"
    },
    {
      "code": "+1 246",
      "name": "Barbados",
      "country_code": "BB"
    },
    {
      "code": "+375",
      "name": "Belarus",
      "country_code": "BY"
    },
    {
      "code": "+32",
      "name": "Belgium",
      "country_code": "BE"
    },
    {
      "code": "+501",
      "name": "Belize",
      "country_code": "BZ"
    },
    {
      "code": "+229",
      "name": "Benin",
      "country_code": "BJ"
    },
    {
      "code": "+1 441",
      "name": "Bermuda",
      "country_code": "BM"
    },
    {
      "code": "+975",
      "name": "Bhutan",
      "country_code": "BT"
    },
    {
      "code": "+591",
      "name": "Bolivia",
      "country_code": "BO"
    },
    {
      "code": "+387",
      "name": "Bosnia and Herzegovina",
      "country_code": "BA"
    },
    {
      "code": "+267",
      "name": "Botswana",
      "country_code": "BW"
    },
    {
      "code": "+55",
      "name": "Brazil",
      "country_code": "BR"
    },
    {
      "code": "+246",
      "name": "British Indian Ocean Territory",
      "country_code": "IO"
    },
    {
      "code": "+1 284",
      "name": "British Virgin Islands",
      "country_code": "VG"
    },
    {
      "code": "+673",
      "name": "Brunei",
      "country_code": "BN"
    },
    {
      "code": "+359",
      "name": "Bulgaria",
      "country_code": "BG"
    },
    {
      "code": "+226",
      "name": "Burkina Faso",
      "country_code": "BF"
    },
    {
      "code": "+257",
      "name": "Burundi",
      "country_code": "BI"
    },
    {
      "code": "+855",
      "name": "Cambodia",
      "country_code": "KH"
    },
    {
      "code": "+237",
      "name": "Cameroon",
      "country_code": "CM"
    },
    {
      "code": "+1",
      "name": "Canada",
      "country_code": "CA"
    },
    {
      "code": "+238",
      "name": "Cape Verde",
      "country_code": "CV"
    },
    {
      "code": "+ 345",
      "name": "Cayman Islands",
      "country_code": "KY"
    },
    {
      "code": "+236",
      "name": "Central African Republic",
      "country_code": "CF"
    },
    {
      "code": "+235",
      "name": "Chad",
      "country_code": "TD"
    },
    {
      "code": "+56",
      "name": "Chile",
      "country_code": "CL"
    },
    {
      "code": "+86",
      "name": "China",
      "country_code": "CN"
    },
    {
      "code": "+61",
      "name": "Christmas Island",
      "country_code": "CX"
    },
    {
      "code": "+61",
      "name": "Cocos-Keeling Islands",
      "country_code": "CC"
    },
    {
      "code": "+57",
      "name": "Colombia",
      "country_code": "CO"
    },
    {
      "code": "+269",
      "name": "Comoros",
      "country_code": "KM"
    },
    {
      "code": "+242",
      "name": "Congo",
      "country_code": "CG"
    },
    {
      "code": "+243",
      "name": "Congo, Dem. Rep. of (Zaire)",
      "country_code": "CD"
    },
    {
      "code": "+682",
      "name": "Cook Islands",
      "country_code": "CK"
    },
    {
      "code": "+506",
      "name": "Costa Rica",
      "country_code": "CR"
    },
    {
      "code": "+385",
      "name": "Croatia",
      "country_code": "HR"
    },
    {
      "code": "+53",
      "name": "Cuba",
      "country_code": "CU"
    },
    {
      "code": "+599",
      "name": "Curacao",
      "country_code": "CW"
    },
    {
      "code": "+537",
      "name": "Cyprus",
      "country_code": "CY"
    },
    {
      "code": "+420",
      "name": "Czech Republic",
      "country_code": "CZ"
    },
    {
      "code": "+45",
      "name": "Denmark",
      "country_code": "DK"
    },
    {
      "code": "+253",
      "name": "Djibouti",
      "country_code": "DJ"
    },
    {
      "code": "+1 767",
      "name": "Dominica",
      "country_code": "DM"
    },
    {
      "code": "+1 809",
      "name": "Dominican Republic",
      "country_code": "DO"
    },
    {
      "code": "+670",
      "name": "East Timor",
      "country_code": "TL"
    },
    {
      "code": "+56",
      "name": "Easter Island",
      "country_code": "CL"
    },
    {
      "code": "+593",
      "name": "Ecuador",
      "country_code": "EC"
    },
    {
      "code": "+20",
      "name": "Egypt",
      "country_code": "EG"
    },
    {
      "code": "+503",
      "name": "El Salvador",
      "country_code": "SV"
    },
    {
      "code": "+240",
      "name": "Equatorial Guinea",
      "country_code": "GQ"
    },
    {
      "code": "+291",
      "name": "Eritrea",
      "country_code": "ER"
    },
    {
      "code": "+372",
      "name": "Estonia",
      "country_code": "EE"
    },
    {
      "code": "+251",
      "name": "Ethiopia",
      "country_code": "ET"
    },
    {
      "code": "+500",
      "name": "Falkland Islands",
      "country_code": "FK"
    },
    {
      "code": "+298",
      "name": "Faroe Islands",
      "country_code": "FO"
    },
    {
      "code": "+679",
      "name": "Fiji",
      "country_code": "FJ"
    },
    {
      "code": "+358",
      "name": "Finland",
      "country_code": "FI"
    },
    {
      "code": "+33",
      "name": "France",
      "country_code": "FR"
    },
    {
      "code": "+594",
      "name": "French Guiana",
      "country_code": "GF"
    },
    {
      "code": "+689",
      "name": "French Polynesia",
      "country_code": "PF"
    },
    {
      "code": "+241",
      "name": "Gabon",
      "country_code": "GA"
    },
    {
      "code": "+220",
      "name": "Gambia",
      "country_code": "GM"
    },
    {
      "code": "+995",
      "name": "Georgia",
      "country_code": "GE"
    },
    {
      "code": "+49",
      "name": "Germany",
      "country_code": "DE"
    },
    {
      "code": "+233",
      "name": "Ghana",
      "country_code": "GH"
    },
    {
      "code": "+350",
      "name": "Gibraltar",
      "country_code": "GI"
    },
    {
      "code": "+30",
      "name": "Greece",
      "country_code": "GR"
    },
    {
      "code": "+299",
      "name": "Greenland",
      "country_code": "GL"
    },
    {
      "code": "+1 473",
      "name": "Grenada",
      "country_code": "GD"
    },
    {
      "code": "+590",
      "name": "Guadeloupe",
      "country_code": "GP"
    },
    {
      "code": "+1 671",
      "name": "Guam",
      "country_code": "GU"
    },
    {
      "code": "+502",
      "name": "Guatemala",
      "country_code": "GT"
    },
    {
      "code": "+224",
      "name": "Guinea",
      "country_code": "GN"
    },
    {
      "code": "+245",
      "name": "Guinea-Bissau",
      "country_code": "GW"
    },
    {
      "code": "+592",
      "name": "Guyana",
      "country_code": "GY"
    },
    {
      "code": "+509",
      "name": "Haiti",
      "country_code": "HT"
    },
    {
      "code": "+504",
      "name": "Honduras",
      "country_code": "HN"
    },
    {
      "code": "+852",
      "name": "Hong Kong SAR China",
      "country_code": "HK"
    },
    {
      "code": "+36",
      "name": "Hungary",
      "country_code": "HU"
    },
    {
      "code": "+354",
      "name": "Iceland",
      "country_code": "IS"
    },
    {
      "code": "+91",
      "name": "India",
      "country_code": "IN"
    },
    {
      "code": "+62",
      "name": "Indonesia",
      "country_code": "ID"
    },
    {
      "code": "+98",
      "name": "Iran",
      "country_code": "IR"
    },
    {
      "code": "+964",
      "name": "Iraq",
      "country_code": "IQ"
    },
    {
      "code": "+353",
      "name": "Ireland",
      "country_code": "IE"
    },
    {
      "code": "+972",
      "name": "Israel",
      "country_code": "IL"
    },
    {
      "code": "+39",
      "name": "Italy",
      "country_code": "IT"
    },
    {
      "code": "+1 876",
      "name": "Jamaica",
      "country_code": "JM"
    },
    {
      "code": "+81",
      "name": "Japan",
      "country_code": "JP"
    },
    {
      "code": "+962",
      "name": "Jordan",
      "country_code": "JO"
    },
    {
      "code": "+7",
      "name": "Kazakhstan",
      "country_code": "KZ"
    },
    {
      "code": "+254",
      "name": "Kenya",
      "country_code": "KE"
    },
    {
      "code": "+686",
      "name": "Kiribati",
      "country_code": "KI"
    },
    {
      "code": "+965",
      "name": "Kuwait",
      "country_code": "KW"
    },
    {
      "code": "+996",
      "name": "Kyrgyzstan",
      "country_code": "KG"
    },
    {
      "code": "+856",
      "name": "Laos",
      "country_code": "LA"
    },
    {
      "code": "+371",
      "name": "Latvia",
      "country_code": "LV"
    },
    {
      "code": "+961",
      "name": "Lebanon",
      "country_code": "LB"
    },
    {
      "code": "+266",
      "name": "Lesotho",
      "country_code": "LS"
    },
    {
      "code": "+231",
      "name": "Liberia",
      "country_code": "LR"
    },
    {
      "code": "+218",
      "name": "Libya",
      "country_code": "LY"
    },
    {
      "code": "+423",
      "name": "Liechtenstein",
      "country_code": "LI"
    },
    {
      "code": "+370",
      "name": "Lithuania",
      "country_code": "LT"
    },
    {
      "code": "+352",
      "name": "Luxembourg",
      "country_code": "LU"
    },
    {
      "code": "+853",
      "name": "Macau SAR China",
      "country_code": "MO"
    },
    {
      "code": "+389",
      "name": "Macedonia",
      "country_code": "MK"
    },
    {
      "code": "+261",
      "name": "Madagascar",
      "country_code": "MG"
    },
    {
      "code": "+265",
      "name": "Malawi",
      "country_code": "MW"
    },
    {
      "code": "+60",
      "name": "Malaysia",
      "country_code": "MY"
    },
    {
      "code": "+960",
      "name": "Maldives",
      "country_code": "MV"
    },
    {
      "code": "+223",
      "name": "Mali",
      "country_code": "ML"
    },
    {
      "code": "+356",
      "name": "Malta",
      "country_code": "MT"
    },
    {
      "code": "+692",
      "name": "Marshall Islands",
      "country_code": "MH"
    },
    {
      "code": "+596",
      "name": "Martinique",
      "country_code": "MQ"
    },
    {
      "code": "+222",
      "name": "Mauritania",
      "country_code": "MR"
    },
    {
      "code": "+230",
      "name": "Mauritius",
      "country_code": "MU"
    },
    {
      "code": "+262",
      "name": "Mayotte",
      "country_code": "YT"
    },
    {
      "code": "+52",
      "name": "Mexico",
      "country_code": "MX"
    },
    {
      "code": "+691",
      "name": "Micronesia",
      "country_code": "FM"
    },
    {
      "code": "+1 808",
      "name": "Midway Island",
      "country_code": "UM"
    },
    {
      "code": "+373",
      "name": "Moldova",
      "country_code": "MD"
    },
    {
      "code": "+377",
      "name": "Monaco",
      "country_code": "MC"
    },
    {
      "code": "+976",
      "name": "Mongolia",
      "country_code": "MN"
    },
    {
      "code": "+382",
      "name": "Montenegro",
      "country_code": "ME"
    },
    {
      "code": "+1 664",
      "name": "Montserrat",
      "country_code": "MS"
    },
    {
      "code": "+212",
      "name": "Morocco",
      "country_code": "MA"
    },
    {
      "code": "+95",
      "name": "Myanmar",
      "country_code": "MM"
    },
    {
      "code": "+264",
      "name": "Namibia",
      "country_code": "NA"
    },
    {
      "code": "+674",
      "name": "Nauru",
      "country_code": "NR"
    },
    {
      "code": "+977",
      "name": "Nepal",
      "country_code": "NP"
    },
    {
      "code": "+31",
      "name": "Netherlands",
      "country_code": "NL"
    },
    {
      "code": "+599",
      "name": "Netherlands Antilles",
      "country_code": "AN"
    },
    {
      "code": "+1 869",
      "name": "Nevis",
      "country_code": "KN"
    },
    {
      "code": "+687",
      "name": "New Caledonia",
      "country_code": "NC"
    },
    {
      "code": "+64",
      "name": "New Zealand",
      "country_code": "NZ"
    },
    {
      "code": "+505",
      "name": "Nicaragua",
      "country_code": "NI"
    },
    {
      "code": "+227",
      "name": "Niger",
      "country_code": "NE"
    },
    {
      "code": "+234",
      "name": "Nigeria",
      "country_code": "NG"
    },
    {
      "code": "+683",
      "name": "Niue",
      "country_code": "NU"
    },
    {
      "code": "+672",
      "name": "Norfolk Island",
      "country_code": "NF"
    },
    {
      "code": "+850",
      "name": "North Korea",
      "country_code": "KP"
    },
    {
      "code": "+1 670",
      "name": "Northern Mariana Islands",
      "country_code": "MP"
    },
    {
      "code": "+47",
      "name": "Norway",
      "country_code": "NO"
    },
    {
      "code": "+968",
      "name": "Oman",
      "country_code": "OM"
    },
    {
      "code": "+92",
      "name": "Pakistan",
      "country_code": "PK"
    },
    {
      "code": "+680",
      "name": "Palau",
      "country_code": "PW"
    },
    {
      "code": "+970",
      "name": "Palestinian Territory",
      "country_code": "PS"
    },
    {
      "code": "+507",
      "name": "Panama",
      "country_code": "PA"
    },
    {
      "code": "+675",
      "name": "Papua New Guinea",
      "country_code": "PG"
    },
    {
      "code": "+595",
      "name": "Paraguay",
      "country_code": "PY"
    },
    {
      "code": "+51",
      "name": "Peru",
      "country_code": "PE"
    },
    {
      "code": "+63",
      "name": "Philippines",
      "country_code": "PH"
    },
    {
      "code": "+48",
      "name": "Poland",
      "country_code": "PL"
    },
    {
      "code": "+351",
      "name": "Portugal",
      "country_code": "PT"
    },
    {
      "code": "+1 787",
      "name": "Puerto Rico",
      "country_code": "PR"
    },
    {
      "code": "+974",
      "name": "Qatar",
      "country_code": "QA"
    },
    {
      "code": "+262",
      "name": "Reunion",
      "country_code": "RE"
    },
    {
      "code": "+40",
      "name": "Romania",
      "country_code": "RO"
    },
    {
      "code": "+7",
      "name": "Russia",
      "country_code": "RU"
    },
    {
      "code": "+250",
      "name": "Rwanda",
      "country_code": "RW"
    },
    {
      "code": "+685",
      "name": "Samoa",
      "country_code": "WS"
    },
    {
      "code": "+378",
      "name": "San Marino",
      "country_code": "SM"
    },
    {
      "code": "+239",
      "name": "Sao Tome and Principe",
      "country_code": "ST"
    },
    {
      "code": "+966",
      "name": "Saudi Arabia",
      "country_code": "SA"
    },
    {
      "code": "+221",
      "name": "Senegal",
      "country_code": "SN"
    },
    {
      "code": "+381",
      "name": "Serbia",
      "country_code": "RS"
    },
    {
      "code": "+248",
      "name": "Seychelles",
      "country_code": "SC"
    },
    {
      "code": "+232",
      "name": "Sierra Leone",
      "country_code": "SL"
    },
    {
      "code": "+65",
      "name": "Singapore",
      "country_code": "SG"
    },
    {
      "code": "+421",
      "name": "Slovakia",
      "country_code": "SK"
    },
    {
      "code": "+386",
      "name": "Slovenia",
      "country_code": "SI"
    },
    {
      "code": "+677",
      "name": "Solomon Islands",
      "country_code": "SB"
    },
    {
      "code": "+252",
      "name": "Somalia",
      "country_code": "SO"
    },
    {
      "code": "+27",
      "name": "South Africa",
      "country_code": "ZA"
    },
    {
      "code": "+82",
      "name": "South Korea",
      "country_code": "KR"
    },
    {
      "code": "+34",
      "name": "Spain",
      "country_code": "ES"
    },
    {
      "code": "+94",
      "name": "Sri Lanka",
      "country_code": "LK"
    },
    {
      "code": "+249",
      "name": "Sudan",
      "country_code": "SD"
    },
    {
      "code": "+597",
      "name": "Suriname",
      "country_code": "SR"
    },
    {
      "code": "+268",
      "name": "Swaziland",
      "country_code": "SZ"
    },
    {
      "code": "+46",
      "name": "Sweden",
      "country_code": "SE"
    },
    {
      "code": "+41",
      "name": "Switzerland",
      "country_code": "CH"
    },
    {
      "code": "+963",
      "name": "Syria",
      "country_code": "SY"
    },
    {
      "code": "+886",
      "name": "Taiwan",
      "country_code": "TW"
    },
    {
      "code": "+992",
      "name": "Tajikistan",
      "country_code": "TJ"
    },
    {
      "code": "+255",
      "name": "Tanzania",
      "country_code": "TZ"
    },
    {
      "code": "+66",
      "name": "Thailand",
      "country_code": "TH"
    },
    {
      "code": "+670",
      "name": "Timor Leste",
      "country_code": "TL"
    },
    {
      "code": "+228",
      "name": "Togo",
      "country_code": "TG"
    },
    {
      "code": "+690",
      "name": "Tokelau",
      "country_code": "TK"
    },
    {
      "code": "+676",
      "name": "Tonga",
      "country_code": "TO"
    },
    {
      "code": "+1 868",
      "name": "Trinidad and Tobago",
      "country_code": "TT"
    },
    {
      "code": "+216",
      "name": "Tunisia",
      "country_code": "TN"
    },
    {
      "code": "+90",
      "name": "Turkey",
      "country_code": "TR"
    },
    {
      "code": "+993",
      "name": "Turkmenistan",
      "country_code": "TM"
    },
    {
      "code": "+1 649",
      "name": "Turks and Caicos Islands",
      "country_code": "TC"
    },
    {
      "code": "+688",
      "name": "Tuvalu",
      "country_code": "TV"
    },
    {
      "code": "+256",
      "name": "Uganda",
      "country_code": "UG"
    },
    {
      "code": "+380",
      "name": "Ukraine",
      "country_code": "UA"
    },
    {
      "code": "+971",
      "name": "United Arab Emirates",
      "country_code": "AE"
    },
    {
      "code": "+44",
      "name": "United Kingdom",
      "country_code": "GB"
    },
    {
      "code": "+1",
      "name": "United States",
      "country_code": "US"
    },
    {
      "code": "+598",
      "name": "Uruguay",
      "country_code": "UY"
    },
    {
      "code": "+998",
      "name": "Uzbekistan",
      "country_code": "UZ"
    },
    {
      "code": "+678",
      "name": "Vanuatu",
      "country_code": "VU"
    },
    {
      "code": "+58",
      "name": "Venezuela",
      "country_code": "VE"
    },
    {
      "code": "+84",
      "name": "Vietnam",
      "country_code": "VN"
    },
    {
      "code": "+1 340",
      "name": "U.S. Virgin Islands",
      "country_code": "VI"
    },
    {
      "code": "+681",
      "name": "Wallis and Futuna",
      "country_code": "WF"
    },
    {
      "code": "+967",
      "name": "Yemen",
      "country_code": "YE"
    },
    {
      "code": "+260",
      "name": "Zambia",
      "country_code": "ZM"
    },
    {
      "code": "+263",
      "name": "Zimbabwe",
      "country_code": "ZW"
    }
  ]
  

interface props{
    onCountryChange: (value: string) => void
}

export default function CountryCodePicker({ onCountryChange }: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country>(
    countries.find(country => country.code === '+1') || countries[0]
  );

  const onSelect = useCallback(
    (country: Country) => {
      setSelectedCountry(country);
      setIsOpen(false);
      onCountryChange(country.code); // Update the parent with the selected country code
    },
    [onCountryChange]
  );

  return (
    <div className="relative">
      {/* Dropdown toggle */}
      <div
        className='w-full px-6 py-4 text-primary-white bg-[#222222] text-xs cursor-pointer flex items-center gap-3 border border-[#424242] rounded-xl'
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: '#222222' }}
      >
        <img
          src={`https://flagcdn.com/w320/${selectedCountry.country_code.toLowerCase()}.png`}
          alt={selectedCountry.name}
          className="w-7 h-4"
        />
        <span>{selectedCountry.code}</span>
      </div>

      {/* Dropdown options */}
      {isOpen && (
        <ul className="z-50 absolute bg-[#222222] text-primary-white border border-gray-500 w-[45vw] md:w-[20vw] max-h-60 overflow-y-auto mt-1 ">
          {countries.map((country, index) => (
            <li
              key={index}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-700"
              onClick={() => onSelect(country)}
            >
              {/* <img
                src={`https://flagcdn.com/${country.country_code.toLowerCase()}.png`}
                alt={country.name}
                className="w-5 h-5 mr-2"
              /> */}
              <span>{`${country.name} ${country.code}`}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
