import Axios from '../utils/axiosInstance'

const createOTP = (phoneNumber: string) => {
    return Axios.post('createOtp', { phoneNumber, mode: 'web' });
}

const createEmailOTP = (email: string) => {
    return Axios.post('sendEmailOtp', {email})
}

const verifyOTP = (data: any) => {
    return Axios.post('verfiyOtp', data)
}

const verifyEmailOTP = (data: any) => {
    return Axios.post('verifyEmailOtp', data)
}

const profileSetup = (data: any) => {
      return Axios.post('profileSetup-web', data)
}

const updateUserDetails = (data: any) => {
    return Axios.put('updateUserDetailsWeb', data)
}

const loginWeb = (data: any) => {
    return Axios.post('login-web', data);
}
const updatePhoneNumber = (data: any) => {
    return Axios.post('UpdatePhoneNumberByID', data);
    
}
const logout = () => {
    localStorage.clear();
}

export const AuthService = {
    createOTP,
    createEmailOTP,
    verifyOTP,
    verifyEmailOTP,
    profileSetup,
    updateUserDetails,
    loginWeb,
    logout,
    updatePhoneNumber
}