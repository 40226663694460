import React, { useCallback, useEffect, useState, useRef } from 'react'
import styles from './Otp.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import { AuthService } from '../../../services/authService';
import { v4 as uuidv4 } from 'uuid';
import { LOCAL_STORAGE } from '../../../utils/constants';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../../utils/localStorage';
import { useSpinner } from '../../../components/spinner/SpinnerContext';
import { toast } from 'react-toastify';
import Logo from '../../../assets/Images/login/logo.png'
import PrimaryButton from '../../../components/primaryButton';

export default function OtpScreen() {
  let location = useLocation();
  const { mobileNumber, email,updatedNumber } = location?.state ?? {};
  const [otp, setOtp] = useState('');
  const [otpTimer, setOtpTimer] = useState('');
  const [otpExpired, setOtpExpired] = useState(false);
  const navigate = useNavigate();
  const [userMobileNumber, setUserMobileNumber] = useState(mobileNumber);
  const linkToDetailPage: any = getLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE);
  const goToDetailPage = linkToDetailPage ? true : false;
  const [OTPError, setOTPError] = useState('');
  const { setShowSpinner } = useSpinner();
  const OTPInterval = useRef<any>(null);

  useEffect(() => {
    startOtpTimer();
    return () => clearOtpTimer(); // Clear the timer on unmount
  }, []);


  const onVerifyClick = async () => {
    try {
      if (!otp) {
        setOTPError('Incorrect OTP. Please try again.');
        return;
      }
      setShowSpinner(true);
      const uuid = uuidv4();
      const payload: { otp: string; uuid: string; email?: string; phoneNumber?: string } = {
        otp: otp,
        uuid: uuid
      };

      if (email) {
        payload.email = email;
      } else {
        payload.phoneNumber = userMobileNumber;
      }

      const verifyFunction = email ? AuthService.verifyEmailOTP : AuthService.verifyOTP;

      const res = await verifyFunction(payload);

      if (res.data.message == 'Verified sucessfully' && res.data.profileStatus != 'NewUser') {
        if (email) {
          setLocalStorage(LOCAL_STORAGE.USER_MOBILE_NUMBER, res?.data?.phoneNumber);
          setUserMobileNumber(res?.data?.phoneNumber);
          setLocalStorage(LOCAL_STORAGE.USER_ID, res?.data?.userID);
        }
        loginWeb(uuid);
        return;
      } else {
        navigate("/auth/signup", { state: { mobileNumber: res?.data?.phoneNumber || mobileNumber, email } });
      }
      setShowSpinner(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      toast.error(errorMessage);
      console.log(error);
      setShowSpinner(false);
    }
  };

  const loginWeb = async (uuid: string) => {
    try {
      setShowSpinner(true);
      const data = {
        phoneNumber: userMobileNumber.toString(),
        UUID: uuid,
        type: "login"
      }
      const res = await AuthService.loginWeb(data);
      if (res && res.status == 1) {
        setLocalStorage(LOCAL_STORAGE.USER_TOKEN, res?.data?.token);
        res?.data?.userID && setLocalStorage(LOCAL_STORAGE.USER_ID, res?.data?.userID);

        if (goToDetailPage) {
          removeLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE)
          window.location.replace(linkToDetailPage)
          setShowSpinner(false);
        } else {
          if (updatedNumber)
            await AuthService.updatePhoneNumber({ ID: res?.data?.userID, phoneNumber: updatedNumber })
          
          navigate('/discover', { replace: true });
          setShowSpinner(false);
        }

      } else {
        navigate('/auth', { replace: true });
        console.log("Error while login");
        setShowSpinner(false);
      }

    } catch (error: any) {
      console.log("error loginWeb : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false);
    }
  }

  function startOtpTimer() {
    let timerSeconds = 59;
    setOtpExpired(false);
    clearOtpTimer();

    OTPInterval.current = setInterval(() => {
      const minutes = Math.floor(timerSeconds / 60).toString().padStart(2, '0');
      const seconds = (timerSeconds % 60).toString().padStart(2, '0');
      setOtpTimer(`${minutes}:${seconds}`);

      if (timerSeconds === 0) {
        setOtpExpired(true);
        clearOtpTimer();
      } else {
        timerSeconds--;
      }
    }, 1000);
  }

  function clearOtpTimer() {
    if (OTPInterval.current) {
      clearInterval(OTPInterval.current);
      OTPInterval.current = null;
    }
  }

  const resendOTP = async () => {
    try {
      setShowSpinner(true);
      if (userMobileNumber && !email) {
        const res = await AuthService.createOTP(userMobileNumber);
        if (res) {
          startOtpTimer();
        }
      } else {
        const res = await AuthService.createEmailOTP(email);
        if (res) {
          startOtpTimer();
        }
      }
      setShowSpinner(false);
    } catch (error: any) {
      console.log("error resend OTP : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false);
    }
  };

  const navigateEmailAuth = useCallback(
    () => {
      navigate("/auth", { state: { useEmail: true } })
    },
    [])

  return (

    <div className='flex flex-col px-2 xl:px-0 gap-16 xl:justify-between items-start text-white w-full md:w-[70%] xl:w-[40%]'>
      <img src={Logo} alt="logo" className='w-20 h-10'/>
      <div className='flex flex-col gap-2'>
        <span className='text-4xl font-bold'>{email ? 'Confirm email address' : 'Verify phone number'}</span>
        <span className='text-sm text-text-gray'>Code has been sent to {email || userMobileNumber}</span>    
      </div>
      <div>
        <input
            type="tel"
          inputMode="numeric"
          maxLength={4}
            className={`w-[55%] bg-[#222222] text-white text-3xl tracking-[1rem] pl-6 mt-4 text-center border-none rounded-xl`}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ''); 
              if (value.length <= 4) { 
                setOtp(value);
              } 
              if (value.length === 4) {
                e.target.blur(); 
              }
            }}
          value={otp}
          />

      {OTPError && <div className='text-primary-red text-sm w-full text-left mt-1'>{OTPError}</div>}

      <div className='w-full flex flex-col justify-between  mt-4 mb-4 '>
          <span>Didn't get the OTP code?</span>
          <div>
            <span className={`${otpExpired ? 'text-primary-orange cursor-pointer ' : 'text-[#5D3200] cursor-not-allowed'} font-bold text-[0.87rem]`} onClick={() => resendOTP()}>Resend code</span>
            {!otpExpired && <> <span>in</span> <span className='text-primary-orange'>{otpTimer}</span></>}
          </div>
        </div>
      </div>
        
        <div className='flex flex-col gap-3'>
                <div className='text-sm text-text-gray'>
                By submitting the four-digit code, you consent to receive text messages from us and our hosts. Message and data rates may apply.
                </div>
                <PrimaryButton name='Continue' onClick={onVerifyClick} classNames='p-3 z-20' />
            </div>
    </div>
  )
}
