import React, { useRef, useState } from 'react';
import styles from './SignUp.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '../../../services/authService';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../../utils/localStorage';
import { LOCAL_STORAGE } from '../../../utils/constants';
import { useSpinner } from '../../../components/spinner/SpinnerContext';
import { toast } from 'react-toastify';
import Profile from '../../../assets/Icons/profile/Proifle.svg'
import Logo from '../../../assets/Images/login/logo.png'
import PrimaryButton from '../../../components/primaryButton';
import { UserService } from '../../../services/userServices';
import Camera from '../../../assets/Icons/camera/camera.svg';
import EMAIL_EXIST from '../../../assets/Images/login/emailExist.png';
import ModalButton from '../../../assets/Images/login/modalButton.svg';
import ImageCropper from '../../../components/ImageCropper/ImageCropper';

const userInfoFields = [
  { key: 'firstName', placeholder: 'Enter your first name *' },
  { key: 'lastName', placeholder: 'Enter your last name *' },
  { key: 'email', placeholder: 'Enter email address *', type: 'email' },
];

export default function SignUp() {

  let location = useLocation();
  const { mobileNumber, email } = location?.state ?? {};
  const [userInfo, setUserInfo] = useState(Object.fromEntries(userInfoFields.map(field => [field.key, ''])));
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState(Object.fromEntries(userInfoFields.map(field => [field.key, ''])));
  const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const { setShowSpinner } = useSpinner();
  const linkToDetailPage: any = getLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [emailExistPopup, setEmailExistPopup] = useState(false);
  const emailInputRef = useRef<any>();

  const goToDetailPage = linkToDetailPage ? true : false;
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;



  const handleChange = (key: string, value: string) => {
    setUserInfo(prevState => ({ ...prevState, [key]: value }));

    if (errors[key])
      setErrors(prevState => ({ ...prevState, [key]: '' }));
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsCropperOpen(true);
    if (file) {
      const url = URL.createObjectURL(file);
      setImageURL(url);
    }
  };

  const handleUpload = async (userId: string, croppedImage: string) => {
    try {
      setShowSpinner(true);
      const croppedImageBlob = await fetch(croppedImage).then(res => res.blob());
      console.log(croppedImageBlob, 'croppedImageBlob');
      const formData = new FormData();
      formData.append('photo1', croppedImageBlob);
      formData.append('ID', userId);

      const response = await UserService.uploadProfilePic(formData);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result?.data) {

        console.log(result.data.message);

      } else {
        throw new Error('No data in response');
      }
    } catch (error) {
      console.error("Error in profile upload:", error);
      toast.error("Unable to upload profile picture.");

    } finally {
      setShowSpinner(false);
    }
  };

  const onProceedClick = async () => {
    try {

      if (!userInfo.firstName)
        setErrors(prevState => ({ ...prevState, firstName: 'First name is required.' }));
      if (!userInfo.lastName)
        setErrors(prevState => ({ ...prevState, lastName: 'Last name is required.' }));
      if (!userInfo.email && !email)
        setErrors(prevState => ({ ...prevState, email: 'Email address is required.' }));
      if (!email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userInfo.email))
        setErrors(prevState => ({ ...prevState, email: 'Please enter a valid email address.' }));
      // add profile validation (profile photo should be manadatory)
      console.log("selectedFile", selectedFile)

      if (selectedFile == null)
        setErrors(prevState => ({ ...prevState, profile: 'Profile photo is required.' }));

      if (!userInfo.firstName || !userInfo.lastName || (!userInfo.email && !email) || selectedFile == null)
        return;
      setShowSpinner(true);
      const uuid = uuidv4();
      const payload = {
        UUID: uuid,
        email: email ? email : userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phoneNumber: mobileNumber
      }
      const res = await AuthService.profileSetup(payload)
      if (res?.data && res?.status) {
        setLocalStorage(LOCAL_STORAGE.USER_ID, res?.data?.userID);
        setLocalStorage(LOCAL_STORAGE.USER_TOKEN, res?.data?.token);
        await handleUpload(res?.data?.userID, imageURL)
        if (goToDetailPage) {
          removeLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE)
          window.location.replace(linkToDetailPage)
          setShowSpinner(false);
        } else {
          navigate('/auth/registration', { state: { email: email ? email : userInfo.email, firstName: userInfo.firstName, lastName: userInfo.lastName }, replace: true });
          setShowSpinner(false);
        }
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      const errorPhoneNumber = error?.response?.data?.result?.phoneNumber;
      const errorEmail = error?.response?.data?.result?.email;
      if (errorPhoneNumber)
        setErrorPhoneNumber(errorPhoneNumber);
      if (errorEmail)
        setErrorEmail(errorEmail);
      if (errorMessage?.includes('Email already exists')) {
        setEmailExistPopup(true);
      } else {
        toast.error(errorMessage);
        navigate('/auth', { replace: true });
      }
      setShowSpinner(false)
    }
  }

  const onEmailExistOptionClick = async (updateMobile: boolean = false) => {
    try {
      setShowSpinner(true);
      await AuthService.createEmailOTP(errorEmail);
      if (updateMobile)
        navigate("/auth/otp", { state: { email: errorEmail, mobileNumber: errorPhoneNumber, updatedNumber: mobileNumber }, replace: true });
      else
        navigate("/auth/otp", { state: { email: errorEmail, mobileNumber: errorPhoneNumber }, replace: true });
      setShowSpinner(false);
    } catch (error) {
      console.log("error while close popup ", error);
    }
  }


  return (
    <>
      <div className='flex flex-col justify-center gap-4 px-4 md:gap-0 md:px-0 w-full md:w-auto'>
        <img src={Logo} alt="logo" className='w-20 h-10 my-4' />
        <div className='flex h-full flex-col justify-center items-center gap-4'>
          <div>
            <input type='file' id='fileInput' onChange={handleFileChange} accept='.jpg,.png,.jpeg' hidden />
            <label htmlFor='fileInput' className='cursor-pointer relative'>
              <img src={imageURL ? imageURL : Profile} alt="profile" className='w-32 h-32 rounded-full' />
              <div className='absolute bottom-0 right-0 w-9 h-9 bg-primary-orange rounded-full flex justify-center items-center'>
                <img src={Camera} alt="camera" />
              </div>
            </label>
            {isCropperOpen && (
              <div className='fixed inset-0 flex justify-center items-center z-50'>
                <div className='bg-white p-4 rounded shadow-lg'>
                  <ImageCropper
                    imageFile={selectedFile}
                    onClose={() => setIsCropperOpen(false)}
                    onCropComplete={(croppedImage: string) => {
                      console.log(croppedImage, 'croppedImage');
                      setImageURL(croppedImage);
                      setIsCropperOpen(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='justify-center items-center'>
            {errors['profile'] && <div className='text-primary-red text-sm w-full text-left mt-1'>{errors['profile']}</div>}
          </div>
          <div className='flex  flex-col md:flex-row justify-center items-center gap-4 w-full'>
            <input type='text' placeholder='First Name*' className='w-full rounded-xl placeholder:font-light border border-[#424242] text-primary-white bg-[#222222]' name='firstName' value={userInfo['firstName']} onChange={(e) => handleChange('firstName', e.target.value)} required />
            {errors['firstName'] && <div className='text-primary-red text-sm w-full text-left mt-1'>{errors['firstName']}</div>}
            <input type='text' placeholder='Last Name*' className='w-full rounded-xl placeholder:font-light border border-[#424242] text-primary-white bg-[#222222]' name='lastName' value={userInfo['lastName']} onChange={(e) => handleChange('lastName', e.target.value)} required />
            {errors['lastName'] && <div className='text-primary-red text-sm w-full text-left mt-1'>{errors['lastName']}</div>}
          </div>
          <input ref={emailInputRef} type='email' placeholder='Email Address*' className='w-full rounded-xl placeholder:font-light border border-[#424242] text-primary-white bg-[#222222]' name='email' value={userInfo['email']} onChange={(e) => handleChange('email', e.target.value)} required />
          {errors['email'] && <div className='text-primary-red text-sm w-full text-left mt-1'>{errors['email']}</div>}
        </div>
        <PrimaryButton type='button' name='Continue' classNames='p-3 z-20' onClick={onProceedClick} />
      </div>

      {emailExistPopup && <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50`}>
        <div className="bg-[#181818] rounded-lg w-[90%] max-w-xl p-6 relative ">
          {/* Modal Body */}
          <div className="py-4 flex flex-col justify-center items-center gap-4 text-white">
            <img src={EMAIL_EXIST} alt="otp-failed" className='w-44 h-40' />
            <div className='flex flex-col text-center'>
              <span className='text-xl font-semibold text-text-gray'>This Email Already Exists</span>
              <span className='text-xl font-semibold'>Please select one of the option below</span>
            </div>
            <span className='text-sm text-text-gray text-center  md:w-[60%]'>{`${errorEmail} is already linked with the phone number ${errorPhoneNumber}`}</span>

            <div className='w-full px-5 py-4 flex justify-between items-center border border-[#424242] rounded-xl p-2 cursor-pointer'
              onClick={() => {
                onEmailExistOptionClick()
                setEmailExistPopup(false)
              }}>
              <span className='text-sm '>Log in to existing account with email OTP and keep your {errorPhoneNumber} phone number?</span>
              <img src={ModalButton} alt="modal-button" className='w-4 h-4' />
            </div>
            <div
              className='w-full px-5 py-4 flex justify-between items-center border border-[#424242] rounded-xl p-2 cursor-pointer'
              onClick={() => {
                onEmailExistOptionClick(true)
                setEmailExistPopup(false)
              }}>
              <span className='text-sm '>Log in to your existing account with email OTP and update phone number to {mobileNumber}</span>
              <img src={ModalButton} alt="modal-button" className='w-4 h-4' />
            </div>
            <div
              className='w-full px-5 py-4 flex justify-between items-center border border-[#424242] rounded-xl p-2 cursor-pointer'
              onClick={() => {
                handleChange('email', '');
                emailInputRef.current.focus()
                setEmailExistPopup(false);
              }}
            >
              <span className='text-sm'>Create new account using a different email address?</span>
              <img src={ModalButton} alt="modal-button" className='w-4 h-4' />
            </div>
          </div>
        </div>
      </div>
      }

    </>

  );
}
