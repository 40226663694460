import Axios from '../utils/axiosInstance'

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY


const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    })
}

const fetchTimezone = async (lat: number, lng: number, timestamp: number): Promise<string | null> => {
    const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${GOOGLE_API_KEY}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.timeZoneId;
    } catch (error) {
      console.error('Timezone API failed due to:', error);
      return null;
    }
  };


  const geocodeByPlaceId = (placeId: string) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId: placeId }, (results:any, status) => {
        if (status === 'OK' && results[0]) {
          resolve(results[0]);
        } else {
          reject(status);
        }
      });
    });
  };

export const LocationService = {
    getCurrentLocation,
    fetchTimezone,
    geocodeByPlaceId
}