import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalenderIcon from "../../assets/Icons/refine/calender.svg";
import CancelIcon from "../../assets/Icons/refine/cancel.svg";
import './HostDatePicker.css'
import { extractDate, extractMonth } from "../../utils/common";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const monthNamesAbbreviated = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
];

const SELECTED_RANGE = {
  START: "Start",
  END: "End",
};

interface Props {
  onDateSelect: (start: Value | any) => void;
  dateProp?: any;
  disabled?: boolean;  // New prop to control disabled state
  timezone?: string;
}

export default function HostDatePicker({ onDateSelect = () => { }, dateProp, disabled = false, timezone }: Props) {
  const currentDate = timezone ? new Date(timezone) : new Date();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [date, setDate] = useState<Value | any>(dateProp || "");
  const divRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dateProp)
      setDate(dateProp);
  }, [dateProp]);

  const month: any = extractMonth(date.toString());
  const day = extractDate(date.toString());

  const onChange = (newValue: any) => {
    if (selectedDate === SELECTED_RANGE.START) {
      setDate(new Date(newValue));
      setSelectedDate(SELECTED_RANGE.END);
      onDateSelect(newValue);
      setShowCalendar(false);
      return;
    }
  };

  return (
    <div>
      <div className={`bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}>
        <img
          src={CalenderIcon}
          className={`p-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={() => {
            if (!disabled) {
              setSelectedDate(SELECTED_RANGE.START);
              setShowCalendar(!showCalendar);
            }
          }}
        />
        <div className="flex justify-between items-center px-3 w-full min-w-[10rem]">
          <div
            className={`flex gap-2 items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => {
              if (!disabled) {
                setSelectedDate(SELECTED_RANGE.START);
                setShowCalendar(!showCalendar);
              }
            }}
          >
            <div
              className={`cursor-pointer ${selectedDate === SELECTED_RANGE.START ? "bg-input-box-gray" : ""}`}
            >
              {date ? (
                <>
                  <span className="text-text-gray ">
                    {month + " "}
                    {day}
                  </span>
                </>
              ) : (
                <span className="text-text-gray">Date Month</span>
              )}
            </div>
          </div>
          <img
            src={CancelIcon}
            className={`cursor-pointer ${disabled ? 'cursor-not-allowed' : ''}`}
            onClick={() => {
              if (!disabled) {
                setDate("");
                onDateSelect('');
                setShowCalendar(false);
              }
            }}
          />
        </div>
      </div>
      {showCalendar && !disabled && (
        <div className="absolute z-10 mt-2" ref={divRef}>
          <Calendar
            onChange={onChange}
            value={date}
            className="font-[DM-sans] custom-calendar"
            minDate={currentDate}
          />
        </div>
      )}
    </div>
  );
}
