import React from "react";
import styles from "./LandingPageCards.module.scss";
import FirstCardMobile from "../../../../assets/Images/landingpage/content-bg-1.png";
import SecondCardMobile from "../../../../assets/Images/landingpage/section-3.png";
import ThirdCardMobile from "../../../../assets/Images/landingpage/content-bg-4.png";
import FourthCardMobile from "../../../../assets/Images/landingpage/content-bg-5.png";
import Heart from "../../../../assets/Images/landingpage/heart.png";
import { motion } from 'framer-motion'

export default function LandingPageCards() {
  return (
    <div className=" p-4 md:p-auto md:container mx-auto contents-card">
      <div className="p-4 md:p-auto md:container mx-auto">
        <section
          className={`${styles.sectionA} overflow-hidden flex justify-between items-center rounded-3xl h-[80vh]  p-4 md:p-auto md:container mx-auto relative`}
        >
          <div className={`${styles.cardContainer} w-full h-full z-10 flex flex-col md:flex-row justify-center items-center`}>
            <div className={`${styles.image} flex items-end md:w-[50%] `}>
              <img src={FirstCardMobile} alt="image" className="w-[90%]" />
            </div>
            <div className={`${styles.content} flex flex-col justify-center h-full md:w-[50%]`}>
              <h1 className="text-2xl md:text-2xl lg:text-5xl xl:text-[2.8rem] font-medium text-primary-white">
                Empower <br />
                your guests <br />
                to seamlessly <br />
                connect
              </h1>
              <p className={`${styles.cardText} md:text-base lg:text-lg text-primary-white font-normal mt-6 lg:w-[95%]`}>
                Guests can connect with each other before, during, and up to 24
                hours after an event!
              </p>
            </div>
          </div>
        </section>

        <section className={`${styles.sectionB} overflow-hidden mt-24 flex justify-between items-center rounded-3xl h-[80vh]  p-4 md:p-auto md:container mx-auto relative`}>
          <div className={`${styles.cardContainer} w-full h-full flex flex-col md:flex-row justify-center  items-center  z-10`}>
            <div className={`${styles.content} flex flex-col justify-center h-full md:w-[55%]`}>
              <motion.h1
                 initial={{ opacity: 0, x: -100 }}
                 whileInView={{ opacity: 1, x: 0}} 
                 transition={{ duration: 2.5, type: "spring"}}
                className="title text-2xl md:text-2xl lg:text-5xl xl:text-[2.8rem] font-medium text-primary-white relative"
              >
                Keep Your Guest In
                <img
                  src={Heart}
                  alt="image"
                  className="absolute top-[-37%] right-[17%] w-32"
                />
                <br />
                The Loop With SMS <br />
                Text Blasts <br />
              </motion.h1>
              <p className={`${styles.cardText} md:text-base lg:text-lg text-primary-white font-normal mt-6 md:w-[90%]`}>
                Send text blasts to invite past guests to upcoming events and
                give important updates!
              </p>
            </div>
            <div className={`${styles.image} flex items-end md:w-[45%]`}>
              <img src={SecondCardMobile} alt="image" className="h-full" />
            </div>
          </div>
          <div className={styles.orangeBg}></div>
        </section>

        <section className={`${styles.sectionD} overflow-hidden mt-24 flex justify-between items-center rounded-3xl h-[80vh]  p-4 md:p-auto md:container mx-auto relative`}>
          <div className={`${styles.cardContainer} w-full h-full flex flex-col md:flex-row justify-center  items-center  z-10`}>
            <div className={`${styles.content} flex flex-col justify-center h-full md:w-[65%] lg:w-[60%]`}>
              <h1 className="text-2xl md:text-2xl lg:text-5xl xl:text-[2.8rem] font-medium text-primary-white">
                Empower your guests to <br />
                interact through event
                <br />
                group chats
              </h1>
              <p className={`${styles.cardText} md:text-base lg:text-lg text-primary-white font-normal mt-6 md:w-[90%]`}>
                Relay information and allow your guests to converse!
              </p>
            </div>
            <div className={`${styles.image} flex items-end md:w-[35%] lg:w-[50%]`}>
              <img src={ThirdCardMobile} alt="image" />
            </div>
          </div>
          <div className={styles.orangeBg}></div>
        </section>

        <section className={`${styles.sectionE} overflow-hidden mt-24 flex justify-end items-center rounded-3xl h-[80vh] p-4 md:p-auto md:container mx-auto relative`}>
          <div className={`${styles.cardContainer} w-full h-full z-10 flex flex-col md:flex-row-reverse justify-center items-center`}>
            <div className={`${styles.content} flex flex-col justify-center h-full`}>
              <h1 className="text-2xl md:text-2xl lg:text-5xl xl:text-[2.8rem] font-medium text-primary-white">
                Optimize your events <br />
                with anonymized user <br />
                analytics
              </h1>
              <p className={`${styles.cardText} md:text-base lg:text-lg text-primary-white font-normal mt-6 md:w-[90%]`}>
                Unlock insights: Maximize success!
              </p>
            </div>
            <div className={`${styles.image} md:w-[43%] lg:w-[50%] flex items-end`}>
              <img src={FourthCardMobile} alt="image" />
            </div>
          </div>
          <div className={styles.orangeBg}></div>
        </section>
        
      </div>
    </div>
  );
}
