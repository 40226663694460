import React from 'react'
import RightArrow from '../../assets/Icons/Analytics/blackRightArrow.svg'


interface props{
    children: React.ReactNode
    heading: string
    onClick?: () => void
}

const Cards = ({ children, heading, onClick }: props) => {
  return (
      <div className='bg-input-box-gray rounded-2xl p-4 md:h-48 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-90 duration-700' onClick={onClick}>
          <div className='flex justify-between'>
              <span className='text-text-gray font-medium text-base'>{heading}</span>
              <img src={RightArrow} />
          </div>
          <div className='mt-8'>
              {children}
          </div>
    </div>
  )
}

export default Cards