import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HostOn from "../../assets/Icons/header/host-on.svg";
import HostOff from "../../assets/Icons/header/host-off.svg";
import DropdownIcon from '../../assets/Icons/refine/dropdown.svg'


interface props {
  onPayoutClick?: () => void;

}

export default function HostCenterMenu({onPayoutClick}: props) {
  const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const divRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  const handleOpen = (tab: string) => {
    console.log(tab)
    if (tab == 'host events') {
      navigate("/host-center?tab=Upcoming");
    }else if(tab == 'refund requests') {
      navigate("/refund-requests");
    }else if(tab == 'request payout') {
      // navigate("/request-payout");
      onPayoutClick && onPayoutClick();
    }
    setIsOpen(!isOpen);
  }

  return (
    <div
      ref={divRef}
      className="flex gap-2 cursor-pointer items-center relative"
      onClick={() => setIsOpen(!isOpen)}
    >
      <img src={location.pathname.includes("host-center") ? HostOn : HostOff} />
      <span
        className={
          location.pathname.includes("host-center") ? "text-primary-white" : ""
        }
      >
        Host Center
      </span>
      <img src={DropdownIcon}
           className={`bg-primary-black px-4 py-2.5 rounded-full cursor-pointer transition-transform duration-300 transform ${isOpen ? 'rotate-180' : ''}`}
          />
          {isOpen && <div  className="px-1 py-2 bg-[#353535] rounded-[1.2rem] absolute w-full right-0 top-full mt-2">
            <div className="flex flex-col items-start">
                <span className="hover:bg-black p-2 w-full cursor-pointer rounded-xl"   onClick={() => handleOpen('host events')}>Host events</span>
                <span  className="hover:bg-black p-2 w-full cursor-pointer rounded-xl"   onClick={() => handleOpen('refund requests')}>Refund requests</span>
                <span  className="hover:bg-black p-2 w-full cursor-pointer rounded-xl"   onClick={() => handleOpen('request payout')}>Request payout</span>
            </div>
      </div>}
    </div>
  );
}
