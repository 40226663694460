import React, { useCallback, useEffect, useState } from "react";
import CalenderIcon from "../../assets/Icons/refine/gray-calender.svg";
import { toast } from "react-toastify";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import { RefundService } from "../../services/refundService";
import {
  convertUtcToLocal,
  getDayOfWeek,
  getMonthAndDay,
} from "../../utils/common";
import UserProfile from "../../assets/Icons/header/user.png";
import RightSideDrawer from "../../components/rightDrawer";
import moment from "moment-timezone";
import Modal from "../../components/modal";

export default function RefundRequest() {
  const { setShowSpinner } = useSpinner();
  const [allEvents, setAllEvents] = useState([]);
  const [refundRequests, setRefundRequests] = useState([]);
  const [error, setError] = useState("");
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [selectedRequest, setSelectedRequest] = useState<any>();
  const [reasonForReject, setReasonForReject] = useState('');
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showSideNavRefReq, setShowSideNavRefReq] = useState(false);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (selectedEvent) fetchRequests();
  }, [selectedEvent]);

  const fetchEvents = async () => {
    try {
      setShowSpinner(true);
      const res = await RefundService.fetchRefundEvents();
      if (res?.data) {
        if (res?.data?.events?.length > 0) {
          setAllEvents(res?.data?.events);
          const event = res?.data?.events?.find((event: any) => event?.eventId == selectedEvent?.eventId);
          if (event) {
            setSelectedEvent(event);
          }else
          setSelectedEvent(res?.data?.events?.[0]);
        } else setError("No requests for refunds have been received.");
      }
      setShowSpinner(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      console.error("Error in fetchEvents: ", errorMessage);
      toast.error(errorMessage);
      setShowSpinner(false);
    }
  };

  const fetchRequests = async () => {
    try {
      setShowSpinner(true);
      const res = await RefundService.fetchRefundRequests(
        selectedEvent?.eventId
      );
      if (res?.data) {
        setRefundRequests(res?.data);
      } else toast.error("unable to fetch refund request for selected event.");
      setShowSpinner(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      console.error("Error in fetchEvents: ", errorMessage);
      toast.error(errorMessage);
      setShowSpinner(false);
    }
  };

  const onApproveClick = (request: any) => {
    setReasonForReject('');
    setSelectedRequest(request);
    setShowAcceptModal(true);
  };

  const onRejectClick = (request: any) => {
    setReasonForReject('');
    setSelectedRequest(request);
    setOpenRightDrawer(true);
    console.log("Hello req",request)
  };

  const onDrawerClose = () => {
    setOpenRightDrawer(false);
  }
  const onApproveYesClick = () => {
    setShowAcceptModal(false);
    updateRefundReqStatus('approved');
  };

  const onProcessClick = () => {
    updateRefundReqStatus('declined');
    setOpenRightDrawer(false);
  }
  const updateRefundReqStatus = async (status: string) => {
    try {
      const data = {
        "purchasedTicketId": selectedRequest?.purchasedTicketId,
        "eventId": selectedRequest?.eventId,
        "purchasedUserId":selectedRequest?.purchasedUserId,
        "hostUserId": selectedRequest?.hostUserId,
        "status":status,
        "allTickets": true,
        "reasonForRequest": reasonForReject
      }
      const res = await RefundService.updateRefundReqStatus(data);
      if (res?.data) {
        setReasonForReject('');
        setSelectedRequest(null);
        fetchEvents();
        setShowSideNavRefReq(false);
      }

    } catch (error:any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      console.error("Error in fetchEvents: ", errorMessage);
      toast.error(errorMessage);
      setShowSpinner(false);
    }
  }

  function formatePaidDate(dateString: string) {
    return moment(dateString).format("DD MMM YYYY");
  }

  return (
    <div className=" mx-2 md:mx-8 mt-[8vh] text-white">
      <label className="text-[2.375rem] font-medium leading-10 tracking-[-.03em] ">
        Refund Request
      </label>
      {!error ? (
        <div className="flex flex-col md:flex-row justify-between mt-4 md:mt-8">
          <div className="event-list ">
            {/* event card */}
            {allEvents?.map((event: any, idx) => (
              <div
                className="flex gap-5 my-8 cursor-pointer"
                onClick={() => { setSelectedEvent(event); setShowSideNavRefReq(true); }}
              >
                <img
                  src={
                    event?.bannerImage ||
                    "https://sondr-dev.s3.us-east-2.amazonaws.com/Socha/event_default_banner.png"
                  }
                  className="w-24 rounded-lg"
                />
                <div className="flex flex-col justify-between ">
                  <div>
                    <span className="text-primary-white text-base">
                      {event?.eventName}
                    </span>
                    <p className="text-[#999999] text-xs">{event?.location}</p>
                  </div>
                  <div className="flex gap-1.5 items-center ">
                    <div className="text-[10px] md:text-sm bg-[#2E2E2E] rounded-lg p-1 w-fit flex items-center gap-1">
                      <img src={CalenderIcon} className="w-3.5 h-3.5" />
                      <div>
                        <span className="text-primary-white">
                          <span className="text-text-gray">
                            {getDayOfWeek(
                              convertUtcToLocal(
                                event?.startTime,
                                event?.timezone
                              )
                            )}
                          </span>
                          <span>
                            {" " +
                              getMonthAndDay(
                                convertUtcToLocal(
                                  event?.startTime,
                                  event?.timezone
                                )
                              )}
                          </span>
                        </span>
                        <span className="text-text-gray"> - </span>
                        <span className="text-primary-white">
                          <span className="text-text-gray">
                            {getDayOfWeek(
                              convertUtcToLocal(event?.endTime, event?.timezone)
                            )}
                          </span>
                          <span>
                            {" " +
                              getMonthAndDay(
                                convertUtcToLocal(
                                  event?.endTime,
                                  event?.timezone
                                )
                              )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="text-[10px] md:text-sm bg-[#2E2E2E] rounded-lg p-1 w-fit flex gap-1 items-center">
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-red"></div>
                      <span>{event?.noOfRefundRequests} requests</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="requests hidden lg:block">
            <div className="overflow-x-auto text-sm font-bold">
              <table className="min-w-full text-white">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Request by</th>
                    <th className="px-4 py-2 text-secondary-gray">
                      Refund amount
                    </th>
                    <th className="px-4 py-2 text-secondary-gray">
                      no. of tickets
                    </th>
                    <th className="px-4 py-2 "></th>
                    <th className="px-4 py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  {refundRequests?.map((request: any, idx) => (
                    <tr className="border-b border-[#474747]" key={idx}>
                      <td className="px-4 py-3 flex items-center">
                        <img
                          src={request?.profilePic || UserProfile}
                          alt="Profile"
                          className="w-8 h-8 rounded-full mr-2"
                        />
                        {request.firstName} {request.lastName}
                      </td>
                      <td className="px-4 py-3 text-right">
                        ${request.amount}
                      </td>
                      <td className="px-4 py-3 text-right">
                        x{request.noOfTickets}
                      </td>
                      <td className="px-4 py-3">
                        <button
                          className="bg-[#27D276] text-primary-black px-3.5 py-1 rounded-xl "
                          onClick={() => onApproveClick(request)}
                        >
                          Approve
                        </button>
                      </td>
                      <td className="px-4 py-3">
                        <button
                          className="bg-primary-red bg-opacity-10 px-3.5 py-1 rounded-xl border border-primary-red"
                          onClick={() => onRejectClick(request)}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-primary-orange italic mt-6">* {error}</div>
      )}
      <RightSideDrawer
        open={openRightDrawer}
        onClose={() => onDrawerClose()}
        style={{ zIndex: "100" }}
      >
        <div className=" px-10 flex flex-col gap-4 cards_wrapper overflow-y-auto max-h-[63vh] md:max-h-[78vh] xl:max-h-[66vh] scrollableDiv ">
          <span className="text-2xl font-bold">Refund</span>

          <div className="flex flex-col">
            <span className="text-sm">Rejecting refund request</span>
            <span className="text-[26px] font-bold ">
              ${selectedRequest?.amount}
            </span>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className="px-4 py-3 rounded-2xl bg-[#222222] text-xs font-bold flex flex-col gap-2">
              <span>
                <span className="text-secondary-gray">Paid on </span>
                {formatePaidDate(selectedRequest?.ticketPurchasedDate)}
              </span>
              <div className="flex items-center gap-3">
                <img
                  src={selectedRequest?.profilePic || UserProfile}
                  alt="profile"
                  className="w-6 h-6 rounded-full object-cover"
                />
                <span>
                  {selectedRequest?.firstName} {selectedRequest?.lastName}
                </span>
              </div>
            </div>
            <div className="px-4 py-3 rounded-2xl bg-[#222222] text-xs font-bold flex gap-3 items-center">
              <img
                src={
                  selectedEvent?.bannerImage ||
                  `https://sondr-dev.s3.us-east-2.amazonaws.com/Socha/event_default_banner.png`
                }
                className="w-8 h-8 rounded-lg"
              />
              <div>
                <span className="text-primary-white text-sm font-[500]">
                  {selectedEvent?.eventName}
                </span>
                <p className="text-[#999999] font-[500]">
                  {selectedEvent?.location}
                </p>
              </div>
            </div>
          </div>

          <div>
            <span className="text-sm ">Reason for decision</span>
            <textarea
              className="ml-1 mt-2 bg-[#222222] w-[98%] self-center h-20 rounded-lg border-none text-sm text-primary-white"
              placeholder="Enter message here"
              onChange={(e) => setReasonForReject(e?.target?.value)}
              value={reasonForReject}
            ></textarea>
          </div>

          <div className="flex justify-between items-center">
            <button className="bg-[#FF8A0021] py-2.5 px-3.5 rounded-xl text-sm border border-primary-orange" onClick={() => onDrawerClose()}>
              Cancel
            </button>
            <button className="bg-primary-orange py-2.5 px-3.5 rounded-xl text-sm" onClick={onProcessClick}>
              Proceed
            </button>
          </div>
        </div>
      </RightSideDrawer>
      {showAcceptModal && <Modal onClose={() => { console.log("closed"); setSelectedRequest(null); setShowAcceptModal(false)}} onSave={onApproveYesClick}>
          <div className="max-w-[225px]">
            <p className="font-semibold mb-2 text-center">
                Would you really like to approve this request for a refund?
            </p>
            <p className="mb-2 text-center text-primary-gray text-[12px]">
                This action cannot be undone
            </p>
        </div>
      </Modal>}

      <RightSideDrawer className={"lg:hidden"} open={showSideNavRefReq} onClose={() => setShowSideNavRefReq(false)} style={{ zIndex: "90" }} >
      <div className=" px-1 flex flex-col gap-4 cards_wrapper overflow-y-auto max-h-[63vh] md:max-h-[78vh] xl:max-h-[66vh] scrollableDiv ">
        <span className="text-2xl font-bold">{selectedEvent?.eventName}</span>

        {refundRequests.map((request: any) => (
          <div key={request.id} className="border-b border-[#474747] flex flex-col gap-1 justify-start pb-3">
            <div className="flex items-center gap-3">
              <img src={request.profilePic || '../../../../assets//Images/user.png'} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
              {request.firstName} {request.lastName}
            </div>

            <div className="flex items-center gap-2.5 mt-3">
              <span className="text-secondary-gray font-bold">Refund amount</span>
              <span className="text-right">${request.amount}</span>
            </div>

            <div className="flex items-center gap-2.5">
              <span className="text-secondary-gray font-bold">no. of tickets</span>
              <span className="text-right">x{request.noOfTickets}</span>
            </div>

            <div className="flex items-center gap-2 mt-3">
              <button className="bg-[#27D276] text-primary-black px-3.5 py-1 rounded-xl" onClick={() => onApproveClick(request)}>Approve</button>
              <button className="bg-primary-red bg-opacity-10 px-3.5 py-1 rounded-xl border border-primary-red" onClick={() => onRejectClick(request)}>Reject</button>
            </div>
          </div>
        ))}
      </div>
      </RightSideDrawer>
    </div>
  );
}
