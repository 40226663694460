import React from "react";
import styles from "./Footer.module.scss";
import Logo from "../../assets/Images/login/logo.png";
import AppStore from "../../assets/Icons/landingpage/apple-store.svg";
import Instagram from "../../assets/Icons/landingpage/Instagram.svg";
import Tiktok from "../../assets/Icons/landingpage/tiktok_icon.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="container mx-auto my-10">
      <div className="container mx-auto xs:m-0">
        <div className="flex justify-between xs:flex-col md:flex-row gap-5 border-t border-b border-[#FFFFFF41] py-4">
          <div className="flex xs:flex-row md:flex-col justify-between gap-4 xs:items-center md:items-start">
            <img
              src={Logo}
              alt="logo"
              className="w-[5rem] cursor-pointer"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            />
            <h1 className="font-medium text-[.85rem] text-primary-white leading-4">
              Unleash the power of <br />
              <span className="text-primary-orange">connections</span> with
              Socha
              <br />
            </h1>
          </div>
          <div className="grid md:grid-flow-col xs:grid-cols-2 md:gap-[4.5rem] xs:gap-2">
            <div className="flex flex-col gap-2">
              <Link to="/help/terms-of-service">
                <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange">
                  Terms of Service
                </span>
              </Link>
              <Link to="/help/privacy-policy">
                <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange">
                  Privacy Policy
                </span>
              </Link>
              <Link to="/help/eula" target="_blank">
                <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange ">
                  EULA
                </span>
              </Link>
            </div>

            <div className="flex flex-col gap-2">
              <Link to="/help/safety-notice">
                <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange">
                  Safety Notice
                </span>
              </Link>
              <Link to="/help/host-agreement">
                <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange">
                  Host Agreement
                </span>
              </Link>
              <a href="mailto:support@joinsocha.com">
                <span className="text-primary-white text-xs font-medium mb-4 hover:text-primary-orange">
                  Contact Us
                </span>
              </a>
            </div>

            <div className="flex flex-col xs:mt-3">
              <a href="https://apps.apple.com/vn/app/socha-social/id6444107828">
                <img
                  src={AppStore}
                  className="w-[8.25rem] transform transition duration-500 hover:scale-110"
                  alt="apple-store"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto container">
        <div className="flex flex-col gap-4 md:flex-row md:gap-0 justify-between items-center mt-6">
          <h5 className="text-[.85rem] text-[#808080] font-normal">
            &copy; {currentYear} Socha Inc. All rights reserved.
          </h5>
          <div className="flex gap-4">
            <a href="https://instagram.com/socha.app?igshid=MzRlODBiNWFlZA==">
              <img src={Instagram} className="w-6" alt="instagram" />
            </a>
            <a href="https://www.tiktok.com/@socha.app?lang=en">
              <img src={Tiktok} className="w-5" alt="tiktok" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
