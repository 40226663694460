import React, { useEffect, useState } from "react";
import styles from "./FAQ.module.scss";
import { FAQ_TYPES } from "../../../../utils/constants";
import PlusIcon from "../../../../assets/Icons/landingpage/plus-white.svg";
import CancelIcon from "../../../../assets/Icons/landingpage/plus-black.svg";
import { useNavigate } from "react-router-dom";

const host = [
  {
    question:
      "What are the differences between “Free”, “Ticketed”, and “Free-ticketed” events?",
    answer:
      "<p>Free events: Any guest can enter the event for free.</p><br/><p>Ticketed events: Guests must purchase a ticket to enter the event. Hosts are able to customize different ticket types with unique prices, names, and durations.</p><br/><p>Free-ticketed events: Guests must obtain a free ticket for entry. This helps manage capacity for the venue.</p>",
    isOpen: false,
  },
  {
    question:
      "What is the difference between a “Public” event and a “Private” event?",
    answer:
      "<p>Public Event: Visible to users under the “All Events” tab on the event discovery page. Users do not have to be invited to attend these types of events.</p><br/><p>Private event: Only visible to users under the “I’m Invited” tab on the event discovery page. Users must be invited to attend these types of events.</p>",
    isOpen: false,
  },
  {
    question: "How do I remove a user from my event?",
    answer:
      "<p>To remove a user from your event go to the Guest list and “uninvite” them. If they paid for a ticket, they will be issued a refund.</p>",
    isOpen: false,
  },
  {
    question:
      "If I decide to cancel my event, what happens to my guests that bought their tickets?",
    answer:
      "<p>If a paid ticketed event is canceled the guests will be refunded for the tickets they purchased.</p>",
    isOpen: false,
  },
  {
    question:
      "If I want to change the details of my event, how do I notify my guests?",
    answer:
      "<p>Whenever you change any of your event’s details your guests will receive an in-app notification.</p><p>Additionally, If you want to communicate event changes to your guests you can send a message in the event group chat where all of your guests can see and communicate with each other.</p>",
    isOpen: false,
  },
  {
    question:
      "How does the text-blast feature work for inviting people to events?",
    answer:
      "<p>Hosts can send text and email blasts to guests from their previous or current events. They can also upload CSV files with contact information.</p>",
    isOpen: false,
  },
  {
    question: "What data can I see from my event?",
    answer:
      "<p>Socha events hosts are able to see the following data:</p><br/><ul><li>Guest demographics: Gender, Age,</li><li>Tickets sold and type of tickets sold</li></ul>",
    isOpen: false,
  },
  {
    question: "How are payouts handled on Socha?",
    answer:
      "<p>Once a host has created their Socha account they will be asked to input their banking information if they wish to create a paid ticketed event. Hosts then get paid out through Stripe once the event is completed.</p>",
    isOpen: false,
  },
  {
    question:
      "Can users block or report other users for inappropriate behavior?",
    answer:
      "<p>Yes, if a user behaves inappropriately they should be reported. Once that user has been reported 3 times, they will be subject to a ban from Socha.</p>",
    isOpen: false,
  },
];
const guest = [
  {
    question: "How do I match with someone and start chatting?",
    answer:
      "<p>Send them a like! They'll get a notification, and if they like you back, you'll form a connection and can begin messaging.</p><br/><p>Socha uses a non-anonymous matching system where liking a profile reveals your identity, and if someone likes your profile, their identity is disclosed as well.</p><br/><p>We are currently developing an additional liking option that users can choose in their preferences. This option will use an anonymous matching system, ensuring that when you like someone's profile, your identity remains hidden until a mutual match occurs. Similarly, when someone likes your profile, their identity will also be concealed until a match is made.</p>",
    isOpen: false,
  },
  {
    question:
      "What is the timeframe for matching before, during, and after events?",
    answer:
      "<p>As soon as you RSVP to an event, you can view and like other attendees' profiles. After the event concludes, you have up to 24 hours to continue liking profiles and connecting with fellow event-goers.</p>",
    isOpen: false,
  },
  {
    question: "What if I'm going to an event but am not looking for romance?",
    answer:
      "<p>Socha is not a dating app! While Socha allows users to form romantic connections, it also is used for creating friendships, forming business relationships, browsing upcoming events, and purchasing tickets.</p>",
    isOpen: false,
  },
  {
    question:
      "Can users create their own events or only attend events created by hosts?",
    answer:
      "<p>Of course! Whether you're a professional event planner or a first-year college student, Socha is a fun and easy way for you to invite guests and manage your event. Our hope is that our guests become our hosts!</p>",
    isOpen: false,
  },
  {
    question: "What measures are in place to ensure user privacy and safety?",
    answer:
      "<p>At Socha, we take privacy and safety very seriously. Upon creating a profile, users are prompted to  submit a selfie for verification purposes.</p><br/><p>Users also have the option to block or report profiles. If a user is blocked or reported multiple times, they will be banned from Socha.</p>",
    isOpen: false,
  },
  {
    question: "What can I do if I have any questions about the Socha platform?",
    answer:
      '<p>You can message “Socha Support” by clicking the “Help Center” button that can be found within the “Profile” page on your mobile app. You can also contact us at <span className="email">support@joinsocha.com</span> and we will get back to you as soon as possible.</p>',
    isOpen: false,
  },
];
export default function FAQ() {
  const [selectedSection, setSelectedSection] = useState(FAQ_TYPES.HOST);
  const [questions, setQuestions] = useState(host);
  const navigate = useNavigate();

  useEffect(() => { 
    if (selectedSection == FAQ_TYPES.GENERAL) setQuestions(guest);
    else setQuestions(host);
  }, [selectedSection]);

  const toggleButton = (question:string) => {
    setQuestions(prev => prev?.map(q => {
      if (q.question === question)
        return { ...q, isOpen: !q.isOpen };
      else
        return q;
    }) || []);
  };

  return (
    <>
      <h1 className="text-primary-white text-center font-bold text-3xl mt-14">
        Frequently asked questions
      </h1>

      <div className="flex justify-center items-center my-16">
        <div
          className={`${styles.buttonContainer} rounded-[35px] p-2 bg-[#121314]`}
        >
          <button
            className={` text-primary-white text-sm font-bold py-[.15rem] px-[2.5rem] ${
              selectedSection == FAQ_TYPES.HOST && styles.active
            }`}
            onClick={() => setSelectedSection(FAQ_TYPES.HOST)}
          >
            Host
          </button>
          <button
            className={`' text-primary-white text-sm font-bold py-[.15rem] px-[2.5rem]  ${
              selectedSection == FAQ_TYPES.GENERAL && styles.active
            }`}
            onClick={() => setSelectedSection(FAQ_TYPES.GENERAL)}
          >
            General
          </button>
        </div>
      </div>

      <div className="container mx-auto">
        {questions?.map((item, i) => (
          <div className="container mx-auto mb-5 md:mb-0" key={i}>
            <div className={`${styles.faqCard} md:p-8 md:container rounded-lg`}>
              <div className={`${styles.faqQuestion} gap-2 md:gap-10`}>
                <div className={`${styles.leftSide} self-baseline`}>
                  <span
                    className={`${styles.itemNumber} text-primary-orange font-semibold text-[1.7rem] md:text-[2.5rem]`}
                  >
                    {i < 10 && "0"}
                    {i + 1}
                  </span>
                </div>
                <div className={styles.middle}>
                  <span
                    className={`${styles.question} text-primary-white text-base md:text-xl font-medium leading-[120%]`}
                  >
                    {item.question}
                  </span>
                  {item?.isOpen && (
                    <div
                      className={`${styles.faqAnswer} text-base font-normal leading-[170%] text-primary-gray`}
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    />
                  )}
                </div>
                <div className={`${styles.rightSide} h-full flex self-start`}>
                  <span className={styles.toggleIcon} onClick={() => toggleButton(item.question)}>
                    {!item?.isOpen ? (
                      <div className="plus-white">
                        <img src={PlusIcon} className="w-8 md:w-10" alt="" />
                      </div>
                    ) : (
                      <div className="plus-black">
                        <img src={CancelIcon} className="w-8 md:w-10" alt="" />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="flex justify-center items-center mt-8">
          <button
            className="more-faq text-xs text-primary-black rounded-lg bg-secondary-white py-3 px-7 font-medium"
            onClick={() => navigate("help/faq")}
          >
            More FAQs
          </button>
        </div>
      </div>
    </>
  );
}
