import React, { CSSProperties } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Location from '../../assets/Icons/refine/location.svg';

interface Props {
  styles: CSSProperties;
  lat?: number;
  lng?: number;
}

const lightMapOptions = {
  disableDefaultUI: true,
  googleLogo: false,
  fullscreenControl: false,
  styles: [
    {
      elementType: 'geometry',
      stylers: [{ color: '#212121' }] 
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'off' }] 
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#777777' }] 
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#000000' }] 
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [{ color: '#2c2c2c' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#8a8a8a' }]
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#373737' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#3c3c3c' }]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'geometry',
      stylers: [{ color: '#4e4e4e' }]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#616161' }]
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#757575' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#000000' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#3d3d3d' }]
    }
  ]
};


function GLMap({ styles, lat = 0, lng = 0 }: Props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyB6oYuR6lLDR78aYs3aIJWKJALGrJC01Ow"
  });

  const userLocation = { lat, lng };

  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={styles}
      center={userLocation}
      zoom={17} 
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={lightMapOptions}
    >
      <Marker position={userLocation} icon={Location} />
    </GoogleMap>
  ) : <></>;
}

export default React.memo(GLMap);
