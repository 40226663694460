import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "../../assets/Icons/refine/cancel.svg";

interface DrawerProps {
  children: React.ReactNode;
  onClose?: () => void;
  open: boolean;
    onSave?: () => void;
  title?: string;
  showBottomMenu?: boolean
    
}

const Drawer: React.FC<DrawerProps> = ({
  children,
  open = false,
  onClose = () => {},
    onSave = () => { },
  title = '',
  showBottomMenu = true
}) => {

  const drawerRef = useRef<HTMLDivElement>(null);

  const toggleDrawer = () => {
    onClose();
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={drawerRef}
      
      className={`lg:hidden fixed bottom-0 left-0 w-full rounded-t-lg transition-transform duration-300 transform ${
        open ? "translate-y-0" : "translate-y-full"
      } bg-[#181818]`}
          style={{ zIndex: '100' }}
          
    >
      <div className="flex items-center p-3 border-b border-2 border-[#2A2A2A26]">
        <img
          src={CancelIcon}
          className="cursor-pointer w-3.5 h-3.5 mr-2"
          onClick={toggleDrawer}
        />
              <span className="text-white flex-1 text-center text-[20px]">{title}</span>
      </div>

      <div className="p-4">{children}</div>
      {showBottomMenu && <div className="flex justify-between items-center my-4 mx-3">
        <div className="text-text-gray text-[.875rem] ">Clear</div>
        <div
          className="bg-primary-orange text-primary-black text-[.875rem] font-medium  w-fit py-1 px-3 rounded-[.875rem]"
          onClick={() => onSave()}
        >
          Apply
        </div>
      </div>}
    </div>
  );
};

export default Drawer;
