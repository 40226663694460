import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CreditCard from "../../../../assets/Icons/payout/credit_card.svg";
import PrimaryButton from '../../../../components/primaryButton';
import CustomRadio from '../../../../components/custom-radio';
import { toast } from 'react-toastify';
import { useSpinner } from '../../../../components/spinner/SpinnerContext';

interface props{
    amount?: number,
    onCancel?:() => void,
    onSuccess?:() => void
    
}

const StripePaymentForm = ({ amount, onCancel, onSuccess = () => { } }: props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [saveCard, setSaveCard] = useState(false);
  const { setShowSpinner } = useSpinner();

    
  const handleSubmit = async (event:any) => {
    event.preventDefault();
    setShowSpinner(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
        redirect: 'if_required'
    });

    if (error) {
      console.log("error in stripe confirm payment : ", error.message);
      toast.error(error.message);
    } else if(paymentIntent?.status == 'succeeded') {
      console.log('Payment succeeded!');
      toast.success("Payment processed successfully!");
      onSuccess()
    } else {
      toast.error("Failed to confirm payment.");
    }
    setShowSpinner(false);
  };

  return (
      <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-between mb-10">
              <div className="flex gap-2 items-center">
                <img src={CreditCard} />
                <span className="text-base">Pay with card</span>
              </div>
              <PrimaryButton
                name="Scan card"
                darkMode={true}
                classNames="py-4 px-3"
              />
            </div>
          <PaymentElement />
          {/* <div className="flex justify-between items-center gap-3 mt-10">
              <div className="flex flex-col gap-1">
                <span className="text-xs">Save my info for next time</span>
                <span className="text-xs text-text-gray">
                  Want to buy tickets even quicker? save your card.
                </span>
              </div>
              <CustomRadio
                value={saveCard}
                onChange={() => setSaveCard(!saveCard)}
              />
          </div> */}
          <div className="sticky w-full mt-5 ">
            <div className="flex justify-end">
              <span className="text-end text-2xl leading-7 tracking-[-0.03em] font-bold">
                <span className="text-sm text-text-gray leading-3 tracking-[-0.03em] font-bold">
                  Payment
                </span>{" "}
                <br /> ${amount?.toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between items-center mt-5">
              <PrimaryButton
                name="Cancel"
                darkMode={true}
                onClick={onCancel}
                classNames=' text-[.875rem] font-medium w-fit py-1 px-3 rounded-[.875rem] flex items-center h-10'
              />
      <button type="submit" disabled={!stripe} className='bg-primary-orange text-primary-black text-[.875rem] font-medium w-fit py-1 px-3 rounded-[.875rem] flex items-center h-10'>
        Purchase
      </button>
            </div>
          </div>
    </form>
  );
};

export default StripePaymentForm;
