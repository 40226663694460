import React, { useEffect, useRef, useState } from 'react'
import BgEvent from '../../../assets/Images/createEvent/createEventbackground.jpg'
import BackArrow from '../../../assets/Icons/backArrow/backArrow.svg'
import Arrow from '../../../assets/Icons/rightArrow/arrow.svg'
import { Outlet, createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import PreviewEvent from '../../../components/previewEventCard';
import TempImg from '../../../assets/temp/eventImage.jpg'
import { getLocalStorage } from '../../../utils/localStorage'
import { LOCAL_STORAGE } from '../../../utils/constants'
import { eventDetailService } from '../../../services/eventDetailServices'
import { useSpinner } from '../../../components/spinner/SpinnerContext'
import { CreateEvent } from '../../../services/createEventService'



function CreateEventLayout() {

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const eventId: any = queryParams.get("eventid");
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [eventDetails, setEventDetails] = useState<Event | any>();
  const [banner, setBanner] = useState<String>('')
  const { setShowSpinner } = useSpinner();
  const [ticketDetails, setTicketDetails] = useState([])
  const scrollableRef = useRef<HTMLDivElement>(null);






  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }

    getBannerImage()
    if (eventId || eventDetails) {
      getHostEventDtls()
      getTicketsDetails()
    }
  }, [location])



  const getHostEventDtls = async () => {
    try {
      setShowSpinner(true);
      const res = await eventDetailService.getHostEventDetails({ eventId, userId });
      if (res?.data) {
        setEventDetails(res?.data?.events);

        setShowSpinner(false);
      }
    } catch (error: any) {
      console.log("error getHostEventDtls : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false);
    }
  }

  const getTicketsDetails = async () => {
    try {
      const res = await CreateEvent.getEventTicket({ id: parseInt(eventId) })
      if (res?.data) {
        setTicketDetails(res?.data)
      }
    }
    catch (error) {
      console.log(error)
    }
  }


  const getBannerImage = async () => {
    try {
      const res = await CreateEvent.getBannerImage({ id: parseInt(eventId) })
      setBanner(res?.data?.bannerImage)
    } catch (error) {
      console.log(error)
    }
  }


  const handleNavigation = (path: string) => {
    if (eventId) {
      navigate({
        pathname: path,
        search: createSearchParams({
          eventid: eventId
        }).toString()
      });
    }
  };

  const updateBannerImage = (newBanner: string) => {
    setBanner(newBanner);

  }



  const handleBackButton = () => {
    if (eventId) {
      navigate({
        pathname: '/host-center/event-details',
        search: createSearchParams({
          eventid: eventId,
          hostid: userId
        }).toString()
      })
    }
    else {
      navigate("/host-center?tab=Upcoming")
    }
  }

  return (
    <div className='relative'>
      <div className="px-8 bg-cover bg-top inset-0 h-[25vh] text-primary-white rounded-b-full z-0" style={{ backgroundImage: `url(${banner ? banner : TempImg})`, filter: "blur(130px)" }}>

      </div>
      <div className='container absolute top-0 py-8 z-100 left-1/2 transform -translate-x-1/2 '>
        <div className='grid grid-cols-10 my-2 '>
          <div className='col-start-2 col-span-8'>
            <div className='flex gap-4 items-center font-bold text-base mb-6'>
              <img src={BackArrow} className='w-8 cursor-pointer' onClick={handleBackButton} />
              <span className="text-text-gray">Host Center</span>
              <img src={Arrow} />
              <span className={location.pathname.includes("create-event")
                ? "text-primary-white"
                : "text-text-gray"}>Create event</span>
            </div>
            {eventDetails?.status !== 'Completed' && <div className='flex items-center justify-between font-medium text-sm xl:text-2xl overflow-x-auto xl:overflow-x-hidden gap-2'>
              <span className={`${location.pathname.includes("basic-details") ? "text-primary-white" : "text-text-gray"} whitespace-nowrap flex-shrink-0 cursor-pointer`} onClick={() => handleNavigation('basic-details')}>Event details</span>
              <img src={Arrow} className="flex-shrink-0" />
              <span className={`${location.pathname.includes("event-info") ? "text-primary-white" : "text-text-gray"} whitespace-nowrap flex-shrink-0 cursor-pointer`} onClick={() => handleNavigation('event-info')}>Event Banner</span>
              <img src={Arrow} className="flex-shrink-0" />
              <span className={`${location.pathname.includes("tickets&promo") ? "text-primary-white" : "text-text-gray"} whitespace-nowrap flex-shrink-0 cursor-pointer`} onClick={() => handleNavigation('tickets&promo')}>Tickets and promo</span>
              <img src={Arrow} className="flex-shrink-0" />
              <span className={`${location.pathname.includes("general-settings") ? "text-primary-white" : "text-text-gray"} whitespace-nowrap flex-shrink-0 cursor-pointer`} onClick={() => handleNavigation('general-settings')}>General settings</span>

              {eventDetails?.event_type === "Ticketed Event" && ticketDetails?.length >= 0 && ticketDetails?.some((ticket: any) => ticket.is_free === false) && (
                <div className='flex gap-4'>
                  <img src={Arrow} className="flex-shrink-0" alt="Arrow Icon" />
                  <span
                    className={`${location.pathname.includes("payouts")
                      ? "text-primary-white"
                      : "text-text-gray"
                      } whitespace-nowrap flex-shrink-0 cursor-pointer`}
                    onClick={() => handleNavigation('payouts')}
                  >
                    Payouts
                  </span>
                </div>
              )}

            </div>}
          </div>
        </div>
        <div className='border-t border-text-gray'></div>
        <div className='grid grid-cols-10 justify-items-start  my-8 w-full h-[calc(100vh-35vh)] overflow-y-auto scrollableDiv' ref={scrollableRef}>
          <div className='col-start-2 col-span-2 hidden lg:block'>
            <span className='text-text-gray text-2xl'>Preview</span>
            <p className='text-text-gray text-sm'>This preview closely resembles a mobile device</p>
            <div className='my-4'>
              <PreviewEvent eventDetails={eventDetails} banner={banner} />
            </div>
          </div>
          <div className='col-start-1 col-span-full lg:col-start-5 lg:col-span-4 w-full mt-4'>
            <Outlet context={{ updateBannerImage }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEventLayout