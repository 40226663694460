import React from 'react'
import BannerSection from './components/banner-section'
import LandingPageCards from './components/landing-page-cards'
import FAQ from './components/faq'
import Footer from '../../components/footer'

export default function LandingPage() {
  return (
      <div>
      <BannerSection />
      <LandingPageCards />
      <FAQ />
      <Footer/>
    </div>
  )
}
