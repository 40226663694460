import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '';

if (!stripePublicKey) {
  throw new Error("REACT_APP_STRIPE_PUBLIC_KEY environment variable is not defined");
}

const stripePromise = loadStripe(stripePublicKey);

const appearance: Appearance = {
  theme: 'night',
  labels:'floating',
  variables: {
    colorText: '#ffffff',
    colorDanger: '#ff1a1a',
    colorTextSecondary: '#aaaaaa',
    colorTextPlaceholder: '#aaaaaa',
    borderRadius: '8px',
    spacingUnit: '6px',
  },
  rules: {
    '.Input': {
      backgroundColor: '#333333',
      border: 'none',
      borderRadius: '12px',
      padding: '12px',
    
    },
    '.Input:focus': {
      border: '1px solid #0B86DF',
    },
    '.Label': {
      color: '#777777',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16.8px',
      letterSpacing: '-0.03em',
      textAlign: 'left',
    },
    '.Field': {
      marginBottom: '10px',
    },
  },
};

const StripeProvider = ({ clientSecret, children }: { clientSecret: string, children: React.ReactNode }) => {
  const options: StripeElementsOptions = {
    clientSecret,
    locale: 'en',
    appearance: appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
