import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import AscendingIcon from '../../assets/Icons/refine/ascending.svg'
import DescendingIcon from '../../assets/Icons/refine/descending.svg'
import DropdownIcon from '../../assets/Icons/refine/dropdown.svg'
import { SORT_BY } from "../../utils/constants";

type props = {
  onChange?: (filter:string) => void;
};

export default function SortBy({ onChange = () => { } }: props) {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');

  const divRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onFilterChange = (filter:string) => {
    if (selectedFilter != filter) {
      setSelectedFilter(filter);
      onChange(filter);
    }
    else {
      setSelectedFilter('');
      onChange('');
    }
    setIsOpen(false);
    
  }

  return (
    <div  ref={divRef}>
      <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-3 items-center px-2 cursor-pointer"  onClick={() => setIsOpen(!isOpen)}>
        <span className="text-text-gray">Sort by</span>
        <div className="flex bg-primary-black gap-1.5 p-2 rounded-md">
          {selectedFilter ? <>
            <span>{selectedFilter.includes('date') ? 'Date': 'Price'}</span>
            <img
              src={selectedFilter.includes('high to low') || selectedFilter.includes('Newest') ? DescendingIcon : AscendingIcon}
            />
            </> : <span className="text-text-gray">Select...</span>
          }
        </div>
        <img src={DropdownIcon}
           className={`bg-primary-black px-2 py-2.5 rounded-full cursor-pointer`}
          />
      </div>
      {isOpen && <div className="p-5 bg-[#353535] rounded-[1.2rem] absolute w-[15rem] right-0 mt-2">
        <h3 className="text-primary-white text-[1.25rem]">Sort</h3>
        {Object.values(SORT_BY).map((item, idx) =>
           <div className="flex justify-between py-4 gap-5 items-center cursor-pointer" key={idx} onClick={() => onFilterChange(item)}>
           <div className="flex gap-2 items-center top-3">
               <span>{item}</span>
              <img src={item.includes('high to low') || item.includes('Newest') ? DescendingIcon : AscendingIcon}/>
           </div>
             <div className="bg-primary-black w-6 h-6 rounded-full flex items-center justify-center">
               {selectedFilter == item && <div className="bg-primary-orange w-3 h-3 p-1.5 rounded-full"></div>}
           </div>
         </div>
        )}
      </div>}
    </div>
  );
}
