import React, { useState } from 'react';
import styles from './Header.module.scss'
import Logo from '../../assets/Images/login/logo.png'
import { useNavigate } from 'react-router-dom';
import { getLocalStorage } from '../../utils/localStorage';
import { LOCAL_STORAGE } from '../../utils/constants';

export default function Header() {

  const navigate = useNavigate();
  const isLoggedIn = getLocalStorage(LOCAL_STORAGE.USER_TOKEN);

  return (
    <div className={`${styles.landingHeader} px-4 pt-4 mx-auto md:pt-[.85rem] md:px-auto flex justify-between items-center lg:mx-16`}>
    <div className={`${styles.logo} w-[5rem] cursor-pointer ${styles.z9}`}>
              <img src={Logo}  className={`w-full ${styles.logo}`} alt="logo" />
    </div>
    <div className="buttons flex justify-center items-center lg:mr-[2rem]">
        <button
          className="text-primary-white bg-tertiary-gray z-9 font-medium text-xs md:text-sm xs:p-3 sm:p-4 py-[.3rem] px-[1rem] md:py-[.45rem] md:px-[2.5rem] mr-[1rem] rounded-[10px]"
        onClick={() => !isLoggedIn ? navigate("/auth") : navigate("/discover")}>
                  {!isLoggedIn ? "Log In" : "Go to Event List"}
      </button>
      <button className="text-primary-white bg-primary-orange z-9 font-medium text-xs md:text-sm xs:p-3 sm:p-4 py-[.3rem] px-[.5rem] md:py-[.45rem] md:px-[1rem] rounded-[10px]" onClick={() => navigate('/create-event/basic-details')}>
        Create Event +
      </button>
    </div>
  </div>
  )
}
