import React, { useEffect, useState } from "react";
import EventCard from "../../components/eventCard";
import SearchIcon from "../../assets/Icons/refine/search.svg";
import CreateIcon from "../../assets/Icons/refine/create.svg";
import CalenderIcon from "../../assets/Icons/refine/calender.svg";
import { DATE_FILTER, HOST_CENTER_TYPES, LOCAL_STORAGE } from "../../utils/constants";
import DatePicker from "../../components/datePicker";

import Drawer from "../../components/bottomDrawer";
import PrimaryButton from "../../components/primaryButton";
import { EventService } from "../../services/eventService";
import { getLocalStorage } from "../../utils/localStorage";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Event } from "../../utils/Interfaces";
import moment from "moment-timezone";
import SearchInput from "../../components/searchInput";



export default function HostCenter() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab: any = queryParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(tab);
  const [showSearchInMobile, setShowSearchInMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState("");
  const [filterStartDate, setFilterStartDate] = useState<any>('');
  const [filterEndDate, setFilterEndDate] = useState<any>('');
  const [searchFilter, setSearchFilter] = useState('');
  const navigate = useNavigate();

  const [allEvents, setAllEvents] = useState([])
  const userId = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const { setShowSpinner } = useSpinner();


  const onDateFilterApply = () => {
    FetchEvents(filterStartDate, filterEndDate);
    setIsDrawerOpen(false);
  }

  useEffect(() => {
    setSelectedTab(tab)
    FetchEvents(filterStartDate, filterEndDate);
  }, [tab, searchFilter])

  const FetchEvents = async (startDate?: string, endDate?: string) => {
    try {
      let res;
      setShowSpinner(true);
      const params = {
        userId: userId,
        pageNo: 1,
        pageSize: 16,
        eventMode: 'OWN',
        search: searchFilter ? searchFilter : undefined,
        startDate: startDate ? moment(startDate, "ddd MMM DD YYYY").format("YYYY-MM-DD") : undefined,
        endDate: endDate ? moment(endDate, "ddd MMM DD YYYY").format("YYYY-MM-DD") : undefined,
      };

      if (tab == HOST_CENTER_TYPES.UPCOMING) {
        res = await EventService.upcomingEvents(params);
      } else if (tab == HOST_CENTER_TYPES.PAST) {
        res = await EventService.pastEvents(params);
      } else if (tab == HOST_CENTER_TYPES.DRAFT) {
        res = await EventService.draftEvents(params);
      }
      if (res?.data) setAllEvents(res?.data?.events);
      setShowSpinner(false)
    } catch (error: any) {
      console.log("error fetch events : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false)
    }
  };


  const onEventClick = (eventId: number, hostId: number) => {

    navigate({
      pathname: 'event-details',
      search: createSearchParams({
        eventid: eventId.toString(),
        hostid: hostId.toString()
      }).toString()
    });
  }

  const onDateFilterClick = (selectedDateFilter: string) => {
    if (selectedDateFilter) {
      if (selectedDateFilter == DATE_FILTER.TODAY) {
        setFilterStartDate(
          moment()
            .startOf("day")
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
        setFilterEndDate(
          moment()
            .startOf("day")
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
      } else if (selectedDateFilter == DATE_FILTER.TOMORROW) {
        setFilterStartDate(
          moment().add(1, "day").format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
        setFilterEndDate(
          moment().add(1, "day").format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
      } else {
        setFilterStartDate(
          moment()
            .clone()
            .day(6)
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
        setFilterEndDate(
          moment()
            .clone()
            .day(7)
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
      }
      setSelectedDateFilter(selectedDateFilter);
    }
  };

  const handleClick = (item: string) => {
    queryParams.set('tab', item);
    navigate({
      pathname: location.pathname,
      search: queryParams.toString()
    });
  };
  return (
    <div className="mx-8 text-primary-white ">
      <h1 className="text-primary-white text-[2.35rem] font-medium flex justify-between items-center">
        <span> Host Center</span>
        <img
          src={SearchIcon}
          alt={""}
          width={25}
          height={25}
          className="cursor-pointer lg:hidden"
          onClick={() => setShowSearchInMobile(!showSearchInMobile)}
        />
      </h1>

      <div className="flex flex-col justify-between lg:flex-row lg:justify-between lg:items-center my-3.5">
        <div className="flex justify-between">
          <div className="flex  text-text-gray bg-input-box-gray gap-1 lg:gap-2 items-center p-1 rounded-xl">
            {Object.values(HOST_CENTER_TYPES).map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer rounded-xl p-2 md:px-4 text-[.9rem] md:text-[1rem] ${selectedTab == item
                  ? "text-primary-white bg-primary-black"
                  : ""
                  }`}
                onClick={() => handleClick(item)}
              >
                {item}
              </div>
            ))}
          </div>

          <div className="flex justify-between gap-3 lg:hidden">
            {/* Date filter */}
            <div className="rounded-xl flex justify-center items-center bg-input-box-gray " onClick={() => setIsDrawerOpen(true)}>
              <div className="flex justify-center items-center mx-1 px-4 py-2 bg-primary-black rounded-xl">
                <img
                  src={CalenderIcon}
                  alt={""}
                  width={20}
                  height={20}
                  className="cursor-pointer"
                />
              </div>
            </div>

            {/* create event Icon */}

            <div
              className="p-1 px-4 rounded-xl flex justify-center items-center bg-primary-orange"
              onClick={() => navigate('/create-event/basic-details')}
            >
              <img
                src={CreateIcon}
                alt={""}
                width={20}
                height={20}
                className="cursor-pointer"
              />
            </div>

          </div>
        </div>
        <div className="flex gap-5">
          {/* search */}
          <div
            className={`w-[18rem] lg:block ${showSearchInMobile ? "block w-full mt-5" : "hidden"
              }`}
          >
            <SearchInput onInputChange={(input) => setSearchFilter(input)} />
          </div>

          <div className="hidden lg:block">
            <DatePicker onDateSelect={(start, end) => {
              setFilterStartDate(start);
              setFilterEndDate(end);
              FetchEvents(start, end)
            }} />
          </div>

          <PrimaryButton name="Create Event" icon={CreateIcon} link='/create-event/basic-details' classNames="hidden md:block px-2" />
        </div>
      </div>

      {(!allEvents || allEvents?.length == 0) ? <div className="text-center mt-32 underline text-primary-orange text-xl cursor-pointer opacity-60" onClick={() => FetchEvents()}>Refresh</div>
        : <div className="py-8 md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 justify-items-center items-center ">
          {allEvents?.map((event: Event, idx) => <EventCard event={event} onCardClick={() => onEventClick(event?.eventId, event?.hostUserId)} />)}
        </div>}
      <Drawer
        open={isDrawerOpen}
        title="Date"
        onClose={() => setIsDrawerOpen(false)}
        onSave={onDateFilterApply}
      >
        <div className="h-[73vh] z-20">
          <h1 className="text-[1.25rem]">Date</h1>
          <div className="mt-6">
            <DatePicker onDateSelect={(start, end) => { setFilterStartDate(start); setFilterEndDate(end); setSelectedDateFilter('') }}
              startDateProp={filterStartDate} endDateProp={filterEndDate} />
            <div className="flex gap-2 items-center mt-4">
              {Object.values(DATE_FILTER).map((item, index) => (
                <div className={`p-3 text-sm  rounded-2xl ${selectedDateFilter != item ? 'bg-input-box-gray text-text-gray' : 'bg-[#472600] border-solid border-[1px] border-primary-orange text-primary-orange'}`} key={index} onClick={() => onDateFilterClick(item)}>
                  {item}
                </div>
              ))
              }
            </div>
          </div>
        </div>
      </Drawer>

    </div>
  );
}
