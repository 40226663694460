import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { LEFT_IMAGE_URL, OTP_IMAGE_URL, REGISTER_IMAGE_URL, SIGNUP_IMAGE_URL } from '../../../assets/assets';

export default function AuthScreenLayout() {

  const pathname = useLocation();

  const getBackgroundImage = () => {
    const path = pathname.pathname;
    const imageMap = {
      'otp': OTP_IMAGE_URL,
      'signUp': SIGNUP_IMAGE_URL,
      'registration': REGISTER_IMAGE_URL,
    };

    for (const [key, image] of Object.entries(imageMap)) {
      if (path.includes(key)) {
        return image;
      }
    }
    return LEFT_IMAGE_URL;
  };

  return (
    <div className='relative h-full'>
      <div className='fixed bottom-0 left-0 right-0 h-60 bg-gradient-to-t from-primary-orange/20 to-transparent blur-md'></div>
      <div className='flex gap-3 justify-center h-full'>
        <img
          src={getBackgroundImage()}
          alt="background"
          className='rounded-lg xl:h-[90vh] lg:h-[40vh] mx-20 hidden xl:block'
        />
        <Outlet />
      </div>
    </div>

  )
}
