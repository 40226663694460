import Axios from '../utils/axiosInstance'
import qs from 'qs';
import { getLocalStorage } from '../utils/localStorage';
import { LOCAL_STORAGE } from '../utils/constants';

const BASE_URL_V1 = process.env.REACT_APP_BASE_URL;

const basicDetails = (data: any) => {
    return Axios.post('createEvent', data)
}

const addBannerImage = async (data: FormData) => {
    const token = getLocalStorage(LOCAL_STORAGE.USER_TOKEN);
    return await fetch(`${BASE_URL_V1}addEventBanner`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}` // Replace `token` with your actual token variable
        },
        body: data
    });

}

const updateEventType = (data: any) => {
    return Axios.put('updateEventSettings', data)
}

const getBannerImage = (data: any) => {
    return Axios.post('getEventBanner', data)
}

const createEventTicket = (data:any) => {
    return Axios.post('createEventTicket', data)
}

const getEventTicket = (data:any) => {
    return Axios.post('getEventTicket', data)
}

const createPromoCode = (data:any) => {
    return Axios.post('createPromoCode', data)
}
const getPromoCodes = (data:any) => {
    return Axios.post('getPromoCode', data)
}

const addStripeAccount  = (data:any) => {
    return Axios.post('create-connected-account', data)
}

const getPayoutList = (data:any) => {
    return Axios.post('list-bank-accounts', data)
} 

const makeDefaultAccount = (data:any) => {
    return Axios.post('makeDefault', data)
}

const deleteStripeAccount = (data:any) => {
    return Axios.post('delete-bank-account', data)
}

const fetchEventById = (params: {}) => {
    return Axios.get(`fetchEventByID${params ? '?' + qs.stringify(params) : ''}`)
}

const updateEvent = (data: any) => {
    return Axios.put('updateEvent', data)
}

const deleteTicket = (data:any) => {
    return Axios.post('deleteEventTicket', data)
}

const deletePromo = (data:any) => {
    return Axios.post('deletePromoCode', data)
}

const updateTicket = (data:any) => {
    return Axios.put('updateEventTicket', data)
}

const updatePromo = (data:any) => {
    return Axios.post('updatePromoCode', data)
}


export const CreateEvent = {
    basicDetails,
    addBannerImage,
    updateEventType,
    getBannerImage,
    createEventTicket,
    getEventTicket,
    createPromoCode,
    getPromoCodes,
    addStripeAccount,
    getPayoutList,
    makeDefaultAccount,
    deleteStripeAccount,
    fetchEventById,
    updateEvent,
    deleteTicket,
    deletePromo,
    updateTicket,
    updatePromo
}