import React from "react";
import styles from "./HostAgreement.module.scss";

export default function HostAgreement() {
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsHeader}>
        <h1>SOCHA™ EVENT HOST AGREEMENT</h1>
        <em>Last revised: June 7, 2023</em>
      </div>
      <div className={styles.termsContent}>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            This Socha™ &nbsp;Event Host Agreement ("
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Host Agreement
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ") between you and Sondr, Inc. (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Sondr
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ,” or "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            we,
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            " "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            us
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ," and "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            our
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ") and the rights and obligations contained in it are in addition to
            and are incorporated into the Socha(™) Terms of Service (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Terms of Service
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). Nothing in this Host Agreement will be interpreted to limit,
            change, or waive any terms of the Terms of Service or our Privacy
            Policy. However, if there is any inconsistency between the Terms of
            Service and this Host Agreement, this Host Agreement will control.
            &nbsp;Access to the Platform and to the Services is provided online
            at&nbsp;
          </span>
          <a href="https://joinsocha.com" style={{ textDecoration: "none" }}>
            <span
              style={{
                color: "#0000ff",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              https://joinsocha.com
            </span>
          </a>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;and through one or more Socha™ mobile apps (collectively, the
            “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            App
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”), the use of which is governed by the Socha™ End User License
            Agreement with Sondr (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            EULA
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”),
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Capitalized terms that are not defined in this Host Agreement have
            the definition provided in the Terms of Service. In addition, the
            headings and subheadings throughout this Host Agreement, including
            the italicized text following each, are for convenience only and
            will not restrict or affect any provisions, and are not legal
            guidance. When we say Sondr “may,” has the right, is permitted, is
            authorized, or is allowed to do something in this Host Agreement, it
            means we may, but are not obligated to, exercise the applicable
            rights or options or take the applicable action, as we determine in
            our sole discretion. Any determinations, decisions, or beliefs by us
            under this Host Agreement may be made by us in our sole discretion.
            As used in this Host Agreement, “including” means “including, but
            not limited to.” When this Host Agreement says that you “will” take
            an action, this means that you are agreeing to take the action and
            that you must take that action.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Please read this entire Host Agreement, the EULA and our Terms of
            Service thoroughly, as they may affect your rights. This Host
            Agreement contains important information regarding payments,
            refunds, and restricted Events, and the EULA and the Terms of
            Service contain provisions for dispute resolution, including binding
            arbitration and a class action waiver, that apply to this Host
            Agreement.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Sondr Services for Hosts
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.1
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Ticketing Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            , We provide you and other Hosts an online platform to sell tickets,
            registrations and other items, and receive payments for your Events
            (the “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Ticketing Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”).&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.2
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Marketing and Operational Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            , If, at a future time, we elect to offer additional services or
            products from us including onboarding support, account management,
            and marketing and promotion services (collectively, “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Marketing and Operational Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”), we will make an announcement on the Platform. All Marketing and
            Operational Services, if any, will be provided as we determine and
            will be subject to the Terms of Service, this Host Agreement, and
            any separate written agreement we determine is necessary. In the
            event of a conflict between the separate written agreement and this
            Host Agreement, the Host Agreement will control. If you elect to
            purchase any of the Marketing and Operational Services, you will be
            charged at the time of your purchase of the Marketing and
            Operational Services, or we may invoice you after your purchase. All
            invoices for Marketing and Operational Services are due and payable
            upon delivery of the invoice unless otherwise indicated on the
            invoice.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.3
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Host Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . When “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Host Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ” is used in this Host Agreement, it means both the Ticketing
            Services and the Marketing and Operational Services, if any. For the
            avoidance of doubt, Host Services, among other types of Services,
            are included in the meaning of “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Services
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ” under the Terms of Service.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            2. Our Host Agreement.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            2.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Purpose and Scope
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            This Host Agreement sets the terms and conditions for your use of
            the Host Services when you act as a Host on the Platform. Users of
            our Host Services may be collectively referred to in this Host
            Agreement as “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ” or “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            your.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ” &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            By using the Host Services, you are agreeing to the terms and
            conditions of this Host Agreement, the Terms of Service, and our
            Privacy Policy, without modification, and entering into a binding
            contract with us that governs our Host Services and your use of the
            Host Services. Do not use the Host Services if you do not agree to
            the terms and conditions of this Host Agreement, the Terms of
            Service, or our Privacy Policy. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3. Eligibility for Host Services.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Eligibility
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            To use the Host Services, you must: (a) have the authority to enter
            into this Host Agreement on your own behalf or on behalf of the
            entity using the Host Services; (b) comply with our Terms of Service
            and all applicable laws; and (c) review and agree to the Stripe
            Connected Account Agreement, which includes the Stripe Services
            Agreement (collectively, the “Stripe Agreements,” which Stripe may
            modify from time to time) and any other required third party
            agreement, if applicable.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            All information you submit must be truthful, accurate, and complete,
            and you must promptly notify us of any changes.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We can approve or deny your registration for the Host Services,
            limit, suspend, or terminate your access to the Host Services,
            and/or place transactional limits on payouts at any time, for any
            reason, with or without notice. We can also change these eligibility
            requirements at any time.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Additional Registration Data
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            After registering as a Member, we may require you to provide
            additional information about yourself, the entity you represent (if
            any), and the principals/beneficial owners of the entity you
            represent (if any) (collectively, "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Additional Registration Data
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            "). As an example, Additional Registration Data may include current
            address, doing business as (DBA) names, description of products,
            website address, bank account or other payment account information,
            tax identification numbers, date of birth, passport or driver’s
            license number, country of origin, copies of government
            identification documents, and other personal information.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may use this information to verify your identity, the validity
            and/or legality of your transactions, and/or whether you qualify to
            use the Host Services. You must: (a) promptly provide accurate and
            complete information and (b) regularly and quickly update this
            information to make sure it remains accurate and complete.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.3&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Disclosure Authorization
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may share Registration Data, Additional Registration Data, and
            information about Events and use of the Services with our Payment
            Processor Partners, and with your bank or other financial
            institution, if the Services involve these third parties. You also
            authorize us to verify your Registration Data and Additional
            Registration Data, and conduct due diligence on you through third
            parties, including third-party credit reporting agencies.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.4&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Failure to Provide
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You are not entitled to receive any payments from tickets,
            registrations, or other items sold from us or our Payment Processor
            Partners unless and until you provide full and accurate Registration
            Data and Additional Registration Data. We reserve the right to
            suspend or terminate your Sondr account and/or your access to the
            Host Services, and to withhold any payments otherwise due to you, if
            we believe that your Registration Data or Additional Registration
            Data is inaccurate or incomplete.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.5&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Hosts; Prohibited Events; Prohibited Transactions
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Hosts
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . By registering for the Host Services and accepting this Host
            Agreement, you represent and warrant that:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you are not located in, and you are not a national or resident of,
            any country for which the United States, United Kingdom, European
            Union, Australia or Canada has embargoed goods and/or services
            similar to our Services ("
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Restricted Countries
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            "); and
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you are not a person or entity or under the control of or affiliated
            with a person or entity that&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            appears on the U.S. Office of Foreign Assets Control's Specially
            Designated Nationals List, Foreign Sanctions Evaders List or
            Palestinian Legislative Council List;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            2.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            appears on the U.S. Department of State's Terrorist Exclusion List;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            appears on the Bureau of Industry and Security's Denied Persons
            List;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            appears on the Consolidated List of Targets published by the U.K. HM
            Treasury;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            appears on the Consolidated List published by the A.U. Department of
            Foreign Affairs and Trade; or
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            6.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            is subject to sanctions in any other country.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If we determine that you fall into any of the categories above, you
            are a "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Host
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            " and may not use the Host Services. &nbsp;We reserve the right to
            cancel any and all Events of a Prohibited Host and to terminate the
            Prohibited Host’s access to the Platform.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Events
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You may not post Events to the Platform or the App or engage in
            activities through the Host Services that:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            violate or help violate any applicable local, state, provincial,
            national or other law, rule or regulation;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            contain any Content that violate the Terms of Service or the Sondr
            Community Guidelines; or
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            take place in Restricted Countries or restricted regions, which
            include (but are not limited to) Iran, North Korea, Syria, Crimea,
            Donetsk People’s Republic, and Luhansk People’s Republic. Also, you
            must have our prior written approval to post Events in Cuba or the
            Russian Federation because additional licensing requirements may
            apply.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If we determine that an Event falls into any of the categories
            above, it is a “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Event
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .” We reserve the right to cancel a Prohibited Event by removing it
            and all references to it from the Platform.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Transactions
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You may not process any of the following transactions, and you
            represent and warrant that you will not submit for processing any of
            the following: &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            any transaction that violates or is considered “high risk” (or
            another restricted category), including any transaction involving
            certain adult content or activities, illegal goods or services
            (including cannabis), paraphernalia associated with any illegal
            goods or service, crypto-currency purchases, raffles, sweepstakes,
            or gambling;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            any fraudulent or criminal transaction;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            any transaction that would be a “
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            restricted transaction
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ” as defined in the Unlawful Gambling Enforcement Act of 2006 or
            Regulation GG; or
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iv.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            any transaction that is unrelated to your Events on the Platform.
            For clarity, our payment processing services may only be used for
            the purchase of tickets or registrations for your Events on the
            Platform, or to sell items or solicit donations directly related to
            such Events.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If we determine that a transaction falls into any of the categories
            above, it is a "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Prohibited Transaction
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ."
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4. Suspension and Termination of Host Services; Survival of
            Obligations.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Suspension and Termination
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may suspend or terminate your and your Affiliates’ ability to
            participate on the Platform as a Host and/or to receive payments at
            any time and for any reason, including if:
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you become ineligible for the Services or violate any provision of
            this Host Agreement, the Terms of Service, or any other Sondr policy
            applicable to you;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you or any of your Affiliates do not make payments owed to us on
            time;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            our Payment Processor Partners refuse to facilitate payments to you
            or to engage in services involving you;&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            d.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            we are served with legal process seeking to attach or garnish any of
            your funds or property in our possession;&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            e.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            we learn that you have granted a right to assignment of payments to
            any party, for any reason; or&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            f.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you are a Prohibited Host, have organized Prohibited Events, and/or
            process or submit Prohibited Transactions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Host Termination
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You may stop participating as a Host at any time by deleting your
            account in accordance with the Terms of Service. However even if you
            delete your account, you are still bound by this Host Agreement and
            any other Sondr policy that applies to you, as well as any other
            written agreement you may have with us.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4.3&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Effect of Termination
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If either party terminates this Host Agreement, we (through our
            Payment Processor Partners) will, within a reasonable time, pay any
            amounts owed to you, as long as the payments are permitted by
            applicable laws, regulations, and orders. We may hold funds we owe
            you, pursuant to Section 6.2 “Deductions, Setoffs, and Reserves,” to
            make sure that there are no refunds, credits, balances owed to us,
            or other deductions that should be made from the funds we owe you.
            We will otherwise have no further payment obligations to you. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4.4&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Continued Obligations
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Some terms will still be effective even after termination.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            All provisions of this Host Agreement that by their nature should
            survive termination of this Host Agreement will survive (including
            your obligations related to refunds and payments).
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5. Payment Processing.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payment Processor Partners
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We partner with third-party payment processors to facilitate your
            transactions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            As part of the Host Services, we may provide tools to help you sell
            tickets, registrations, and other items to Guests interested in your
            Events. To facilitate payments for you, we partner with third-party
            payment processors (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payment Processor Partners
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You and we are subject to the rules and regulations of our Payment
            Processor Partners. In certain cases, you may be required to enter
            into separate agreements with our partners (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payment Processor Agreements
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”) to receive payments. &nbsp;&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Stripe, Inc. (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Stripe
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”) is one of our Payment Processor Partners that may facilitate
            payment processing services for you. When you agree to this Host
            Agreement or continue to operate as a Host on Sondr, you also agree
            to the Stripe Connected Account Agreement and the Stripe Services
            Agreement. For us to enable payment processing services through
            Stripe, you must provide us accurate and complete information about
            you and your business, and you authorize us to share it and
            transaction information with Stripe.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Sondr Payment Processing
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . This is the payment processing method that most Hosts on our
            platform will use to collect proceeds for tickets or registrations
            they sell to Guests.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            SPP
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            For all payment transactions you will use Sondr Payment Processing
            ("
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Sondr Payment Processing
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            " or "
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            SPP
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            "), and we will act as your limited payments agent to facilitate
            payment transactions on your behalf using our Payment Processor
            Partners. As a limited agent, we process the total value of tickets,
            registrations, and other items purchased by Guests for your Events
            (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Event Proceeds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”) less the amounts set forth below in "Payouts."&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payouts
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Hosts &nbsp;will receive the Event Proceeds, less Guest Fees, Fees
            and Taxes, and Other Deductions and Setoffs (each as defined below),
            as applicable. Once ticket transactions for an Event are completed,
            our Payment Processor Partners will facilitate the payout to your
            preferred payout method. We will not be liable for delayed,
            rejected, or missed payouts that occur from the delay, failure, or
            contractual breach by you, a Payment Processor Partner or any other
            third-party provider. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payout Methods
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Acceptable Payout Methods
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You can receive payouts of Event Proceeds in several ways,
            currently including: (1) Manual Payout and (2) Instant Payout.
            &nbsp;In the Manual Payout, you will enter the payout amount (not to
            exceed the amount of funds currently available from the Event
            Proceeds) and will initiate the transfer of funds from the SPP to
            your bank account in the United States in your name in USD currency
            only. &nbsp;In the Instant Payout, the payout amount to be selected
            by you (not to exceed the amount of funds currently available from
            the Event Proceeds) will be automatically transferred to your bank
            account in the United States in your name according to the schedule
            you establish through the Platform.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            False or Incorrect Information
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We and our Payment Processor Partners rely on the information you
            provide to facilitate payment transactions on your behalf. If any
            account or card details that you provide to us or our Payment
            Processor Partners are incorrect, you must reimburse, indemnify, and
            hold us and our Payment Processor Partners harmless for any losses
            or expenses incurred by us relying on the incorrect
            information.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            d.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Transaction Limits
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . For risk management, security, or to meet our Payment Processor
            Partners’ requirements, we may, from time to time, determine to
            impose a transaction limit on the amount of any given transaction
            that you process through SPP, and you authorize us to reject any
            transaction over that limit.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            e.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Currencies
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We only provide SPP for certain currencies and for Hosts in
            certain locations listed here. In addition, Event Proceeds collected
            in a currency may only be paid out to you in the currency in which
            they are collected. We do not provide currency conversion
            services.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.3&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Guest Payment Methods
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Guests may choose a payment method for purchasing tickets to your
            Event. &nbsp;Currently payment methods are limited to a U.S.-issued
            credit card or Apple Pay®. &nbsp;Guests will be required to enter
            certain credit card information or Apple Pay® credentials at the
            time of purchasing tickets on the Platform.
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.4&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Commercial Entity Agreements
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You may be required to enter into an additional agreement directly
            with one of our Payment Processor Partners. If we believe that your
            account is likely to be subject to this additional requirement, we
            will provide you with a "Commercial Entity Agreement" to which you
            must agree to continue receiving payouts. If you fail to accept that
            Commercial Entity Agreement, we may suspend or terminate your
            account.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Except for any Commercial Entity Agreement applicable to you, and
            notwithstanding the Stripe Connected Account Agreement, the Stripe
            Services Agreement, or any other Payment Processor Agreement you may
            have entered into with our partners, you are not a direct party to
            any agreement between Sondr or any of its Affiliates and any Payment
            Processor Partner or another partner, nor are you a third party
            beneficiary of any such agreement.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.5&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Roles and Relationships
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our Role
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We do not and will not provide you with banking, deposit taking,
            stored value, insurance or any other financial services other than
            serving as a limited payments agent as described above. Although we
            may show you a balance of Event Proceeds in your Sondr account, that
            balance merely reflects the Event Proceeds collected by our Payment
            Processor Partners . This information does not constitute a deposit
            or other obligation of Sondr or any Payment Processor Partners to
            you and is provided for reporting and informational purposes only.
            You are not entitled to, and have no ownership or other rights in
            the balance displayed, until applicable funds are paid out to you in
            accordance with this Host Agreement. You are not entitled to any
            interest or other compensation associated with such funds pending
            payout.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Your Obligations to Guests
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . When a Guest makes a payment (e.g., when a Guest purchases a
            ticket for an Event) and that payment is then processed by one of
            our Payment Processor Partners, you will treat it the same as if the
            Guest paid you directly. This means that you will sell or provide
            the Guest all advertised goods and services as if you had received
            the Event Proceeds directly from the Guest, regardless of whether
            you have received or ever receive the Event Proceeds.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;We, acting as your agent, are authorized to:
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            hold, retain and pay out Event Proceeds to you using our Payment
            Processor Partners;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            transfer or upgrade a Guest’s ticket or registration (if those
            transfers are permitted by you in your Event registration process);
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            issue refunds to Guests as set forth in this Host Agreement, the
            Stripe Agreements, and any other Payment Processor Agreement; and
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iv.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            delegate our obligations to certain of our Affiliates and/or
            partners within and outside the United States, provided that we
            remain liable to fulfill our obligations under this Host
            Agreement.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We are not liable for any of your acts or omissions, and any
            obligation to pay you is conditional on you complying with this Host
            Agreement, the Terms of Service, and Guests’ actual payment of Event
            Proceeds.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.6&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Confirmations
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            When an order is placed by a Guest and confirmed through Sondr, we
            generate a confirmation message and issue a unique confirmation
            number (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Ticket ID
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). You must accept, honor and fulfill all ticketing, registration,
            merchandise and donation commitments that have been confirmed
            through the Services. It is your responsibility to verify a Guest's
            Ticket ID and/or any Event restrictions prior to the applicable
            Event. &nbsp;You will be required to record all tickets (either by
            scanning QR codes or through other methods as we may instruct you)
            submitted by Guests for your Event prior to holding the Event so
            that all attending Guests are recorded in the Platform. &nbsp;You
            are solely responsible for providing all the services to Guests
            before or after check-in at the Event venue.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            5.7&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Escheatment
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payouts will automatically be made to your payment method on file.
            If, for some reason, we cannot make a payout to that payment method
            and you fail to provide an updated, approved payout method for a
            period of time as set forth in applicable unclaimed property laws
            (e.g., escheatment), we will escheat the amount, after due notice,
            to the applicable government authority in accordance with applicable
            laws.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            6. Fees, Deductions, and Reserves
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            6.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Fees
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Fees and Taxes
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You are solely responsible for setting prices for tickets,
            registrations, and other items for your Events.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You must pay us all applicable fees for the Marketing and
            Operational Services, if any (the “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Sondr Fees
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). You are solely responsible for the Sondr Fees, if any,
            applicable Taxes (described in Section 9 “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Taxes
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”), and any other amounts due to any other party arising from the
            Host Services (collectively with Sondr Fees and Research Fees, “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Fees and Taxes
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). Fees and Taxes vary by jurisdiction and currency. Fees and Taxes
            may change from time to time with respect to transactions that occur
            following the change.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may also charge you, at standard and reasonable rates, for (i)
            research costs and/or legal fees that we incur in order to respond
            to any third party or government subpoena, levy or garnishment on
            your account; and (ii) research and activities that are necessary
            for us to verify and execute any change of payee (collectively, “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Research Fees
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Guest Fees
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . &nbsp;We may charge various fees to Guests, separately from Sondr
            Fees, that are not passed on to you, related to ticket,
            registration, payment processing, and other item sales, processing,
            handling, and/or access to various Sondr content and services (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Guest Fees
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). Guest Fees will be added automatically to the ticket price you
            set on the Platform, and Guests will only see the aggregate purchase
            price for their tickets. We are not required to disclose Guest Fees
            to you, and we may change Guest Fees at any time. &nbsp;You
            understand and agree that tickets are not returnable or refundable
            unless you uninvite a Guest from the applicable Event through the
            Platform or cancel such Event, in which case you are solely
            responsible for issuing a refund to such Guest of the full amount of
            the ticket, including Guest Fees.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You must not preference one form or method of payment over another,
            and you must not charge Guests any fees or other amounts in
            connection with the purchase of tickets or any other items, products
            or services in connection with your Event.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Delays and Omissions
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We strive to inform you in a timely manner of amounts due, but our
            failure to do so will not relieve you of your payment obligations.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If we fail to send a timely invoice, you are still responsible for
            your payment obligations. If we omit in a statement or invoice a
            payment that you owe to us or any third party, it will not
            constitute a waiver of the right to that payment. You will still owe
            that payment and we may include it in a subsequent statement or
            invoice.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            6.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Deductions, Setoffs, and Reserves
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Deductions and Setoffs
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Without limiting Section 6.1 above, we will deduct the following
            from the Event Proceeds: &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Sondr Fees, Research Fees and other Fees, as applicable;&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            2.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Guest Fees;&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            3.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            all applicable Taxes; and&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            4.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            amounts for returns, refunds, &nbsp;discounts, and credits.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You will only be entitled to payments of the amount of your sales
            after these deductions have been made. Additionally, we may deduct
            all other deductions authorized under this Host Agreement and we may
            set off for any debts, fees, or other amounts that you or any of
            your Affiliates owe to us under this Host Agreement or any other
            agreement between or among you and/or any of your Affiliates and us
            and/or any of our Affiliates (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Affiliated Agreement
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”), including &nbsp;refunds, Sondr Fees, Research Fees, reserves,
            and customer complaints ("
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Other Deductions and Setoffs
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). We are not liable to you for any claims resulting from our
            decision to make Other Deductions and Setoffs.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            For clarity, we may exercise our setoff rights against payouts
            related or unrelated to the amount owed. If the exercise of our
            setoff right does not fully cover the amount of funds that you or
            any of your Affiliates owe to us under this Host Agreement or any
            Affiliated Agreement, then that amount of funds will be deemed due
            and owing to us until you have fully satisfied the amount and, in
            this case, we may collect the amount pursuant to Section 11
            “Non-Exclusive Remedies and Collections."
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may also deduct from your Event Proceeds as required by law,
            including Section 9-406 of the Uniform Commercial Code and
            garnishment orders. In addition, we may block, reject, freeze, or
            turn over to law enforcement agencies any portion of payments
            involving the Platform that are made to you, owed by you, or
            otherwise involve you as permitted and/or required by applicable
            laws and regulations.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Reserves
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We reserve the right to retain all or a certain percentage of Event
            Proceeds and any other fees for Services that we provide to you or
            any of your Affiliates (with such percentage being determined by us)
            to fund a reserve:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            at any time we determine is necessary based upon the level (or
            expected level) of refunds, disputed charges, customer complaints,
            allegations of fraud, or changes in your credit profile or the
            relevant Event(s)' risk profile(s); and
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            2.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            as otherwise necessary to secure the performance of your or any of
            your Affiliates’ obligations under this Host Agreement or any
            Affiliated Agreement, or to protect us against illegal, fraudulent,
            or improper activity.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our right to hold a reserve will continue following the completion
            of your applicable Event(s) and until either:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            1.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you and your Affiliates have discharged all obligations under this
            Host Agreement and any Affiliated Agreement and the applicable
            periods for refunds, disputed charges, &nbsp;and complaints have
            passed; or
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            2.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you and your Affiliates have otherwise provided us with adequate
            security (as determined by us) for your and your Affiliates’
            obligations under this Host Agreement and any other Affiliated
            Agreement, whether matured or unmatured, contingent or
            non-contingent, or liquidated or unliquidated.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            iii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may use any amounts that we hold in reserve to set-off amounts
            that you or your Affiliates owe us, as detailed above in Section
            6.2(a).&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            7. Payouts
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            7.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payout Methods&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . &nbsp;&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.5",
            textIndent: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a. &nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payout Method Selection
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . &nbsp;You will have to select the available method for receiving
            payouts of Event Proceeds from all of Your Events, currently
            including: (1) Manual Payout and (2) Instant Payout, on the
            Platform. &nbsp;This is done by making a selection with the “Payout
            Preferences” button under the "Profile” tab and then inputting the
            required details for the applicable payout method. &nbsp;Once the
            payout method is added successfully to your account, you will be
            notified through the Platform and in your registered email address.
            &nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You can view the payout details added to the platform by clicking on
            the “payout preferences” option, which will show your bank details
            added for the payout method. &nbsp;For the Manual Payout, you will
            have to enter the payout amount and initiate the payout manually to
            your own registered bank account.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            With the
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Instant Payout, the available payout amount will be instantly
            withdrawn and sent to your bank account periodically.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payout Terms
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . All Payouts are merely advances of amounts that you may earn at a
            later date as Event Proceeds. We may apply a maximum aggregate cap
            on Payouts and withhold a certain amount of Event Proceeds to fund a
            reserve. We may at any time (i) terminate or suspend your right to
            receive Payouts; and/or (ii) alter the terms (i.e., frequency,
            reserve rate, and maximum aggregate cap) of the Payouts. We reserve
            the right to implement the cap in US Dollars, with:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            the applicable exchange rate for setting the cap being the rate
            provided on oanda.com at 9:00 am PT on the date on which we agree to
            make Scheduled Payouts to you, and&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            the applicable exchange rate for calculating Event Proceeds against
            the cap being the rate provided on floatrates.com at 5:00 am PT on
            the date of payout.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We may select reasonable substitutes for oanda.com and
            floatrates.com.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Recovery of Payouts by Sondr
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We may demand back any portion of Payouts, for any reason, prior
            to or after the conclusion of your Event and settlement of all risk
            of refunds. Upon receipt of notice of a demand for repayment of any
            portion of a Scheduled Payout, you must promptly comply with the
            demand. We have the right to have our Payment Processor Partners
            withhold funds per Section 6.2 “Deductions, Setoffs, and Reserves”
            and Section 11 “Non-Exclusive Remedies and Collections.” You also
            accept your obligations under Section 8 “Refunds,” including your
            obligations to reimburse us for refunds.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            7.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Cancelations; Nonperformance
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We and our Payment Processor Partners are not required to make
            payments to you for any Event that has been, or we believe is at
            risk of being, a Canceled Event (as defined in Section 8.1 “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Refund Policy
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”). If we choose to issue full or partial payment to you for a
            Canceled Event, you will be fully responsible for refund
            requests.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            8. Refunds.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            8.1 Refund Policy
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You understand and agree that refunds for tickets or any other item
            in connection with Events may only be issued to your Guests if you
            uninvite such Guests through the Platform or if the Event is
            cancelled through the Platform (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Canceled Event
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”) and you ensure that every Guest receives a refund. &nbsp;Refunds
            do not include Sondr Fees, which we may retain whether or not an
            Event takes place. &nbsp; You must set a refund policy, consistent
            with the requirements of this Section 8 and communicate it to Guests
            for each Event. You must make sure that your refund policy complies
            with this Host Agreement and apply your refund policy in compliance
            with this Host Agreement.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            8.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Handling Refunds, Disputes, and Fake Tickets
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            All sales are ultimately made by you, as the Guests are your
            customers, and Guests must contact you directly with any refund
            requests. You are responsible for the funding and processing of
            refunds. Regardless of what payment method is selected, all disputes
            regarding refunds are between you and your Guests.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Further, you are the only one responsible for making sure that your
            Events are ticketed correctly, and that only valid tickets are
            accepted. We are not responsible for any fake or invalid tickets, or
            any costs associated with your decision to accept or reject tickets.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            8.3&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Process for Refunds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Refunds Are Your Responsibility
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You have sole responsibility for all refunds to Guests associated
            with your Event.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Initiating Refunds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Refunds for ticket transactions must be initiated and processed
            through the Platform.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Primary Refund Sources
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You will issue refunds to Guests either by using backup funding
            sources within the Platform (including adding funds to the Event
            balance or using your credit card) or remitting funds due for
            refunds back to us so that we can process refunds on your
            behalf.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            d.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Alternative Refund Sources
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . If we are unable to process a refund on your behalf for any reason
            (for example, the Guest’s payment card account cannot accept the
            refund), we will not process the refund through the Platform and you
            will have sole responsibility for meeting your refund obligations
            under this Host Agreement and your refund policy. In such cases, you
            may refund through cash or check or through a method described in
            subsection (m), below.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            e.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Payment Processing Deadlines for Refunds; Manual Refunds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You refund Guests directly through the Platform within certain
            windows permitted by our Payment Processor Partners. If the refund
            is outside such windows, then it will need to be processed manually
            by you.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            f.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Our Deadlines for Refunds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . We reserve the right to set a time period for receiving refund
            requests for specified reasons. After that time period, we can
            decline to process refund requests through the Platform. We may
            allow you to refuse refunds for a Canceled Event or other specified
            reason when the refund requests are received after that period;
            however, you are still responsible for all refund disputes.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            g.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            No Offline Refunds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Unless otherwise instructed by us, no refunds will be made outside
            of the Platform (e.g. offline).&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            h.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            No Misuse of Refunds
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Refund transactions may only be used for bona fide Event refunds,
            and not for other activity such as money transfers, including cash
            advance transactions.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Refunds Processed by Sondr
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . If you choose to remit funds back to us so that we can process
            refunds on your behalf, to the extent we are able to do so, you must
            remit funds to us that are sufficient to cover refunds due to Guests
            within 5 days of the Event cancelation, Event nonperformance, or the
            other reason for the Specified Refunds.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            j.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Notification to Guests
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You must notify Guests through the Sondr Emails to Attendees tool
            of the Event cancelation or nonperformance as soon as reasonably
            possible and prior to the Event start time.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            k.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Guest Contact
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . You will be the sole point of contact for Guests with refund
            requests, and you will instruct the Guests to not contact us with
            refund requests. &nbsp;You will respond to all refund requests
            within a maximum of 5 business days.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            l.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Multi-Day Events
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . If you perform only part of a multi-day Event, then you must
            refund a pro-rata portion of the purchase price of each multi-day
            ticket based on the portion of the multi-day Event that was canceled
            or not performed. For example, if you sell a 3-day ticket to a
            festival for $150, and cancel 1 day of the festival, you must
            provide a $50 refund to Guests for the canceled day. &nbsp;Partial
            refunds for multi-day Events will only be made off the Platform with
            our prior consent.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;m.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Credits
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Any funds associated with a credit that is usable only for your
            future Events will be processed by us in the same manner as a refund
            from your account. We will process the credit on your behalf as part
            of the final settlement of the Event when the credit is used, as
            described in the terms for SPP. A credit is “used” once the Guest
            either attends the relevant concluded Event or fails to attend the
            relevant concluded Event. However, if you have established a
            redemption period and the credit is not used during the redemption
            period, the credit will be applied to your account and settled with
            your next scheduled payout, as long as you offered a substitute
            Event that was ticketed and completed during the established period.
            &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            9. Taxes.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            9.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Your Tax Responsibility
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            You are solely responsible for determining which, if any, sales,
            use, amusement, value added, consumption, excise and other taxes,
            duties, levies and charges (collectively, “
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Taxes
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”) apply to your use of the Host Services and to sales you make
            using the Host Services. It is your sole responsibility to, and you
            will, collect and remit the correct amounts of all such Taxes to the
            applicable governmental authorities (“
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Tax Authorities
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ”).
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Taxes may vary based on the nature of your Event, the nature of your
            tax status (individual, entity, business, consumer, etc.), your
            location, the location of your Guests, credits and deductions for
            which you may qualify and other factors.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            If you do collect Taxes, then subject to Sections 9.2, 9.3, and 9.4,
            we will pay such amounts to you at the same time as the applicable
            Event Proceeds. You are responsible for remitting all such Taxes to
            the appropriate Tax Authorities. If you use any Tax Tools that
            require you to input a tax registration number, you represent and
            warrant that such tax registration number is true and correct. We
            cannot give you legal or tax advice, so please be sure to check with
            your own legal and/or tax advisor about any applicable Taxes. If a
            Tax Authority requires us to pay any Taxes attributable to your use
            of the Host Services or to sales that you make using the Host
            Services, you must promptly and fully reimburse us for such Taxes
            upon demand, plus all associated costs, penalties, interest and
            expenses.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            9.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Request for Information
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Despite what is stated in Section 9.1, we may, in certain
            jurisdictions, be required to collect and remit Taxes relating to
            your sales of tickets, registrations, or other items that are made
            using the Host Services. To determine whether we must collect any
            Taxes on your behalf, we may request certain information when you
            create an Event using the Host Services. Such information may relate
            to your tax exempt status, the nature of your Event and/or other
            similar information. If we request such information, you represent
            and warrant that the information you provide is true and correct. We
            cannot give you legal or tax advice, so please be sure to check with
            your own legal and/or tax advisor about any information you provide
            through the Platform. If a Tax Authority requires us to pay any
            Taxes attributable to your Event as a result of the information you
            provided us being incorrect, you must promptly and fully reimburse
            us for such Taxes upon demand and all associated costs, penalties,
            interest and expenses.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            9.3&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Collection by Sondr
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Despite what is stated in Section 9.1, we may, in certain
            jurisdictions, be required to collect and remit Taxes on Sondr Fees
            &nbsp;to the Tax Authorities. In such jurisdictions, we will collect
            from you Taxes on Sondr Fees, and you must pay such Taxes. We may,
            at our sole election, invoice you for Taxes on Sondr Fees or
            withhold (from amounts we would otherwise pay to you) the amount of
            Taxes on Sondr Fees. With the exception of Taxes on Sondr Fees that
            we collect from you pursuant to this paragraph and Taxes collected
            and remitted pursuant to Section 9.2, you remain responsible for
            collecting and remitting the correct amount of any Taxes that apply
            to your use of the Services and to sales you make using the
            Services.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            9.4&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            IRS Reporting
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            In addition, under United States federal tax law, if we process
            transactions above a specified threshold during a given calendar
            year and in the aggregate across all of your accounts, we might be
            required to report to the IRS (i) the gross amount of the total
            reportable payment card/third party network transactions for the
            calendar year related to your Events (i.e., the total dollar amount
            of total reportable payment transactions, determined on the date of
            the transaction, without regard to any adjustments for credits, cash
            equivalents, discount amounts, fees, refunded amounts, or any other
            amounts); (ii) your name; (iii) your address; and (iv) your tax
            identification number ("
          </span>
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Your Tax Information
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            "). In such instances, we are required to either (a) collect Your
            Tax Information when you reach the specified threshold; or (b)
            establish that you are a foreign person not subject to Form 1099-K
            information reporting. If applicable, upon request you will provide
            us with an IRS Form W-8 establishing your foreign status. You will
            not receive any further payments from us until either (a) or (b)
            above are satisfied. To learn more about these tax reporting rules
            and why we are required to do this, see our 1099-K FAQ.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            9.5&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Right to Withhold
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We reserve the right to withhold the payment of any amounts that we
            owe to you and pay such amounts as required by applicable local,
            state, provincial, national or other law, rule, regulation, judgment
            or order, in each case as determined by us, or to seek later payment
            from you of any amounts of Taxes uncollected and unremitted that are
            related to your Events.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            10. Representations and Warranties.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            10.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Authority
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            In addition to the representations and warranties throughout this
            Host Agreement, you represent and warrant to us, on behalf of
            yourself and the entity you represent (if applicable), that:
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            the entity you represent is properly organized and in good standing
            under applicable laws;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you have all the power and authority necessary to enter into this
            Host Agreement and to fulfill your obligations;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            entering into and performing under this Host Agreement will not
            cause you to breach any laws, rules, court orders, or other
            agreements that you must follow; and
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            d.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            you have the full authority and legal power to bind the entity you
            represent to this Host Agreement.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            11. Non-Exclusive Remedies and Collections.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            11.1&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Non-Exclusive Remedies
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            a.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Interest on Overdue Amounts
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Overdue amounts that you or your Affiliates owe under this Host
            Agreement or any Affiliated Agreement will bear interest calculated
            from the due date until paid in full, at a rate equal to the lesser
            of:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            one percent (1%) per month, compounded monthly; or
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            the maximum amount permitted by applicable law.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            b.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Setoffs and Invoices
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . If you or any of your Affiliates owe us any amounts under this
            Host Agreement or any Affiliated Agreement, we may, to the extent
            allowed by applicable law:&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            i.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            withhold and/or set-off any amounts that we would otherwise pay to
            you or any of your Affiliates (as described in Section 6.2
            “Deductions, Setoffs, and Reserves”); and/or
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            ii.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            send an invoice (which may be sent by mail, email or other methods
            outside of the Platform) to you or any of your Affiliates for
            overdue amounts, to the extent that the amount we could withhold is
            insufficient to cover the amounts you owe us, in which case you or
            any of your Affiliates will pay the invoice within thirty (30) days
            after the date of the invoice.
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            c.
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
              &nbsp; &nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Non-Exclusive
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            . Our rights and remedies in this Host Agreement are non-exclusive
            (that is, our remedies are cumulative and any can be exercised by us
            in addition to, and do not prevent us from exercising, any other
            rights or remedies available to us now or in the future under law,
            this Host Agreement, any Affiliated Agreements, other agreements, or
            otherwise). Our failure or delay in enforcing or exercising any
            right, remedy or provision of this Host Agreement will not be
            considered a waiver of those rights. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            11.2&nbsp;
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              WebkitTextDecorationSkip: "none",
              textDecorationSkipInk: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Collections; Costs of Recovery
          </span>
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            .&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.3800000000000001",
            marginTop: "0pt",
            marginBottom: "10pt",
          }}
        >
          <span
            style={{
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            We have the right to pursue collection of any late and unpaid
            amounts due to us if such amounts are not paid within thirty (30)
            days after the date of the invoice. We have the right to send you
            collection notices; sending such a notice will not be a requirement
            for taking legal or other action to collect overdue sums. In
            addition, you must promptly reimburse us upon demand for all
            out-of-pocket costs (including reasonable attorneys' fees and costs)
            incurred by us in collecting overdue amounts or any other amounts
            that you or any of your Affiliates owe under this Host Agreement or
            any other agreement with Sondr or its Affiliate. You and your
            Affiliates agree that if we must seek recovery of past due amounts
            and associated fees and costs, we have the right to pursue unpaid
            amounts through judicial proceedings, and such actions will not be
            arbitrated regardless of any arbitration provisions in the Terms of
            Service.
          </span>
        </p>
      </div>
      <div className="terms-footer">*****</div>
    </div>
  );
}
