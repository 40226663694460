import Axios from '../utils/axiosInstance'
import qs from 'qs';

const getHostEventDetails = (params: {}) => {
    return Axios.get(`getHostEventDetails${params ? '?' + qs.stringify(params) : ''}`);
}

const getGuestEventDetails = (params: {}) => {
    return Axios.get(`getGuestEventDetails${params ? '?' + qs.stringify(params) : ''}`);
}

const getGuestList = (params: {}) => {
    return Axios.get(`getGuestLists${params ? '?' + qs.stringify(params) : ''}`);
}

const updateEventJoiningStatus = (data: any) => {
    return Axios.post('updateEventJoiningStatus', data)
}

const updateEvent = (data: any) => {
    return Axios.put('updateEvent', data)
}

const deleteEvent = (data: any) => {
    return Axios.post('event/requestCancelEventEmail', data)
}

const getGuestDetails = (params: {}) => {
    return Axios.get(`getUserProfile${params ? '?' + qs.stringify(params) : ''}`);
}

const likeEventUserProfile = (data: any) => {
    return Axios.post('likeEventUserProfile', data)
}

const publishEvent = (data: any) => {
    return Axios.put('event/publish', data)
}

export const eventDetailService = {
    getHostEventDetails,
    getGuestEventDetails,
    getGuestList,
    updateEventJoiningStatus,
    updateEvent,
    deleteEvent,
    getGuestDetails,
    likeEventUserProfile,
    publishEvent
}
