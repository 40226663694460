import { AnyNaptrRecord } from 'dns'
import React from 'react'
import { Link } from 'react-router-dom'

interface props {
    icon?: any,
    link?: any,
    name: string,
    type?: any,
    classNames?: string,
    onClick?: () => void,
    darkMode?: boolean
}

const PrimaryButton = ({ icon, name, link, type, classNames, onClick, darkMode = false }: props) => {
  
  return (
    <button
    className={`${classNames} ${darkMode ? 'bg-third-orange text-primary-white border border-primary-orange ' :' bg-primary-orange text-primary-black'} text-[.875rem] font-medium w-fit rounded-[.875rem] ${icon && 'gap-3'}`} type={type} onClick={onClick}>
      {link ? (
        <Link to={link} className='flex items-center gap-2'>
        <span>{name}</span>
           {icon && <img
            src={icon}
            alt={""}
            width={20}
            height={20}
            className="cursor-pointer"
            />}
        </Link>) : (
          <div className='flex items-center gap-2 '>
            <span>{name}</span>
           {icon && <img
            src={icon}
            alt={""}
            width={20}
            height={20}
            className="cursor-pointer"
            />}
          </div>
        )}
  </button>
  )
}

export default PrimaryButton