import React, { useCallback, useEffect, useMemo, useState } from "react";
import Ticket from "../../../components/ticket";
import WhiteTicket from "../../../assets/Icons/payout/whiteTicket.svg";
import CustomInput from "../../../components/custom-input";
import PromoStar from "../../../assets/Icons/payout/PromCodeStar.svg";
import PrimaryButton from "../../../components/primaryButton";
import Offer from "../../../assets/Icons/payout/offer.svg";
import DoubleTick from "../../../assets/Icons/payout/doubleTick.svg";
import BuyNowIcon from "../../../assets/Icons/payout/BuyNow.svg";
import { DRAWERS, LOCAL_STORAGE } from "../../../utils/constants";
import TempEvent from '../../../assets/temp/eventImage.jpg';
import TicketGroup from '../../../assets/Icons/payout/tickets_group.svg';
import AppStore from '../../../assets/Icons/payout/appStore.svg';
import PlayStore from '../../../assets/Icons/payout/playStore.svg';
import { PayoutService } from "../../../services/payoutService";
import { useSpinner } from "../../../components/spinner/SpinnerContext";
import { getLocalStorage } from "../../../utils/localStorage";
import { toast } from "react-toastify";
import StripeProvider from "./stripeCheckout/StripeProvider";
import StripePaymentForm from "./stripeCheckout/StripePaymentForm";
import { decodeBase64, formatLocalDateForEndTime, formatLocalDateForStartTime } from "../../../utils/common";
import Modal from "../../../components/modal";
import { MOBILE_TICKET_IMAGE_URL } from "../../../assets/assets";

interface props {
  onCompChange?: (type: string) => void;
  selectedDrawer?: string;
  eventId: string,
  isHost?: boolean,
}

export default function Payout({
  onCompChange = () => { },
  selectedDrawer,
  eventId,
  isHost
}: props) {
  const [selectedTicket, setSelectedTicket] = useState<any>("");
  const [selectTktQuantity, setSelectTktQuantity] = useState<any>();
  const [promoCodeSearch, setPromoCodeSearch] = useState("");
  const [saveCard, setSaveCard] = useState(false);
  const [eventTicketTypes, setEventTicketTypes] = useState<Array<any>>();
  const [error, setError] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountCopy, setTotalAmountCopy] = useState(0);
  const [selectedPromo, setSelectedPromo] = useState<any>();
  const [purchaseTicket, setPurchaseTicket] = useState<any>();
  const [eventInfo, setEventInfo] = useState<any>();
  const [isPaidTicketAvailable, setIsPaidTicketAvailable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const userId = getLocalStorage(LOCAL_STORAGE.USER_ID);

  const { setShowSpinner } = useSpinner();

  const fetchTickets = useCallback(async () => {
    try {
      setShowSpinner(true);
      let params = {
        userId: userId,
        eventId: eventId,
      };
      const res = await PayoutService.getTicketsToBuy(params);
      if (res?.data) {

        if (res.data.events?.event_type !== 'Free Event') {
          res.data.eventTicketTypes.forEach((ticket: any) => {
            if (ticket.ticketPrice > 0) {
              setIsPaidTicketAvailable(true)
              return;
            }
          })
        }

        let ticketTypes = res.data.eventTicketTypes.map((e: any) => {
          if (!e.is_free && e.ticketPrice !== 0) {
            let basePrice = e.ticketPrice + 1;
            let handlingFeePercentage = (basePrice * 10) / 100;
            basePrice = (basePrice + handlingFeePercentage).toFixed(2);
            e.calculatePrice = basePrice;
          } else {
            e.calculatePrice = 0;
          }
          return e;
        });
        setEventInfo(res?.data?.events);
        setEventTicketTypes(ticketTypes);
        if (!isHost)
          checkTickets(ticketTypes);
      } else {
        setError("Record not found.");
      }
      setShowSpinner(false);
    } catch (error: any) {
      console.log(
        "error Fetch tickets : ",
        error?.response?.data?.error || error.message
      );
      setShowSpinner(false);
    }
  }, [userId, eventId]);

  function checkTickets(ticketTypes: any) {
    if (ticketTypes && Array.isArray(ticketTypes)) {
      for (let i = 0; i < ticketTypes.length; i++) {
        if (ticketTypes[i].availableTickets >= 1) {
          onTicketSelect(ticketTypes[i]);
          break;
        }
      }
    }
  }

  useEffect(() => {
    if (selectedDrawer === DRAWERS.BUY_TICKETS) {
      fetchTickets();
    }
  }, [selectedDrawer, fetchTickets]);


  const calculatePrice = useCallback((ticketDetails: any, ticketCount: any) => {
    let ticketPrice = ticketDetails?.ticketPrice;
    let otherFee = 1;
    let handlingFee = 10;
    if (!ticketDetails.is_free && ticketDetails?.ticketPrice !== 0) {
      let basePrice = ticketPrice + otherFee;
      let handlingFeePercentage = (basePrice * handlingFee) / 100;
      basePrice = basePrice + handlingFeePercentage;

      return +(basePrice * ticketCount).toFixed(2);
    } else {
      return 0;
    }
  }, []);

  const onPromoRemove = useCallback(() => {
    setPromoCodeSearch('');
    setSelectedPromo(null);
    calculateDiscountValues(0);
    const newTotalAmount = calculatePrice(selectedTicket, selectTktQuantity);
    setTotalAmount(newTotalAmount);
    setTotalAmountCopy(newTotalAmount);
  }, [selectedTicket, selectTktQuantity, calculatePrice, setPromoCodeSearch, setSelectedPromo, calculateDiscountValues, setTotalAmount, setTotalAmountCopy]);

  const memoizedTotalAmount = useMemo(() => {
    if (selectedTicket && selectTktQuantity > 0) {
      if (selectedPromo)
        onPromoRemove()
      return calculatePrice(selectedTicket, selectTktQuantity);
    }
    return 0;
  }, [selectedTicket, selectTktQuantity, calculatePrice]);

  useEffect(() => {
    setTotalAmount(memoizedTotalAmount);
    setTotalAmountCopy(memoizedTotalAmount);
  }, [memoizedTotalAmount, setTotalAmount, setTotalAmountCopy]);


  const onTicketSelect = (ticketDetails: any) => {
    setSelectedTicket(ticketDetails);
    setSelectTktQuantity(ticketDetails.MinorderQuantity);
  };

  const handlePasswordChange = (e: any) => {
    console.log(e.target.value)
    setPassword(e.target.value)
  }

  const handlePasswordSubmit = async () => {
    if (decodeBase64(selectedTicket?.password) === password) {
      setShowModal(false);
      await processPayment();
    } else {
      toast.error("Password is incorrect");
    }
  };


  const InitializePayment = async () => {
    try {
      // ... existing code ...

      if (selectedTicket?.is_password_protected) {
        setShowModal(true);
        return; // Exit the function early to wait for password input
      }

      // Continue with payment process if no password is required
      await processPayment();

    } catch (error: any) {
      // ... existing error handling ...
      console.log(error?.response?.data?.error || error.message)
    }
  };


  const processPayment = async () => {
    try {
      const payload = {
        ticketTypeId: +selectedTicket.ID,
        totalTicket: +selectTktQuantity,
        eventId: +selectedTicket.eventId,
        purchasedUserId: userId && +userId,
        promoCodeId: undefined,
      };
      if (selectedPromo?.promoCodeId !== 0) {
        payload["promoCodeId"] = selectedPromo?.promoCodeId;
      }


      const res = await PayoutService.purchaseTickets(payload);
      if (res?.data) {
        if (
          res?.data?.message === "The ticket has been successfully purchased."
        ) {
          toast.success(res?.data?.message);
          setPurchaseTicket(res?.data);
          await updatePaymentStatus(res?.data)
          onCompChange(DRAWERS.TICKET);
          window.location.reload();
          setShowSpinner(false);
          return;
        } else if (isPaidTicketAvailable) {
          if (+res?.data?.totalAmount === +totalAmount) {
            setPurchaseTicket(res?.data);
            onCompChange(DRAWERS.PAYMENT);
            setShowSpinner(false);
          } else {
            toast.error("Amount calculation error check later");
          }
        } else {
          toast.error("unable to create payment intent.");
        }
      }
      setShowSpinner(false);

    } catch (error: any) {
      console.log(
        "error Fetch tickets : ",
        error?.response?.data?.error || error.message
      );
      toast.error(error?.response?.data?.error);
      setShowSpinner(false);

    }
  }



  const OnPromoApply = async () => {
    try {
      setShowSpinner(true);
      const params = {
        eventId: selectedTicket?.eventId,
        promoCodeName: promoCodeSearch,
        totalTicket: selectTktQuantity,
        amount: selectedTicket?.ticketPrice,
        ticketTypeId: selectedTicket?.ID,
      };

      const res = await PayoutService.checkPromCode(params);
      if (res?.data) {
        setSelectedPromo(res?.data);
        setTotalAmount(+res?.data?.totalAmount);
        calculateDiscountValues(res?.data?.promoCodePercentage);
      } else {
        toast.error("unable to fetch promo.");
      }
      setShowSpinner(false);
    } catch (error: any) {
      console.log(
        "error Discover Event : ",
        error?.response?.data?.error || error.message
      );
      toast.error(error?.response?.data?.error);
      setShowSpinner(false);
    }
  };

  function calculateDiscountValues(promoCodePercentage: any) {
    if (promoCodePercentage !== 0) {
      const discountPrice = (totalAmountCopy * promoCodePercentage) / 100;
      setSelectedPromo((prevSelectedPromo: any) => ({
        ...(prevSelectedPromo || {}),
        discountPrice,
      }));
    }
  }



  const updatePaymentStatus = async (purchasedTicket: any) => {
    try {
      setShowSpinner(true);
      let startDate = formatLocalDateForStartTime(eventInfo?.startTime, eventInfo?.timezone)
      let endDate = formatLocalDateForEndTime(eventInfo?.endTime, eventInfo?.timezone)

      const payload = {
        url: window.location.href?.includes('share') ? window.location.href : `${window.location.href}&share=true`,
        purchaseId: +purchasedTicket?.purchaseId,
        payment_status: 'Paid',
        redeemLink: window.location.href?.includes('share') ? `${window.location.href}&purchaseid=${+purchasedTicket?.purchaseId}` : `${window.location.href}&purchaseid=${+purchasedTicket?.purchaseId}&share=true`,
        userId: userId && +userId,
        purchaseIntendId: purchasedTicket.purchaseIntendId,
        eventStartTime: startDate,
        eventEndTime: endDate,
        paymentIntend: isPaidTicketAvailable ? purchasedTicket.paymentIntend : ''

      }

      const res = await PayoutService.updatePaymentStatus(payload);
      if (res?.data) {
        onCompChange(DRAWERS.TICKET);
        window.location.reload();
      }

      setShowSpinner(false);
    } catch (error: any) {
      console.log(
        "error Fetch tickets : ",
        error?.response?.data?.error || error.message
      );
      toast.error(error?.response?.data?.error);
      setShowSpinner(false);
    }

  }

  return (
    <>
      {selectedDrawer == DRAWERS.BUY_TICKETS ? (
        selectedTicket || isHost ? <div className="relative flex flex-col gap-12 px-3 lg:px-4 2xl:px-20 overflow-y-auto overflow-hidden max-h-[70vh] md:max-h-[78vh] xl:max-h-[66vh] 2xl:max-h-[80vh] scrollableDiv">
          {/* tickets list */}
          <div className="flex flex-col justify-center gap-10 items-center">
            {eventTicketTypes?.map((item: any) => (
              <Ticket
                ticket={item}
                isSelected={item?.ID == selectedTicket?.ID}
                onSelect={() => !isHost ? onTicketSelect(item) : undefined}
                isHost={isHost}
              />
            ))}
          </div>

          {!isHost && <>
            {/* ticket quantity */}
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <img src={WhiteTicket} />
                <span className="text-text-gray">Ticket quantity</span>
              </div>
              <div className="mt-3 flex gap-1 flex-wrap gap-y-3">
                {Array(selectedTicket?.MaxorderQuantity <= selectedTicket?.availableTickets ? selectedTicket?.MaxorderQuantity : selectedTicket?.availableTickets)
                  .fill(1)
                  .map((item: any, idx: number) => (
                    <div
                      className={`${selectTktQuantity == idx + 1
                        ? "bg-primary-orange text-primary-black"
                        : "bg-input-box-gray text-text-gray"
                        } py-3.5 px-[2.1rem] rounded-2xl w-fit cursor-pointer ${selectedTicket.MinorderQuantity > idx + 1 && 'opacity-35'}`}
                      onClick={() => { if (selectedTicket.MinorderQuantity <= idx + 1) setSelectTktQuantity(idx + 1) }}
                    >
                      {idx + 1}
                    </div>
                  ))}
              </div>
            </div>

            {/* promo code */}
            <div className="flex flex-col gap-5">
              <div className="flex gap-2 items-center">
                <CustomInput
                  icon={PromoStar}
                  placeHolder="Promo code"
                  value={promoCodeSearch}
                  onChange={(e) => setPromoCodeSearch(e?.target?.value)}
                />
                <PrimaryButton name="Apply" classNames="py-4 px-4" onClick={OnPromoApply} />
              </div>
              {/* Promo card */}
              {selectedPromo && <div className="relative rounded-2xl bg-input-box-gray py-4 px-8 flex flex-col gap-5">
                <div className="flex justify-between items-center">
                  <div className="flex gap-2 items-center">
                    <img src={Offer} /> {selectedPromo?.promoCodePercentage}% off
                  </div>
                  <div className="text-2xl">-${selectedPromo?.discountPrice?.toFixed(2)}</div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-xs flex gap-2 items-center">
                    <img src={DoubleTick} />
                    Promo code applied
                  </div>
                  <div className="text-xs text-primary-red font-medium cursor-pointer" onClick={() => onPromoRemove()}>
                    Remove
                  </div>
                </div>

                <div className="absolute top-2 left-0 flex flex-col items-center gap-2">
                  <div
                    className={`w-3 h-5 bg-primary-black rounded-r-full`}
                  ></div>
                  <div
                    className={`w-3 h-6 bg-primary-black rounded-r-full`}
                  ></div>
                  <div
                    className={`w-3 h-5 bg-primary-black rounded-r-full`}
                  ></div>
                </div>

                <div className="absolute top-2 right-0 flex flex-col items-center gap-2">
                  <div
                    className={`w-3 h-5 bg-primary-black rounded-l-full`}
                  ></div>
                  <div
                    className={`w-3 h-6 bg-primary-black rounded-l-full`}
                  ></div>
                  <div
                    className={`w-3 h-5 bg-primary-black rounded-l-full`}
                  ></div>
                </div>
              </div>}
            </div>

            <div className=" w-full flex justify-between mt-12 pb-10 items-center">
              <div>
                <div>
                  <div>
                    <span className="text-text-gray text-sm font-bold">
                      {selectedTicket?.ticketName}
                    </span>{" "}
                    ${selectedTicket?.calculatePrice} <span className="text-text-gray">x</span>{selectTktQuantity}
                  </div>
                  <div className="font-bold text-2xl">${totalAmount?.toFixed(2)}</div>
                </div>
              </div>
              <PrimaryButton
                name="Continue"
                icon={BuyNowIcon}
                classNames="rounded px-2 py-4"
                onClick={InitializePayment}
              />
            </div>
          </>}
        </div> : <div>Tickets not added yet.</div>
      ) : selectedDrawer == DRAWERS.PAYMENT ? (
        <div className="relative flex flex-col gap-12 px-5 lg:px-10 2xl:px-24 overflow-y-auto overflow-hidden max-h-[79vh] md:max-h-[79vh] xl:max-h-[72vh] 2xl:max-h-[82vh] scrollableDiv">
          {purchaseTicket?.clientSecret && (
            <StripeProvider clientSecret={purchaseTicket?.clientSecret}>
              <StripePaymentForm amount={totalAmount} onCancel={() => onCompChange(DRAWERS.BUY_TICKETS)} onSuccess={() => updatePaymentStatus(purchaseTicket)} />
            </StripeProvider>
          )}
        </div>

      ) : (
        <div className="relative">
          <div
            className="mt-10 bg-cover bg-top inset-0 h-[25vh] rounded-full z-0"
            style={{ backgroundImage: `url(${TempEvent})`, filter: "blur(100px)" }}
          ></div>
          <div className="absolute -top-10 z-10 container text-primary-white flex flex-col items-center gap-8 overflow-y-auto overflow-hidden max-h-[77vh] md:max-h-[82vh] xl:max-h-[66vh] 2xl:max-h-[80vh] scrollableDiv">
            <div className="flex flex-col items-center gap-4">
              <img src={TicketGroup} />
              <span className="text-base">Tickets purchased successfully. </span>
            </div>
            <span className="text-3xl text-center font-bold">Download the app to view your ticket</span>
            <div className="flex items-center gap-6">
              <img src={AppStore} className="cursor-pointer" />
              <img src={PlayStore} className="cursor-pointer" />
            </div>
            <img src={MOBILE_TICKET_IMAGE_URL} className="sticky md:mt-16" />
          </div>
        </div>
      )}
      {showModal && <Modal onClose={() => setShowModal(false)} onSave={handlePasswordSubmit} className={'px-5'}>
        <span className="text-base font-bold text-primary-white">Ticket Password</span>
        <p className="text-sm text-text-gray my-4">This ticket is password-protected <br />Enter the password to continue.</p>
        <input type="text" placeholder="Enter the password" className="w-full p-2 rounded-md text-[#222222]" onChange={handlePasswordChange} />
      </Modal>}
    </>
  );
}
