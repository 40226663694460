import Axios from '../utils/axiosInstance';
import { LOCAL_STORAGE } from '../utils/constants';
import { getLocalStorage } from '../utils/localStorage';


const fetchRefundEvents = () => {
    const userId = getLocalStorage(LOCAL_STORAGE.USER_ID);
    return Axios.get(`listRefundRequestsEvents?pageNo=1&pageSize=20&hostUserId=${userId}`);
}
const fetchRefundRequests = (eventId: any) => {
    return Axios.get(`event/refundRequests?eventId=${eventId}`);
}

const updateRefundReqStatus = (data:any) => {
    return Axios.put('event/updateRefundRequestStatus', data);
}

const requestRefundEmail = (data: any) => {
    return Axios.post('event/requestRefundEmail', data);
}   

export const RefundService = {
    fetchRefundEvents,
    fetchRefundRequests,
    updateRefundReqStatus,
    requestRefundEmail
}