import React, { useEffect, useRef, useState } from 'react'
import CustomCheckbox from '../custom-checkbox'
import Modal from '../modal';
import Lock from '../../assets/Icons/lock/lock.svg'

interface props {
    isSelected?: boolean,
    onSelect?: (id: any) => void,
    ticket: any,
    isHost?: boolean
}

export default function Ticket({ isSelected = false, onSelect = () => { }, ticket, isHost }: props) {

    const [lineCount, setLineCount] = useState(0);
    const [showModal, setShowModal] = useState(false)

    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflow, setIsOverflow] = useState(false);
    const paragraphRef: any = useRef(null);

    useEffect(() => {
        if (paragraphRef.current) {
            const lineHeight = parseInt(window.getComputedStyle(paragraphRef.current).lineHeight);
            const lines = paragraphRef.current.scrollHeight / lineHeight;

            // Check if lines exceed 5
            setIsOverflow(lines > 4);
        }
    }, [ticket?.description]);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const onTicketClick = () => {
        onSelect(ticket?.ID);

    }

    return (
        <>
            <div className={`w-full overflow-hidden  ${(ticket?.availableTickets >= 1 || isHost) ? 'cursor-pointer' : 'hidden'}`} onClick={() => ticket?.availableTickets >= 1 ? onTicketClick() : undefined} >
                <div className={` h-36 bg-input-box-gray rounded-xl p-4 flex flex-col justify-between relative ${isSelected && 'border border-primary-orange'}`}>
                    <div className='flex justify-between'>
                        <div className='flex items-center gap-2'>
                            <span>{ticket.ticketName.charAt(0).toUpperCase()}{ticket.ticketName.slice(1)}</span>
                            {ticket?.is_password_protected && <img src={Lock} alt='lock' width={14} height={14} />}
                            {isHost && <div className="bg-[#161718] text-primary-white py-1.5 px-2 rounded-md text-xs self-center">
                                {ticket?.availableTickets || 0} available
                            </div>}
                        </div>
                        <span className='text-4xl flex items-center'> <span className='text-2xl'>$</span>{ticket?.calculatePrice}</span>
                    </div>

                    <div className='flex justify-between items-center'>
                        <div>
                            <span
                                ref={paragraphRef}
                                className={`text-xs text-text-gray ${!isExpanded ? 'line-clamp-5' : ''}`}
                                style={{ display: '-webkit-box', WebkitLineClamp: isExpanded ? 'unset' : 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}
                            >
                                {ticket?.description}
                            </span>
                            {isOverflow && (
                                <div className="text-blue-500 text-xs" onClick={() => setShowModal(true)}>
                                    {!isExpanded && '...see more'}
                                </div>
                            )}
                        </div>
                        <CustomCheckbox selected={isSelected} />
                    </div>
                    <div className="absolute bottom-4 text-opacity-5 text-7xl font-bold text-[#F1F1F1] overflow-hidden">{ticket.ticketName.charAt(0).toUpperCase()}{ticket.ticketName.slice(1)}</div>
                    <div className={`absolute -bottom-1 left-[55%] w-8 h-4 bg-primary-black rounded-t-full ${isSelected && 'border-t border-primary-orange'}`}></div>
                    <div className={`absolute -top-1 left-[55%] w-8 h-4 bg-primary-black rounded-b-full ${isSelected && 'border-b border-primary-orange'}`}></div>
                </div>
            </div>
            {showModal && <Modal onOkClick={() => { onSelect(ticket?.ID); setShowModal(false) }} showButtons={false} className={'px-5'} scroll='overflow-y-auto scrollableDiv max-h-60 my-4'>
                <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                    {ticket?.description}
                </div>
            </Modal>}
        </>
    )
}
