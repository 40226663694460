import React, { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import TagIcon from "../../assets/Icons/refine/tag.svg";
import CancelIcon from "../../assets/Icons/refine/cancel.svg";
import RangeSlider from "../rangeSlider";

type props = {
  onChange?: (values:any) => void;
};

export default function Filter({ onChange = () => { } }: props) {
  const [range, setRange] = useState<Array<number>>([0, 1000]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState([0, 1000]);

  const divRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const OnRangeChange = (value: [number, number]) => {
    setRange(value);
  };

  return (
    <div  ref={divRef}>
      <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center">
        <img
          src={TagIcon}
          className="p-2 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        />
        <div className="bg-primary-black flex rounded-lg p-2 gap-2">
          <span
            className="cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            {selectedRange[0] === 0 ? "Free" : `$${selectedRange[0]}`} &nbsp; -
            &nbsp;${selectedRange[1]}
          </span>
          <img
            src={CancelIcon}
            className="cursor-pointer"
            onClick={() => {
              setSelectedRange([0, 1000]);
              onChange([]);
            }}
          />
        </div>
      </div>
      {isOpen && (
        <div className="px-5 pt-2 pb-6 bg-[#353535] rounded-[1.2rem] absolute mt-2" >
            <div className="flex justify-end items-center gap-6  text-primary-orange">
            <span onClick={() => {
                 setSelectedRange(range);
                 onChange(range);
                 setIsOpen(false);
          }} className="cursor-pointer">Apply</span>
          <img
            src={CancelIcon}
            className="cursor-pointer"
            width={10}
            height={10}
            onClick={() => {
              setIsOpen(false);
            }}
          />
          </div>
          <h3 className="text-primary-white text-[1.25rem]">Price</h3>

          <div className="flex gap-3 my-3">
            <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center w-full">
              <img src={TagIcon} className="p-2 cursor-pointer" />
              <div className="bg-primary-black flex rounded-lg p-2 w-28">
                <span>{range[0] === 0 ? "Free" : `$${range[0]}`}</span>
              </div>
            </div>
            <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center w-full">
              <img src={TagIcon} className="p-2 cursor-pointer" />
              <div className="bg-primary-black flex rounded-lg p-2 w-28">
                <span>${range[1]}</span>
              </div>
            </div>
          </div>
          <RangeSlider onSliderChange={OnRangeChange} value={range}/>
        </div>
      )}
    </div>
  );
}
