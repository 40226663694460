const FAQ_TYPES = {
    HOST: 'host',
    GENERAL: 'Guest'
}

const SORT_BY = {
    PRICE_HIGH_TO_LOW: 'Price high to low',
    PRICE_LOW_TO_HIGH: 'Price low to high',
    NEWEST_DATE_FIRST: "Newest date first",
    OLDEST_DATE_FIRST: 'Oldest date first',
}

const GENDER = {
    MEN: 'Men',
    WOMEN: 'Women',
    NON_BINARY: 'Non-Binary'
}

const CONNECTION_PREFERENCE = {
    OPEN_TO_ANYTHING: 'Open to anything',
    FRIENDSHIP: 'Friendship',
    DATING: 'Dating'
}

const DATE_FILTER = {
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    THIS_WEEKEND: 'This Weekend'
}
  
const REVENUE_DATE_FILTER = {
    TODAY: 'Today',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month'
  }

const CONNECTION_STATUS = {
    DATING: 'Heart',
    OPEN_TO_ANYTHING: 'Open',
    FRIENDSHIP: 'Handshake'
}

const MY_EVENTS_TYPES = {
    UPCOMING: 'Upcoming',
    INVITED: 'Invited',
    ATTENDED: 'Attended',
    // ALL_PAST: 'All Past'
}

const HOST_CENTER_TYPES = {
    UPCOMING: 'Upcoming',
    PAST: 'Past',
    DRAFT: 'Drafts'
}

const EVENT_ATTENDING_ANSWER = {
    YES: 'Yes',
    MAYBE: 'Maybe',
    NO: 'No'
}
const EVENT_ATTENDING_STATUS = {
    GOING: 'Going',
    MAYBE: 'Maybe',
    NOT_GOING: 'Not Going'
}

const DRAWERS = {
    GUEST_LIST: 'Guest list',
    GROUP_CHAT: 'Group chat',
    BUY_TICKETS: 'Buy tickets',
    PAYMENT: 'Payment',
    TICKET: 'Ticket',
    USER_TICKETS: 'user tickets',
    CONNECTION: 'Connection'
}

const GUEST_LIST_TABS = {
    INVITED: 'Invited',
    GOING: 'Going',
    MAYBE: 'Maybe'
}

const LOCAL_STORAGE = {
    USER_TOKEN: 'user_token',
    USER_MOBILE_NUMBER: 'userMobileNumber',
    USER_ID: 'userID',
    SHOW_DETAIL_PAGE: 'showDetailPage',
    CONTACT_LIST: 'contactList'

}

const EVENT_SHARE_TYPE = {
    SHARE: 'Share',
    REDEEM: 'Redeem'
}

const API_VERSION = {
    V1: 'v1',
    V2: 'v2'
}

export {
    FAQ_TYPES,
    SORT_BY,
    DATE_FILTER,
    MY_EVENTS_TYPES,
    HOST_CENTER_TYPES,
    EVENT_ATTENDING_ANSWER,
    GUEST_LIST_TABS,
    LOCAL_STORAGE,
    EVENT_SHARE_TYPE,
    EVENT_ATTENDING_STATUS,
    API_VERSION,
    DRAWERS,
    GENDER,
    CONNECTION_PREFERENCE,
    CONNECTION_STATUS,
    REVENUE_DATE_FILTER
}
