import React, { useEffect } from "react";
import styles from "./SafetyNotice.module.scss";
export default function SafetyNotice() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsHeader}>
        <h1>SONDR, INC.</h1>
        <h1>IMPORTANT SAFETY NOTIFICATIONS – PLEASE READ</h1>
      </div>
      <div className={styles.termsContent}>
        <p>
          If you are a resident of one of the following states and are using our
          Socha™ mobile apps, online platform or services, we are required to
          provide you with the following safety awareness notifications with
          respect to online dating practices. We encourage all of our members to
          read and observe the following safety measures regardless of where
          they reside.
        </p>
        <ol>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>Connecticut </b>
            </p>
            <p>
              There is no substitute for acting with caution when communicating
              with any stranger who wants to meet you.
            </p>
            <p>
              Never include your last name, electronic mail address, home
              address, telephone number, place of work or any other identifying
              information in your Internet profile or initial electronic mail
              messages. Stop communicating with anyone who pressures you for
              personal or financial information or attempts in any way to trick
              you into revealing it.
            </p>
            <p>
              If you choose to have a face-to-face meeting with another member,
              always tell someone in your family or a friend where you are going
              and when you will return. Never agree to be picked up at your
              home. Always provide your own transportation to and from your date
              and meet in a public place with many people around.
            </p>
            <p>
              <b>SONDR DOES NOT CONDUCT CRIMINAL BACKGROUND SCREENINGS.</b>
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>Illinois</b>
            </p>
            <p>
              Anyone who is able to commit identity theft can also falsify a
              dating profile, a child care profile, a senior care profile, or a
              home care profile."
            </p>
            <p>
              There is no substitute for acting with caution when communicating
              with any stranger who wants to meet you.
            </p>
            <p>
              Never include your last name, electronic mail address, home
              address, telephone number, place of work or any other identifying
              information in your Internet profile or initial electronic mail
              messages. Stop communicating with anyone who pressures you for
              personal or financial information or attempts in any way to trick
              you into revealing it.
            </p>
            <p>
              If you choose to have a face-to-face meeting with another member,
              always tell someone in your family or a friend where you are going
              and when you will return. Never agree to be picked up at your
              home. Always provide your own transportation to and from your date
              and meet in a public place with many people around.
            </p>
            <p>
              <b>SONDR DOES NOT CONDUCT CRIMINAL BACKGROUND SCREENINGS.</b>
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>New Jersey</b>
            </p>
            <p>
              Anyone who is able to commit identity theft can also falsify a
              dating profile.
            </p>
            <p>
              There is no substitute for acting with caution when communicating
              with any stranger who wants to meet you.
            </p>
            <p>
              Never include your last name, electronic mail address, home
              address, telephone number, place of work or any other identifying
              information in your Internet profile or initial electronic mail
              messages. Stop communicating with anyone who pressures you for
              personal or financial information or attempts in any way to trick
              you into revealing it.
            </p>
            <p>
              If you choose to have a face-to-face meeting with another member,
              always tell someone in your family or a friend where you are going
              and when you will return. Never agree to be picked up at your
              home. Always provide your own transportation to and from your date
              and meet in a public place with many people around.
            </p>
            <p>
              <b>SONDR DOES NOT CONDUCT CRIMINAL BACKGROUND SCREENINGS.</b>
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>New York</b>
            </p>
            <p>
              There is no substitute for acting with caution when communicating
              with any stranger who wants to meet you.
            </p>
            <p>
              Never include your last name, electronic mail address, home
              address, telephone number, place of work or any other identifying
              information in your Internet profile or initial electronic mail
              messages. Stop communicating with anyone who pressures you for
              personal or financial information or attempts in any way to trick
              you into revealing it.
            </p>
            <p>
              If you choose to have a face-to-face meeting with another member,
              always tell someone in your family or a friend where you are going
              and when you will return. Never agree to be picked up at your
              home. Always provide your own transportation to and from your date
              and meet in a public place with many people around.
            </p>
          </li>
          <li>
            <p>
              <b style={{ textDecoration: "underline" }}>Texas</b>
            </p>
            <p>
              <b>SONDR DOES NOT CONDUCT CRIMINAL BACKGROUND SCREENINGS.</b>
            </p>
            <p>
              Anyone who is able to commit identity theft can also falsify a
              dating profile.
            </p>
            <p>
              There is no substitute for acting with caution when communicating
              with any stranger who wants to meet you.
            </p>
            <p>
              Never include your last name, electronic mail address, home
              address, telephone number, place of work or any other identifying
              information in your Internet profile or initial electronic mail
              messages. Stop communicating with anyone who pressures you for
              personal or financial information or attempts in any way to trick
              you into revealing it.
            </p>
            <p>
              If you choose to have a face-to-face meeting with another member,
              always tell someone in your family or a friend where you are going
              and when you will return. Never agree to be picked up at your
              home. Always provide your own transportation to and from your date
              and meet in a public place with many people around.
            </p>
          </li>
        </ol>
      </div>
      <div className={styles.termsFooter}>*****</div>
    </div>
  );
}
