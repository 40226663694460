import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/login/logo.png";
import DiscoverOff from "../../assets/Icons/header/discover-off.svg";
import EventsOff from "../../assets/Icons/header/events-off.svg";
import HostOff from "../../assets/Icons/header/host-off.svg";
import DiscoverOn from "../../assets/Icons/header/discover-on.svg";
import EventsOn from "../../assets/Icons/header/events-on.svg";
import HostOn from "../../assets/Icons/header/host-on.svg";
import Setting from "../../assets/Icons/header/setting.svg";
import Menu from "../../assets/Icons/header/menu.svg";
import Drawer from "../../components/bottomDrawer";
import { UserService } from "../../services/userServices";
import { getLocalStorage } from "../../utils/localStorage";
import { LOCAL_STORAGE } from "../../utils/constants";
import RightSideDrawer from "../../components/rightDrawer";
import { GeneralService } from "../../services/generalService";
import { toast } from "react-toastify";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import { getMonthAndDay, getTime } from "../../utils/common";
import ProfileImgPopup from "../../components/profileImgPopup";
import UserProfile from '../../assets/Icons/header/user.png'
import Modal from "../../components/modal";
import { AuthService } from "../../services/authService";
import HostCenterMenu from "../../components/hostCenterMenu";
import { PayoutService } from "../../services/payoutService";
import PrimaryButton from "../../components/primaryButton";
import Girl from '../../assets/Images/connection.png'
import ProfileMenu from "../../components/profileMenu";
import LogoutImage from '../../assets/Images/logout/logoutImage.svg'
import { PHONE_2_IMAGE_URL } from "../../assets/assets";
import AppStore from "../../assets/Icons/payout/appStore.svg";
import PlayStore from "../../assets/Icons/payout/playStore.svg";
import CLOSE_ICON from '../../assets/Icons/nav/x.png';

export default function HomeLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userDetails, setUserDetails] = useState<any>('');
  const [showNotifications, setShowNotifications] = useState(false);
  const [showPayoutDrawer, setShowPayoutDrawer] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [payoutRecords, setPayoutRecords] = useState([]);
  const userId = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [showProfileUpload, setShowProfileUpload] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const { setShowSpinner } = useSpinner();
  const [showMenu, setShowMenu] = useState(false);
  const [msgType, setMsgType] = useState('preferences')
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 0.2) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    if (userId)
      getUserProfileDetails();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showNotifications)
      getAllNotifications()
    if (showPayoutDrawer)
      getPayoutRecords()
  }, [showNotifications, showPayoutDrawer])

  const getUserProfileDetails = async () => {
    try {
      const res = await UserService.getUserProfileDetails();
      if (res?.data) {
        setUserDetails(res?.data);
      }
    } catch (error: any) {
      console.log("error getGuestEventDtls : ", error?.response?.data?.error || error?.message);
    }
  }

  const getPayoutRecords = async () => {
    try {
      const res = await PayoutService.payoutRecords({ userId, pageNo: 1, pageSize: 10 });
      if (res?.data) {
        setPayoutRecords(res?.data);
      }
    } catch (error: any) {
      console.log("error getPayoutRecords : ", error?.response?.data?.error || error?.message);
    }
  }

  const requestPayout = async (eventId: string) => {
    try {
      setShowSpinner(true);
      const res = await PayoutService.requestPayoutEmail({ eventId });
      if (res?.data) {
        setPayoutRecords(res?.data);
      }
      getPayoutRecords();
      setShowSpinner(false);
    } catch (error: any) {
      console.log("error requestPayout : ", error?.response?.data?.error || error?.message);
    }
    setShowSpinner(false);
  }

  const getAllNotifications = async () => {
    try {
      setShowSpinner(true);
      const res = await GeneralService.getAllNotifications({ pageNo: 1, pageSize: 50 });
      if (res?.data) {
        setAllNotifications(res?.data?.notifications);
      }
      setShowSpinner(false);
    } catch (error: any) {
      console.log(
        "error Fetch tickets : ",
        error?.response?.data?.error || error.message
      );
      toast.error(error?.response?.data?.error);
      setShowSpinner(false);
    }
  }

  const logout = () => {
    AuthService.logout();
    setTimeout(() => {
      setShowSpinner(false);
      navigate("/auth");
    }, 300);
  }

  const handleOpen = (tab: string) => {
    if (tab == 'host events') {
      navigate("/host-center?tab=Upcoming");
    } else if (tab == 'refund requests') {
      navigate("/refund-requests");
    } else if (tab == 'request payout') {
      setShowPayoutDrawer(true)
    }
    setIsOpen(false);
    setShowMobileNavigation(false);
  }

  const handleModalClick = (tab: string) => {
    if (tab == 'preference') {
      setMsgType('preference')
      console.log(msgType, "---157")
      setShowMenu(true)
    } else if (tab == 'connections') {
      setMsgType('connections')
      setShowMenu(true)
    }
  }



  return (
    <div>
      {location.pathname !== "/" && (
        <div
          id="header"
          className={`h-[15vh] 2xl:h-[7vh] z-50 flex justify-between items-center text-primary-gray py-6 px-6 lg:px-8 backdrop-blur-lg
          ${isHeaderFixed && !location.pathname.includes("event-details") && "fixed w-full top-0"
            }
          ${location.pathname.includes("event-details") && "fixed w-full bg-[#000000] bg-opacity-75 top-0"
            }
          `}
        >
          <img src={Logo} className="w-24 " />

          {!location.pathname.includes("create-event") && <div className="hidden lg:flex lg:gap-7">
            <div
              className="flex gap-2 cursor-pointer items-center"
              onClick={() => navigate("/discover")}
            >
              <img
                src={
                  location.pathname.includes("discover")
                    ? DiscoverOn
                    : DiscoverOff
                }
              />
              <span
                className={
                  location.pathname.includes("discover")
                    ? "text-primary-white"
                    : ""
                }
              >
                Discover
              </span>
            </div>
            <div
              className="flex gap-2 cursor-pointer items-center"
              onClick={() => navigate("/events?tab=Upcoming")}
            >
              <img
                src={
                  location.pathname.includes("events") ? EventsOn : EventsOff
                }
              />
              <span
                className={
                  location.pathname.includes("events")
                    ? "text-primary-white"
                    : ""
                }
              >
                {" "}
                Invited Events{" "}
              </span>
            </div>
            <HostCenterMenu onPayoutClick={() => setShowPayoutDrawer(true)} />
          </div>}

          <div className="flex gap-6">
            <img
              src={Setting}
              className=" cursor-pointer"
              onClick={() => setShowNotifications(true)}
            />
            <img
              src={Menu}
              className=" cursor-pointer lg:hidden"
              onClick={() => setIsOpen(true)}
            />
            <ProfileMenu profileImage={userDetails?.profilePhoto ? userDetails?.profilePhoto : UserProfile} onLogoutClick={() => setShowLogout(true)} onPefClick={handleModalClick} />
          </div>
        </div>
      )}
      <Outlet context={{ setShowPayoutDrawer }} />
      <Drawer
        open={isOpen}
        showBottomMenu={false}
        onClose={() => setIsOpen(false)}
      >
        <div className="text-primary-gray flex flex-col gap-5 my-4 mx-24">
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              navigate("/discover");
              setIsOpen(false);
            }}
          >
            <img
              src={
                location.pathname.includes("discover")
                  ? DiscoverOn
                  : DiscoverOff
              }
            />
            <span
              className={
                location.pathname.includes("discover")
                  ? "text-primary-white"
                  : ""
              }
            >
              Discover
            </span>
          </div>
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              navigate("/events?tab=Upcoming");
              setIsOpen(false);
            }}
          >
            <img
              src={location.pathname.includes("events") ? EventsOn : EventsOff}
            />
            <span
              className={
                location.pathname.includes("events") ? "text-primary-white" : ""
              }
            >
              {" "}
              Invited Events{" "}
            </span>
          </div>
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => {
              setShowMobileNavigation(true);
            }}
          >
            <img
              src={location.pathname.includes("host-center") ? HostOn : HostOff}
            />
            <span
              className={
                location.pathname.includes("host-center")
                  ? "text-primary-white"
                  : ""
              }
            >
              Host Center
            </span>
          </div>
        </div>
      </Drawer>
      <RightSideDrawer
        open={showNotifications}
        onClose={() => setShowNotifications(false)}
        title={"Notification"}
        showTitle={true}
        showCloseIcon={true}
      >
        <div className="text-primary-white flex flex-col gap-4 overflow-y-auto max-h-[63vh] md:max-h-[78vh] xl:max-h-[66vh] scrollableDiv">
          {allNotifications?.map((item: any) =>
            <div className="flex items-center gap-4 mx-5">
              <img src={item?.senderProfilePhoto || item?.bannerImage} className="h-7 w-7 rounded-full object-cover cursor-pointer" />
              <div className="flex flex-col gap-1">
                <span className="text-sm font-bold">{item?.message}</span>
                <span className=" text-text-gray text-sm">
                  {getMonthAndDay(item?.notificationTime) + " " + getTime(item?.notificationTime)}
                </span>
              </div>
            </div>
          )}
        </div>
      </RightSideDrawer>
      {showProfileUpload && <ProfileImgPopup onCancel={() => setShowProfileUpload(false)} onUpload={() => { setShowProfileUpload(false); getUserProfileDetails() }} />}
      {showLogout && <Modal onSave={() => logout()} onClose={() => setShowLogout(false)} saveTitle="Log out" bgPopup="bg-[#181818]" >
        <div className="flex flex-col items-center md:mx-28 px-5 w-full md:w-[24vw] text-center">
          <img src={LogoutImage} alt="logout" />
          <span className="text-2xl">Are you sure you want to log out?</span>
        </div>
      </Modal>}
      <RightSideDrawer open={showPayoutDrawer} onClose={() => setShowPayoutDrawer(false)}>
        <div className="overflow-y-auto max-h-[63vh] md:max-h-[78vh] xl:max-h-[66vh] scrollableDiv">
          <span className="text-primary-white text-2xl mx-5">Payout Summary by Events</span>
          <br /> <br />
          <p className="text-text-gray text-xs italic mx-5">Events won't be displayed here until they have ended. <br /> <br />
            After you request a payout, Socha will approve and initiate the transfer. It may take approximately 3 business days for the funds to be deposited into your bank account.
          </p>
          {payoutRecords?.map((item: any) => <div className="bg-input-box-gray p-4 rounded-lg my-8 mx-5">
            <span className="text-primary-white text-lg">{item?.name}</span>
            <div className="flex justify-between items-center my-4">
              <span className="text-primary-white text-sm bg-[#363636] p-2 rounded-lg">Revenue: ${item?.revenue}</span>
              {item?.requested_payout_email === "0" ? <PrimaryButton name="Request Payout" type="button" classNames="px-2 py-2" onClick={() => requestPayout(item?.eventId)} /> : ""}
            </div>
            {item?.requested_payout_email === "1" && <span className="text-text-gray text-xs">Payout request submitted</span>}
          </div>)}
        </div>
      </RightSideDrawer>
      {showMobileNavigation && <Modal showButtons={false} onOkClick={() => setShowMobileNavigation(false)}>
        <div className="flex flex-col items-start -mb-6">
          <span className="hover:bg-black p-2 w-full cursor-pointer rounded-xl" onClick={() => handleOpen('host events')}>Host events</span>
          <span className="hover:bg-black p-2 w-full cursor-pointer rounded-xl" onClick={() => handleOpen('refund requests')}>Refund requests</span>
          <span className="hover:bg-black p-2 w-full cursor-pointer rounded-xl" onClick={() => handleOpen('request payout')}>Request payout</span>
        </div>
      </Modal>}
      {showMenu && <Modal showButtons={false} bgPopup="bg-[#181818]" showOK={false}>
        <div className="w-full md:w-[35vw] flex flex-col gap-2 h-60 ">
          <div className="flex justify-between items-center">
            <img src={Logo} className="w-14 h-7" />
            <img src={CLOSE_ICON} className="w-5 h-5 cursor-pointer" onClick={() => setShowMenu(false)} />
          </div>
          <div className="flex flex-col md:flex-row text-primary-white h-full bg-[#181818] justify-between  items-center rounded-lg">
            <div className="relative flex flex-col items-start gap-8 z-20">
              <span className="text-xl font-bold text-start">
                {msgType == 'preference' ? "Download the socha app to update your connection preferences and more!" : "Please download the Socha App to view your Connections and interact with other guests. "}
              </span>
              <div className="flex items-center gap-6">
                <a href="https://apps.apple.com/in/app/socha-social/id6444107828" target="_blank"><img src={AppStore} alt="app store" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.app.socha&pcampaignid=web_share" target="_blank"><img src={PlayStore} alt="google play" /></a>
              </div>
            </div>
            <img src={PHONE_2_IMAGE_URL} className="hidden md:inline relative h-64 w-36 z-10" />
          </div>
          <div className="bottom-1/4 right-1/4 absolute h-64 w-64 rounded-full bg-orange-400 bg-opacity-20 blur-2xl z-10"></div>
        </div>
      </Modal>}
    </div>
  );
}
