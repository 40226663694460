import React, { ChangeEvent, useEffect, useState } from 'react'
import EventImage from '../../../assets/Images/createEvent/eventImageUpload.svg'
import PrimaryButton from '../../../components/primaryButton'
import ForwardButton from '../../../assets/Icons/forward/forward.svg'
import { createSearchParams, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { CreateEvent } from '../../../services/createEventService';
import { toast } from "react-toastify";
import { useSpinner } from '../../../components/spinner/SpinnerContext'
import ImageCropper from '../../../components/ImageCropper/ImageCropper';




function EventInfo() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [eventData, setEventData] = useState<any>()
  const { updateBannerImage }: any = useOutletContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId: any = queryParams.get("eventid");
  const { setShowSpinner } = useSpinner();
  const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false);


  useEffect(() => {
    fetchEvents()

  }, [])

  useEffect(() => {
    if (eventData) {
      setImageURL(eventData?.bannerImage)
    }
  }, [eventData])

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsCropperOpen(true);
    if (file) {
      const url = URL.createObjectURL(file)
      setImageURL(url)
      updateBannerImage(url);

    }

    // Generate a preview of the selected file
    const reader = new FileReader();
    reader.readAsDataURL(file);
  };




  const fetchEvents = async () => {
    try {
      const res = await CreateEvent.fetchEventById({ id: eventId })
      if (res?.data) {
        setEventData(res?.data?.event)
      }
    } catch (error) {
      console.log(error, 'Error in fetching events')
    }
  }

  const handleUpload = async (croppedImage: string) => {
    try {
      const croppedImageBlob = await fetch(croppedImage).then(res => res.blob());
      const formData = new FormData();
      const filename = new Date().toISOString();
      formData.append('bannerImage', croppedImageBlob, filename);
      formData.append('id', eventId);

      const response = await CreateEvent.addBannerImage(formData)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result?.data) {
        console.log(result.data.message);
        navigate({
          pathname: "/create-event/tickets&promo",
          search: createSearchParams({
            eventid: eventId
          }).toString()
        });
        setShowSpinner(false)
      }

    } catch (error) {
      setShowSpinner(false)
      console.log(error);
    }
  }


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setShowSpinner(true)
    navigate({
      pathname: "/create-event/tickets&promo",
      search: createSearchParams({
        eventid: eventId
      }).toString()
    });
    setShowSpinner(false)
  }
  // const handleSubmit = async (event: any) => {
  //   try {
  //     event.preventDefault();
  //     setShowSpinner(true)
  //     if (!selectedFile) {
  //       console.log('Please select a file first!');
  //       navigate({
  //         pathname: "/create-event/tickets&promo",
  //         search: createSearchParams({
  //           eventid: eventId
  //         }).toString()
  //       });
  //       return;
  //     }

  //     let filename = new Date().toISOString();
  //     const formData = new FormData();

  //     formData.append('bannerImage', selectedFile, filename);
  //     formData.append('id', eventId);


  //     const response = await CreateEvent.addBannerImage(formData)
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const result = await response.json();
  //     if (result?.data) {
  //       console.log(result.data.message);
  //       navigate({
  //         pathname: "/create-event/tickets&promo",
  //         search: createSearchParams({
  //           eventid: eventId
  //         }).toString()
  //       });
  //       setShowSpinner(false)
  //     }

  //   } catch (error) {
  //     setShowSpinner(false)
  //     console.log(error);
  //   }
  // };

  const handleCloseCropper = () => {
    setImageURL('')
    updateBannerImage('')
    setIsCropperOpen(false)
  }


  return (
    <form onSubmit={handleSubmit}>
      <div className='w-full bg-input-box-gray border-0 rounded-2xl flex xs:flex-col lg:flex-row justify-center lg:justify-around items-center pt-10 pb-6'>
        <img src={imageURL ? imageURL : EventImage} className='w-[251px] h-[251px]' />
        <input type='file' className="hidden" id="fileInput" accept="image/jpeg, image/png" onChange={handleFileChange} />
        <label htmlFor='fileInput' className='cursor-pointer text-sm text-primary-white border-2 rounded-xl border-primary-orange bg-third-orange p-2'>Choose Event Banner</label>
        {isCropperOpen && (
          <div className='fixed inset-0 flex justify-center items-center z-50'>
            <div className='bg-white p-4 rounded shadow-lg'>
              <ImageCropper
                imageFile={selectedFile}
                onClose={handleCloseCropper}
                onCropComplete={(croppedImage) => {
                  console.log(croppedImage, 'croppedImage');
                  setImageURL(croppedImage);
                  updateBannerImage(croppedImage);
                  setIsCropperOpen(false);
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className='border-t border-text-gray my-8'></div>

      <div className='flex place-content-between items-center my-8'>
        <PrimaryButton name='Previous' type='button' classNames='px-2 py-3' onClick={() => navigate({
          pathname: "/create-event/basic-details",
          search: createSearchParams({
            eventid: eventId
          }).toString()
        })} />
        <PrimaryButton name='Save & Next' icon={ForwardButton} type='submit' classNames='px-2 py-3' onClick={() => handleUpload(imageURL)} />
      </div>
    </form>
  )
}

export default EventInfo