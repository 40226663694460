import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage } from '../utils/localStorage';
import { LOCAL_STORAGE } from '../utils/constants';

type ProtectedRouteProps = PropsWithChildren<{}>;

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = getLocalStorage(LOCAL_STORAGE.USER_TOKEN);
    if (!token) {
      navigate('/auth', { replace: true });
    }
  }, [navigate]);

  return <>{children}</>;
}
