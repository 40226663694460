

import moment from 'moment-timezone';


function convertToUtcDateTime(dateString: string, timeString: string, timeZone: string) {
  // Ensure moment-timezone is loaded
  console.log(dateString, timeString, timeZone, "--------9");

  // Remove the GMT part from the date string if it exists
  const cleanDateString = String(dateString).replace(/GMT.*$/, '').trim();

  // Parse the date string using moment with the provided timeZone
  const localDate = moment.tz(cleanDateString, 'ddd MMM DD YYYY HH:mm:ss', timeZone);

  // Parse the time string in 12-hour format with AM/PM
  const timeParts = moment(timeString, 'hh:mm A');

  // Apply the parsed time to the date
  localDate.hours(timeParts.hours()).minutes(timeParts.minutes()).seconds(timeParts.seconds());

  // Convert the local time to UTC
  const utcDateTime = localDate.utc();

  // Format the UTC date time
  const formattedUtcDateTime = utcDateTime.format('YYYY-MM-DD HH:mm:ss');
  console.log(formattedUtcDateTime, "-----bitch");

  return formattedUtcDateTime;
}

function getCurrentDateTimeInUtc(timeZone: string) {
  // Get the current date and time in the specified timezone
  const localDateTime = moment.tz(timeZone);

  // Convert the local date and time to a UTC moment object
  const utcDateTime = localDateTime.clone().utc();

  // Format the UTC date time
  const formattedUtcDateTime = utcDateTime.format('YYYY-MM-DD HH:mm:ss');

  return formattedUtcDateTime;
}

function validatePhoneNumber(phoneNumber: string) {
  const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '');

  const phoneNumberPattern = /^\d+$/;
  return phoneNumberPattern.test(sanitizedPhoneNumber);
}

function convertUtcToLocal(utcTime: string, timeZone: string) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  return new Date(utcTime).toLocaleString('en-US', options);
}


const getDayOfWeek = (inputDateStr: string, format = 'short') => {
  const inputDate = new Date(inputDateStr);
  const daysOfWeekShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysOfWeekLong = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  if (format === 'short') {
    return daysOfWeekShort[inputDate.getDay()];
  } else {
    return daysOfWeekLong[inputDate.getDay()];
  }
};

const getMonthAndDay = (inputDateStr: string) => {
  const inputDate = new Date(inputDateStr);
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = months[inputDate.getMonth()];
  const day = inputDate.getDate();
  return `${month} ${day}`;
};

const getTime = (inputDateStr: string) => {
  const inputDate = new Date(inputDateStr);
  let hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
};


function formatLocalDateForEndTime(originalDate: any, timezone: string) {

  let formattedDate = moment(originalDate).tz(timezone).format('MMM D, YYYY');
  let formattedTime = moment(originalDate).tz(timezone).format('hh:mm A');

  let combined = formattedDate + ' at ' + formattedTime + ' ' + timezone + ' ' + 'Time zone';
  return combined
}

function formatLocalDateForStartTime(originalDate: any, timezone: string) {
  let formattedDate = moment(originalDate).tz(timezone).format('MMM D, Y');
  let formattedTime = moment(originalDate).tz(timezone).format('hh:mm A');

  let combined = formattedDate + ' at ' + formattedTime;
  return combined
}

function getDateTimeFromString(dateTimeStr: any) {
  const date = new Date(dateTimeStr);
  const year = date.getFullYear()
  const month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth()
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  return `${year}-${month}-${day} ${hours}:${minutes}:00`
}

function convertToUTC(dateString: string, timeZone: string) {
  // Define the input format and timezone
  const inputFormat = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ";
  // India Standard Time

  // Parse the input date string using moment-timezone
  const momentDate = moment.tz(dateString, inputFormat, timeZone);

  // Check if the parsed date is valid
  if (!momentDate.isValid()) {
    throw new Error("Invalid date string format.");
  }

  // Convert the date to UTC and format it as required
  const utcDateString = momentDate.utc().format("YYYY-MM-DD HH:mm:ss");

  return utcDateString;
}

function utcToLocalDate(utcTime: string, timeZone: string) {
  const localDateTime = moment.utc(utcTime).tz(timeZone);
  const formattedDate = localDateTime.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  console.log(formattedDate, timeZone, "----------149")
  return formattedDate;
}

function utcToLocalTime(utcTime: string, timeZone: string): string {
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };

  const time = new Date(utcTime).toLocaleTimeString('en-US', options);
  return time;
}

function decodeBase64(s: string) {

  // Decode the base64 string

  const data = atob(s);

  return data;

}

function extractMonth(dateString: string): string | null {
  const dateParts = dateString.split(' ');
  if (dateParts.length < 5) return null;

  const monthName = dateParts[1];
  return monthName;
}

function extractDate(dateString: string): number | null {
  const dateParts = dateString.split(' ');
  if (dateParts.length < 5) return null;

  const day = parseInt(dateParts[2], 10);
  return day;
}




export {
  validatePhoneNumber,
  convertUtcToLocal,
  getDayOfWeek,
  getMonthAndDay,
  getTime,
  convertToUtcDateTime,
  formatLocalDateForEndTime,
  formatLocalDateForStartTime,
  getDateTimeFromString,
  getCurrentDateTimeInUtc,
  convertToUTC,
  utcToLocalDate,
  utcToLocalTime,
  decodeBase64,
  extractMonth,
  extractDate,
}