import React, { useState } from "react";
import FilterIcon from "../../assets/Icons/refine/filterIcon.svg";
import SortIcon from "../../assets/Icons/refine/sortIcon.svg";
import Drawer from "../bottomDrawer";
import { DATE_FILTER, SORT_BY } from "../../utils/constants";
import AscendingIcon from '../../assets/Icons/refine/ascending.svg'
import DescendingIcon from '../../assets/Icons/refine/descending.svg'
import DatePicker from "../datePicker";
import RangeSlider from "../rangeSlider";
import TagIcon from "../../assets/Icons/refine/tag.svg";
import moment from "moment-timezone";

const REFINE_OPTION = {
  FILTER: "filter",
  SORT: "sort",
};

interface props{
  onFilterApplyClick: (start: string, end: string, range: Array<number>) => void;
  onSortApplyClick: (sort: string) => void;
}


export default function FilterMenu({ onFilterApplyClick = () => { }, onSortApplyClick = () => { } }: props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const [range, setRange] = useState([0, 1000]);

  const [selectedSort, setSelectedSort] = useState("");
  const [selectedDateFilter, setSelectedDateFilter] = useState("");

  const [filterStartDate, setFilterStartDate] = useState<any>('');
  const [filterEndDate, setFilterEndDate] = useState<any>('');

  const OnRangeChange = (value: [number, number]) => {
    setRange(value);
  }


  const onSortApply = () => {
    onSortApplyClick(selectedSort);
    setIsOpen(false);
  };

  const onFilterApply = () => {
    if (selectedDateFilter) {
      if (selectedDateFilter == DATE_FILTER.TODAY) {
        onFilterApplyClick(
          moment().toString(),
          moment().toString(),
          range
        );
      } else if (selectedDateFilter == DATE_FILTER.TOMORROW) {
        onFilterApplyClick(
          moment().add(1, "day").toString(),
          moment().add(1, "day").toString(),
          range
        );
      } else {
        onFilterApplyClick(
          moment().clone().day(6).toString(),
          moment().clone().day(7).toString(),
          range
        );
      }
    } else
      onFilterApplyClick(filterStartDate, filterEndDate, range);
    
    setIsOpen(false);
  };

  const onDateFilterClick = (selectedDateFilter: string) => {
    if (selectedDateFilter) {
      if (selectedDateFilter == DATE_FILTER.TODAY) {
        setFilterStartDate(
          moment()
            .startOf("day")
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
        setFilterEndDate(
          moment()
            .startOf("day")
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
      } else if (selectedDateFilter == DATE_FILTER.TOMORROW) {
        setFilterStartDate(
          moment().add(1, "day").format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
        setFilterEndDate(
          moment().add(1, "day").format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
      } else {
        setFilterStartDate(
          moment()
            .clone()
            .day(6)
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
        setFilterEndDate(
          moment()
            .clone()
            .day(7)
            .format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)")
        );
      }
      setSelectedDateFilter(selectedDateFilter);
    }
  };

  return (
    <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center">
      <div className="bg-primary-black flex rounded-lg p-2 gap-1">
        <img
          src={FilterIcon}
          className="cursor-pointer h-6"
          onClick={() => {
            setSelectOption(REFINE_OPTION.FILTER);
            setIsOpen(true);
          }}
        />
        <div className="border-r border-[1.5px] h-auto border-input-box-gray"></div>
        <img
          src={SortIcon}
          className="cursor-pointer h-6 w-9"
          onClick={() => {
            setSelectOption(REFINE_OPTION.SORT);
            setIsOpen(true);
          }}
        />
      </div>

      {selectOption == REFINE_OPTION.SORT ? (
        <Drawer
          onClose={() => setIsOpen(false)}
          open={isOpen}
          onSave={onSortApply}
          title="Sort"
        >
          {Object.values(SORT_BY).map((item, idx) =>
           <div className="flex justify-between py-4 gap-5 items-center cursor-pointer" key={idx} onClick={() => setSelectedSort(item)}>
           <div className="flex gap-2 items-center top-3">
               <span>{item}</span>
               <img src={item.includes('high to low') || item.includes('Newest') ? DescendingIcon : AscendingIcon}/>
           </div>
             <div className="bg-primary-black w-6 h-6 rounded-full flex items-center justify-center" >
               {selectedSort == item && <div className="bg-primary-orange w-3 h-3 p-1.5 rounded-full"></div>}
           </div>
         </div>
        )}
        </Drawer>
      ) : (
        <Drawer
          open={isOpen}
          title="Filter"
          onClose={() => setIsOpen(false)}
          onSave={onFilterApply}
        >
          <div className="h-fit z-20">
          <h1 className="text-[1.25rem]">Date</h1>
            <div className="mt-6">
                <DatePicker
                  onDateSelect={(start, end) => { setFilterStartDate(start); setFilterEndDate(end); setSelectedDateFilter('') }}
                  startDateProp={filterStartDate} endDateProp={filterEndDate} />
              <div className="flex gap-2 items-center mt-4">
                  {Object.values(DATE_FILTER).map((item, index) => (
                    <div className={`p-3 text-sm  rounded-2xl ${selectedDateFilter != item ? 'bg-input-box-gray text-text-gray' : 'bg-[#472600] border-solid border-[1px] border-primary-orange text-primary-orange'}`}
                      key={index}
                      onClick={() => onDateFilterClick(item)}>
                    {item}
                  </div>
                  ))
                  }
              </div>
            </div>

            <h1 className="text-[1.25rem] mt-10">Price</h1>
            <div className="flex gap-3 my-3 mt-6">
              <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center w-full">
                <img src={TagIcon} className="p-2 cursor-pointer" />
                <div className="bg-primary-black flex rounded-lg p-2 w-32">
                  <span>{range[0] === 0 ? "Free" : `$${range[0]}`}</span>
                </div>
              </div>
              <div className="bg-input-box-gray flex rounded-[.875rem] p-1 gap-1 items-center w-full">
                <img src={TagIcon} className="p-2 cursor-pointer" />
                <div className="bg-primary-black flex rounded-lg p-2 w-32">
                  <span>${range[1]}</span>
                </div>
              </div>
            </div>
            <div className="justify-center flex mx-1 mt-8 mb-10">
              <RangeSlider onSliderChange={OnRangeChange} value={range} />
            </div>
          </div>
        </Drawer>
      )}
    </div>
  );
}
