import React, { useState } from 'react';
import styles from './ProfileImgPopup.module.scss';
import profile from '../../assets/Icons/header/user.png';
import Add from '../../assets/Icons/header/add.png';
import { getLocalStorage } from '../../utils/localStorage';
import { LOCAL_STORAGE } from '../../utils/constants';
import { UserService } from '../../services/userServices';
import { toast } from 'react-toastify';
import { useSpinner } from '../spinner/SpinnerContext';
import ImageCropper from '../ImageCropper/ImageCropper'; // Import the ImageCropper component

interface Props {
  onCancel: () => void;
  onUpload: () => void;
}

const ProfileImgPopup = ({ onCancel, onUpload }: Props) => {
  const [imageURL, setImageURL] = useState('');
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const { setShowSpinner } = useSpinner();
  const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setIsCropperOpen(true);
    }
  };

  const handleUpload = async (croppedImage: string) => {
    try {
      setShowSpinner(true);

      const croppedImageBlob = await fetch(croppedImage).then(res => res.blob());
      const formData = new FormData();
      formData.append('photo1', croppedImageBlob);
      formData.append('ID', userId);

      const response = await UserService.uploadProfilePic(formData);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if (result?.data) {
        toast.success("Profile picture has been uploaded successfully.");
        console.log(result.data.message);
        onUpload();
        window.location.reload();
      } else {
        throw new Error('No data in response');
      }
    } catch (error) {
      console.error("Error in profile upload:", error);
      toast.error("Unable to upload profile picture.");
      onCancel();
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div className={`${styles.popupOuter} backdrop-blur-xl bg-white/30 z-[200]`}>
      <div className={styles.popupOverlay} onClick={onCancel}></div>
      <div className={styles.popupContent}>
        <h4 className="text-lg text-primary-white text-center mb-4">Upload profile picture</h4>
        <div className={`${styles.sideNav} animateNav bg-primary-black border-dashed border-2 border-[grey]`}>
          <div
            className={`${styles.contentWrapper} w-[19rem] h-[19rem] bg-cover bg-center bg-no-repeat flex justify-center items-center relative`}
            style={{ backgroundImage: `url(${imageURL ? imageURL : profile})` }}>
            <input
              type="file"
              id="fileInput"
              accept=".jpg,.png,.jpeg"
              onChange={handleFileChange}
              hidden
            />
            <label htmlFor="fileInput" className="text-primary-white inline-block rounded-[50px] bg-[#2E3034] cursor-pointer">
              <img src={Add} alt="Add Profile" />
            </label>
            {isCropperOpen && (
              <div className='fixed inset-0 flex justify-center items-center z-50'>
                <div className='bg-white p-4 rounded shadow-lg'>
                  <ImageCropper
                    imageFile={selectedFile}
                    onClose={() => setIsCropperOpen(false)}
                    onCropComplete={(croppedImage) => {
                      console.log(croppedImage, 'croppedImage');
                      setImageURL(croppedImage);
                      setIsCropperOpen(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="buttons flex justify-center items-center mt-4">
          <button
            className="text-primary-white bg-tertiary-gray font-medium text-xs md:text-sm xs:p-3 sm:p-4 py-[.3rem] px-[.5rem] md:py-[.45rem] md:px-[1rem] mr-[1rem] rounded-[10px]"
            onClick={onCancel}>
            Cancel
          </button>
          {selectedFile && (
            <button
              className="text-primary-white bg-primary-orange font-medium text-xs md:text-sm xs:p-3 sm:p-4 py-[.3rem] px-[.5rem] md:py-[.45rem] md:px-[1rem] rounded-[10px]"
              onClick={() => handleUpload(imageURL)}>
              Upload +
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileImgPopup;