import React, { ChangeEvent, useEffect, useState } from 'react'
import RadioInput from '../../../components/radioInput'
import ForwardButton from '../../../assets/Icons/forward/forward.svg'
import PrimaryButton from '../../../components/primaryButton'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { CreateEvent } from '../../../services/createEventService'
import { eventDetailService } from '../../../services/eventDetailServices'
import { getLocalStorage } from '../../../utils/localStorage'
import { LOCAL_STORAGE } from '../../../utils/constants'
import { toast } from 'react-toastify'

const GeneralSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const eventid: any = queryParams.get("eventid");
  const [eventDetails, setEventDetails] = useState<Event | any>();
  const [chatOption, setChatOption] = useState('Host And Guest');
  const [guestShow, setGuestShow] = useState('Show');
  const [ticketDetails, setTicketDetails] = useState([])


  useEffect(() => {
    if (eventid) {
      getHostEventDtls()
      getTicketsDetails()

    }
  }, [eventid])

  useEffect(() => {
    if (eventDetails) {
      setChatOption(eventDetails?.chat_settings)
      setGuestShow(eventDetails?.showGuestlist)
    }
  }, [eventDetails])

  const getHostEventDtls = async () => {
    try {
      const res = await eventDetailService.getHostEventDetails({ eventId: eventid, userId });
      if (res?.data) {
        setEventDetails(res?.data?.events);
      }
    } catch (error: any) {
      console.log("error getHostEventDtls : ", error?.response?.data?.error || error?.message);
    }
  }

  const getTicketsDetails = async () => {
    try {
      const res = await CreateEvent.getEventTicket({ id: parseInt(eventid) })
      if (res?.data) {
        setTicketDetails(res?.data)

      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleChatOptionChange = (event: ChangeEvent<HTMLInputElement>) => {

    setChatOption(event.target.value);
  };

  const handleGuestOptionChange = (event: ChangeEvent<HTMLInputElement>) => {

    setGuestShow(event.target.value);
  };


  const submitTicketType = async (e: any) => {
    e.preventDefault()
    try {
      const params = {
        eventId: parseInt(eventid),
        chat_settings: chatOption,
        showGuestlist: guestShow
      }
      const res = await CreateEvent.updateEventType(params);
      if (res?.data) {


        // Conditional navigation based on event type
        if (eventDetails?.event_type === "Free Event") {

          navigate({
            pathname: "/host-center/event-details",
            search: createSearchParams({
              eventid: eventid,
              hostid: userId,
            }).toString(),
          });
        } else if (ticketDetails?.length >= 0 && ticketDetails?.every((ticket: any) => ticket.is_free === true)) {

          navigate({
            pathname: "/host-center/event-details",
            search: createSearchParams({
              eventid: eventid,
              hostid: userId,
            }).toString(),
          });
        }
        else {

          navigate({
            pathname: "/create-event/payouts",
            search: createSearchParams({
              eventid: eventid,
            }).toString(),
          });
        }
      }
    } catch (error) {
      console.log('error submitting date : ')
    }
  }

  const submitPublishTicketType = async () => {
    try {
      const params = {
        eventId: parseInt(eventid),
        chat_settings: chatOption,
        showGuestlist: guestShow
      }
      const res = await CreateEvent.updateEventType(params);
      if (res?.data) {

        if (eventDetails?.event_type === "Free Event") {

          const res = await eventDetailService.publishEvent({ eventId: parseInt(eventid), hostUserId: parseInt(userId), isPublished: true });
          if (res?.status) {
            toast.success("Event published successfully!")
          }

          navigate({
            pathname: "/host-center/event-details",
            search: createSearchParams({
              eventid: eventid,
              hostid: userId,
            }).toString(),
          });
        } else if (ticketDetails?.length >= 0 && ticketDetails?.every((ticket: any) => ticket.is_free === true)) {

          const res = await eventDetailService.publishEvent({ eventId: parseInt(eventid), hostUserId: parseInt(userId), isPublished: true });
          if (res?.status) {
            toast.success("Event published successfully!")
          }

          navigate({
            pathname: "/host-center/event-details",
            search: createSearchParams({
              eventid: eventid,
              hostid: userId,
            }).toString(),
          });
        }
        else {

          navigate({
            pathname: "/create-event/payouts",
            search: createSearchParams({
              eventid: eventid,
            }).toString(),
          });
        }
      }
    } catch (error) {
      console.log('error submitting date : ')
    }
  }

  return (
    <form onSubmit={submitTicketType}>
      <div>
        <label className='text-primary-white text-lg'>Chat settings</label>
        <div className='flex gap-4 mt-2'>
          <RadioInput heading='Guest and host' description='The chat will be open to all members' value='Host And Guest' option={chatOption} optionChange={handleChatOptionChange} />
          <RadioInput heading='Host only' description='Only host will be able to chat, guest can view' value='Host Only' option={chatOption} optionChange={handleChatOptionChange} />
        </div>
      </div>

      <div className='my-8'>
        <label className='text-primary-white text-lg'>Post RSVP guest list visibility</label>
        <div className='flex gap-4 mt-2'>
          <RadioInput heading='Show' value='Show' option={guestShow} optionChange={handleGuestOptionChange} />
          <RadioInput heading='Hide' value='Hide' option={guestShow} optionChange={handleGuestOptionChange} />
        </div>
      </div>

      <div className='border-t border-text-gray'></div>

      <div className='flex place-content-between items-center my-8'>
        {eventDetails?.status !== 'Completed' && <PrimaryButton name='Previous' type='button' classNames='px-2 py-3' onClick={() => navigate({
          pathname: "/create-event/tickets&promo",
          search: createSearchParams({
            eventid: eventid
          }).toString()
        })} />}
        <div className='flex gap-2'>
          {ticketDetails?.length >= 0 && ticketDetails?.every((ticket: any) => ticket.is_free === true) && <PrimaryButton
            name={(eventDetails?.event_type === "Free Event") ? "Save as Draft" : (ticketDetails?.length >= 0 && ticketDetails?.every((ticket: any) => ticket.is_free === true)) ? "Save as Draft" : "Save & Next"}
            icon={ForwardButton}
            type="submit"
            classNames="px-2 py-3"
          />}
          <PrimaryButton
            name={(eventDetails?.event_type === "Free Event") ? "Publish" : (ticketDetails?.length >= 0 && ticketDetails?.every((ticket: any) => ticket.is_free === true)) ? "Publish" : "Save & Next"}
            icon={ForwardButton}
            type="submit"
            classNames="px-2 py-3"
            onClick={() => submitPublishTicketType()}
          />
        </div>

      </div>

    </form>
  )
}

export default GeneralSettings