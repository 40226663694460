import React, { useEffect } from "react";
import styles from "./TermsOfService.module.scss";
export default function TermsOfService() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsHeader}>
        <h1>SOCHA™</h1>
        <h2>TERMS OF SERVICE</h2>
        <em>Last Updated: June 7, 2023</em>
      </div>
      <div className={styles.termsContent}>
        <ol>
          <li>
            <p>
              <b>1. Acceptance of These Terms of Service</b>
            </p>
            <p>
              Welcome! This is a legal agreement (<b>“Agreement”</b> or
              <b>{' '}“Terms of Service”</b>) between you and Sondr, Inc. (
              <b>"Sondr"</b>
              or <b>"we," "us,"</b> and <b>"our"</b>). By accessing our Socha™
              venue and event-based online platform, currently located at
              https://www.joinsocha.com (the <b>“Platform,”</b> including all
              sub-domains), whether through a mobile device, mobile application
              or computer, and using any of our Services (as defined below) made
              available through the Platform as software as a service (
              <b>"SaaS"</b>) services and/or the mobile devices using Socha™
              mobile applications, you agree to, and are bound by, the terms and
              conditions of these Terms of Service for as long as you continue
              to use the Platform, the App (as defined below) or the Services,
              including all exclusions and limitations of liability herein, and
              warrant that you have full authority and capacity, legal and
              otherwise, to use the Services.
              <b>
                IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF SERVICE, DO
                NOT USE THE PLATFORM, THE APP OR THE SERVICES
              </b>
              . Your use of, or participation in, certain Services may be
              subject to additional terms, and such terms will be either listed
              in these Terms of Service or will be presented to you for your
              acceptance when you sign up to use such Services.
            </p>
            <p>
              The principal Services consist of the following: creation and
              management of online or in-person events (<b>"Events"</b>)
              organized and hosted by our Members (<b>"Hosts"</b>) and online
              announcement and sharing of Events to specific invitees
              pre-selected by Hosts or the general public who attend or
              participate in such Events (<b>"Guests"</b>) by accepting
              invitations or purchasing tickets to the Events through the
              Platform (<b>“Event-Based Services”</b>), an introduction service
              for individuals who expressed interest in forming connections with
              each other based on mutual interest, whether at Events or at other
              locations where one or more other Members are physically present (
              <b>"Introduction Service"</b>), interactive content and community
              services (<b>“Content Services”</b>), and any premium services,
              such as Marketing and Operational Services, once they become
              available (<b>“Premium Services”</b>) (collectively, the
              <b>“Services”</b>).
            </p>
            <p>
              Using the App or the Platform, Hosts can create, post and manage
              online or in-person events, sell tickets, solicit donations and
              stay connected to Guests through our Services. Hosts may offer
              Events without charge or sell tickets to Events. Each Host is
              solely responsible for ensuring that such Host's Event and any
              online announcement, content and posting referring to, depicting
              or displaying the Event complies with all applicable laws, rules,
              and regulations, that the Event is conducted in compliance with
              all applicable laws, rules and regulations and that the goods and
              services described in any such announcement, posting or other
              materials are delivered as described and in an accurate,
              satisfactory and safe manner. If you act as a Host you will be
              required to execute Sondr Event Host Agreement (
              <b>"Host Agreement"</b>) during your initial registration with the
              Platform and will be bound by the terms and conditions of the Host
              Agreement in addition to these Terms of Service.
            </p>
            <p>
              If a Host is hosting an Event with paid tickets, the Host will set
              pricing and other terms for the tickets for the Event as more
              fully described in the Host Agreement. Guests must use the payment
              processing method we currently offer to purchase tickets. We will
              act as the Host's limited agent to process payments from Guests on
              the Host's behalf using our third-party payment service providers
              as more fully described in the Host Agreement. Guests may be able
              to reserve attendance at Events via one or more Socha™ mobile apps
              (collectively, the <b>“App”</b>), which can be downloaded from a
              third party app store and use pursuant to the End User License
              Agreement with Sondr (<b>“EULA”</b>), the terms of which are
              incorporated herein by reference, or online on the Platform.
            </p>
            <p>
              Sondr may offer additional services or revise any of the Services
              at its discretion, and these Terms of Service will apply to all
              additional services or revised Services. Sondr also reserves the
              right to cease offering any of the Services.
            </p>
            <p>
              The Platform, the App and the Services are owned and operated by
              Sondr. These Terms of Service (and the Host Agreement, if
              applicable) govern your access to and use of the Services
              available on this Platform and, together with EULA, through the
              App. Specific features of the Platform may have additional rules
              and terms associated with them, and such additional rules and
              terms will be prominently posted or otherwise made available to
              each user of the Platform, the App or the Services (<b>"you"</b>{" "}
              and <b>"your"</b>) and are incorporated herein by reference.
            </p>
            <p>
              THESE TERMS OF SERVICE LIMIT THE REMEDIES AVAILABLE IN THE EVENT
              OF A DISPUTE AND REQUIRE THAT ALL DISPUTES BE RESOLVED THROUGH
              BINDING ARBITRATION. THIS MEANS THAT YOU AGREE TO RESOLVE DISPUTES
              INDIVIDUALLY ACCORDING TO THE ARBITRATION PROVISIONS BELOW RATHER
              THAN IN A COURT OF LAW OR THROUGH A CLASS ACTION LAWSUIT.
            </p>
          </li>
          <li>
            <p>
              <b>2. License to Use Services; Eligibility</b>
            </p>
            <p>
              This Platform primarily operates as an online platform for the
              purpose of providing the Services. Subject to these Terms of
              Service of Service, including, without limitation, the applicable
              eligibility requirements and payment of applicable fees for the
              Services you select to receive, you are granted a personal,
              worldwide, non-transferable, non-exclusive, royalty-free, limited
              license to use and access the Platform, directly or through the
              App, to obtain the Services. You must use this license only in the
              manner permitted by these Terms of Service for the sole purpose of
              using and enjoying the benefit of the Services provided by the
              Platform. Your use of the App is also governed by the EULA.
            </p>
            <p>
              a. <u>Minimum Age</u>. You must be at least 18 years old to use
              the Platform, open an account or to register for the Services in
              order to become a registered user (<b>“Member”</b>). By using the
              Services, you represent and warrant that you are at least 18 years
              old. Other Services may have other age requirements for all or
              portion of such Services, and such other age requirements are
              stated on such Services or portions thereof.
            </p>
            <p>
              b. <u>Criminal History</u>. By requesting to use, registering to
              use, and/or using the Services, you represent and warrant that you
              have never been convicted of a felony and/or are not required to
              register as a sex offender with any government entity. SONDR DOES
              NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND SCREENINGS ON ITS
              MEMBERS. However, Sondr reserves the right to conduct any criminal
              background check, at any time and using available public records,
              to confirm your compliance with this subsection. BY AGREEING TO
              THESE TERMS AND CONDITIONS, YOU HEREBY AUTHORIZE ANY SUCH CHECK.
            </p>
            <p>
              c. <u>Personal Profile</u>. The Introduction Service requires that
              you create a personal profile on the Platform, along with your
              physical presence at an Event or another location where other
              Members may attend or congregate at the same time as you. Other
              Members may use your personal profile to make decisions about
              connecting with you or inviting you to their Events. Occasionally,
              a given Member in whom you expressed interest may not be present
              in your location or at the Event you are attending or may not be
              interested in connecting with you, in which case the Introduction
              Service cannot be provided.
            </p>
          </li>
          <li>
            <p>
              <b>3. Advertisements</b>
            </p>
            <p>
              The Services provided may include advertisements, some of which
              may be targeted based on the type of information on the Platform,
              Member's profile, inquiries made, or other information, whether
              submitted by you or others. The types and amount of advertising on
              the Platform, the App or through the Services is subject to
              change.
            </p>
          </li>
          <li>
            <p>
              <b>4. Cookies</b>
            </p>
            <p>
              The Platform uses cookies. Cookies store information related to
              visitors' preferences, browser type, and other information in
              order to optimize their experiences. By using this Platform you
              acknowledge your understanding of and consent to our use of
              cookies. You may delete cookies already on your computer by
              consulting the instructions for your file management software. You
              may also disable future cookies in your browser settings. Please
              note, however, that disabling cookies may prevent or impair your
              ability to access and fully make use of certain Services and areas
              of the Platform.
            </p>
            <p>
              You also acknowledge that websites such as Google and other
              third-party vendors to our Platform may use DART cookies to
              provide add-ons to our Platform based upon visitors visiting our
              Platform and other websites. Visitors may decline the use of DART
              cookies by visiting the Google ad and content network Privacy
              Policy at the following URL:
              <a href="http://www.google.com/privacy_ads.html" target="_blank">
                http://www.google.com/privacy_ads.html
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              <b>5. Acceptable Use Policy</b>
            </p>
            <p>
              By using this Platform you agree to use the Services in compliance
              with these Terms of Service and all applicable rules and
              regulations, including the local, state, national, and
              international laws that apply to your jurisdiction. We are based
              in New York, United States. This Platform is meant for use by
              persons within the United States. It may be illegal for certain
              persons to use the Platform in some countries. We make no claim
              that the Platform will be legal or accessible outside of the
              United States and we may further limit its availability to certain
              locations within the United States from time to time.
            </p>
            <p>
              Subject to these Terms of Service, you may view, print, and use
              our content and Services as permitted on the Platform and only for
              your own personal, non-commercial use. You further agree and
              acknowledge that your use of this Platform and the Services
              results in you sending electronic interstate transmissions through
              our computer network, and such transmissions may include, but are
              not limited to, any searches, file uploads, posts, instant
              messages, or emails
            </p>
            <p>
              The following uses of the Platform are prohibited unless we have
              intended to provide you with access or have provided you express
              written permission to the contrary:
            </p>
            <p>
              a. Using the Platform in a manner that causes, or may cause,
              damage to the Platform or in a way that impairs visitors'
              abilities to access or use the Platform and the Services,
              including using the Platform in a manner or for a purpose that is
              unlawful or fraudulent;
            </p>

            <p>
              b. Using the Platform in order to copy, store, upload, publish,
              use, transmit, host, or distribute anything consisting of or
              linked to any computer virus, spyware, rootkit, worm, keystroke
              logger, or other malicious or invasive computer software or
              software that may destroy, damage, or alter a computer system;
            </p>

            <p>
              c. Conducting any systematic or automated data collection on or
              related to the Platform or its Members without their consent,
              including, without limitation, data mining, data extraction,
              scraping, data harvesting, "framing," or article "spinning";
            </p>

            <p>
              d. Using the Platform to collect, harvest, or compile information
              or data regarding other Members without their consent;
            </p>

            <p>
              e. Using software or automated agents or scripts to generate
              automated searches, requests, or queries on the Platform or to
              mine data from the Platform, provided, however, that operators of
              public search engines have our revocable permission to copy
              materials from the Platform for the sole purpose of and only to
              the extent necessary for creating public search indices, but not
              caches or archives of such materials, according to the parameters
              in our robots.txt file;
            </p>

            <p>
              f. Transmitting or sending unsolicited communications, commercial
              or otherwise, including any advertising materials, or conducting
              any marketing activities, including using the Platform to send
              spam, pyramid schemes, or chain letters;
            </p>

            <p>
              g. Republishing (including on another website or platform),
              duplicating, copying, redistributing, selling, leasing,
              transferring, hosting, renting, or licensing any part of the
              Platform or otherwise commercially exploiting content, whether in
              whole or in part, on the Platform;
            </p>

            <p>
              h. Editing, modifying, making derivative works of, reverse
              engineering, or reverse compiling any information, content,
              systems, or Services on the Platform;
            </p>

            <p>
              i. Accessing the Platform in order to create a similar or
              competitive business, product, service, or website or platform;
            </p>

            <p>
              j. Collecting, transmitting, distributing, uploading, or
              displaying any content, communications or otherwise using the
              Platform in a manner that (i) violates the rights of any third
              party, including any intellectual property rights or rights to
              privacy, (ii) is unlawful, tortious, threatening, vulgar,
              defamatory or libelous, pornographic, obscene, patently offensive,
              racist, or promotes physical harm or injury, (iii) causes or may
              cause harm to minors in any way, (iv) impersonates another
              individual or organization or otherwise misrepresents affiliation
              with another person or entity without permission or (v) tends to
              be or has the effect of harassing, bullying, or stalking others,
              whether Members or other third parties.
            </p>

            <p>
              k. Interfering with, disrupting, or overburdening servers or
              networks connected to the Platform;
            </p>

            <p>
              l. Gaining or attempting to gain unauthorized access by any means
              to any part of the Platform or to computers or networks connected
              to the Platform;
            </p>

            <p>
              m. Providing or promoting false or misleading information about
              yourself, other Members or other third parties; and
            </p>

            <p>
              n. Harassing, stalking, harming, or otherwise interfering with or
              negatively affecting another Member's normal use and enjoyment of
              the Platform.
            </p>
          </li>
          <li>
            <p>
              <b>6. Safety; Your Interactions with Other Members</b>
            </p>
            <p>
              Though Sondr strives to encourage a respectful user experience
              through features like the double opt-in that only allows Members
              to communicate if they have both indicated interest in one
              another, it is not responsible for the conduct of any user,
              visitor or Member on or off of the Platform, the App or with
              respect to any Service. You agree to use caution in all
              interactions with other Members, particularly if you decide to
              communicate off the Platform, meet in person or attend an Event.
              In addition, you agree to review and follow our
              <u>Safety Tips and Introduction Rules</u> located at
              <a href="https://www.joinsocha.com/safety" target="_blank">
                https://www.joinsocha.com/safety
              </a>
              prior to using any Service. Sondr is not responsible for the
              conduct of any user, visitor or Member on or off of the Platform,
              the App or with respect to any Service, including without
              limitation at any Event. You agree that you will not provide your
              financial information (for example, your credit card or bank
              account information), or wire or otherwise send money, to others.
            </p>
            <p>
              YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHERS. YOU
              UNDERSTAND THAT SONDR DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS
              ON ITS MEMBERS, ATTEMPT TO VERIFY THE STATEMENTS OF ITS MEMBERS OR
              OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS. SONDR MAKES
              NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF MEMBERS, AS
              TO A MEMBER'S COMPATIBILITY WITH ANY OTHER CURRENT OR FUTURE
              MEMBERS, OR THE QUALITY OR SAFETY OF EVENTS. SONDR RESERVES THE
              RIGHT TO CONDUCT - AND YOU AGREE THAT SONDR MAY CONDUCT - ANY
              CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX
              OFFENDER REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC
              RECORDS. YOU AGREE, HOWEVER, THAT WE ARE UNDER NO OBLIGATION TO
              CONDUCT ANY BACKGROUND CHECKS ON MEMBERS AND MAY ONLY DO IT IN OUR
              OWN ABSOLUTE DISCRETION.
            </p>
          </li>
          <li>
            <p>
              <b>7. Member Accounts</b>
            </p>
            <p>
              You may create a single member account and/or profile for yourself
              as permitted by the Platform or the App. Hosts and Guests may have
              different access privileges to the functions and features of the
              Platform depending on the Service they chose to use. Upon
              registering for our Services through the App as a Host and/or a
              Guest, you are considered a registered user or <b>"Member"</b> of
              the Platform. You represent that all information you submit when
              creating an account is true and accurate, and you promise to
              update and maintain the accuracy of this information at all times.
              Each Member is the sole authorized user of his or her account.
              Members are responsible for keeping their passwords and account
              access information confidential. Therefore, you should take
              measures to restrict access to your account and to any devices
              from which you access your account. As a Member, you are
              responsible for all activities that occur under your account, and
              you acknowledge that Sondr is not responsible for unauthorized
              access to your account that results from theft or misappropriation
              of your account or password. Members are prohibited from assigning
              or otherwise transferring their accounts and passwords to others.
              You must notify us immediately if you know of or suspect that an
              unauthorized use or other breach of security of your account, the
              Platform or the App has occurred. We may not be held liable for
              any loss or damage that may arise from your failure to maintain
              the security of your account. You may delete your account at any
              time and for any reason by following the instructions on the
              Platform and/or the App. We retain the right to suspend or
              terminate your account at any time and for any reason, as further
              detailed below.
            </p>
          </li>
          <li>
            <p>
              <b>8. Use of Services by Members</b>
            </p>
            <p>As a Member, you agree to the following:</p>
            <p>
              a. <u>Exclusive Use</u>. Your account is for your personal use
              only and not on behalf of any other person, entity or
              organization. You may not authorize others to use your account,
              and you may not assign or otherwise transfer your account to any
              other person or entity. You acknowledge that Sondr is not
              responsible for third party access to your account that results
              from theft or misappropriation of your Member names and passwords.
            </p>
            <p>
              b. <u>Geographic Limitations</u>. The Platform and the Services
              are intended for use in the United States only and only in those
              areas where a Member's mobile phone or other mobile or wireless
              device has reliable connection to an available network and proper
              reception. You will only use the Services in a manner consistent
              with these Terms of Service and any and all applicable local,
              state, national and international laws and regulations, including,
              but not limited to, United States export control laws. By using
              the Services, you represent that you have not been designated by
              the United States government as a “Specially Designated National”
              or other person to whom the provisions of the Services are
              prohibited. Registration for, and use of, the Services are void
              where prohibited. You are responsible for determining whether your
              use of the Services is legal in your jurisdiction.
            </p>
            <p>
              c. <u>Information Submitted</u>. You are solely responsible for,
              and assume all liability regarding, (i) the information and
              content you contribute to the Service; (ii) the information and
              content you post, transmit, communicate, publish, or otherwise
              make available (hereinafter <b>“post”</b>) through the Services;
              and (iii) your interactions with other Members through the
              Services. In addition to sharing your information with other
              Members in whom you expressed an interest, we may allow such
              Members to share your profile information with members of their
              social network(s) who may not be our Members.
            </p>
            <p>
              d. <u>Risk Assumption and Precautions</u>. You assume all risk
              when using the Services, including but not limited to all of the
              risks associated with any online or offline interactions with
              others, including attending Events, meeting in person or otherwise
              through the Introduction Service, dating and other social
              activities. You agree to take all necessary precautions when
              attending Events, meeting others in person or otherwise through
              the Services. In addition, you agree to review and follow the
              recommendations set forth in Sondr's Safety Tips and Introduction
              Rules, which will be provided to you prior to enrolling in the
              Introduction Service.
            </p>
            <p>
              e. <u>No Guarantees</u>. Sondr makes no guarantees as to the
              quality, safety or any other aspects of Events. You understand
              that Sondr makes no guarantees, either express or implied,
              regarding the conduct of Events by Hosts and their agents,
              contractors and service providers, the conduct of any performers
              and other acts at the Events or the conduct of other Guests. Sondr
              may not be able to provide introductions for everyone seeking to
              use its Introduction Services. Further, Sondr makes no guarantees
              as to the number or frequency of Introductions through the
              Introduction Service, or to other Members’ ability, desire or
              criteria to communicate with you. You understand that Sondr makes
              no guarantees, either express or implied, regarding your ultimate
              compatibility with individuals you meet through the Introduction
              Service or as to the conduct of such individuals. All
              introductions through the Introduction Service rely on mutual
              interests of Members to form connections among themselves and are
              entirely voluntary on the part of each Member.
            </p>

            <p>
              f. <u>Reporting of Violations</u>. You will promptly report to
              Sondr any violation of the Agreement by others, including but not
              limited to, Members.
            </p>

            <p>
              g. <u>Content Removal</u>. Sondr reserves the right, but has no
              obligation, to monitor the information or material you submit to
              the Services or post in the public areas of the Services. Sondr
              will have the right to remove any such information or material
              that in its sole opinion violates, or may violate, any applicable
              law or either the letter or spirit of these Terms of Service or
              upon the request of any third party. Sondr further reserves the
              right to remove your communications with other Members, in its
              reasonable discretion, in order to assure that you have a quality
              experience on the Platform.
            </p>

            <p>
              h. <u>Posting and Communication Restrictions</u>. You will not
              post on the Services, transmit to other Members, communicate any
              content (or links thereto), or otherwise engage in any activity on
              the Platform or through the App or the Services, that:
            </p>
            <ul className={styles.ml4}>
              <li>
                i. promotes racism, bigotry, hatred or physical harm of any kind
                against any group or individual;
              </li>
              <li>
                ii. is intended to or tends to harass, annoy, threaten or
                intimidate any other Members, users or visitors of the Platform
                or the Services;
              </li>
              <li>
                iii. is defamatory, inaccurate, abusive, obscene, profane,
                offensive, sexually oriented, obscene or otherwise
                objectionable;
              </li>
              <li>
                iv. contains others’ copyrighted content (e.g., music, movies,
                videos, photographs, images, software, etc.) without obtaining
                permission first;
              </li>
              <li>
                v. contains video, audio photographs, or images of another
                person without his or her permission (or in the case of a minor,
                the minor’s legal guardian);
              </li>
              <li>
                vi. promotes or enables illegal or unlawful activities, such as
                instructions on how to make or buy illegal weapons or drugs,
                violate someone’s privacy, harm or harass another person, obtain
                others’ identity information, create or disseminate computer
                viruses, or circumvent copy-protect devices;
              </li>
              <li>
                vii. intended to defraud, swindle or deceive other Members,
                visitors or users of the Platform or the Services;
              </li>
              <li>
                viii. contains viruses, time bombs, trojan horses, cancelbots,
                worms or other harmful, or disruptive codes, components or
                devices;
              </li>
              <li>
                ix. promotes or solicits involvement in or support of a
                political platform, religion, cult, or sect;
              </li>
              <li>
                x. disseminates another person’s personal information without
                his or her permission, or collects or solicits another person’s
                personal information for commercial or unlawful purposes;
              </li>
              <li>
                xi. is off-topic, meaningless, or otherwise intended to annoy or
                interfere with others’ enjoyment of the Platform or the
                Services;
              </li>
              <li>
                xii. impersonates, or otherwise misrepresents affiliation,
                connection or association with, any person or entity;
              </li>
              <li>
                xiii. solicits gambling or engages in any gambling or similar
                activity;
              </li>
              <li>
                xiv. uses scripts, bots or other automated technology to access
                the Platform or the Services;
              </li>
              <li>
                xv. uses the Platform or the Services for chain letter, junk
                mail or spam e-mails;
              </li>
              <li>
                xvi. collects or solicits personal information about anyone
                under 18; or
              </li>
              <li>
                xvii. is in any way used for or in connection with spamming,
                spimming, phishing, trolling, or similar activities.
              </li>
            </ul>
            <p>
              i. <u>No False Information</u>. You will not provide inaccurate,
              misleading or false information to Sondr or to any other Member.
              If information provided to Sondr or another Member subsequently
              becomes inaccurate, misleading or false, you will promptly notify
              Sondr of such change.
            </p>
            <p>
              j. <u>No Advertising or Commercial Solicitation</u>. You will not
              advertise or solicit any Member to buy or sell any products or
              services other than the tickets to your Events and any related
              products and services to be purchased or consumed during such
              Events (provided that the cost of such related products and
              services is included in the cost of tickets to the applicable
              Events) through the Platform or the Services. You may not transmit
              any chain letters, junk or spam e-mail to other Members. Further,
              you will not use any information obtained from the Services in
              order to contact, advertise to, solicit, or sell to any Member
              without their prior explicit consent. If you breach the terms of
              this subsection and send or post unsolicited bulk email, “spam” or
              other unsolicited communications of any kind through the Services,
              you acknowledge that you will have caused substantial harm to
              Sondr, but that the amount of such harm would be extremely
              difficult to ascertain. As a reasonable estimation of such harm,
              you agree to pay Sondr $100 for each such unsolicited
              communication you send through the Services.
            </p>
            <p>
              k. <u>Unique and Bona Fide Profile</u>. As a Member, you will
              create only one unique profile for yourself. In addition, your use
              of the Hosting Services must be for bona fide Event hosting or
              attendance and your use of the Introduction Service must be for
              bona fide relationship seeking or socialization purpose in order
              to maintain the integrity of the Services (for example, you may
              not become a Member solely to compile a report of Events in your
              area, to create a list of individuals sharing a certain
              characteristic or to write a school research paper). From time to
              time, Sondr may create test profiles in order to monitor the
              operation of the Services.
            </p>
            <p>
              l. <u>No Harassment of Sondr Employees or Agents</u>. You will not
              harass, annoy, intimidate or threaten any of our employees or
              agents engaged in providing any portion of the Services to you.
            </p>
            <p>
              m. <u>Social Media</u>. We may provide you the option to connect
              your Member account to your account on some social networking
              platforms or websites for the purpose of logging in, uploading
              information or enabling certain features on the Service. When
              enabling this feature, we will disclose to you the information we
              collect from the connected social networking platform, and will
              use such information in compliance with our Privacy Policy. Once
              connected, some of the Members you were introduced to may also be
              able to see information about your social network, such as the
              size of your network and your friends, including common friends.
              By connecting your Sondr account to your account on any social
              networking platform, you hereby consent to the continuous release
              of information about you to Sondr. We will not send any of your
              Sondr account information to the connected social networking
              platform without first disclosing that to you. You can always
              disconnect the accounts via your App’s Profile page. Each social
              network may further allow you to set privacy controls around your
              information on their system, and Sondr’s collection of information
              will always follow such controls and permissions. This feature is
              subject to continuous change and improvement by us and each social
              networking platform involved, and therefore the available features
              and shared information are subject to change without notice to
              you.
            </p>
            <p>
              n. <u>Push Notifications</u>. We may provide you with emails, text
              messages, push notifications, alerts and other messages related to
              the Platform, the App and/or the Services, such as enhancements,
              offers, products, events, and other promotions. After downloading
              the App, you will be asked to accept or deny push
              notifications/alerts. If you deny, you will not receive any push
              notifications/alerts. If you accept, push notifications/alerts
              will be automatically sent to you. If you no longer wish to
              receive push notifications/alerts from the App, you may opt out by
              changing your notification settings on your mobile device. With
              respect to other types of messaging or communications, such as
              emails, text messages, etc., you can unsubscribe or opt out by
              either following the specific instructions included in such
              communications, or by emailing us with your request at
              support@joinsocha.com.
            </p>
            <p>
              o. <u>Location-Based Features</u>. In addition to the Event Based
              Services and Introduction Services, which are based on your and
              other Members' current location, the Platform and/or the App may
              allow access to or make available opportunities for you to view
              certain content and receive other products, services and/or other
              materials based on your location, attendance of prior Events,
              preferences or interests. To make these opportunities available to
              you, the Platform and/or the App will determine your location
              using one or more reference points, such as GPS, Bluetooth and/or
              software within your mobile device. If you have set your mobile
              device to disable GPS, Bluetooth or other location determining
              software or do not authorize the App to access your location data,
              you will not be able to access such location-specific content,
              products, services and materials. For more about how the Platform
              and the App use and retain your information, please read our
              Privacy Policy.
            </p>
            <p>
              p. <u>SMS Messages</u>. By downloading the App and/or registering
              for the Services, you agree to receive SMS marketing material from
              us, and you automatically opt-in to this service. You do however
              have the ability to opt-out of SMS messages directly via SMS or by
              emailing us with your request at support@joinsocha.com. The auto
              opt-in only applies to American Members and those using American
              mobile numbers within the U.S. If you feel that the auto opt-in
              does not apply to you based on your country of origin and the
              mobile number you are using, please contact us immediately to
              ensure prompt removal of this service. The App will only be
              available for download and use in the United States.
            </p>
            <p>
              q. <u>Legal Compliance</u>. Due to the global nature of the
              internet, you understand and agree that it is your responsibility
              to ensure that your use of the Platform, the App and the Services
              complies with all local, international, and other laws that may
              apply. In addition, United States import and export control laws
              and the import regulations of other countries may apply to the use
              of the Platform. You agree not to export, upload, post, or
              transfer, directly or indirectly, any software, technical data, or
              technology acquired through us, the Platform, the App or the
              Services in violation of such export or import laws, including,
              but not limited to, the United States Export Administration
              Regulations (EAR) and the various United States sanctions
              programs.
            </p>
            <p>
              In addition, if you are a Host, along with your other
              representations and warranties, you represent and warrant to us
              that:
            </p>
            <p>
              a. You will obtain, before starting ticket sales, all applicable
              licenses, permits, and authorizations (individually and
              collectively,
              <b>"Licensure"</b>) for your Events. Licensure includes state,
              county, municipal, or other local authority's authorization of the
              event, traffic engineering authorizations, fire department
              inspection reports, fire marshal permits, authorization to receive
              minors, sanitary authorization, and property operation permits;
            </p>
            <p>
              b. You will comply, and will ensure that the venues for your
              Events will comply, with all applicable laws, regulations, rules,
              and ordinances;
            </p>
            <p>
              c. You will maintain throughout the use of the Services the
              applicable Licensure to promote, produce, sponsor, host, and sell
              tickets for all of your Events; and
            </p>
            <p>
              d. You will provide evidence of Licensure and related information
              prior to offering tickets or registrations for your Events and
              promptly upon our reasonable request from time to time.
            </p>
          </li>
          <li>
            <p>
              <b>9. Fees</b>
            </p>
            <p>
              Creating an account on the Platform and listing an Event are
              currently free. However, we may charge fees (<b>"Guest Fees"</b>)
              when you buy tickets, reservations or registrations for Events.
              Guest Fees may vary based on individual agreements between us and
              certain Hosts and also vary for different Events. Guest Fees are
              included in the ticket or registration price and may include other
              charges, including facility fees, royalties, taxes, processing
              fees, and fulfillment fees. We do not control (and cannot
              disclose) fees levied by your bank and/or credit card company,
              including fees for purchasing tickets and registrations in foreign
              currencies or from foreign persons. Be sure to check with your
              bank or credit card company prior to purchasing to understand all
              applicable fees, credit card surcharges, and currency conversion
              rates. Premium Services, when available, will be fee-based.
            </p>
            <p>
              Ticket refunds may only be issued to Guests if the applicable Host
              uninvites you through the Platform or if the Event itself is
              cancelled through the Platform. Guests requesting a refund should
              contact the Host as only the Host may issue a refund for ticket/s
              you purchased.
            </p>
            <p>
              Guests must not use a ticket that has been refunded, and Hosts
              must not accept invalid tickets. If you are a Guest and you
              receive a refund for your ticket, you will discard the ticket and
              will not use it (or any copy of it) to attend the Event. Violation
              of this is fraud.
            </p>
            <p>
              If you are a Host, you acknowledge that the applicable procedure
              to check the validity of the ticket must always be followed,
              including checking in Guests with the App or on the Platform.
            </p>
            <p>
              We will not be liable under any circumstances for any costs
              arising from Hosts’ non-compliance with the procedures that must
              be implemented by Hosts to check the validity of tickets or any
              violations of these Terms of Service or the Host Agreement. We
              will not be liable under any circumstances for costs and/or damage
              arising from ticket-related fraud and/or the purchase of the
              ticket through non-official means, such as third parties.
            </p>
          </li>
          <li>
            <p>
              <b>10. Ticket Purchasing</b>
            </p>

            <p>
              a. <u>Our Role</u>
            </p>
            <p>
              We are not event organizers, venue owners or operators or artists
              or performers who may appear at an Event. Instead, we provide an
              online platform which allows Hosts to sell tickets and collect
              payments. We are acting solely as a limited agent of Host with
              respect to ticket sales for those Events that are not offered on a
              free basis, and we are not involved with the operations of any
              Event. This means that, among other things, we have no influence
              on the quality of the Event, the decision to modify an Event in
              any way or the enforcement of the Host's rules for the attendance
              of an Event.
            </p>
            <p>
              If you are a Guest at an Event and are unhappy with the Event,
              your sole recourse is to take the matter up directly with the Host
              who can address your concerns and issue refunds, at the Host's
              discretion. By using the Platform, you agree not to bring any
              claims against us or our Affiliates for any potential issues you
              may have with a Host's terms or services. “Affiliate” means a
              person, company or entity controlling, controlled by, under common
              control, or working in concert, with a party.
            </p>

            <p>
              b. <u>Pricing and Availability</u>
            </p>

            <p>
              Hosts set the number of tickets available for an Event, and their
              pricing. Pricing and availability may change over time.
            </p>

            <p>
              Once you have successfully added tickets to our checkout on the
              Platform, you will have a limited amount of time to complete your
              purchase. If the time expires, you will have to begin the process
              again, and you may see changes in price or availability for your
              chosen tickets.
            </p>

            <p>
              c. <u>Errors</u>
            </p>

            <p>
              If, due to human error or our Platform's malfunction, you purchase
              a ticket at a time or price not approved by the Host, we have the
              right to cancel the transaction and refund the full amount you
              paid.
            </p>

            <p>
              d. <u>Purchasing</u>
            </p>

            <p>
              Before purchasing a ticket, you should make sure to carefully read
              all the details and the terms and conditions for attending an
              Event and purchasing tickets. This includes confirming that you
              have selected the correct time, date, quantity, and type of
              ticket. You should also read the Host's rules listed on the Event
              page and make sure that you understand what the Host expects of
              you (e.g., appropriate wardrobe and conduct).
            </p>

            <p>
              We and our Hosts may put conditions on your use of promotional and
              discount codes. Please check the terms of these codes individually
              when you receive them.
            </p>

            <p>
              e. <u>Order Confirmation</u>
            </p>

            <p>
              If the ticket order is correct you may make your purchase. By
              doing so, you agree to pay in full and use only the payment
              methods you are authorized to use. After you finish, you will be
              emailed an order confirmation. You can also view your tickets by
              logging into your Member account on the Platform.
            </p>

            <p>
              If you do not receive a confirmation for your tickets within 24
              hours, please contact us at Support to check the status of your
              order. Let us know as many details as you can about the
              transaction, including your full name and order number.
            </p>

            <p>
              f. <u>Using Your Tickets</u>
            </p>

            <p>
              After you purchase a ticket, you should keep track of it and not
              let anyone else get a copy of your tickets or have access to your
              email account where they can print duplicates or create
              counterfeit tickets. In certain cases, the Events for which we
              sell tickets may be using scanning equipment to verify the
              validity of your tickets.
            </p>

            <p>
              g. <u>Scheduling Changes and Cancellations</u>
            </p>

            <p>
              Any decision to cancel is the Host's and not in our control. We
              ask Hosts to give advance warning, and we will notify you as soon
              as we become aware of a cancellation of an Event. However, we
              cannot guarantee that you will not be inconvenienced by an Event
              cancellation.
            </p>

            <p>
              h. <u>Refunds</u>
            </p>

            <p>
              All purchases are final and cannot be exchanged for tickets for
              another Event. Please be aware that a ticket is a revocable
              license. Admission can be refused at the Host's discretion. A
              ticket cannot be redeemed for cash. Refunds will only be issued at
              the discretion of the Host and the amount of the refund for any
              ticket shall not include the Guest Fees or payment processing
              charges, which shall be deducted from the refund amount.
            </p>
            <p>
              If an Event has been moved or rescheduled, please contact the Host
              directly for further information.
            </p>

            <p>
              i. <u>Unlawful Resale</u>
            </p>

            <p>
              We can guarantee the authenticity of only those tickets purchased
              directly on our Platform. Other online vendors, brokers, or
              individuals may try to sell you tickets to Events; but we can only
              verify the legitimacy of tickets purchased directly through our
              Platform.
            </p>

            <p>
              Some states have limits on the maximum resale value of tickets. If
              you choose to resell your ticket, you are responsible for
              following all laws that govern resale transactions.
            </p>

            <p>
              Our tickets are for individual use only. The advertising of our
              tickets in sweepstakes or promotions is not allowed without our
              written preauthorization.
            </p>

            <p>
              j. <u>Ejection</u>
            </p>

            <p>
              The Host always reserves the right to refuse admission to, or
              remove, anyone who acts with disorderly conduct or otherwise
              violates any rule of the Host (e.g., refusing entry to those
              carrying alcohol, recording devices, and/or noise/chemical
              irritants).
            </p>

            <p>
              To enforce this policy, the Host may subject you and your personal
              belongings to a search when you enter the Event location or at any
              time afterwards.
            </p>

            <p>
              k. <u>Security</u>
            </p>

            <p>
              We use the best commercially available security measures possible
              to safeguard our systems, and to ensure the security of your data.
              However, the Internet is never 100% secure. You should take the
              steps you can to protect yourself from identity theft.
            </p>

            <p>
              Never share your passwords. We, therefore, assume no liability for
              any problems that arise from the interception and misuse of
              information on our sites and apps.
            </p>
          </li>
          <li>
            <p>
              <b>11. Member Content</b>
            </p>

            <p>
              <b>"Member Content"</b> means any material that you or other
              Members or in some instances visitors submit or transmit in any
              manner to the Platform for any purpose, whether publicly posted or
              privately transmitted, and includes, but is not limited to, any
              text, images, audio material, video material, and audio-visual
              material. With respect to Hosts, all information, materials and
              content related to their Events is Member Content for which the
              Hosts are solely responsible. This Platform, the App and the
              Services provided may include multiple ways of submitting Member
              Content, including, for example, through posting of Events, use of
              forums, chats, online bulletin boards, and similar tools that
              allow Hosts to post Events and Members to communicate with others
              or submit content. Unless we indicate that particular Member
              Content you submit will be kept confidential, your Member Content
              may be made available for other Members and in some instances
              publicly to visitors to view on the Platform or through
              third-party services. You hereby represent and warrant that the
              Member Content you submit does not violate our Acceptable Use
              Policy stated herein.
            </p>

            <p>
              You are prohibited from submitting any Member Content that is
              illegal, unlawful, or infringes on the legal rights of any third
              party. Furthermore, you may not submit Member Content that has
              ever been the subject of any actual or threatened legal
              proceedings or any Member Content that may give rise to a legal
              action under any applicable law, whether against you, us, or a
              third party.
            </p>

            <p>
              You agree that you are solely responsible for any consequences
              that may result from submitting Member Content, including use of
              the Member Content by other visitors of the Platform and by our
              third party partners. Unless we indicate that particular Member
              Content you submit will be kept confidential, your Member Content
              may be distributed, published, broadcast, or syndicated by us or
              our third-party partners. You may be subject to liability if you
              submit Member Content without the authority to do so.
            </p>

            <p>
              We do not monitor the submission or publication of Member Content
              on the Platform and are not responsible for the Member Content. We
              do not endorse or support any Member Content or the opinions
              contained therein and do not guarantee the truthfulness, accuracy,
              suitability, or reliability of any Member Content or other
              communications submitted or published on the Platform. You agree
              that we are not responsible for any loss or damage you may incur
              as a result of interacting with others on the Platform or through
              the App, and we are under no obligation to become involved in any
              such disputes. You may not represent that we have provided,
              endorsed, or supported the Member Content you submit. Your use or
              reliance on any Member Content is at your own risk. You assume all
              risks associated with the Member Content you submit, including any
              reliance on the Member Content and the risk of disclosure of the
              Member Content that may identify you personally to a third party.
              You understand and accept that by using the Services or Platform
              you may be exposed to Member Content that is offensive,
              inaccurate, deceptive, harmful, or otherwise inappropriate.
            </p>

            <p>
              Member Content may be subject to specific rules and limitations we
              post or communicate to you from time to time. You agree to only
              submit Member Content conforming to such rules and limitations.
            </p>

            <p>
              We may remove, edit, or delete any Member Content submitted,
              hosted, or stored on the Platform or our servers without providing
              you with prior notice. We are not obligated to store or back up
              any Member Content. Therefore, you are responsible for creating
              backup copies of any Member Content you wish to keep.
            </p>

            <p>
              Under no circumstances may Sondr be held liable in any way for any
              Member Content, including, but not limited to, for any errors or
              omissions in the Member Content or for any loss or damage of any
              kind that may occur as a result of any Member Content submitted,
              published, or otherwise made available on the Platform, the App,
              the Services, or elsewhere.
            </p>
          </li>
          <li>
            <p>
              <b>12. Member Content License</b>
            </p>

            <p>
              By submitting Member Content you grant Sondr an irrevocable,
              non-exclusive, royalty-free and fully paid, worldwide license
              (with right to assign and to sublicense) to use, publish, copy,
              adapt, modify, process, reproduce, transmit, distribute,
              translate, publicly and privately display and perform, incorporate
              into other works, prepare derivative works of, bring actions for
              infringement of, and otherwise use and exploit your Member Content
              in any and all media or distribution methods now known or later
              developed. This license includes the right to provide the Member
              Content to our third-party partners for distribution, publication,
              syndication, and broadcast on other media, devices, and services
              subject to our terms and conditions for the use of such Member
              Content. Furthermore, you irrevocably waive any claims of moral
              rights or attribution regarding your Member Content. You represent
              and warrant that you have all rights, power, and authority to
              grant all the rights pertaining to Member Content as stated in
              these Terms of Service.
            </p>

            <p>
              This means that you will always maintain ownership of the Member
              Content you submit. However, among other things, we have the right
              to display, perform, copy, edit and arrange the Member Content,
              make various changes to it as are necessary in providing our
              Services and improving our customers' experience on the Platform,
              create listings and databases, and to conform to the needs of
              different media, devices, services, and computer networks. At no
              time will you be entitled to any compensation for this license of
              your Member Content to us.
            </p>

            <p>
              If you participate in any online sessions, webinars or other
              interactive sessions organized by Sondr, you irrevocably agree and
              consent that Sondr and its agents may record the video and audio
              content of your appearances at any such webinars, workshops,
              training sessions, exercises, interviews and other events you
              attend online or in person and use such recordings of you and any
              derivative works based on or related to them for any purpose,
              including without limitation, for training, educational,
              advertising, promotional and marketing purposes.
            </p>

            <p>
              You also grant to Sondr for valuable consideration, the receipt
              and sufficiency of which you acknowledge, the right, throughout
              the world and in perpetuity, to use your appearance, picture,
              silhouette and other reproductions of your name, image, likeness
              and voice, including your personal identification, biographical
              information, personal experiences, life story, ideas, suggestions,
              techniques, methods and responses to the interviewer’s questions,
              your performance and/or any other works authored by you or
              produced with your participation as may be recorded in connection
              with any video, motion picture, television, computer-based,
              multimedia, software, SaaS and/or any other audio-visual, audio
              and/or visual work, program or content in which your appearance,
              interview and/or performance may be incorporated, and in any
              advertising promoting it. You agree to execute all documents,
              agreement, certificates and instruments to effectuate this grant
              of copyright and other rights to Sondr.
            </p>

            <p>
              Sondr and/or its agents may edit your appearance, interview and
              performance they see fit. Sondr shall have all right, title and
              interest in any and all works utilizing such appearance, interview
              and performance and all results and proceeds from said use. Sondr
              may authorize or license others to perform, reproduce, broadcast,
              distribute and otherwise display any and all such works.
            </p>

            <p>
              The rights irrevocably granted to Sondr here are perpetual,
              worldwide, exclusive, royalty-free and fee-free and include the
              use of recordings in any medium, including broadcast and cable
              television, motion pictures, internet, software, websites, SaaS
              platforms, mobile apps, and in any and all media which currently
              exist or may exist in the future in all countries of the world and
              in perpetuity. Sondr will have copyright in all such recordings
              and works, and you will not have any ownership or interest in such
              recordings and works or any copyrights thereto.
            </p>
          </li>
          <li>
            <p>
              <b>13. Enforcement of Acceptable Use Policy</b>
            </p>

            <p>
              We reserve the right to review and investigate your use of the
              Platform and the App and to take any appropriate action against
              you that we determine is necessary in our sole discretion should
              you violate these Terms of Service, other rules and policies
              posted on the Platform or the App or otherwise create liability,
              loss, or damage for us, our Platform, other Members, visitors or
              other third parties. Such action may include, but is not limited
              to, restricting your account or membership privileges or
              terminating your account and membership, initiating proceedings to
              recover any losses and reporting you to law enforcement
              authorities.
            </p>
          </li>
          <li>
            <p>
              <b>14. Third-Party Resources</b>
            </p>
            <p>
              The Platform may contain links and advertisements to third-party
              websites and services (collectively, "Third-Party Resources"). You
              agree and acknowledge that Third-Party Resources are not under our
              control and we are not responsible for the content, products, or
              services they provide.
            </p>
            <p>
              We provide access to Third-Party Resources only as a convenience
              to you and do not monitor, endorse, warrant, or make any
              representations regarding Third-Party Resources. It is your sole
              responsibility to exercise appropriate caution and discretion when
              using Third-Party Resources, and you acknowledge that you assume
              all risks that arise from such use.
            </p>
          </li>
          <li>
            <p>
              <b>15. Modification of Platform or Services</b>
            </p>
            <p>
              We reserve the right to modify, add to, suspend, or terminate all
              or part of the Platform or Services at any time with or without
              providing prior notice to you. This includes the right to create
              limits on our Members' use of Services and data storage. You agree
              that we are not liable for using or enforcing the rights stated in
              this paragraph. Unless we indicate otherwise, any future
              modifications to the Platform and Services are subject to these
              Terms of Service.
            </p>
          </li>
          <li>
            <p>
              <b>16. Support or Maintenance</b>
            </p>
            <p>
              Although we may choose to provide customer support or website
              maintenance, you acknowledge and agree that we are under no
              obligation to do so.
            </p>
          </li>
          <li>
            <p>
              <b>17. Restricted Access</b>
            </p>
            <p>
              Some parts of the Platform may be restricted to certain visitors
              or certain Members (such as Hosts). If you have permission to
              access restricted parts of the Platform, you agree to not share
              your access information and password with third parties. We may
              change the restricted parts of the Platform from time to time. If
              you do not have access to restricted parts of the Platform, you
              agree not to use another Member's account to gain such access or
              otherwise attempt to gain improper access to the restricted parts
              of the Platform.
            </p>
          </li>
          <li>
            <p>
              <b>18. Privacy</b>
            </p>
            <p>
              We manage the collection, use, and security of your personal
              information according to our Privacy Policy, incorporated herein
              by reference. By using this Platform and our Services you consent
              to our collection and use of your personal information as set
              forth in the Privacy Policy.
            </p>
            <p>
              At times we may need to send you communications related to the
              Platform or the Services. Such communications are considered part
              of the Services, and you may not be able to opt out of receiving
              them.
            </p>
            <p>
              We reserve the right to access and disclose the information and
              Member Content you submit to the Platform if required to do so by
              law or if we have a reasonable, good-faith belief that doing so is
              necessary for (i) responding to requests for customer service,
              (ii) addressing fraud, security, or technical issues, (iii)
              protecting the rights, property, and safety of Sondr, its Members,
              visitors, employees, agents, independent contractors,
              sub-contractors and the public, (iv) responding to legal claims
              and processes, or (v) enforcing these Terms of Service, including
              investigating potential violations.
            </p>
          </li>
          <li>
            <p>
              <b>19. Proprietary Rights and Licenses</b>
            </p>

            <p>
              a. <u>Ownership of Proprietary Information</u>. You hereby
              acknowledge and agree that Sondr is the owner of highly valuable
              proprietary information, including without limitation, the Event
              Hosting Service, the Introduction Service, the Content Service and
              the Premium Service, and all of their components and elements
              (collectively,
              <b>“Confidential Information”</b>) . Sondr owns and hereby retains
              all proprietary rights in the Platform, the App and the Services
              including but not limited to, all Confidential Information.
            </p>

            <p>
              b. <u>No Use of Confidential Information</u>. You will not post,
              copy, modify, transmit, disclose, show in public, create any
              derivative works from, distribute, make commercial use of, or
              reproduce in any way any (i) Confidential Information or (ii)
              other copyrighted material, trademarks, or other proprietary
              information accessible via the Services, without first obtaining
              the prior written consent of the owner of such proprietary rights.
            </p>

            <p>
              c. <u>Other Members’ Information</u>. Other Members may post
              copyrighted information, which has copyright protection whether or
              not it is identified as copyrighted. You agree that you will not
              copy, modify, publish, transmit, distribute, perform, display,
              commercially use, or sell any Sondr or third party proprietary
              information available to you via the Platform, the App and the
              Services.
            </p>

            <p>
              d. <u>License to Posted or Accessed Member Content</u>. By posting
              information or content to any profile pages or public area of the
              Services, or making it accessible to us by linking your account
              with us to any of your social network accounts, you automatically
              grant, and you represent and warrant that you have the right to
              grant, to Sondr and its Members, an irrevocable, perpetual,
              non-exclusive, fully-paid, worldwide license to use, reproduce,
              publicly perform, publicly display and distribute such information
              and content, and to prepare derivative works of, or incorporate
              into other works, such information and content, and to grant and
              authorize sub-licenses of the foregoing. From time to time, we may
              create, test or implement new features or programs on the Platform
              in which you may voluntarily choose to participate or may be a
              part of a test group with special access, in accordance with the
              additional terms and conditions of such features or programs. By
              your participation in such features or programs, you grant us the
              rights stated in this subsection in connection with the additional
              terms and conditions (if any) of such features or programs.
            </p>

            <p>
              e. <u>Rights to the Platform and the Services</u>. Unless
              otherwise stated, Sondr and its permitted licensors own all
              intellectual property rights in the Platform and the Services and
              their respective contents, excluding Member Content. These rights
              include, but are not limited to, ownership of all text, graphics,
              images, logos, copyrighted material, trademarks, patents,
              software, and other distinctive brand features displayed on the
              Platform or Services, including the compilation of any of the
              foregoing items. Except for the limited rights granted by these
              Terms of Service, neither these Terms of Service nor your use or
              access to the Platform give you or any third party any
              intellectual property rights. We reserve all rights not explicitly
              granted by these Terms of Service, which do not grant any implied
              licenses. All copyrights, trademarks, and other intellectual
              property notices on the Platform or Services must be retained on
              all copies thereof. You may not publish, reverse engineer, modify,
              distribute, transmit, sell, create derivative or plagiaristic
              works of, or use or exploit for any commercial reason, whether in
              whole or in part, any of the content on the Platform or Services
              other than your own Member Content without our express prior
              written consent or the consent of any third-party owners of the
              content. Platform and Services content is not for resale under any
              circumstances.
            </p>
            <p>
              f. <u>Our Content</u>. Any text, graphics, user interfaces,
              trademarks, logos, sounds, photos, videos, artwork, other content
              and other intellectual property appearing on the Platform, the App
              or the Services, other than Member Content and any third party
              content ("Our Content") are owned, controlled or licensed by us
              and are protected by copyright, trademark and other intellectual
              property law rights. All right, title and interest in and to Our
              Content remains with us at all times.
            </p>
            <p>
              We grant you a non-exclusive, limited, personal, non-transferable,
              revocable, license to access and use Our Content, without the
              right to sublicense, under the following conditions:
            </p>
            <ul className={styles.ml4}>
              <li>
                i. you shall not use, sell, modify, or distribute Our Content
                except as permitted by the functionality of the App;
              </li>
              <li>
                ii. you shall not use our name in metatags, keywords and/or
                hidden text;
              </li>
              <li>
                iii. you shall not create derivative works from Our Content or
                commercially exploit Our Content, in whole or in part, in any
                way; and
              </li>
              <li>iv. you shall use Our Content for lawful purposes only.</li>
            </ul>

            <p>We reserve all other rights to Our Content.</p>

            <p>
              g. The visual interfaces, information, graphics, design,
              compilation, computer code, products, software, methodologies,
              tools, processes and know-how, including the mobile device
              applications, and all other elements of the Platform, the App and
              the Services (collectively, the
              <b>“Technology”</b>) are protected by United States copyright,
              trade dress, patent, and trademark laws, international laws and
              conventions, and all other relevant intellectual property and
              proprietary rights, and applicable laws. All Technology contained
              in the Services are the exclusive property of Sondr or its
              subsidiaries or Affiliates and/or third-party licensors. All
              trademarks, service marks, and trade names displayed on the
              Platform, the App and the Services are proprietary to Sondr or its
              Affiliates and/or third-party licensors. Except as expressly
              authorized by Sondr under these Terms of Service, you agree not to
              sell, license, distribute, copy, modify, publicly perform or
              display, transmit, publish, edit, adapt, create derivative works
              from, or otherwise use the Technology.
            </p>
          </li>
          <li>
            <p>
              <b>20. Copyright Policy</b>
            </p>

            <p>
              We expect you to respect the intellectual property rights of
              others when using the Platform, the App and the Services. We will
              respond to any notices of copyright infringement that we receive
              that comply with applicable law and are properly submitted to us
              (pursuant to 17 U.S.C. 512(c)). In order to submit a notice of
              infringement of your copyrighted material, please provide us with
              the following information:
            </p>

            <p>
              a. A physical or electronic signature of the copyright owner or a
              person authorized to act on their behalf;
            </p>
            <p>
              b. Identification of the copyrighted work or works claimed to have
              been infringed;
            </p>
            <p>
              c. Identification of the material that is claimed to be infringing
              or to be the subject of infringing activity and that is to be
              removed or access to which is to be disabled, and information
              reasonably sufficient to permit us to locate the material;
            </p>
            <p>
              d. Your contact information, including your address, telephone
              number, and an email address, if available;
            </p>
            <p>
              e. A statement by you that you have a good-faith belief that use
              of the material in the manner complained of is not authorized by
              the copyright owner, its agent, or the law; and
            </p>
            <p>
              f. A statement that the information in the notification is
              accurate, and, under penalty of perjury, that you are authorized
              to act on behalf of the copyright owner.
            </p>

            <p>
              We may remove any content that is alleged to be infringing at our
              sole discretion without prior notice and without liability to you.
              We also reserve the right to take other appropriate action against
              infringers, such as terminating the Member's account if the Member
              is determined to be a repeat infringer. Please send any notice of
              alleged copyright infringement to our designated copyright agent
              at the following address:
            </p>

            <p>
              Sondr, Inc.
              <br />
              Attn: Copyright Agent
              <br />
              442 Fifth Avenue, #1625
              <br />
              New York, NY 10018
              <br />
              Email: legal@joinsocha.com
            </p>

            <p>
              Note that pursuant to 17 U.S.C. 512(f), any material
              misrepresentation in a written notification that content is
              infringing or that allegedly infringing content was removed by
              mistake or misidentification automatically subjects the
              complaining party to liability for damages, including costs and
              attorney's fees incurred by the alleged infringer, by any
              copyright owner or copyright owner's authorized licensee, or by
              us, if injured by such misrepresentation.
            </p>
          </li>
          <li>
            <p>
              <b>21. Disclaimers</b>
            </p>

            <p>
              THIS SECTION ONLY APPLIES TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW.
            </p>

            <p>
              <b>
                THE PLATFORM, THE APP AND ALL SERVICES ARE PROVIDED TO YOU ON AN
                "AS-IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY REPRESENTATIONS OR
                WARRANTIES WITH REGARD TO THE CONTENT PROVIDED ON THE PLATFORM,
                THE APP OR IN ANY SERVICES. WE, OUR SUBSIDIARIES, AFFILIATES,
                SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS,
                ADVISORS, PARTNERS, SUPPLIERS, AGENTS, AND LICENSORS EXPRESSLY
                DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND,
                WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES
                OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT
              </b>
              . THIS INCLUDES, WITHOUT LIMITATION TO THE FOREGOING, NO WARRANTY
              THAT THE PLATFORM, THE APP, ITS CONTENT, OR THE SERVICES WILL BE
              CONSTANTLY AVAILABLE OR AVAILABLE AT ALL, UNINTERRUPTED, USEFUL,
              TRUE, ACCURATE, NON-MISLEADING, TIMELY, RELIABLE, COMPLETE,
              ERROR-FREE, FREE OF OMISSIONS, SECURE, FREE OF VIRUSES OR OTHER
              HARMFUL CODE, LEGAL, OR SAFE. NO INFORMATION, WHETHER ORAL OR
              WRITTEN, OBTAINED FROM US OR THROUGH THE PLATFORM OR SERVICES WILL
              CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. YOUR USE OF THIS
              PLATFORM, THE APP AND THE SERVICES IS AT YOUR SOLE RISK AND WE
              ASSUME NO RESPONSIBILITY FOR HARM TO YOUR COMPUTER SYSTEM, LOSS OF
              DATA, THE DELETION OF INFORMATION YOU TRANSMIT ON THE PLATFORM, OR
              THE DELETION OR FAILURE TO STORE OR TRANSMIT MEMBER CONTENT OR
              PERSONALIZATION OR PROFILE SETTINGS THAT MAY RESULT FROM YOUR
              ACCESS TO OR USE OF THE PLATFORM AND SERVICES. YOU HEREBY WAIVE
              ANY AND ALL CLAIMS AND CAUSES OF ACTION THAT MAY CAUSE DAMAGE TO
              YOUR COMPUTER OR INTERNET ACCESS.
            </p>

            <p>
              THIS PLATFORM AND ITS CONTENTS ARE PROVIDED FOR INFORMATIONAL
              PURPOSES ONLY. NOTHING ON THIS PLATFORM CONSTITUTES, IS MEANT TO
              CONSTITUTE, OR MAY BE USED AS ADVICE OF ANY KIND, INCLUDING, BUT
              NOT LIMITED TO, LEGAL, FINANCIAL (INCLUDING TRADING OR INVESTMENT
              PURPOSES), OR MEDICAL ADVICE. WE ENCOURAGE YOU TO CONSULT THE
              APPROPRIATE PROFESSIONAL SHOULD YOU REQUIRE LEGAL, FINANCIAL,
              MEDICAL, OR OTHER PROFESSIONAL ADVICE.
            </p>

            <p>
              IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE
              PLATFORM OR THE APP ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO
              NINETY (90) DAYS FROM THE DATE OF FIRST USE.
            </p>

            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME
              JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
              WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
              NOTHING IN THIS SECTION IS INTENDED TO LIMIT ANY RIGHTS YOU MAY
              HAVE WHICH MAY NOT BE LAWFULLY LIMITED.
            </p>

            <p>
              Opinions, advice, statements, offers, or other information or
              content made available through the Platform, the App and the
              Services, but not directly by Sondr, are those of their respective
              authors, and should not necessarily be relied upon. Such authors
              are solely responsible for such content. SONDR DOES NOT: (i)
              GUARANTEE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY
              INFORMATION PROVIDED ON THE SERVICES, OR (ii) ADOPT, ENDORSE OR
              ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
              OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN SONDR.
              UNDER NO CIRCUMSTANCES WILL SONDR BE RESPONSIBLE FOR ANY LOSS OR
              DAMAGE RESULTING FROM ANYONE’S RELIANCE ON INFORMATION OR OTHER
              CONTENT POSTED ON THE PLATFORM OR THE SERVICES, OR TRANSMITTED TO
              OR BY ANY MEMBERS.
            </p>

            <p>
              If you purchase a ticket for an Event through the Platform, you
              assume all risks associated with attending the Event, including
              but not limited to the risks of inclement weather, and waive any
              claims for loss or destruction of property, personal injury or
              death against us and our Affiliates.
            </p>

            <p>
              From time to time, Sondr may offer new “beta” features or tools
              with which its Members may experiment on the Platform, the App
              and/or the Services. Such features or tools are offered solely for
              experimental purposes and without any warranty of any kind, and
              may be modified or discontinued at Sondr’s sole discretion. The
              provisions of this Disclaimer of Warranty section apply with full
              force to such “beta” features or tools.
            </p>
          </li>
          <li>
            <p>
              <b>22. Limitation of Liability</b>
            </p>

            <p>
              To the extent allowed under applicable laws, the Services are
              provided on an "as is" and "as available" basis. We expressly
              disclaim all warranties of any kind, express or implied, including
              implied warranties of merchantability, title, non-infringement,
              and fitness for a particular purpose. For example, we make no
              warranty that:
              <br />
              (a) the Services (or any portion of the Services) will meet your
              requirements or expectations;
              <br />
              (b) the Services will be uninterrupted, timely, secure, or
              error-free; or
              <br />
              (c) the results that may be obtained from the use of the Services
              will be accurate or reliable.
            </p>

            <p>
              We have no control over and do not guarantee the (i) quality,
              safety, success, accuracy, or legality of any Event or Member
              Content associated with an Event, (ii) accuracy of any information
              provided by Members (including Feedback and Guests’ personal
              information shared with Hosts in connection with events), or (iii)
              ability of any Member to complete a transaction.
            </p>

            <p>
              You hereby agree that we are not liable for the acts or omissions
              of any third parties, including third parties that help us provide
              the Services, that a Host chooses to assist with an Event, or that
              you choose to use or contract with when using the Services.
            </p>

            <p>
              We do not host any Events; Hosts do. We may elect to sponsor
              certain Events at our absolute discretion and based on a written
              agreement between us and a Host of each such Event, but these
              Hosts shall remain solely responsible for anything that takes
              place at their Events regardless of our sponsorship. You hereby
              acknowledge that Events may carry inherent risk and by
              participating in those Events, you choose to assume those risks
              voluntarily. For example, some Events may carry risk of illness,
              bodily injury, disability, or death, and you freely and willfully
              assume those risks by choosing to participate in those Events.
            </p>

            <p>
              The disclaimers in these Terms of Service apply to the maximum
              extent permitted by law. If any warranties are required by
              applicable law, they will be limited to the shortest duration
              allowed.
            </p>

            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SONDR,
              INCLUDING ITS SUBSIDIARIES, AFFILIATES, SHAREHOLDERS, OFFICERS,
              DIRECTORS, MANAGERS, EMPLOYEES, CONTRACTORS, ADVISORS, PARTNERS,
              SUPPLIERS, AGENTS, AND LICENSORS, MAY NOT BE HELD LIABLE FOR ANY
              INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
              OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
              INDIRECTLY, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL
              DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS
              OR MEETINGS WITH OTHER MEMBERS OR THIRD PARTIES OR ATTENDANCE OF
              EVENTS. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM
              THE CONDUCT OF MEMBERS WHO HAVE REGISTERED UNDER FALSE PRETENSES
              OR WHO ATTEMPT TO DEFRAUD OR HARM YOU OR ANY LOSS OF DATA, USE,
              GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR
              ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE PLATFORM,
              THE APP OR THE SERVICES; (ii) THE COST OF PROCURING SUBSTITUTE
              GOODS OR SERVICES; (iii) ANY CONDUCT OR CONTENT OF ANY THIRD
              PARTY, INCLUDING OTHER MEMBERS, ON THE PLATFORM, AT AN EVENT OR
              THROUGH SERVICES, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY,
              OFFENSIVE, UNLAWFUL OR ILLEGAL CONDUCT OF OTHER MEMBERS OR THIRD
              PARTIES; (iv) ANY CONTENT OBTAINED FROM THE SERVICES; (v)
              UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR TRANSMISSIONS OR
              CONTENT; OR (vi) OTHER MATTERS RELATED TO THE PLATFORM, THE APP,
              EVENTS OR SERVICES. THESE LIMITATIONS APPLY EVEN IF WE HAVE BEEN
              EXPRESSLY ADVISED OF THE POTENTIAL LOSS OR LIABILITY.
            </p>

            <p>
              FOR THE AVOIDANCE OF DOUBT, TO THE MAXIMUM EXTENT PERMITTED BY
              APPLICABLE LAW, IN NO EVENT WILL SONDR BE LIABLE FOR ANY DAMAGES
              WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL,
              COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR
              RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH
              THE USE OF THE PLATFORM OR THE SERVICES, INCLUDING WITHOUT
              LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY OTHER
              DAMAGES RESULTING FROM INTRODUCTION, COMMUNICATIONS OR MEETINGS
              WITH OTHER MEMBERS OR THIRD PARTIES. THIS INCLUDES ANY CLAIMS,
              LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF MEMBERS WHO HAVE
              REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM
              YOU.
            </p>

            <p>
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
              EVENT MAY OUR AGGREGATE LIABILITY EXCEED THE GREATER OF THE TICKET
              PRICE YOU PAID FOR AN EVENT AND TEN (US$10) DOLLARS. THE EXISTENCE
              OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT
              OUR AFFILIATES, SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES,
              PARTNERS, SUPPLIERS, AGENTS, ADVISORS AND LICENSORS WILL HAVE NO
              LIABILITY OF ANY KIND ARISING FROM OR RELATING TO YOUR USE OF THE
              PLATFORM, THE APP OR SERVICES OR ATTENDANCE AT AN EVENT. YOU
              FURTHER AGREE NOT TO BRING ANY CLAIM PERSONALLY AGAINST OUR
              SUBSIDIARIES, AFFILIATES, SHAREHOLDERS, OFFICERS, DIRECTORS,
              MANAGERS, EMPLOYEES, CONTRACTORS, AGENTS, ADVISORS, PARTNERS,
              SUPPLIERS, OR LICENSORS. THE LIMITATIONS IN THIS SECTION APPLY
              REGARDLESS OF THE THEORY OF LIABILITY, WHETHER BASED IN CONTRACT,
              TORT (INCLUDING NEGLIGENCE), WARRANTY, STATUTE, OR OTHERWISE.
            </p>

            <p>
              OTE THAT SOME JURISDICTIONS PROHIBIT THE LIMITATION OR EXCLUSION
              OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND
              THEREFORE THE LIMITATIONS AND EXCLUSION ABOVE MAY NOT APPLY TO
              YOU. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>

            <p>
              <u>Information Verification</u>. Sondr and its service providers
              may use various ways of verifying information that visitors and
              Members have provided. However, you agree that Sondr and its
              service providers will have no liability to you arising from any
              incorrectly verified information or from any failure to verify
              information.
            </p>
          </li>
          <li>
            <p>
              <b>23. Indemnity</b>
            </p>
            <p>
              You agree to indemnify, defend, and hold harmless Sondr and its
              subsidiaries, Affiliates, shareholders, officers, directors,
              employees, contractors, agents, advisors, partners, suppliers, and
              licensors, including, but not limited to, legal costs and
              attorney's fees, from any claim or disputes by a third party
              arising out of or relating to any third party claim with respect
              to: (a) your use of or inability to use the Platform, the App or
              the Services, (b) any Member postings (including, without
              limitation), Event postings, made by you or through your account,
              including without limitation your posting, modifying or otherwise
              transmitting Member Content through the Platform, the App or the
              Services, (c) your violation of any terms of these Terms of
              Service, the Host Agreement (when you act as a Host), or your
              violation of any rights of a third party, or (d) your violation of
              any applicable laws, rules, regulations or ordinances. We reserve
              the right, at your expense, to assume exclusive control over the
              defense of any claim or dispute for which you must indemnify us.
              You agree to cooperate fully with us in defending such claims or
              disputes, and you agree not to settle any such claims or disputes
              without our prior written consent. We will make a reasonable
              effort to provide you with notice of any such claim or dispute
              once we receive notice.
            </p>
          </li>
          <li>
            <p>
              <b>24. Release</b>
            </p>
            <p>
              To the fullest extent permitted by applicable law, you hereby
              release and forever discharge Sondr (and our members, officers,
              managers, employees, agents, successors, and assigns) from, and
              hereby waive and relinquish, each and every past, present and
              future dispute, claim, controversy, demand, right, obligation,
              liability, action and cause of action of every kind and nature
              (including personal injuries, emotional distress, identity theft,
              death, and property loss and damage), that has arisen or arises
              directly or indirectly out of, or relates directly or indirectly
              to, (1) the conduct of an Event you host or attend, (2) any
              interactions with, or act or omission of, or Member Content
              provided by, other Members or third parties or (3) any third-party
              platform, products, services, and links included on or accessed
              through the Services.
            </p>
          </li>
          <li>
            <p>
              <b>25. Term and Termination</b>
            </p>
            <p>
              We may suspend or terminate your access to the Platform, the App
              and any Service at any time and for any reason or no reason at
              all, with or without notice, at our sole discretion. This may
              result in deletion of information associated with your account.
              You may also terminate your account by deactivating it or by
              submitting a termination request and discontinuing your use of the
              Services. Your account may be deactivated if it experiences a
              prolonged period of inactivity. Your Member Content may also be
              deleted in the event your access is terminated. Where applicable,
              all rights and responsibilities of the parties under these Terms
              of Service and the Host Agreement will survive the termination of
              these Terms of Service and the Host Agreement, including, without
              limitation, intellectual property ownership, warranties,
              disclaimers, and limitations of liability.
            </p>
            <p>
              These Terms of Service will become effective upon your acceptance
              of the Agreement at the initial registration or by your use of the
              Platform, the App or the Services and will remain in effect in
              perpetuity unless terminated hereunder. Either you or Sondr may
              terminate your account at any time, for any reason or no reason,
              without explanation, effective upon sending written notice to the
              other party. Sondr reserves the right to immediately suspend or
              terminate your access to any of the Services, without notice, for
              any reason or no reason. We also reserve the right to remove your
              account information and/or data from our Services and any other
              records at any time at our sole discretion. In the event your
              access to any of the Services is suspended due to the breach of
              these Terms of Service, you agree that all fees then paid to Sondr
              by you will be nonrefundable and all outstanding or pending
              payments will immediately be due. You may terminate your account
              by following the steps in the applicable section under
              “Cancellations” below, or by sending a notice of cancellation to:
              support@joinsocha.com. Following any termination of any Member’s
              use of the Services, Sondr reserves the right to send a notice
              thereof to other Members which whom you have corresponded.
            </p>
          </li>
          <li>
            <p>
              <b> 26. Cancellations </b>
            </p>
            <p>
              a. <u>Cancellation At Any Time With No Refund</u>. In the event we
              introduce any Premium Service, you will be able to cancel your
              free membership with us or fee-based subscription to any such
              Premium Service at any time during the term of such subscription
              or any renewal period by accessing the “Profile” page option on
              the App, clicking on “Cancel My Subscription.” or “Delete Account”
              link, and providing the information requested. With respect to
              Premium Services which require payment of a subscription fee,
              except as otherwise stated in this section, your subscription will
              then terminate at the end of the subscription term for which you
              have paid, and you will not receive any refund for any unused days
              of such subscription term. If you purchase a subscription on an
              installment payment basis, your ability to cancel such
              subscription as described above will be available to you only once
              your final installment payment has been paid.
            </p>
            <p>
              If you purchased the subscription to a Premium Service through the
              App from a Third Party Store, you will continue to have access to
              such Premium Service through the App for the remainder of your
              paid subscription, however, if you delete your account under the
              Profile page, your profile will be deleted and you will no longer
              have access to the Premium Service.
            </p>
            <p>
              b. <u>3-Day Cancellation</u>. Regarding certain Premium Services,
              you, the subscriber, may have the right under applicable state law
              to cancel the subscription, without any penalty or obligation, at
              any time prior to midnight of the third business day following the
              date of these Terms of Service, excluding Sundays and holidays. If
              this applies to you and you did not subscribe to the Premium
              Service through a Third Party Store, to cancel the premium
              Service, click the Delete Account link on the Profile page of the
              App. Any refunds under this 3-day cancellation policy will be made
              within 10 days after Sondr’s receipt of your cancellation notice.
            </p>
          </li>
          <li>
            <p>
              <b>27. Renewals</b>
            </p>
            <p>
              In order to provide continuous service, Sondr automatically renews
              all paid subscriptions for the Services on the date such
              subscriptions expire. We always communicate renewal periods to you
              in the subscription plan page or on the App, before you finalize
              the purchase of your subscription, upon confirmation of purchase,
              and in the body of any special promotions sent to our Members. By
              subscribing to a Service, you acknowledge that your account will
              be subject to the above-described automatic renewals. In all
              cases, if you do not wish your account to renew automatically,
              please follow the directions set out under “Cancellation At Any
              Time With No Refund” above.
            </p>
          </li>
          <li>
            <p>
              <b>28. Modification of These Terms of Service</b>
            </p>
            <p>
              We may modify or update these Terms of Service from time to time
              at our sole discretion. All updates will be effective from the
              time and date that they are posted. We recommend checking this
              page regularly for any updates. Your continued use of the Platform
              and Services signifies your acceptance of the updates that occur.
              We may send you notice of updates to these Terms of Service,
              including, but not limited to, by email, posting on the Platform,
              or other reasonable means.
            </p>
          </li>
          <li>
            <p>
              <b>29. Violations of These Terms of Service</b>
            </p>
            <p>
              Should you breach these Terms of Service, we may take any and all
              actions we deem appropriate in our sole discretion under the
              circumstances, including, but not limited to, suspending,
              blocking, or terminating your access to the Platform and Services
              and your Member account. We may also choose to remove your Member
              Content.
            </p>
          </li>
          <li>
            <p>
              <b>30. Dispute Resolution</b>
            </p>
            <p>
              Unless both parties agree otherwise, you and Sondr agree that any
              dispute, claim, or controversy you may have arising out of or
              relating to the Platform, the App, the Services, these Terms of
              Service, the Host Agreement or EULA will be resolved through
              mandatory binding final arbitration administered by the American
              Arbitration Association (AAA) in accordance with its Guest
              Arbitration Rules, and the judgment of its arbitrator(s) may be
              entered by any court of competent jurisdiction. You further agree
              that the U.S. Federal Arbitration Act governs the interpretation
              and enforcement of this provision, and THE PARTIES FURTHER AGREE
              THAT EACH IS WAIVING ITS CONSTITUTIONAL RIGHT TO A TRIAL BY JURY
              AS WELL AS THE RIGHT TO BRING OR PARTICIPATE IN A CLASS ACTION OR
              MULTI-PARTY ACTION. ALL CLAIMS AND DISPUTES RELATED TO YOUR USE OF
              THE PLATFORM OR SERVICES OR ARISING UNDER THE TERMS MUST BE
              ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
              BASIS. ANY DISPUTE, CLAIM, OR CAUSE OF ACTION THAT YOU MAY HAVE
              ARISING OUT OF OR RELATING TO THE PLATFORM, SERVICES, OR THESE
              TERMS MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE ACCRUES;
              OTHERWISE, SUCH CAUSE OF ACTION WILL BE PERMANENTLY BARRED. This
              provision will survive the termination of these Terms of Service
              and the termination of your account.
            </p>
            <p>
              You and Sondr agree that Sondr may seek injunctive or other
              appropriate relief in the appropriate state or federal court
              should you violate or threaten to violate the intellectual
              property rights of us or our subsidiaries, Affiliates, partners,
              suppliers, or licensors, and you consent to exclusive jurisdiction
              and venue in such courts.
            </p>
          </li>
          <li>
            <p>
              <b>31. Third Party App Store</b>
            </p>
            <p>
              The following additional terms and conditions apply to you if you
              download the App from a third party app store (“Third Party
              Store”). To the extent that the other terms and conditions of
              these Terms of Service are less restrictive than, or otherwise
              conflict with, the terms and conditions of this Section, the more
              restrictive or conflicting terms and conditions in this Section
              will apply, but solely with respect to the App and the Third Party
              Store. You acknowledge and agree that:
            </p>
            <ul className={styles.ml4}>
              <li>
                a. These Terms of Service are concluded solely between you and
                Sondr and not with the providers of the Third Party Store, and
                Sondr (and not the Third Party Store providers) is solely
                responsible for the App and the content thereof. To the extent
                that these Terms of Service provide for usage rules for the App
                which are less restrictive or in conflict with the applicable
                terms of service of the Third Party Store from which you obtain
                the App, the more restrictive or conflicting term of the Third
                Party Store will take precedence and will apply.
              </li>
              <li>
                b. The Third Party Store provider has no obligation whatsoever
                to provide any maintenance and support services with respect to
                the App. Sondr is solely responsible for any product warranties,
                whether express or implied by law, to the extent not effectively
                disclaimed. The Third Party Store provider will have no warranty
                obligation whatsoever with respect to the App, and any other
                claims, losses, liabilities, damages, costs or expenses
                attributable to any failure to conform to any warranty will be
                the sole responsibility of Sondr.
              </li>
              <li>
                c. Sondr, not the Third Party Store provider, is responsible for
                addressing any claims you or any third party may have relating
                to the App or your possession and/or use of the App, including,
                but not limited to: (i) product liability claims; (ii) any claim
                that the App fails to conform to any applicable legal or
                regulatory requirement; (iii) claims arising under Guest
                protection or similar legislation; and/or (iv) intellectual
                property infringement claims.
              </li>
            </ul>

            <p>
              The Third Party Store provider and its subsidiaries are third
              party beneficiaries of these Terms of Service, and, upon your
              acceptance of these Terms of Service, the Third Party Store
              provider from whom you obtained the App will have the right (and
              will be deemed to have accepted the right) to enforce these Terms
              of Service against you as a third party beneficiary thereof.
            </p>
          </li>
          <li>
            <p>
              <b>32. Premium Products and Services; In-App Purchases.</b>
            </p>
            <p>
              The App may be dependent on and/or interoperate with third-party
              owned and/or operated websites, platforms and services, e.g.,
              Apple (iTunes, etc.), Google, Facebook, Twitter, etc. (each, a
              “Third Party Platform”) and may require that you be a registered
              member of such Third Party Platforms and provide certain account
              credentials and other information in order to access the App. By
              using the App, you agree to comply with any applicable terms,
              conditions or requirements promulgated by any provider of a Third
              Party Platform (e.g., Facebook’s Terms of Use, iTunes Store Terms
              of Use, etc.).
            </p>
            <p>
              We may make certain products and/or services available to the
              Members who are users of the App in consideration of a
              subscription fee or other fees as Premium Services, including the
              ability to purchase products, services and enhancements, such as
              the ability to see who has reviewed or saved your member profile (
              <b>“In-App Products”</b>). If you choose to use Premium Services
              or purchase In-App Products, you acknowledge and agree that
              additional terms may apply to your use of, access to and purchase
              of such Premium Services and In-App Products, and such additional
              terms are incorporated herein by reference. You may purchase
              Premium Services and In-App Products through the following payment
              methods (each, a <b>“Premium Payment Method”</b>): (a) making a
              purchase through the Apple App Store ®, Google Play or other
              mobile or web application platforms or storefronts authorized by
              us (each, a <b>“Third Party Store”</b>), (b) paying with your
              credit card, debit card, or PayPal account (when applicable),
              which will be processed by a third party processor, or (c) adding
              charges to your mobile carrier bill and remitting payment directly
              to your carrier. Once you have requested a Premium Service or
              In-App Product, you authorize us to charge your chosen Premium
              Payment Method (based on what is available in the app) and your
              payment is non-refundable. If payment is not received by us from
              your chosen Premium Payment Method, you agree to promptly pay all
              amounts due upon demand by us. If you want to cancel or change
              your Premium Payment Method at any time, you can do so either via
              the payment settings option under your Profile page within the App
              or by contacting your mobile service provider. If your chosen
              Premium Payment Method is via your mobile service provider, then
              please check with them about their payment terms, as their payment
              terms will govern how payments to us are made as well as how such
              payments may be changed or canceled. Your subscription to our
              Premium Services will automatically renew until you decide to
              cancel in accordance with such terms. In the event of a conflict
              between a Third Party Store’s terms and conditions and these
              Terms, the terms and conditions of the Third Party Store or
              service provider shall govern and control. We are not responsible
              and have no liability whatsoever for goods or services you obtain
              through the Third Party Store, our third party service providers
              or other web sites or web pages. We encourage you to make whatever
              investigation you feel necessary or appropriate before proceeding
              with any online transaction with any of these third parties.
            </p>
            <p>
              If you choose to purchase an In-App Product, you will be prompted
              to enter details for your account with the Third Party Store you
              are using (e.g., Android, Apple, etc.) (“your Mobile Platform
              Account”), and your Mobile Platform Account will be charged for
              the Premium Service and/or In-App Product in accordance with the
              terms disclosed to you at the time of purchase, as well as the
              general terms applicable to all other in-app purchases made
              through your Mobile Platform Account (e.g., Android, Apple, etc.).
              Premium Services and In-App Products may include one-time
              purchases as well as monthly subscriptions (e.g., a one-month
              subscription, three-month subscription, six-month subscription,
              etc.) to additional account features. At the end of the free trial
              period (if any), you will be charged the price of the subscription
              and will continue to be charged until you cancel your
              subscription.
            </p>
            <p>
              Please note that for Premium Services and In-App Products bought
              on a subscription basis, your subscription will automatically
              renew for the same subscription period as you initially purchased
              (e.g., if you bought an In-App Product on a six-month
              subscription, your subscription will be automatically renewed for
              an additional six-months). To avoid any charges for additional
              periods, you must cancel before the end of the free trial period,
              subscription period or renewal, as applicable, in accordance with
              the terms and conditions of your Mobile Platform Account and the
              terms and conditions of any applicable Third Party Store. The
              pricing may vary due to a number of factors, such as (but not
              limited to) promotional offers, loyalty bonuses and other
              discounts that might apply to your age group.
            </p>
            <p>
              Please note that for Premium Services and In-App Products you will
              be billed continuously for the subscription or service until you
              cancel in accordance with your Mobile Platform Account’s or your
              Premium Payment Method’s terms. In all cases, we are not
              responsible and have no liability whatsoever for any payment
              processing errors (including card processing, identity
              verification, analysis and regulatory compliance) or fees or other
              service-related issues, including those issues that may arise from
              inaccurate account information, or products or goods you obtain
              through your Mobile Platform Account or Third Party Stores.
              Further, we do not guarantee that product descriptions or other
              content and products will be available, accurate, complete,
              reliable, current or error-free. Descriptions and images of, and
              references to, products or services (including Premium Services or
              In-App Products) do not imply our or any of our Affiliates'
              endorsement of such products or services. Moreover, we and our
              third party operational service providers reserve the right, with
              or without prior notice, for any or no reason, to change product
              descriptions, images, and references; to limit the available
              quantity of any product; to honor, or impose conditions on the
              honoring of, any coupon, coupon code, promotional code or other
              similar promotions; to bar any Member from conducting any or all
              transaction(s); and/or to refuse to provide any Member with any
              product. Further, if we terminate your use of or registration to
              the App and/or to the Platform because you have breached these
              Terms, you shall not be entitled to a refund of any unused portion
              of any fees, payments or other consideration. We encourage you to
              review the terms and conditions of the applicable third party
              payment processors, Third Party Store or Mobile Platform Account
              before you make any In-App Products or Premium Service purchases.
            </p>
          </li>
          <li>
            <p>
              <b> 33. Miscellaneous</b>
            </p>

            <p>
              a. Entire Agreement. These Terms of Service, Host Agreement, EULA
              and our Privacy Policy represent the entire and exclusive
              agreement between you and Sondr regarding your use of the
              Platform, the App and the Services, superseding and replacing all
              previous agreements. You may also be subject to additional terms
              and conditions or separate agreements regarding specific Services
              we provide, partner or affiliate services, use of Third-Party
              Resources, or any purchases you may make through the Platform or
              the App. In the event that these Terms of Service are translated
              into other languages and there is a discrepancy between the two
              language versions, the English language version will prevail in
              all cases to the extent that such discrepancy is the result of an
              error in translation.
            </p>

            <p>
              b. Waiver and Severability. Our failure to enforce any right or
              provision of these Terms of Service will not operate as a waiver
              of such right or provision. If any provision of these Terms of
              Service or the application thereof is held to be invalid or
              unenforceable for any reason and to any extent, that provision
              will be considered removed from these Terms of Service; however,
              the remaining provisions will continue to be valid and enforceable
              according to the intentions of the Parties and to the maximum
              extent permitted by law. If it is held that any provision of these
              Terms of Service is invalid or unenforceable, but that by limiting
              such provision it would become valid and enforceable, then such
              provision will be deemed to be written, construed, and enforced as
              so limited.
            </p>

            <p>
              c. Assignment. Your rights and obligations under these Terms of
              Service, including any accounts, profiles, or personalization or
              profile settings you may have, may not be assigned, subcontracted,
              delegated, or otherwise transferred by you without our prior
              written consent, and any attempt to do so will be null and void.
              We may freely assign these Terms of Service and our rights and
              obligations hereunder without notice to you, and these Terms of
              Service will continue to be binding on assignees.
            </p>

            <p>
              d. Cumulative Rights. The rights of all parties under these Terms
              of Service are cumulative and will not be construed as exclusive
              of each other unless otherwise required by law.
            </p>

            <p>
              e. Law and Jurisdiction. These terms shall be governed by, and
              will be construed under, the laws of the State of New York, United
              States, without regard to or application of its conflict of law
              principles or your state or country of residence. Other than as
              provided in Section 28 with respect to mandatory arbitration, all
              claims, disputes, and legal proceedings related to or arising out
              of these Terms of Service or your use of the Platform or Services
              will be brought exclusively in the federal or state courts located
              in New York, New York, and you hereby consent to and waive any
              objection of inconvenient forum as to such jurisdiction.
            </p>
          </li>
          <li>
            <p>
              <b>34. Electronic Communications and Privacy</b>
            </p>
            <p>
              Electronic communications occur whenever you use the Platform or
              Services, you send us emails, we send you emails, and we post
              notices on the Platform. You consent to receive such electronic
              communications and agree that the electronic communications,
              including, without limitation, all notices, terms, disclosures,
              and agreements, has the same legal effect and satisfy any legal
              requirement that such communications would satisfy if provided to
              you in a written hardcopy.
            </p>
            <p>
              We may use your email address to send you messages notifying you
              of important changes to the Platform or any Services or special
              offers. Further, we may contact you by telephone if you
              voluntarily provide us with your telephone number, in order to
              communicate with you regarding the Services. If you do not want to
              receive such email messages or telephone calls (including at any
              wireless number you may have voluntarily provided us), please
              refer to our Privacy Policy to review your options.
            </p>
          </li>
          <li>
            <p>
              <b>35. Feedback and Complaints</b>
            </p>
            <p>
              You hereby assign to us all rights in any feedback or complaints
              you provide us concerning the Platform, the App or the Services
              and agree that we have the right to use and fully exploit all such
              feedback or complaints in any manner we wish, commercial or
              otherwise. We will treat all such feedback or complaints as
              non-confidential and non-proprietary. Do not provide us with any
              feedback or complaints that you consider confidential or
              proprietary.
            </p>
          </li>
          <li>
            <p>
              <b>36. Contact Information</b>
            </p>
            <p>
              Please report any violations of these Terms of Service to us. If
              you require any more information or have any questions about these
              Terms of Service, you may contact us as follows:
            </p>
            <p>
              Sondr, Inc.
              <br />
              442 Fifth Avenue, #1625
              <br />
              New York, NY 10018
              <br />
              Email: legal@joinsocha.com
            </p>
          </li>
        </ol>
      </div>
      <div className={styles.termsFooter}>*****</div>
    </div>
  );
}
