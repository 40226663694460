import React, { useEffect, useState } from 'react'
import TempImg from '../../assets/temp/eventImage.jpg'
import { convertUtcToLocal, getDayOfWeek, getMonthAndDay, getTime } from '../../utils/common';
import EventImage from '../../assets/Images/createEvent/eventImageUpload.svg'
import DummyContent from '../../assets/Images/createEvent/contentload.svg'

interface props {
  eventDetails: Event | any
  banner?: String | any
}

const PreviewEvent = ({eventDetails, banner}:props) => {

  const [eventStartDateTime, setEventStartDateTime] = useState('');

  useEffect(() => {
    if(eventDetails)
      {
        setEventStartDateTime(convertUtcToLocal(eventDetails?.startTime, eventDetails?.timezone))
      }

  }, [eventDetails])

  return (
    <div className='border-8 rounded-md border-input-box-gray'>
    <img src={banner ? banner : EventImage} className='w-64 h-56' />
    {!eventDetails ? ( 
    <img src={DummyContent} className='w-72 h-40 pb-2'/>
    ) : (
    <div className='m-2'>
      <span className='bg-input-box-gray text-primary-white text-[0.59rem] rounded p-1'>{eventDetails?.eventMode} Event</span>
      <h1 className='font-medium text-2xl tracking-tighter text-primary-white'>{eventDetails?.eventName ? eventDetails?.eventName : 'Event name'}</h1>
      <span className='text-primary-white text-xs'>{eventStartDateTime ? getDayOfWeek(eventStartDateTime, 'long') + ' ' + getMonthAndDay(eventStartDateTime) : ''} </span>
      <span className='text-text-gray text-xs'>{eventStartDateTime ? getTime(eventStartDateTime) : 'Event date'}</span>
      <h5 className='text-base font-bold text-primary-white mt-2'>About this event</h5>
      <p className='text-xs text-text-gray max-h-32 overflow-y-auto  scrollableDiv my-2'>{eventDetails?.description}</p>
    </div>)}
  </div>
  
  )
}

export default PreviewEvent