import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../components/primaryButton'
import ForwardButton from '../../../assets/Icons/forward/forward.svg'
import { getLocalStorage } from '../../../utils/localStorage';
import { LOCAL_STORAGE } from '../../../utils/constants';
import { CreateEvent } from '../../../services/createEventService';
import Delete from '../../../assets/Icons/delete/delete.svg';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSpinner } from "../../../components/spinner/SpinnerContext";
import { toast } from "react-toastify";
import { eventDetailService } from '../../../services/eventDetailServices';


const Payouts = () => {
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId: any = queryParams.get("eventid");
  const [accounts, setAccounts] = useState<any>([])
  const [eventDetails, setEventDetails] = useState<Event | any>();
  const navigate = useNavigate();
  const { setShowSpinner } = useSpinner();

  useEffect(() => {
    getAccounts()
    getHostEventDtls()
  }, [userId])


  const getHostEventDtls = async () => {
    try {
      const res = await eventDetailService.getHostEventDetails({ eventId, userId });
      if (res?.data) {
        setEventDetails(res?.data?.events);
      }
    } catch (error: any) {
      console.log("error getHostEventDtls : ", error?.response?.data?.error || error?.message);
    }
  }

  const addAccount = async () => {
    try {
      const redirectUrl = window.location.href
      const params = {
        userID: parseInt(userId),
        redirectUrl: redirectUrl,
        refreshUrl: redirectUrl
      }
      const res = await CreateEvent.addStripeAccount(params)
      if (res?.data?.url) {
        window.open(res?.data?.url)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const getAccounts = async () => {
    setShowSpinner(true); // Show spinner immediately

    try {
      const res = await CreateEvent.getPayoutList({ userId: parseInt(userId) });

      if (res?.data?.accounts) {


        let newArr = res.data.accounts.map((ele: any) => ele?.accountDetails[0]);
        newArr = newArr.filter((ele: any) => ele?.charges_enabled && ele?.details_submitted);

        setAccounts(newArr); // Set accounts once they are processed
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowSpinner(false); // Hide spinner after all operations complete
    }
  };



  const defaultAccount = async (accountId: Number) => {
    try {

      setShowSpinner(true);
      const param = {
        id: accountId,
        userID: parseInt(userId)
      }
      const res = await CreateEvent.makeDefaultAccount(param)
      if (res?.data) {
        getAccounts()
      }
    } catch (error) {
      console.log(error)
      setShowSpinner(false);
    }
  }

  const deletePayoutAccount = async (accountId: Number) => {
    try {
      setShowSpinner(true);
      const res = await CreateEvent.deleteStripeAccount({ id: accountId })
      if (res?.data) {
        getAccounts();
        if (accounts.length > 1) {
          const newDefaultAccount = accounts.find((ele: any) =>
            ele?.charges_enabled &&
            ele?.details_submitted &&
            !ele?.isDefault
          );
          if (newDefaultAccount) {

            defaultAccount(newDefaultAccount.id);
          }
        }
        toast.success(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowSpinner(false);
    }
  }
  const saveAsDraftEvent = () => {
    if (eventDetails?.event_type === 'Ticketed Event' && accounts.length > 0) {
      defaultAccount(accounts?.[0]?.id)
      navigate({
        pathname: "/host-center/event-details",
        search: createSearchParams({
          eventid: eventId,
          hostid: userId
        }).toString()
      })
    }
    else if (eventDetails?.event_type === 'Free Event') {
      navigate({
        pathname: "/host-center/event-details",
        search: createSearchParams({
          eventid: eventId,
          hostid: userId
        }).toString()
      })
    }
    else {
      toast.error("Add a payout account")
    }
  }

  const publishEvent = async () => {

    if (eventDetails?.event_type === 'Ticketed Event' && accounts.length > 0) {
      defaultAccount(accounts?.[0]?.id)

      const res = await eventDetailService.publishEvent({ eventId: parseInt(eventId), hostUserId: parseInt(userId), isPublished: true });
      if (res?.status) {
        setShowSpinner(false);
        toast.success("Event published successfully!")
      }

      navigate({
        pathname: "/host-center/event-details",
        search: createSearchParams({
          eventid: eventId,
          hostid: userId
        }).toString()
      })
    }
    else if (eventDetails?.event_type === 'Free Event') {

      const res = await eventDetailService.publishEvent({ eventId: parseInt(eventId), hostUserId: parseInt(userId), isPublished: true });
      if (res?.status) {
        setShowSpinner(false);
        toast.success("Event published successfully!")
      }

      navigate({
        pathname: "/host-center/event-details",
        search: createSearchParams({
          eventid: eventId,
          hostid: userId
        }).toString()
      })
    }
    else {
      toast.error("Add a payout account")
    }
  }

  return (
    <div>
      <div>
        <span className='text-primary-white text-lg'>Payouts</span>
        {accounts.length <= 0 ? (
          <div className='w-full bg-input-box-gray border-0 rounded-2xl text-center mt-2 py-8'>
            <button className='text-sm text-primary-white border-2 rounded-xl border-primary-orange bg-third-orange p-2' onClick={addAccount}>
              Add Account
            </button>
          </div>
        ) : (
          <React.Fragment>
            {accounts.map((ele: any) => (
              <div className='w-full bg-input-box-gray border-0 rounded-2xl mt-2 p-4 flex justify-between' key={ele.id}>
                <div>
                  <button className='bg-default-grey text-sm text-primary-white rounded-lg px-2 py-1' onClick={() => defaultAccount(ele?.id)}>
                    {ele?.isDefault ? 'Default' : 'Make Default'}
                  </button>
                  <p className='text-[#535353] text-sm'>Account number</p>
                  <p className='text-[#F1F1F1] text-lg'>{ele?.last4}</p>
                </div>
                <div className='flex flex-col justify-end'>
                  <span className='text-[#535353] text-sm'>Payment method</span>
                  <div className='flex justify-between'>
                    <p className='font-bold text-[#F1F1F1] text-xl'>Stripe</p>
                    <img src={Delete} className='w-5 cursor-pointer' onClick={() => deletePayoutAccount(ele?.id)} alt='Delete icon' />
                  </div>
                </div>
              </div>
            ))}
            <div className='flex justify-center py-8'>
              <button className='text-sm text-primary-white border-2 rounded-xl border-primary-orange bg-third-orange p-2' onClick={addAccount}>
                Add Account
              </button>
            </div>
          </React.Fragment>
        )}
      </div>

      <div className='border-t border-text-gray mt-8'></div>
      <div className='flex place-content-between items-center my-8'>
        <PrimaryButton name='Previous' type='button' classNames='px-2 py-3' onClick={() => navigate({
          pathname: "/create-event/general-settings",
          search: createSearchParams({
            eventid: eventId
          }).toString()
        })} />
        <div className='flex gap-2'>
          <PrimaryButton name='Save as Draft' icon={ForwardButton} onClick={saveAsDraftEvent} classNames='px-2 py-3' />
          <PrimaryButton name='Publish' icon={ForwardButton} onClick={publishEvent} classNames='px-2 py-3' />
        </div>
      </div>
    </div>
  )
}

export default Payouts