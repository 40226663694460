import React, { useEffect, useState } from 'react'
import BackArrow from '../../assets/Icons/backArrow/backArrow.svg'
import Arrow from '../../assets/Icons/rightArrow/arrow.svg'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import DummyImage from '../../assets/Images/socha_banner.png'
import SelectAll from '../../assets/Icons/selectAll/selectAll.svg'
import PrimaryButton from '../../components/primaryButton';
import ForwardButton from '../../assets/Icons/forward/forward.svg'
import { TextBlastService } from '../../services/textBlast';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage';
import { LOCAL_STORAGE } from '../../utils/constants';
import { toast } from 'react-toastify';
import { convertUtcToLocal, getDayOfWeek, getMonthAndDay } from '../../utils/common';
import RightSideDrawer from '../../components/rightDrawer';
import Drawer from '../../components/bottomDrawer';

const RecipientSelection = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const queryParams = new URLSearchParams(location.search);
  const id: any = queryParams.get("eventid");
  const [selectedHostEvent, setSelectedHostEvent] = useState<number[]>([])
  const [hostedEventDetails, setHostedEventDetails] = useState([])
  const [getContactList, setGetContactList] = useState([])
  const [uploadedContact, setUploadedContact] = useState([])
  const [allContacts, setAllContacts] = useState<any[]>([])
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [opendrawer, setOpenDrawer] = useState(false)


  useEffect(() => {
    getHostedEventDetails()
  }, [])

  useEffect(() => {
    if (selectedHostEvent?.length > 0) {
      getPastInvitedGuest()
    }
  }, [selectedHostEvent])

  useEffect(() => {
    // Show all contacts whenever getContactList or uploadedContact changes
    showAllContacts();
  }, [getContactList, uploadedContact]);

  useEffect(() => {
    if (selectedContacts.length === allContacts.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedContacts, allContacts]);


  const getHostedEventDetails = async () => {

    try {
      const res = await TextBlastService.hostedEvents({ userId, pageNo: 1, pageSize: 100 });
      if (res?.data) {
        setHostedEventDetails(res?.data?.hostedEvents)
      }
    } catch (error: any) {
      console.log("error hostedeventDtls : ", error?.response?.data?.error || error?.message);

    }
  }


  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(allContacts);
    }
    setSelectAll(!selectAll);
  };

  const handleContactCheckboxChange = (contact: any) => {
    setSelectedContacts(prevState => {
      if (prevState.includes(contact)) {
        return prevState.filter(item => item !== contact);
      } else {
        return [...prevState, contact];
      }
    });
  };



  const handleCheckboxChange = (id: number) => {
    setSelectedHostEvent((prevSelectedHostEvent) =>
      prevSelectedHostEvent.includes(id)
        ? prevSelectedHostEvent.filter((itemId) => itemId !== id)
        : [...prevSelectedHostEvent, id]
    );
  };



  const getPastInvitedGuest = async () => {
    let eventIdList = selectedHostEvent
    try {
      const res = await TextBlastService.pastInvitedGuest({ pageNo: 1, pageSize: 100, eventIdList });
      if (res?.data) {
        setGetContactList(res?.data?.invitedPastEventGuest)
      }
    } catch (error: any) {
      console.log("error hostedeventDtls : ", error?.response?.data?.error || error?.message);
    }
  }

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log(selectedFile);
      uploadContacts(selectedFile)
      console.log('Selected file:', selectedFile);
    } else {
      console.log('No file selected.');
    }
  }

  const uploadContacts = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('contacts', file,);
      const res = await TextBlastService.importContacts(formData)
      if (res?.data) {
        setUploadedContact(res?.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const showAllContacts = () => {
    let combinedContacts: any[] = [];

    if (getContactList?.length > 0) {
      combinedContacts = [...getContactList];
    }

    if (uploadedContact?.length > 0) {
      combinedContacts = [...combinedContacts, ...uploadedContact];
    }

    setAllContacts(combinedContacts);
  };

  const onContinue = () => {
    setLocalStorage(LOCAL_STORAGE.CONTACT_LIST, JSON.stringify(selectedContacts))
    if (selectedContacts.length > 0) {
      navigate({
        pathname: '/textBlast-details',
        search: createSearchParams({
          eventid: id
        }).toString()
      })
    }
    else {
      toast.error("Select atleast one Contact")
    }
  }



  return (
    <div className=''>
      <div className='grid grid-cols-10 justify-items-start my-2'>
        <div className='col-start-2 col-span-8'>
          <div className='flex gap-4 items-center font-bold text-base mb-6'>
            <img src={BackArrow} className='w-8 cursor-pointer' onClick={() => navigate(-1)} />
            <span className="text-primary-white">Text Blast</span>
          </div>
          <div className='flex items-center font-medium text-sm lg:text-2xl overflow-x-auto lg:overflow-x-hidden w-auto gap-6'>
            <span className={location.pathname.includes("recipient-selection")
              ? "text-primary-white"
              : "text-text-gray"}>Recipient Selection</span>
            <img src={Arrow} />
            <span className={location.pathname.includes("textBlast-details")
              ? "text-primary-white"
              : "text-text-gray"}>Text blast details</span>
          </div>
        </div>
      </div>
      <div className='border-t border-text-gray mt-8'></div>
      <div className='grid grid-cols-10 relative'>
        <div className='my-8 col-start-2 col-span-10 md:col-start-2 md:col-span-4 md:flex justify-center h-[calc(100vh-52vh)] overflow-y-auto scrollableDiv '>
          <div className=''>
            <span className='text-3xl text-primary-white'>Guests from</span>
            <p className='text-[#8A8A8A] text-xs font-normal'>You’ll be able to select guests from following events</p>

            {hostedEventDetails?.map((data: any) => (
              <div className='flex items-center gap-2 my-8 ' key={data?.eventId}>
                <input
                  type='checkbox'
                  checked={selectedHostEvent?.includes(data?.eventId)}
                  onChange={() => handleCheckboxChange(data?.eventId)}
                />
                <img src={DummyImage} className='w-24' />
                <div>
                  <span className='text-primary-white text-base'>{data?.eventName}</span>
                  <p className='text-[#999999] text-xs'>{data?.location}</p>
                  <div className='text-xs bg-[#2E2E2E] rounded-lg p-1 w-fit'>
                    <span className='text-text-gray'>{getDayOfWeek(convertUtcToLocal(data?.startTime, "America/New_York"), 'short')} </span>
                    <span className='text-primary-white'> {getMonthAndDay(convertUtcToLocal(data?.startTime, "America/New_York"))} </span>
                    <span className='text-text-gray'> - </span>
                    <span className='text-text-gray'> {getDayOfWeek(convertUtcToLocal(data?.endTime, "America/New_York"), 'short')} </span>
                    <span className='text-primary-white'> {getMonthAndDay(convertUtcToLocal(data?.endTime, "America/New_York"))}</span>
                  </div>

                </div>
              </div>
            ))}

          </div>

        </div>
        <div className='bg-[#121316] text-primary-white md:hidden absolute inset-x-0 bottom-0 w-full py-3 px-2 flex justify-end '>
          <PrimaryButton name='Select guests' classNames='px-2 py-3' onClick={() => setOpenDrawer(true)} />
        </div>
        <div className='col-start-6 col-end-11 hidden md:block'>
          <div className='flex gap-8 h-full'>
            <div className='border-l border-text-gray h-full'></div>
            <div className='my-8'>
              <span className='text-3xl text-primary-white'>Select guests</span>
              <p className='text-[#8A8A8A] text-xs font-normal'>Selected events guests will be shown here</p>

              <div className='flex items-center my-8 gap-4'>
                <p className='text-sm text-primary-white'>Import contacts to<br />reach more people</p>
                <input className="hidden" id="fileInput" type='file' accept='.csv' onChange={handleFileChange} />
                <label htmlFor='fileInput' className='cursor-pointer text-xs text-primary-white border-2 rounded-lg border-primary-orange bg-third-orange p-1'>Upload CSV</label>

                {/* <button className='text-xs text-primary-white border-2 rounded-lg border-primary-orange bg-third-orange p-1' type='button'>Imports from contacts</button> */}
              </div>
              <div className='flex items-center gap-4'>
                <input
                  type='checkbox'
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                <img src={SelectAll} className='w-7' />
                <label className='text-primary-white font-bold text-sm'>Select All guests</label>
              </div>
              {allContacts?.map((data: any) => (
                <div className='flex items-center gap-4 my-2'>
                  <input type='checkbox'
                    checked={selectedContacts.includes(data)}
                    onChange={() => handleContactCheckboxChange(data)} />
                  <img src={SelectAll} className='w-7' />
                  <label className='text-primary-white font-bold text-sm'>{data?.firstName} {data?.lastName}</label>
                </div>
              ))}
            </div>
          </div>
          <div className='border-b border-text-gray'></div>
          <div className='flex items-center justify-around border-l border-text-gray py-6'>
            <p className='text-sm text-primary-white'>{selectedContacts?.length} guests selected</p>
            <PrimaryButton type='button' name='Next step' icon={ForwardButton} onClick={onContinue} classNames='px-2 py-3' />
          </div>
        </div>
      </div>
      <div className='md:hidden'>
        <RightSideDrawer
          open={opendrawer}
          onClose={() => setOpenDrawer(false)}
        >
          <div className='h-full'>
            <div className='border-l border-text-gray h-full'></div>
            <div className='my-8 px-4'>
              <span className='text-3xl text-primary-white'>Select guests</span>
              <p className='text-[#8A8A8A] text-xs font-normal'>Selected events guests will be shown here</p>

              <div className='my-8 gap-4 bg-[#161616] p-5 px-10 rounded-xl'>
                <p className='text-sm text-primary-white my-2'>Import contacts to<br />reach more people</p>
                <input className="hidden" id="fileInput" type='file' accept='.csv' onChange={handleFileChange} />
                <label htmlFor='fileInput' className='cursor-pointer text-xs text-primary-white border-2 rounded-lg border-primary-orange bg-third-orange p-1'>Upload CSV</label>

                <button className='text-xs text-primary-white mx-2 border-2 rounded-lg border-primary-orange bg-third-orange p-1' type='button'>Imports from contacts</button>
              </div>
              <div className='flex items-center gap-4'>
                <input
                  type='checkbox'
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                <img src={SelectAll} className='w-7' />
                <label className='text-primary-white font-bold text-sm'>Select All guests</label>
              </div>
              {allContacts?.map((data: any) => (
                <div className='flex items-center gap-4 my-2'>
                  <input type='checkbox'
                    checked={selectedContacts.includes(data)}
                    onChange={() => handleContactCheckboxChange(data)} />
                  <img src={SelectAll} className='w-7' />
                  <label className='text-primary-white font-bold text-sm'>{data?.firstName} {data?.lastName}</label>
                </div>
              ))}
            </div>
          </div>
          <Drawer open={true}><div className='flex items-center justify-between mb-6 border-text-gray'>
            <p className='text-sm text-primary-white'>{selectedContacts?.length} guests selected</p>
            <PrimaryButton type='button' name='Next step' icon={ForwardButton} onClick={onContinue} classNames='px-2 py-3' />
          </div></Drawer>
        </RightSideDrawer>
      </div>
    </div>
  )
}

export default RecipientSelection