import React, { useState } from 'react'
import Logo from '../../../assets/Images/login/logo.png'
import PrimaryButton from '../../../components/primaryButton'
import Dating from '../../../assets/Icons/Dating/dating.svg'
import Friendship from '../../../assets/Icons/Dating/friendship.svg'
import OpenToAnything from '../../../assets/Icons/Dating/Open.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService } from '../../../services/authService'
import { getLocalStorage } from '../../../utils/localStorage'
import { LOCAL_STORAGE } from '../../../utils/constants'
import { useSpinner } from '../../../components/spinner/SpinnerContext'
import { toast } from 'react-toastify'
import { GENDER, CONNECTION_PREFERENCE } from '../../../utils/constants'

const Registration = () => {
    let location = useLocation()
    const navigate = useNavigate()
    const [gender, setGender] = useState('')
    const [connection, setConnection] = useState('')
    const [interestedIn, setInterestedIn] = useState<string>('')
    const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID)
    const { email, firstName, lastName } = location?.state ?? {}
    const { setShowSpinner } = useSpinner();



    const handleGender = (value: string) => {
        console.log(value)
        setGender(value)
    }

    const datingInterestedIn = (value: string) => {
        setInterestedIn(prev => {
            const currentValues = prev ? prev.split(', ') : [];
            if (currentValues.includes(value)) {
                return currentValues.filter(item => item !== value).join(', ');
            }
            return [...currentValues, value].join(', ');
        });
    }

    const handleConnection = (value: string) => {
        console.log(value)
        setConnection(value)
    }

    const submitUserPreferences = async () => {
        setShowSpinner(true)
        try {
            const payload = {
                id: parseInt(userId),
                firstName,
                lastName,
                email,
                gender,
                meetingPurpose: connection,
                lookingFor: interestedIn,
            }
            console.log(payload)
            const res = await AuthService.updateUserDetails(payload)
            if (res?.status) {
                navigate('/discover', { replace: true })
                toast.success('Account created successfully')
            }
            setShowSpinner(false)
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
            setShowSpinner(false)
        }
    }

    console.log(interestedIn, "-------72")
    return (
        <div className='flex flex-col gap-6'>
            <img src={Logo} alt="logo" className='w-20 h-10 my-4' />
            <div className='text-primary-white text-4xl font-bold'>
                Explore and connect <br /> with others!
            </div>
            <div className='flex flex-col gap-2 text-base text-primary-white'>
                <span>Select your gender</span>
                <div className='flex gap-2'>
                    <div className={`bg-input-box-gray rounded-2xl px-8 py-6 cursor-pointer ${gender === GENDER.MEN ? 'border border-primary-orange' : ''}`} onClick={() => handleGender(GENDER.MEN)}>
                        <span>Male</span>
                    </div>
                    <div className={`bg-input-box-gray rounded-2xl px-8 py-6 cursor-pointer ${gender === GENDER.WOMEN ? 'border border-primary-orange' : ''}`} onClick={() => handleGender(GENDER.WOMEN)}>
                        <span>Female</span>
                    </div>
                    <div className={`bg-input-box-gray rounded-2xl px-8 py-6 cursor-pointer ${gender === GENDER.NON_BINARY ? 'border border-primary-orange' : ''}`} onClick={() => handleGender(GENDER.NON_BINARY)}>
                        <span>Non-Binary</span>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-2 text-base text-primary-white z-10'>
                <span>Select your connection preferences</span>
                <div className='flex flex-col gap-2'>
                    <div className={`bg-input-box-gray rounded-2xl px-2 w-full flex items-center justify-between cursor-pointer ${connection === CONNECTION_PREFERENCE.OPEN_TO_ANYTHING ? 'border border-primary-orange' : ''}`} onClick={() => handleConnection(CONNECTION_PREFERENCE.OPEN_TO_ANYTHING)}>
                        <div className='flex items-center gap-2'>
                            <input type='radio' className='w-4 h-4 bg-primary-black border-none focus:ring-0 focus:ring-offset-0 focus:bg-primary-orange text-primary-orange' checked={connection === CONNECTION_PREFERENCE.OPEN_TO_ANYTHING} />
                            <span>Open to anything</span>
                        </div>
                        <img src={OpenToAnything} alt="open-to-anything" className='mt-5' />
                    </div>
                    <div className={`bg-input-box-gray rounded-2xl px-2 w-full flex items-center justify-between cursor-pointer ${connection === CONNECTION_PREFERENCE.FRIENDSHIP ? 'border border-primary-orange' : ''}`} onClick={() => handleConnection(CONNECTION_PREFERENCE.FRIENDSHIP)}>
                        <div className='flex items-center gap-2'>
                            <input type='radio' className='w-4 h-4 bg-primary-black border-none focus:ring-0 focus:ring-offset-0 focus:bg-primary-orange text-primary-orange' checked={connection === CONNECTION_PREFERENCE.FRIENDSHIP} />
                            <span>Friendship or professional</span>
                        </div>
                        <img src={Friendship} alt="friendship" className='mt-5' />
                    </div>
                    <div className={`bg-input-box-gray rounded-2xl px-2 w-full flex items-center justify-between cursor-pointer ${connection === CONNECTION_PREFERENCE.DATING ? 'border border-primary-orange' : ''}`} onClick={() => handleConnection(CONNECTION_PREFERENCE.DATING)}>
                        <div className='flex items-center gap-2'>
                            <input type='radio' className='w-4 h-4 bg-primary-black border-none focus:ring-0 focus:ring-offset-0 focus:bg-primary-orange text-primary-orange' checked={connection === CONNECTION_PREFERENCE.DATING} />
                            <span>Dating</span>
                        </div>
                        <img src={Dating} alt="dating" className='mt-5' />
                    </div>
                </div>
            </div>
            {(connection === CONNECTION_PREFERENCE.DATING || connection === CONNECTION_PREFERENCE.OPEN_TO_ANYTHING) && (
                <div className='flex flex-col gap-2 text-base text-primary-white z-10'>
                    <span>Who are you interested in dating?</span>
                    <div className='flex gap-2'>
                        <div className={`bg-input-box-gray rounded-2xl px-8 py-6 cursor-pointer ${interestedIn.includes(GENDER.MEN) ? 'border border-primary-orange' : ''}`} onClick={() => datingInterestedIn(GENDER.MEN)}>
                            <span>Men</span>
                        </div>
                        <div className={`bg-input-box-gray rounded-2xl px-8 py-6 cursor-pointer ${interestedIn.includes(GENDER.WOMEN) ? 'border border-primary-orange' : ''}`} onClick={() => datingInterestedIn(GENDER.WOMEN)}>
                            <span>Women</span>
                        </div>
                        <div className={`bg-input-box-gray rounded-2xl px-8 py-6 cursor-pointer ${interestedIn.includes(GENDER.NON_BINARY) ? 'border border-primary-orange' : ''}`} onClick={() => datingInterestedIn(GENDER.NON_BINARY)}>
                            <span>Non-Binary</span>
                        </div>
                    </div>
                </div>
            )}
            <PrimaryButton name='Submit' classNames='py-3 px-6 my-4 z-10' onClick={submitUserPreferences} />
        </div>
    )
}

export default Registration