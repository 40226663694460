import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import { FAQ_TYPES } from "../../../utils/constants";
import { guest, host } from "../../../utils/faqQuestions";
import styles from "./FAQPage.module.scss";
import Footer from "../../../components/footer";
import PlusIcon from "../../../assets/Icons/landingpage/plus-white.svg";
import CancelIcon from "../../../assets/Icons/landingpage/plus-black.svg";

export default function FAQPage() {
  const [selectedSection, setSelectedSection] = useState(FAQ_TYPES.HOST);
  const [questions, setQuestions] = useState(host);

  useEffect(() => {
    if (selectedSection == FAQ_TYPES.GENERAL) setQuestions(guest);
    else setQuestions(host);
  }, [selectedSection]);

  const toggleButton = (question: string) => {
    setQuestions(
      (prev) =>
        prev?.map((q) => {
          if (q.question === question) return { ...q, isOpen: !q.isOpen };
          else return q;
        }) || []
    );
  };

  return (
    <div className="mx-auto bg-primary-black">
      <div
        className={`${styles.bgImage} bg-no-repeat bg-cover bg-top w-full h-screen`}
      >
        <Header />
        <div className={styles.faqDetailContainer}>
          <h1 className="text-primary-orange font-bold text-center text-3xl pt-14">
            Frequently asked questions
          </h1>
          <div className="flex justify-center items-center my-16">
            <div
              className={`${styles.buttonContainer} rounded-[35px] p-2 bg-[#121314]`}
            >
              <button
                className={` text-primary-white text-sm font-bold py-[.15rem] px-[2.5rem] ${
                  selectedSection == FAQ_TYPES.HOST && styles.active
                }`}
                onClick={() => setSelectedSection(FAQ_TYPES.HOST)}
              >
                Host
              </button>
              <button
                className={`' text-primary-white text-sm font-bold py-[.15rem] px-[2.5rem]  ${
                  selectedSection == FAQ_TYPES.GENERAL && styles.active
                }`}
                onClick={() => setSelectedSection(FAQ_TYPES.GENERAL)}
              >
                General
              </button>
            </div>
          </div>

          <div className="container mx-auto">
            {questions?.map((item, i) => (
              <div className="container mx-auto mb-5 md:mb-0" key={i}>
                <div
                  className={`${styles.faqCard} md:p-8 md:container rounded-lg`}
                >
                  <div className={`${styles.faqQuestion} gap-2 md:gap-10`}>
                    <div className={`${styles.leftSide} self-baseline`}>
                      <span
                        className={`${styles.itemNumber} text-primary-orange font-semibold text-[1.7rem] md:text-[2.5rem]`}
                      >
                        {i < 10 && "0"}
                        {i + 1}
                      </span>
                    </div>
                    <div className={styles.middle}>
                      <span
                        className={`${styles.question} text-primary-white text-base md:text-xl font-medium leading-[120%]`}
                      >
                        {item.question}
                      </span>
                      {item?.isOpen && (
                        <div
                          className={`${styles.faqAnswer} text-base font-normal leading-[170%] text-primary-gray`}
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                      )}
                    </div>
                    <div
                      className={`${styles.rightSide} h-full flex self-start`}
                    >
                      <span
                        className={styles.toggleIcon}
                        onClick={() => toggleButton(item.question)}
                      >
                        {!item?.isOpen ? (
                          <div className="plus-white">
                            <img
                              src={PlusIcon}
                              className="w-8 md:w-10"
                              alt=""
                            />
                          </div>
                        ) : (
                          <div className="plus-black">
                            <img
                              src={CancelIcon}
                              className="w-8 md:w-10"
                              alt=""
                            />
                          </div>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
