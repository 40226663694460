import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const customStyles = {
    control: (provided:any) => ({
      ...provided,
      borderRadius: '16px',
      borderColor: '#ccc',
      boxShadow: 'none',
      border: 'none',
      backgroundColor: '#222222',
      '&:hover': {
        borderColor: '#aaa',
      },
    }),
    input: (provided:any) => ({
      ...provided,
      color: '#F3F3F3',
      
    }),
    singleValue: (provided:any) => ({
      ...provided,
      color: '#F3F3F3',
    }),
    placeholder: (provided:any) => ({
      ...provided,
      color: '#F3F3F3',
    }),
    menu: (provided:any) => ({
      ...provided,
      borderRadius: '8px',
      zIndex: 9999,
    }),
    option: (provided:any, state:any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#f0f0f0' : '#fff',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    }),
  };

interface props {
    location: any
    handleLocationSelect: (place: any) => void
}

const LocationSearch = ({location, handleLocationSelect=() => {}}: props) => {
  const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_API_KEY

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey = {GOOGLE_KEY}
        selectProps={{
          value: location,
          onChange: handleLocationSelect,
          placeholder: "Search location",
          styles: customStyles,
        }}
      />
    </div>
  );
};

export default LocationSearch;
