import React, { createContext, useState, useContext } from 'react';

interface SpinnerContextType {
  showSpinner: boolean;
  setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpinnerContext = createContext<SpinnerContextType | undefined>(undefined);

export const SpinnerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [showSpinner, setShowSpinner] = useState(false);
  
    return (
      <SpinnerContext.Provider value={{ showSpinner, setShowSpinner }}>
        {children}
      </SpinnerContext.Provider>
    );
  };

export const useSpinner = () => {
  const context = useContext(SpinnerContext);
  if (!context) {
    throw new Error('useSpinner must be used within a SpinnerProvider');
  }
  return context;
};