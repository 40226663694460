import React from 'react'

interface props{
    selected?: boolean,
    label?: string,
    onChange?: () => void
}

export default function CustomCheckbox({ selected, label, onChange }:props) {
    return (
        <div className='bg-primary-black p-2 rounded-full cursor-pointer' onClick={onChange}>
            <div className={`${ selected ? 'bg-primary-orange' : 'bg-primary-black'} w-3 h-3 rounded-full`}/>
        </div>
      );
}
