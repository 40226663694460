import React, { CSSProperties } from "react";
import Select from "react-select";
import SearchIcon from "../../assets/Icons/refine/search.svg";

type props = {
  onChange?: Function;
  options?: Array<{}>;
  extraProps?: any;
  onInputChange?: (input: string) => void;
  className?: string
  isMulti?: boolean
  value?: any
};

export default function SearchSelect(props: props) {
  return (
    <div className={`${props.className} lg:w-full relative`}>
      <img
        src={SearchIcon}
        alt={""}
        width={15}
        height={15}
        className="absolute top-[30%] left-[5%] z-10"
      />
      <Select
        {...props.extraProps}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "#222222",
            border: "none",
            boxShadow: "none",
            paddingLeft: "2rem",
            borderRadius: "16px",
            minHeight: "3rem",
          }),
          input: (baseStyles, state) => ({
            ...baseStyles,
            color: "white",
            outline: "none",
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
          }),
          indicatorsContainer: (baseStyles, state) => ({
            ...baseStyles,
            display: "none",
          }),
          indicatorSeparator: (baseStyles, state) => ({
            ...baseStyles,
            display: "none",
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            color: "green",
            outline: "none",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
          }),
          placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: "#777777",
            fontSize: ".875rem",
            fontWeight: "500",
            lineHeight: "1.05rem",
          }),
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: "white",
          }),

          menu: (baseStyles, state) => ({
            ...baseStyles,
            color: "black",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            ":hover": {
              background: "rgba(255, 138, 0, 1)",
            },
            background: state.isSelected ? "rgba(255, 138, 0, 1)" : "white",
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            maxHeight: "160px",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(255, 138, 0, 1)",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
          }),
        }}
        placeholder="Find an ticket type"
        options={props.options}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        isMulti={props.isMulti}
        value={props.value}
      />
    </div>
  );
}
