import React, { useEffect, useRef, useState } from "react";
import CancelIcon from "../../assets/Icons/refine/cancel.svg";

interface DrawerProps {
  children: React.ReactNode;
  onClose?: () => void;
  open: boolean;
  title?: string;
  showTitle?: boolean;
  showCloseIcon?: boolean
  className?: any,
  style?: any
}

const RightSideDrawer: React.FC<DrawerProps> = ({
  children,
  open = false,
  onClose = () => {},
  title = "",
  showTitle = true,
  showCloseIcon = true,
  className = "",
  style = {}
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  const toggleDrawer = () => {
    onClose();
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      drawerRef.current &&
      !drawerRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={drawerRef}
      className={`${className} w-full md:w-[50vw] lg:w-[35vw] fixed top-[5.8rem] right-0 h-full px-1 transition-transform duration-700 transform ${
        open ? "translate-x-0" : "translate-x-full"
      } bg-primary-black border-l-[1px] border-l-[#393939]`}
      style={{ zIndex: "100", ...style }}
    >
      <div className="flex justify-between items-center px-2">
        <span className="text-primary-white flex-1 font-dm font-bold text-2xl leading-8 tracking-tighter text-left">
          {showTitle && title}
        </span>
       {showCloseIcon && <img
          src={CancelIcon}
          className="cursor-pointer w-3 h-3"
          onClick={toggleDrawer}
        />}
      </div>

      <div className={`mt-2 p-1`}>{children}</div>
    </div>
  );
};

export default RightSideDrawer;
