import React, { useEffect, useState } from 'react'
import { GUEST_LIST_TABS } from '../../../utils/constants'
import { eventDetailService } from '../../../services/eventDetailServices';
import { useSpinner } from '../../../components/spinner/SpinnerContext';
import ProfileIcon from "../../../assets/Icons/eventDetails/ProfIcon.svg";

interface props {
  eventId: string | null
  handleConnection: (connection: boolean, reqUserId: number) => void
}

export default function GuestList({ eventId, handleConnection }: props) {
  const [selectedGuestListTab, setSelectedGuestListTab] = useState(GUEST_LIST_TABS.GOING);
  const [guestList, setGuestList] = useState([]);
  const [guestListCount, setGuestListCount] = useState<any>({
    'Going': 0,
    'Maybe': 0,
    'Invited': 0,
  });

  const { setShowSpinner } = useSpinner();

  useEffect(() => {
    if (eventId) {
      getGuestList(selectedGuestListTab)
    }

  }, [eventId, selectedGuestListTab]);



  const getGuestList = async (status: string) => {
    try {
      setShowSpinner(true)
      const params = {
        id: eventId,
        pageSize: 20,
        pageNo: 1,
        status
      }
      const res = await eventDetailService.getGuestList(params);
      if (res?.data) {
        setGuestList(res?.data?.Users);
        setShowSpinner(false);
        setGuestListCount(
          {
            'Going': res?.data?.goingGuestCount || 0,
            'Maybe': res?.data?.maybeGuestCount || 0,
            'Invited': res?.data?.invitedGuestCount || 0,

          }
        )
      }
    } catch (error: any) {
      console.log("error getGuestEventDtls : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false);
    }


  }


  return (
    <div className="w-full md:w-[50vw] lg:w-[35vw]">
      {/* guest list tabs */}
      <div className="flex text-text-gray bg-input-box-gray justify-around lg:gap-2 items-center p-1 rounded-xl w-full md:w-fit">
        {Object.values(GUEST_LIST_TABS).map((item, index) => (
          <div
            key={index}
            className={`cursor-pointer rounded-xl p-2 px-4 text-[1rem] ${selectedGuestListTab == item
                ? "text-primary-white bg-primary-black"
                : ""
              }`}
            onClick={() => setSelectedGuestListTab(item)}
          >
            {item}
            {" " + guestListCount[item]}
          </div>
        ))}
      </div>

      {/* guest list */}
      <div className={`grid grid-cols-2 md:grid-cols-3 gap-y-6 mt-5 overflow-y-auto max-h-[63vh] md:max-h-[78vh] xl:max-h-[66vh] scrollableDiv`}>
        {guestList?.length > 0 ? guestList?.map((item: any, idx) => (
          <div className="relative cursor-pointer" key={idx} onClick={() => handleConnection(true, item?.userId)}>
            <img
              src={item?.profilePhoto || ProfileIcon}
              alt="profile"
              className="w-[90%] h-[13.93rem] rounded-lg object-cover bg-text-gray"

            />
            <div
              className="absolute bottom-0 left-0 p-2 text-sm w-full"
              style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)" }}
            >
              <span>{item?.username}</span>
            </div>
          </div>
        ))
          : <div>Be the first to RSVP!</div>}
      </div>

    </div>
  )
}
