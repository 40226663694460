import Axios from '../utils/axiosInstance'
import qs from 'qs';

const fetchAnalytics = (params: {}) => {
    return Axios.get(`dashboard/event/details${params ? '?' + qs.stringify(params) : ''}`)
}

const fetchEventRevenueDetails = (params: {}) => {
    return Axios.get(`event/GetEventRevenueDetails${params ? '?' + qs.stringify(params) : ''}`)
}

const fetchGuests = (params: {}) => {
    return Axios.get(`getGuestLists${params ? '?' + qs.stringify(params) : ''}`)
}

export const AnalyticsService = {
    fetchAnalytics,
    fetchEventRevenueDetails,
    fetchGuests
}
