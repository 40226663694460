import React, { useEffect, useState } from "react";
import ShareIcon from "../../assets/Icons/eventDetails/share.svg";
import GuestIcon from "../../assets/Icons/eventDetails/guestList.svg";
import ExploreIcon from "../../assets/Icons/eventDetails/explore.svg";
import ProfileIcon from "../../assets/Icons/eventDetails/ProfIcon.svg";
import ChatIcon from "../../assets/Icons/eventDetails/eventChat.svg";
import QrCode from "../../assets/Icons/eventDetails/qrcode.svg";
import { CONNECTION_STATUS, DRAWERS, EVENT_ATTENDING_ANSWER, EVENT_ATTENDING_STATUS, EVENT_SHARE_TYPE, LOCAL_STORAGE } from "../../utils/constants";
import GoogleMap from "../../components/googleMap";
import RightSideDrawer from "../../components/rightDrawer";
import GuestList from "./guestList";
import GroupChat from "./groupChat";
import { useLocation, useNavigate } from "react-router-dom";
import { convertUtcToLocal, formatLocalDateForStartTime, getDayOfWeek, getMonthAndDay, getTime } from "../../utils/common";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorage";
import { Event } from "../../utils/Interfaces";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import { eventDetailService } from "../../services/eventDetailServices";
import { UserService } from "../../services/userServices";
import { toast } from "react-toastify";
import PrimaryButton from "../../components/primaryButton";
import BuyNowIcon from '../../assets/Icons/payout/BuyNow.svg'
import Payout from "./payout";
import Modal from "../../components/modal";
import { PayoutService } from "../../services/payoutService";
import { AuthService } from "../../services/authService";
import UserTickets from "../../components/userTickets";
import ConnectionIcon  from '../../assets/Icons/connections/Connection.svg'
import ConnectionIcon2  from '../../assets/Icons/connections/ConnectionOpen.svg'
import OpentoAnythingIcon from '../../assets/Icons/connections/openToAnything.svg'
import FriendshipIcon from '../../assets/Icons/connections/friendship.svg'
import DatingIcon from '../../assets/Icons/connections/dating.svg'
import Girl from '../../assets/Images/connection.png'
import WorkIcon from '../../assets/Icons/connections/work.svg'
import AddressIcon from '../../assets/Icons/connections/address.svg'
import AboutIcon from '../../assets/Icons/connections/about.svg'
import OpenToAnythingIcon1 from '../../assets/Icons/connections/profile_details_connection_open_to_anything.svg'
import FriendshipIcon1 from '../../assets/Icons/connections/profile_details_connection_friendship.svg'
import DatingIcon1 from '../../assets/Icons/connections/profile_details_connection_dating.svg'

export default function EventDetails() {

  const [eventDetails, setEventDetails] = useState<Event | any>();
  const [guestList, setGuestList] = useState<any>();
  const [eventStartDateTime, setEventStartDateTime] = useState('');
  const [eventEndDateTime, setEventEndDateTime] = useState('');
  const [isHost, setIsHost] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId: any = queryParams.get("eventid");
  const hostId = queryParams.get("hostid");
  const purId = queryParams.get("purchaseid");
  const showBuyScreen = queryParams.get('openbuy') === 'true' ? queryParams.get('openbuy') : false;
  const [purchaseId, setPurchaseId] = useState(purId);
  const isShare = queryParams.get("share");
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [userDetails, setUserDetails] = useState<any>('');
  const [tickets, setTickets] = useState();
  const [ticketPurchased, setTicketPurchased] = useState<any>(null);

  const [selectedAns, setSelectedAns] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDrawer, setSelectedDrawer] = useState('');

  const [buyRedeem, setBuyRedeem] = useState(false);
  const [message, setMessage] = useState('');

  const [showTickets, setShowTickets] = useState(false)
  const [showGuestListVisibility, setShowGuestListVisibility] = useState(false);
  const [clickedButton, setClickedButton] = useState('');
  const [showUninvitedScreen, setShowUninvitedScreen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [isAllFreeTickets, setIsAllFreeTickets] = useState(true);
  const [isTicketsAvailable, setIsTicketsAvailable] = useState(false);
  const [isConnectionModalOpen, setIsConnectionModalOpen] = useState(false);
  const [connectionExpanded, setConnectionExpanded] = useState(false);
  const [guestDetails, setGuestDetails] = useState<any>();
  const [isGuestSame, setIsGuestSame] = useState(false);
  const [reqGuestId, setReqGuestId] = useState<number | null>(null);

  const navigate = useNavigate();
  const { setShowSpinner } = useSpinner();

  const [isExpanded, setIsExpanded] = useState(false);
  const [words, setWords] = useState('');
  const [truncatedText, setTruncatedText] = useState('');

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setEventStartDateTime(convertUtcToLocal(eventDetails?.startTime, eventDetails?.timezone))
    setEventEndDateTime(convertUtcToLocal(eventDetails?.endTime, eventDetails?.timezone))
    const totalWords = eventDetails?.description?.split(" ");
    setWords(totalWords);
    setTruncatedText(totalWords?.slice(0, 31)?.join(" "));

  }, [eventDetails])


  useEffect(() => {
    if (hostId === userId) {
      navigate(`/host-center/event-details?eventid=${eventId}&hostid=${hostId}`, { replace: isShare ? true : false });
    } else {
      getGuestEventDtls();
      setIsHost(false)
    }
    if (userId)
      getUserProfileDetails()
  }, [eventId])


  function checkTicketsAvailable(ticketTypes: any) {
    if (ticketTypes && Array.isArray(ticketTypes)) {
      for (let i = 0; i < ticketTypes.length; i++) {
        if (ticketTypes[i].availableTickets >= 1) {
          setIsTicketsAvailable(true);
          break;
        }
      }
    }
  }

  const fetchGuestDetails = async (reqUserId: number) => {
    try {
      const res = await eventDetailService.getGuestDetails({ userId, req_user_id: reqUserId });
      if (res?.data) {
        setGuestDetails(res?.data?.current_user?.userProfileResponse);
        if (userId == reqUserId) {
          setIsGuestSame(true);
        } else {
          setIsGuestSame(false);
        }
      }
    } catch (error: any) {
      console.log("error fetchGuestDetails : ", error?.response?.data?.error || error?.message);
    }
  }

  const sendConnectionRequest = async (status: string) => {
    try {
      const payload = {
        senderUserId: parseInt(userId),
        receiverUserId: reqGuestId,
        eventId: parseInt(eventId),
        response_status: status,
        isAnonymous: false
      }
      const res = await eventDetailService.likeEventUserProfile(payload);
      if (res?.data) {
        toast.success(res?.data?.message);
      }
    } catch (error: any) {
      console.log("error sendConnectionRequest : ", error?.response?.data?.error || error?.message);
    }
  }

  const getGuestEventDtls = async (isFromRedeem: boolean = false) => {
    try {
      let invitedMe = isFromRedeem || isShare;
      setShowSpinner(true);
      const res = await eventDetailService.getGuestEventDetails({ eventId, userId, inviteMe: invitedMe });
      if (res?.data) {
        if (res?.data.events.isUnInvited == true) {
          setShowUninvitedScreen(true);
          return;
        }

        setEventDetails(res?.data?.events);
        setGuestList(res?.data?.InvitedGuest);
        setTickets(res?.data?.eventTicketTypes);
        setTicketPurchased(res?.data?.ticketPurchased);
        let joingStatus: any = res?.data?.events?.joiningStatus == EVENT_ATTENDING_STATUS.GOING ? EVENT_ATTENDING_ANSWER.YES : (res?.data?.events?.joiningStatus == EVENT_ATTENDING_STATUS.NOT_GOING ? EVENT_ATTENDING_ANSWER.NO : (res?.data?.events?.joiningStatus == EVENT_ATTENDING_STATUS.MAYBE ? EVENT_ATTENDING_ANSWER.MAYBE : undefined));
        setSelectedAns(joingStatus);
        checkTicketsAvailable(res?.data?.eventTicketTypes);
        res?.data?.eventTicketTypes.forEach((element: any) => {
          if (!element.is_free)
            setIsAllFreeTickets(false);
        });
        if (
          showBuyScreen &&
          res?.data?.events.ticketPurchased == null &&
          res?.data?.events?.event_type !== "Free Event" &&
          ticketPurchased == null
        ) {
          if (!purchaseId) {
            setSelectedDrawer(DRAWERS.BUY_TICKETS);
            setIsModalOpen(!isModalOpen);
          } else {
            setBuyRedeem(true);
            setMessage("Do you want to redeem the ticket or purchase a new ticket?");
          }
        }
        setShowSpinner(false);
      }

    } catch (error: any) {
      console.log("error getGuestEventDtls : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false);
    }
  }

  const getUserProfileDetails = async () => {
    try {
      const res = await UserService.getUserProfileDetails();
      if (res?.data) {
        setUserDetails(res?.data);
      }
    } catch (error: any) {
      console.log("error getGuestEventDtls : ", error?.response?.data?.error || error?.message);
    }
  }

  const MapLink = () => {
    const latitude = eventDetails?.coordinates?.split(',')?.[0];
    const longitude = eventDetails?.coordinates?.split(',')?.[1];
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      window.open(`maps://maps.apple.com/?q=${latitude},${longitude}`);
    } else

      window.open(`https://www.google.com/maps?q=${latitude},${longitude}`);

  };

  const ShareUrl = async (From: string) => {
    if (From == EVENT_SHARE_TYPE.SHARE) {
      const shareUrl = `${process.env.REACT_APP_BASE_URL?.split('api/')?.[0]}eventLinkPreview?eventid=${eventDetails?.eventId}&hostid=${eventDetails?.hostUserId}&share=true`;
      await navigator.clipboard.writeText(`${userDetails?.firstName ? (userDetails?.firstName) : 'I have'} invited you to event, ${eventDetails?.eventName} ${shareUrl}`);
      console.log(shareUrl)
      toast.success("Event Link Copied!");
    }
  }

  const updateJoiningStatus = async (ans: string) => {
    try {
      setShowSpinner(true);
      if (!userId || userDetails.isDeletedUser == true) {
        const showDetailPageUrl = window?.location?.href + (ans === EVENT_ATTENDING_ANSWER.YES && eventDetails?.event_type !== 'Free Event' ? '&openbuy=true' : '');
        if (!purchaseId)
          setLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE, showDetailPageUrl);
        else
          setLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE, window?.location?.href);
        setTimeout(() => {
          setShowSpinner(false);
          navigate('/auth');
        }, 500);
        return;
      }
      if (eventDetails?.event_type !== 'Free Event' && ans === EVENT_ATTENDING_ANSWER.YES && ticketPurchased == null) {
        if (purchaseId) {
          setBuyRedeem(true);
          setMessage('Do you want to redeem the ticket or purchase a new ticket?');
        }
        else {
          setSelectedDrawer(DRAWERS.BUY_TICKETS);
          setIsModalOpen(!isModalOpen);
        }
      } else {
        const status = ans === EVENT_ATTENDING_ANSWER.YES ? EVENT_ATTENDING_STATUS.GOING : (ans === EVENT_ATTENDING_ANSWER.NO ? EVENT_ATTENDING_STATUS.NOT_GOING : EVENT_ATTENDING_STATUS.MAYBE);
        const data = {
          id: parseInt(userId),
          status: status,
          eventId: parseInt(eventId)
        };
        const res = await eventDetailService.updateEventJoiningStatus(data);
        if (res?.data) {
          setSelectedAns(ans);
          window.location.reload()
        }

      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || error?.message;
      console.error("Error in updateJoiningStatus: ", errorMessage);
      toast.error(errorMessage);
    } finally {
      setShowSpinner(false);
    }
  };

  const buyOrRedeem = (value: string) => {
    setBuyRedeem(false);
    if (value === "Redeem") {
      redeemTicket();
    } else if (value === "Buy") {
      setSelectedDrawer(DRAWERS.BUY_TICKETS);
      setIsModalOpen(!isModalOpen);
    }
  };

  const redeemTicket = async () => {
    try {
      setShowSpinner(true);
      if (userId && userDetails && userDetails.isDeletedUser == false) {
        const params = {
          purchaseId: purchaseId,
          eventId: eventDetails?.eventId,
          userId: userId,
          eventStartTime: formatLocalDateForStartTime(
            eventDetails?.startTime,
            eventDetails?.timezone
          ),
          eventEndTime: formatLocalDateForStartTime(
            eventDetails?.endTime,
            eventDetails?.timezone
          ),
        };
        const redeemRes = await PayoutService.redeemTicket(params);
        if (redeemRes?.data) {
          toast.success(redeemRes?.data?.message);
          getGuestEventDtls(true);
          setShowSpinner(false);
        }
      } else {
        AuthService.logout();
        setLocalStorage(
          LOCAL_STORAGE.SHOW_DETAIL_PAGE,
          window?.location?.href
        );
        setTimeout(() => {
          setShowSpinner(false);
          navigate("/auth");
        }, 500);
        return;
      }
      setPurchaseId("");

    } catch (error: any) {
      const errorMessage = error.response?.data?.error || error.message;
      toast.error(errorMessage)
      setShowSpinner(false);
      return;
    }
  };

  const onBuyNow = () => {
    if (!userId) {
      const showDetailPageUrl = window?.location?.href + '&openbuy=true';
      setLocalStorage(LOCAL_STORAGE.SHOW_DETAIL_PAGE, showDetailPageUrl);
      setTimeout(() => {
        setShowSpinner(false);
        navigate('/auth');
      }, 500);
      return;
    } else {
      if (userDetails?.isDeletedUser == false) {
        if (eventDetails?.IsAccountEnabled || isAllFreeTickets) {
          setSelectedDrawer(DRAWERS.BUY_TICKETS);
          setIsModalOpen(!isModalOpen);
        } else {
          setInfoMessage('Currently, this paid ticket is unavailable for purchase as the event host has not set up their payout account yet.')
          setShowInfo(true);
        }
      }
    }
  }

  const handleConnection = (connection: boolean, reqUserId: number) => {
    setIsConnectionModalOpen(connection);
    if (reqUserId) {
      fetchGuestDetails(reqUserId);
      setReqGuestId(reqUserId);
    }
  }

  const toggleConnectionExpanded = () => {
    setConnectionExpanded(!connectionExpanded);
  };

  return (
    <>
      <div className={`${!eventDetails && 'hidden'} relative text-primary-white`}>
        <div
          className="px-8 bg-cover bg-top inset-0 h-[45vh] text-primary-white rounded-b-full z-0"
          style={{ backgroundImage: `url(${eventDetails?.bannerImage})`, filter: "blur(100px)" }}
        ></div>

        <div className="absolute top-24 py-8 z-100 px-7 xl:px-[7rem] container left-1/2 transform -translate-x-1/2 pb-28">
          <div className="flex gap-4 md:gap-14 md:flex-row flex-col-reverse">
            {/* left side */}
            <div className="flex-1 flex flex-col gap-6">
              <div className="hidden md:flex justify-between items-center">
                <div className="px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 text-sm border border-[#FFFFFF26]">
                  {eventDetails?.eventMode == 'Open' ? 'Public Event' : 'Private Event'}
                </div>
                <div className="px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 border border-[#FFFFFF26] cursor-pointer" onClick={() => ShareUrl(EVENT_SHARE_TYPE.SHARE)}>
                  <img src={ShareIcon} alt="back" width={14} height={14} />
                </div>
              </div>

              <div className="flex flex-col gap-1 md:gap-6">
                <div className="font-bold text-4xl tracking-tighter text-left ">
                  {eventDetails?.eventName}
                </div>

                <div className="flex gap-2.5">
                  <div className=" flex gap-2">
                    <span>
                      {getDayOfWeek(eventStartDateTime) +
                        " " +
                        getMonthAndDay(eventStartDateTime)}
                    </span>
                    <span className=" text-[#F1F1F1B2]">
                      {getTime(eventStartDateTime)}
                    </span>
                  </div>
                  -
                  <div className="flex gap-2">
                    <span>
                      {getDayOfWeek(eventEndDateTime) +
                        " " +
                        getMonthAndDay(eventEndDateTime)}
                    </span>
                    <span className=" text-[#F1F1F1B2]">
                      {getTime(eventEndDateTime)}
                    </span>
                  </div>
                </div>
              </div>

              {ticketPurchased != null && <div
                className={`w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer`}
                onClick={() => {
                  setShowTickets(true)
                }}
              >
                <div className="flex gap-2 items-center">
                  <img src={QrCode} alt="back" width={20} height={20} />
                  <span className="text-sm font-medium ">  View Ticket</span>
                </div>
                <img src={ExploreIcon} alt="back" width={20} height={20} />
              </div>}

              <div>
                {/* guest list box */}
                <div
                  className={`${(!selectedAns || selectedAns == EVENT_ATTENDING_ANSWER.NO || eventDetails?.showGuestlist == 'Hide') && 'blur-[2px]'} w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer`}
                  onClick={() => {
                    if ((!selectedAns || selectedAns == EVENT_ATTENDING_ANSWER.NO || eventDetails?.showGuestlist == 'Hide')) {
                      setShowGuestListVisibility(true);
                      setClickedButton(DRAWERS.GUEST_LIST)
                    } else {
                      setSelectedDrawer(DRAWERS.GUEST_LIST)
                      setIsModalOpen(!isModalOpen);
                    }
                  }}
                >
                  <div className="flex gap-2 items-center">
                    <img src={GuestIcon} alt="back" width={20} height={20} />

                    {/* guests profile */}
                    <div className="flex justify-center items-center ">
                      {guestList?.slice(0, 4).map((item: any, idx: number) =>
                        item?.profilePic ? (
                          <div
                            key={idx}
                            className={`w-7 h-7 ${idx !== 0 && "ml-[-0.5rem]"}`}
                          >
                            <img
                              src={item.profilePic}
                              alt="back"
                              className="rounded-full border-[2px] border-[#1D1D1D] border-solid w-full h-full object-cover object-center"
                            />
                          </div>
                        ) : (
                          <div
                            key={idx}
                            className={`w-7 h-7 bg-text-gray rounded-full border-[2px] border-[#1D1D1D] border-solid flex justify-center ${idx !== 0 && "ml-[-0.5rem]"
                              }`}
                          >
                            <img
                              src={ProfileIcon}
                              alt="back"
                              width={17}
                              height={17}
                            />
                          </div>
                        )
                      )}
                    </div>

                    <span className="text-sm font-medium ">See guest list</span>
                  </div>
                  <img src={ExploreIcon} alt="back" width={20} height={20} />
                </div>

                {/* attendance */}
                {!isHost &&
                  (eventDetails?.status?.toLowerCase() !== 'cancelled' && eventDetails?.status?.toLowerCase() !== 'completed') &&
                  <div className=" mt-3 w-full bg-[#1D1D1D] rounded-xl p-4 ">
                    <div className="flex justify-between w-full">
                      <div className="text-lg font-medium leading-4 tracking-tighter text-left my-2">
                        <span>
                          <span className="text-text-gray">Are you</span>
                          &nbsp;attending?
                        </span>
                      </div>
                    </div>

                    <div className="flex gap-2 mt-6">
                      {Object.values(EVENT_ATTENDING_ANSWER).map((item, idx) => (
                        <div
                          className={`p-2 rounded-xl w-full text-center cursor-pointer ${selectedAns == item
                            ? "bg-primary-orange text-primary-black"
                            : "text-text-gray bg-[#181818]"
                            }`}
                          onClick={() => updateJoiningStatus(item)}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>}
              </div>

              {/* about */}
              <div className="flex flex-col gap-2">
                <span className="text-lg font-bold">About</span>
                <div className="text-[#c5c3c3]">
                  <div>
                    {isExpanded ? (
                      <>
                        {eventDetails?.description}
                        <button
                          onClick={toggleExpanded}
                          className="text-primary-white"
                        >
                          &nbsp;read less
                        </button>
                      </>
                    ) : (
                      <>
                        {truncatedText}
                        <button
                          onClick={toggleExpanded}
                          className="text-primary-white"
                        >
                          {words?.length >= 31 && '...\u00A0read more'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* Map */}
              <div className="flex flex-col gap-2">
                <span className="text-lg font-bold">Where</span>
                <span className="text-[#c5c3c3] cursor-pointer" onClick={() => MapLink()}>{eventDetails?.location}</span>
                <div className="rounded-xl h-56 w-full bg-[#1D1D1D] border border-[#1D1D1D]">
                  {eventDetails && eventDetails?.coordinates ? <GoogleMap
                    styles={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "0.75rem",
                    }}
                    lat={Number(eventDetails?.coordinates?.split(',')?.[0])}
                    lng={Number(eventDetails?.coordinates?.split(',')?.[1])}
                  /> : <span className="m-3">No location found!</span>}
                </div>
              </div>

              {/* event chat */}
            <div
                className={`${(!selectedAns || selectedAns == EVENT_ATTENDING_ANSWER.NO ) && 'blur-[2px]'} w-full bg-[#1D1D1D] rounded-xl p-4 py-5 flex justify-between items-center cursor-pointer`}
                onClick={() => {
                  if ((!selectedAns || selectedAns == EVENT_ATTENDING_ANSWER.NO)) {
                    setShowGuestListVisibility(true);
                    setClickedButton(DRAWERS.GROUP_CHAT)
                  } else { 
                    setSelectedDrawer(DRAWERS.GROUP_CHAT);
                    setIsModalOpen(!isModalOpen);
                  }
                }}>
                <div className="flex gap-2">
                  <img src={ChatIcon} alt="back" width={20} height={20} />
                  <span>Event Chat</span>
                </div>
                <img src={ExploreIcon} alt="explore" width={20} height={20} />
              </div>
            </div>

            {/* right side */}
            <div className="flex-1 relative self-center md:self-auto">
              <img
                src={eventDetails?.bannerImage || 'https://sondr-dev.s3.us-east-2.amazonaws.com/Socha/event_default_banner.png'}
                className="w-[25.18rem] aspect-square border-t-20 border-l-20 rounded-3xl shadow-lg object-cover"
              />
              <div className=" md:hidden absolute bottom-0 left-0 px-2 pb-2 w-full">
                <div className="flex justify-between items-center ">
                  <div className="px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 text-sm border border-[#FFFFFF26]">
                    {eventDetails?.eventMode == 'Open' ? 'Public Event' : 'Private Event'}
                  </div>
                  <div className="px-2.5 py-2 rounded-xl bg-primary-white backdrop-blur-sm bg-opacity-15 border border-[#FFFFFF26] cursor-pointer" onClick={() => ShareUrl(EVENT_SHARE_TYPE.SHARE)}>
                    <img src={ShareIcon} alt="back" width={14} height={14} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        {(eventDetails?.event_type == 'Ticketed Event'
          && !isHost
          && (eventDetails?.status?.toLowerCase() !== 'cancelled' && eventDetails?.status?.toLowerCase() !== 'completed')
        ) &&
          <div className="fixed bottom-0 bg-[#101114] py-4 md:pr-32 w-full flex justify-around md:justify-end gap-12 ">
            {isTicketsAvailable ? <div className="flex flex-col gap-1.5">
              <span className="text-text-gray text-sm">Ticket starting from</span>
              <span>$ {(+eventDetails?.minimumTicketPrice)?.toFixed(2) || '00'}</span>
            </div> : <span className="text-text-gray text-sm ">*Tickets are not available.</span>}

            {purchaseId ? <PrimaryButton
              name="Redeem Ticket"
              icon={BuyNowIcon} classNames="rounded px-2 py-3"
              onClick={() => redeemTicket()} />
              : <PrimaryButton
                name="Buy Now"
                icon={BuyNowIcon} classNames="rounded px-2 py-3"
                onClick={() => onBuyNow()} />
            }
          </div>}


        <RightSideDrawer
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={selectedDrawer == DRAWERS.GUEST_LIST ? DRAWERS.GUEST_LIST : selectedDrawer == DRAWERS.GROUP_CHAT ? DRAWERS.GROUP_CHAT : DRAWERS.BUY_TICKETS}
          showTitle={selectedDrawer == DRAWERS.PAYMENT || selectedDrawer == DRAWERS.TICKET ? false : true}
          showCloseIcon={selectedDrawer == DRAWERS.PAYMENT ? false : true}
        >
          {selectedDrawer == DRAWERS.GUEST_LIST ?
            <GuestList eventId={eventId} handleConnection={handleConnection} /> :
            selectedDrawer == DRAWERS.GROUP_CHAT ?
              <GroupChat event={eventDetails}  /> :
              <Payout
                onCompChange={(value) => setSelectedDrawer(value)}
                selectedDrawer={selectedDrawer}
                eventId={eventId}
              />}
        </RightSideDrawer>

        <RightSideDrawer
        open={isConnectionModalOpen}
        onClose={() => setIsConnectionModalOpen(false)}
        title={DRAWERS.CONNECTION}
      >
         <div className="overflow-y-auto h-[75vh] scrollableDiv relative">

          <div className="flex flex-col justify-start items-center">
              <img src={guestDetails?.userPhotos?.[0]} className="w-full md:w-2/3 object-cover"/>
              <div className="flex flex-col w-full md:w-2/3 absolute top-[50%] ">
                <div className="font-bold text-3xl px-6">
                  <span className="text-primary-white">{guestDetails?.firstName} {guestDetails?.lastName} </span>
                  <span className="text-text-gray">{guestDetails?.age}</span>
                </div>
                {connectionExpanded && <div className="absolute inset-0 bg-gradient-to-b from-black to-transparent z-20"></div>}
                <div className="flex flex-col gap-6 bg-[#121212] w-full py-8 relative">
                {!isGuestSame && <img src={connectionExpanded ? ConnectionIcon2 : ConnectionIcon} alt="Connection" className="absolute top-4 right-4 z-30 cursor-pointer" onClick={toggleConnectionExpanded}/>}
                      {connectionExpanded && <div className="absolute inset-0 bg-black z-20">
                        <div className="absolute top-[-3rem] left-[-3rem] flex gap-2 items-start transition-all duration-300 transform animate-slideIn">
                          <span className="text-text-gray text-sm">Open to anything</span>
                          <img src={OpentoAnythingIcon} alt="Open to anything" className="animate-pulse cursor-pointer" onClick={() => sendConnectionRequest(CONNECTION_STATUS.OPEN_TO_ANYTHING)}/>
                        </div>
                        <div className="absolute left-[-1rem] top-[1rem] transition-all duration-500 transform animate-slideIn">
                          <span className="text-text-gray text-sm">Friendship/Professional</span>
                          <img src={FriendshipIcon} alt="Friendship"  className="animate-pulse cursor-pointer" onClick={() => sendConnectionRequest(CONNECTION_STATUS.FRIENDSHIP)}/>
                        </div>
                        <div className="absolute top-[7rem] left-[1rem] flex items-center gap-2 transition-all duration-700 transform animate-slideIn">
                          <span className="text-text-gray text-sm">Dating</span>
                          <img src={DatingIcon} alt="Dating"  className="animate-pulse cursor-pointer" onClick={() => sendConnectionRequest(CONNECTION_STATUS.DATING)}/>
                        </div>
                      </div>}
                  <div className="flex flex-col gap-2 px-6">
                    {guestDetails?.occupationVisblit &&
                    <div className="flex gap-2 bg-[#1D1D1D] p-2 rounded-lg ">
                      <img src={WorkIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.job_title} at {guestDetails?.workplace}</span>
                    </div>}
                    {guestDetails?.information_visible &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AddressIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.university}</span>
                    </div>}
                    {guestDetails?.information_visible &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AddressIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.city}, {guestDetails?.country}</span>
                    </div>}
                    {guestDetails?.heightVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.height}</span>
                    </div>}
                    {guestDetails?.genderCategoryVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.groupCategory}</span>
                    </div>}
                    {guestDetails?.genderVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.gender}</span>
                    </div>}
                    {guestDetails?.lookingForVisibility &&
                    <div className="flex gap-2  bg-[#1D1D1D] p-2 rounded-lg">
                      <img src={AboutIcon} alt="back" width={20} height={20} />
                      <span className="text-text-gray text-sm">{guestDetails?.groupBy}</span>
                    </div>}
                  </div>
                      {guestDetails?.information_visible && guestDetails?.interests?.length > 0 && <div className="px-6">
                        <span className="text-primary-white text-base">Interests</span>
                        <div className="flex flex-wrap gap-2 mt-2">
                          {guestDetails?.interests?.map((item: any, idx: number) => (
                            <span key={idx} className="bg-[#1D1D1D] p-2 rounded-lg text-text-gray text-sm font-semibold">
                              {item}
                            </span>
                          ))}
                        </div>
                      </div>}
                  

                  {guestDetails?.profileStatus && guestDetails?.promptQuestions?.length > 0 && guestDetails?.promptQuestions.map((item: any, idx: number) => (
                  <div className="px-6">
                    <div className="my-2 text-primary-white text-base">{idx==0 && guestDetails?.profileStatus}</div>
                    <span className="text-text-gray text-base">{item?.question}</span>
                    <p className="text-sm text-primary-gray">{item?.answer}</p>
                  </div>
                  ))}
                </div>
              </div>
          </div>
        </div>
      </RightSideDrawer>


        {buyRedeem && <Modal onClose={() => buyOrRedeem('Buy')} onSave={() => buyOrRedeem('Redeem')} saveTitle="Redeem" cancelTitle="Buy">
          {message}
        </Modal>}

        {showTickets &&
          <Modal showButtons={false} onOkClick={() => setShowTickets(false)}>
            <div className="w-[25rem]">
              <UserTickets ticketPurchased={ticketPurchased} eventDetail={eventDetails} userDetails={userDetails} />
            </div>
          </Modal>}
        {showGuestListVisibility &&
          <Modal showButtons={false} onOkClick={() => setShowGuestListVisibility(false)}>
            <div className="w-[20rem] text-center">
             {eventDetails?.showGuestlist == 'Hide' ? 'Viewing the guest list is not allowed by the host.' :` RSVP 'Yes' to see the ${clickedButton}`}
            </div>
          </Modal>}
        {showUninvitedScreen &&
          <Modal showButtons={false} onOkClick={() => { setShowGuestListVisibility(false); navigate(-1) }}>
            <div className="w-[20rem] text-center">
              You have been uninvited for this event by host.
            </div>
          </Modal>}
        {showInfo &&
          <Modal showButtons={false} onOkClick={() => { setShowInfo(false); setInfoMessage('') }}>
            <div className="w-[20rem] text-center">
              {infoMessage}
            </div>
          </Modal>}
      </div>
    </>
  );
}
