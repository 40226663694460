import React, { ChangeEvent, useEffect, useState } from 'react'
import PrimaryButton from '../../../components/primaryButton'
import ForwardButton from '../../../assets/Icons/forward/forward.svg'
import BackArrow from '../../../assets/Icons/backArrow/backArrow.svg'
import Arrow from '../../../assets/Icons/rightArrow/arrow.svg'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { TextBlastService } from '../../../services/textBlast'
import { getLocalStorage } from '../../../utils/localStorage'
import { LOCAL_STORAGE } from '../../../utils/constants'

const TextBlastDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const eventId: any = queryParams.get("eventid");
    const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
    const contactList = getLocalStorage(LOCAL_STORAGE.CONTACT_LIST)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const inviteSelectedGuests = async (e: any) => {
        e.preventDefault()
        try {
            if (contactList) {
                const contactListArray = JSON.parse(contactList);
                const params = {
                    GuestInvitedDetails: contactListArray,
                    campaign: title,
                    campaignContent: description,
                    campaignLink: `https://socha-beta.ngrok.app/event-details?eventid=${eventId}&hostid=${userId}&share=true`,
                    eventId: parseInt(eventId)
                }
                const res = await TextBlastService.inviteGuests(params)
                if (res?.data) {
                    navigate({
                        pathname: '/text-blast',
                        search: createSearchParams({
                            eventid: eventId
                        }).toString()
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value)
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value)
    }

    const handleFirstNameClick = (id: number) => {
        if (id === 1) {
            setDescription(description + '[First name]');
        }
        else {
            setDescription(description + '[Event link]');
        }
    };


    return (
        <div className='md:container'>
            <div className='grid grid-cols-10 justify-items-start my-2'>
                <div className='col-start-2 col-span-8'>
                    <div className='flex gap-4 items-center font-bold text-base mb-6'>
                        <img src={BackArrow} className='w-8 cursor-pointer' onClick={() => navigate(-1)} />
                        <span className="text-primary-white">Text Blast</span>
                    </div>
                    <div className='flex items-center font-medium text-sm lg:text-2xl overflow-x-auto lg:overflow-x-hidden w-auto gap-6'>
                        <span className={location.pathname.includes("basic-details")
                            ? "text-primary-white"
                            : "text-text-gray"}>Recipient Selection</span>
                        <img src={Arrow} />
                        <span className={location.pathname.includes("textBlast-details")
                            ? "text-primary-white"
                            : "text-text-gray"}>Text blast details</span>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-10 md:grid-cols-4'>
                <div className='col-start-2 col-span-8 md:col-start-2 md:col-span-2  my-8'>
                    <form onSubmit={inviteSelectedGuests}>
                        <div>
                            <label className='text-primary-white text-lg'>Text blast title</label>
                            <div className='mt-2'>
                                <input className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-lg ' placeholder='Enter text blast title here' value={title} onChange={handleTitleChange} />
                                <div className='flex justify-end'>
                                    <span className='text-lg text-[#535353]'>60 words</span>
                                </div>
                            </div>
                        </div>
                        <div className='my-8'>
                            <label className='text-primary-white text-lg'>Message</label>
                            <div className='mt-2'>
                                <textarea className='w-full bg-input-box-gray border-0 rounded-2xl text-primary-white placeholder: text-base' placeholder='Hello First name join us on this special event, heres the link' value={description} onChange={handleDescriptionChange} />
                                <div className='flex justify-end'>
                                    <span className='text-lg text-[#535353]'>120 words</span>
                                </div>
                            </div>
                        </div>
                        <div className='mb-8'>
                            <span className='text-primary-white text-sm'>Dynamic inputs</span>
                            <div className='flex gap-2 my-2'>
                                <button className='text-sm text-primary-white border-2 rounded-lg border-primary-orange bg-third-orange p-1' type='button' onClick={() => handleFirstNameClick(1)}>First name</button>
                                <button className='text-sm text-primary-white border-2 rounded-lg border-primary-orange bg-third-orange p-1' type='button' onClick={() => handleFirstNameClick(2)}>Event Link</button>
                            </div>
                        </div>
                        <div className='border-t border-text-gray'></div>

                        <div className='flex place-content-end items-center my-8' >
                            <PrimaryButton name='Publish' icon={ForwardButton} type='submit' classNames='px-2 py-3' />
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default TextBlastDetails