import React, { useEffect, useState } from "react";
import DatePicker from "../../components/datePicker";
import Filter from "../../components/Filter";
import SortBy from "../../components/sortBy";
import EventCard from "../../components/eventCard";
import FilterMenu from "../../components/FilterMenu";
import { LocationService } from "../../services/locationService";
import { EventService } from "../../services/eventService";
import moment from 'moment-timezone';
import { createSearchParams, useNavigate } from "react-router-dom";
import { Event } from "../../utils/Interfaces";
import { useSpinner } from "../../components/spinner/SpinnerContext";
import SearchInput from "../../components/searchInput";
import { getLocalStorage } from "../../utils/localStorage";
import { LOCAL_STORAGE } from "../../utils/constants";
import PrimaryButton from "../../components/primaryButton";
import { toast } from "react-toastify";
import WelcomeModal from "../../components/WelcomeModal";
import Logo from "../../assets/Images/login/logo.png"
import AppStore from "../../assets/Images/appleDownload.svg"
import GooglePlay from "../../assets/Images/playStoreDownload.svg"
import { PHONE_2_IMAGE_URL, PHONE_SVG_IMAGE_URL } from "../../assets/assets";
import { UserService } from "../../services/userServices";
export default function Discover() {
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [selectedSort, setSelectedSort] = useState<string>('');
  const [filterStartDate, setFilterStartDate] = useState<any>('');
  const [filterEndDate, setFilterEndDate] = useState<any>('');
  const [filterRange, setFilterRange] = useState<Array<number>>([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [error, setError] = useState('');
  const [allEvents, setAllEvents] = useState([]);
  const userId: any = getLocalStorage(LOCAL_STORAGE.USER_ID);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const navigate = useNavigate();
  const { setShowSpinner } = useSpinner();

  useEffect(() => {
    fetchData();
    getUserProfileDetails();
  }, []);

  const fetchData = async () => {
    try {
      setShowSpinner(true)
      const res = await LocationService.getCurrentLocation();
      console.log("bye")
      setCurrentLocation(res);
      setShowSpinner(false)
    } catch (error) {
      console.error("Error while fetching location:", error);
      setError("Please allow location permission so that you may see nearby events.");
      setShowSpinner(false)
    }
  };

  const getUserProfileDetails = async () => {
    try {
      const response = await UserService.getUserProfileDetails();
      setShowWelcomeModal(response?.data?.show_banner);
    } catch (error) {
      console.error("Error fetching user profile details:", error);
    }
  };

  useEffect(() => {
    if (currentLocation)
      FetchEvents();
  }, [currentLocation, selectedSort, filterStartDate, filterEndDate, filterRange, searchFilter]);


  const requestLocationPermission = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('Location access granted:', position);
        fetchData()
      },
      (error) => {
        console.error('Location access denied:', error);
        toast.error("Location permission was denied previously. Please allow it in your browser settings.");
      }
    );
  };

  const FetchEvents = async () => {
    try {
      setShowSpinner(true)
      const params = {
        pageNo: 1,
        pageSize: 16,
        longitude: currentLocation?.longitude || undefined,
        latitude: currentLocation?.latitude || undefined,
        search: searchFilter || undefined,
        startDate: filterStartDate ? moment(filterStartDate, "ddd MMM DD YYYY").format("YYYY-MM-DD") : undefined,
        endDate: filterEndDate ? moment(filterEndDate, "ddd MMM DD YYYY").format("YYYY-MM-DD") : undefined,
        lowPrice: filterRange[0],
        highPrice: filterRange[1],
        sort: selectedSort ? selectedSort : undefined
      }

      const res = await EventService.discoverEvents(params);
      if (res?.data) {
        setAllEvents(res.data?.events);
      } else {

      }
      setShowSpinner(false)
    } catch (error: any) {
      console.log("error Discover Event : ", error?.response?.data?.error || error?.message);
      setShowSpinner(false)
    }

  }

  const onEventClick = (eventId: number, hostId: number) => {
    if (hostId == userId)
      navigate({
        pathname: '/host-center/event-details',
        search: createSearchParams({
          eventid: eventId.toString(),
          hostid: hostId.toString()
        }).toString()
      });
    else
      navigate({
        pathname: '/event-details',
        search: createSearchParams({
          eventid: eventId.toString(),
          hostid: hostId.toString()
        }).toString()
      });
  }


  return (
    <React.Fragment>
      <div className="mx-8 text-primary-white ">
        <h1 className="text-primary-white text-[2.35rem] font-medium">
          Discover
        </h1>

        <div className="flex justify-between items-center my-3.5">
          <div className="w-full lg:w-[30%] flex justify-between gap-1.5">
            <SearchInput
              onInputChange={(input) => setSearchFilter(input)}
            />
            <div className="lg:hidden">
              <FilterMenu
                onSortApplyClick={(value) => setSelectedSort(value)}
                onFilterApplyClick={(start, end, range) => {
                  setFilterStartDate(start);
                  setFilterEndDate(end);
                  setFilterRange(range);
                }}
              />
            </div>
          </div>
          <div className="hidden lg:flex gap-8 items-center">
            <div className="flex gap-4 items-center">
              <DatePicker onDateSelect={(start, end) => {
                setFilterStartDate(start);
                setFilterEndDate(end);
              }} />
              <Filter onChange={(values) => { setFilterRange(values) }} />
            </div>
            <SortBy onChange={(value) => setSelectedSort(value)} />
          </div>
        </div>
        {(!allEvents || allEvents?.length == 0) ?
          <div className="flex flex-col justify-center items-center gap-3 mt-32 " >
            {error ? <>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-10 h-10 mb-1 text-primary-orange">
                <circle cx="12" cy="10" r="3" />
                <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                <line x1="2" y1="2" x2="22" y2="22" />
              </svg>
              <span className="text-sm text-white">{error}</span>
              <PrimaryButton name="Allow Location Permission" classNames="px-3 py-1.5 mt-5xl" onClick={() => requestLocationPermission()} />
            </> :
              <>
                <span className="text-xl font-mono">KEEP AN EYE OUT</span>
                <span className="text-xs text-text-gray">We haven't found any events, but stay tuned!</span>
                <PrimaryButton name="Refresh" classNames="px-3 py-1.5" onClick={() => FetchEvents()} />
              </>}
          </div>
          : <div className="py-8 md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 justify-items-center items-center ">
            {allEvents?.map((event: Event, idx) => <EventCard event={event} onCardClick={() => onEventClick(event?.eventId, event?.hostUserId)} />)}
          </div>}
      </div>
      {showWelcomeModal && <WelcomeModal>
        <div>
          <div className="flex justify-between items-center">
          <img src={Logo} alt="logo" className='w-20 h-10 my-4' />
          <h1 className="font-bold text-primary-white text-lg cursor-pointer" onClick={() => setShowWelcomeModal(false)}>X</h1>
          </div>
          <h1 className="text-text-gray text-[3.4rem] font-medium">Welcome to</h1>
          <h1 className="text-primary-white text-[3.4rem] font-medium mt-[-30px]">Socha</h1>
          <PrimaryButton
            name="Find events!"
            classNames="py-4 px-10 my-4"
            darkMode={true}
            onClick={async () => {
              try {
                await UserService.updateShowBanner({ userId: userId, show_banner: false });
                setShowWelcomeModal(false);
              } catch (error) {
                console.error("Error updating show banner:", error);
              }
            }}
          />
          <div className="flex items-center gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex text-lg gap-2">
                <span className="text-text-gray">Download</span>
                <span className="text-primary-white">Socha Social</span>
              </div>
              <div className="flex gap-4">
                <a href="https://apps.apple.com/in/app/socha-social/id6444107828" target="_blank"><img src={AppStore} alt="app store" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.app.socha&pcampaignid=web_share" target="_blank"><img src={GooglePlay} alt="google play" /></a>
              </div>
            </div>
            <div className="md:flex hidden">
              <img src={PHONE_2_IMAGE_URL} alt="iphone" className="h-[25rem]" />
              <img src={PHONE_SVG_IMAGE_URL} alt="android" className="ml-[-42px] h-[25rem]" />
            </div>
          </div>
        </div>
      </WelcomeModal>}
    </React.Fragment>
  );
}
