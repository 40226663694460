import Axios, { createInstance } from '../utils/axiosInstance'
import qs from 'qs';

const getAllNotifications = (params: {}) => {
    return Axios.get(`getAllNotifications${params ? '?' + qs.stringify(params) : ''}`);
}

const updateBuildPreference = (params: {}) => {
    return Axios.put(`updateBuildPreference${params ? '?' + qs.stringify(params) : ''}`);
}

export const GeneralService = {
    getAllNotifications,
    updateBuildPreference
}