import Axios from '../utils/axiosInstance'
import qs from 'qs';
import { getLocalStorage } from '../utils/localStorage';
import { LOCAL_STORAGE } from '../utils/constants';

const BASE_URL_V1 = process.env.REACT_APP_BASE_URL;

const getUserProfileDetails = () => {
    const id = getLocalStorage(LOCAL_STORAGE.USER_ID);
    return Axios.get(`getUserProfileDetails?ID=${id}`);
}

const uploadProfilePic = async (data: FormData) => {
    const token = getLocalStorage(LOCAL_STORAGE.USER_TOKEN);
    return await fetch(`${BASE_URL_V1}uploadProfilePic`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    });

}
const updateShowBanner = (params: {}) => {
    return Axios.put(`updateShowBanner${params ? '?' + qs.stringify(params) : ''}`);
}

export const UserService = {
    getUserProfileDetails,
    uploadProfilePic,
    updateShowBanner
}