import React, { useEffect, useState } from 'react'
import BackArrow from "../../../assets/Icons/backArrow/backArrow.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import RightArrow from '../../../assets/Icons/Analytics/blackRightArrow.svg'
import TempEvent from '../../../assets/temp/event2.png';
import CalenderIcon from "../../../assets/Icons/refine/calender.svg";
import CancelIcon from "../../../assets/Icons/refine/cancel.svg";
import Calendar from 'react-calendar';
import { toast } from 'react-toastify';
import RightSideDrawer from '../../../components/rightDrawer';
import Tick from '../../../assets/Icons/Analytics/greenTick.svg'
import Cross from '../../../assets/Icons/Analytics/cross.svg'
import { AnalyticsService } from '../../../services/analyticsService';
import banner from '../../../assets/Images/socha_banner.png'
import ProfileIcon from '../../../assets/Icons/profileMenu/profile.svg';
import { getDayOfWeek, getMonthAndDay, getTime } from '../../../utils/common';
import moment from 'moment-timezone';
import { REVENUE_DATE_FILTER } from '../../../utils/constants';
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const monthNamesAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SELECTED_RANGE = {
  START: "Start",
  END: "End",
};

export default function Revenue() {

  const navigate = useNavigate();
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [selectedDateType, setSelectedDateType] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState<Value | any>("");
  const [endDate, setEndDate] = useState<Value | any>("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [data, setData] = useState<any>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventId: any = queryParams.get("eventid");
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const onDateFilterClick = (selectedDateFilter: string) => {
    setStartDate('');
    setEndDate('');
    if (selectedDateFilter) {
      if (selectedDateFilter == REVENUE_DATE_FILTER.TODAY) {
        getEventRevenueDetails(eventId, moment().toString(), moment().toString())

      } else if (selectedDateFilter == REVENUE_DATE_FILTER.THIS_WEEK) {
        getEventRevenueDetails(eventId, moment().startOf("week").toString(), moment().endOf("week").toString())
      } else if (selectedDateFilter == REVENUE_DATE_FILTER.THIS_MONTH) {
        getEventRevenueDetails(eventId, moment().startOf("month").toString(), moment().endOf("month").toString())
      }
    }
  };


  const onChange = (newValue: any) => {
    if (selectedDate == SELECTED_RANGE.START) {
      setStartDate(newValue);
      setSelectedDate(SELECTED_RANGE.END);
      return;
    } else {
      if (newValue < startDate) {
        toast.error("End date must be later than start date.");
        return;
      }
      setEndDate(newValue);
      setSelectedDate('');
      getEventRevenueDetails(eventId, startDate, newValue)
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    getEventRevenueDetails(eventId);
  }, []);

  const getEventRevenueDetails = async (eventId: number, startDate: string | undefined = undefined, endDate: string | undefined = undefined) => {
    try {
      
      const response = await AnalyticsService.fetchEventRevenueDetails({ eventId: eventId, startDate: moment(startDate, "ddd MMM DD YYYY").format("YYYY-MM-DD"), endDate: moment(endDate, "ddd MMM DD YYYY").format("YYYY-MM-DD") });
      setData(response?.data);
    } catch (error) {
      console.log("error in  revenue", error);
    }
  }


  const onItemClick = (item: any) => {
    setShowDrawer(true);
    setSelectedItem(item);
  }
  return (
    <div className="container mt-8 text-white flex flex-col gap-5">
      <div className="flex flex-col md:flex-row md:items-center justify-between">
        <div className="flex items-center gap-4 font-bold text-base mb-6">
          <img
            src={BackArrow}
            alt="Back"
            className="w-8 cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-text-gray font-medium text-2xl">Analytics</span>
          <img src={RightArrow} alt="Right Arrow" />
          <span className="font-medium text-2xl">Revenue</span>
        </div>
        <div className="flex items-center gap-2 px-4 py-2 bg-input-box-gray rounded-2xl w-full md:w-[40%]">
          <img
            src={data?.bannerImage ? data?.bannerImage : banner}
            alt="Event"
            className="w-7 h-7 rounded-md"
          />
          <span className="truncate">
            {data?.eventName}
          </span>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:items-center gap-3 md:justify-between">
        <div className="flex items-center gap-2 px-4 py-2 bg-input-box-gray rounded-2xl justify-between min-w-[25%]">
          <div>
            <span className="font-semibold">${data?.totalEarned || '00'}</span>
            <span className="text-text-gray text-sm">&nbsp;Earned</span>
          </div>
          <div>
            <span className="font-semibold">${data?.totalTicketsSold || '00'}</span>
            <span className="text-text-gray text-sm">&nbsp;Tickets Sold</span>
          </div>
        </div>
       {data?.eventRevenueDetails?.length > 0 && <div className={` text-sm flex items-center gap-1.5 px-2 bg-input-box-gray rounded-2xl justify-between min-w-[20%] py-1`}>
          <img src={CalenderIcon} className='cursor-pointer'
            onClick={() => {
              setSelectedDateType('');
              setShowCustomDate(true)
            }}
          />
          {Object.values(REVENUE_DATE_FILTER).map((label, index) => (
            <span
              key={index}
              className={`cursor-pointer ${selectedDateType == label && 'border border-primary-orange bg-[#FF8A0024]'} px-2 py-1.5 rounded-2xl`}
              onClick={() => { setSelectedDateType(label); setShowCustomDate(false); onDateFilterClick(label); }}
            >
              {label}
            </span>
          ))}
          {showCustomDate && <div className='relative z-10 flex items-center gap-2 bg-black px-3 py-1 rounded-2xl justify-between'>
            <span>Custom range</span>
            <div className='flex items-center gap-2 border border-primary-orange px-3 py-1.5 bg-[#FF8A0024] rounded-lg justify-between' onClick={() => setShowCalendar(true)}>
              <div
                className={`cursor-pointer ${selectedDate == SELECTED_RANGE.START ? "bg-input-box-gray" : ""
                  }`}
                onClick={() => {
                  setSelectedDate(SELECTED_RANGE.START);
                  setShowCalendar(!showCalendar);
                }}
              >
                {startDate ? (
                  <>
                    <span className="text-text-gray ">
                      {monthNamesAbbreviated[startDate?.getMonth()] + " "}
                    </span>
                    {startDate?.getDate()}
                  </>
                ) : (
                  <span className="text-text-gray">Start</span>
                )}
              </div>
              <span>-</span>
              <div
                className={`cursor-pointer ${selectedDate == SELECTED_RANGE.END ? "bg-input-box-gray" : ""
                  }`}
                onClick={() => {
                  if (!startDate) {
                    setSelectedDate(SELECTED_RANGE.START);
                  } else
                    setSelectedDate(SELECTED_RANGE.END);
                  setShowCalendar(!showCalendar);
                }}
              >
                {endDate ? (
                  <>
                    <span className="text-text-gray ">
                      {monthNamesAbbreviated[endDate?.getMonth()] + " "}
                    </span>
                    {endDate?.getDate()}
                  </>
                ) : (
                  <span className="text-text-gray">End</span>
                )}
              </div>
            </div>
            <img src={CancelIcon} className='cursor-pointer'
              onClick={() => {
                setShowCustomDate(false);
                setStartDate('');
                setEndDate('');
                getEventRevenueDetails(eventId);
              }} />
            {showCalendar && (
              <div className="absolute top-11">
                <Calendar
                  onChange={onChange}
                  value={selectedDate == SELECTED_RANGE.START ? startDate : endDate}
                  className={`font-[DM-sans] custom-calendar`}
                  maxDate={new Date()}
                />
              </div>
            )}
          </div>}
        </div>}
      </div>
     {data?.eventRevenueDetails?.length > 0 && <div className="overflow-x-auto">
        <table className="w-full text-left text-sm">
          <thead>
            <tr className='text-text-gray'>
              <th className="py-2 px-4">User name</th>
              <th className="py-2 px-4">Ticket purchased</th>
              <th className="py-2 px-4">Amount</th>
              <th className="py-2 px-4">Ticket type</th>
              <th className="py-2 px-4"></th>
            </tr>
          </thead>
          <tbody>
            {data?.eventRevenueDetails?.map((row: any) => (
              <tr key={row.id} className="hover:bg-input-box-gray" onClick={() => onItemClick(row)}>
                <td className="py-2 px-4 flex items-center gap-2">
                  <img src={row?.profilePhoto ||ProfileIcon} className='rounded-full w-5 h-5' />{row.firstName +' '+ row?.lastName}
                </td>
                <td className="py-2 px-4">{row.totalTicketsPurchased}x</td>
                <td className="py-2 px-4">${row.totalAmount}</td>
                <td className="py-2 px-4 flex items-center gap-1">
                  {row.ticketTypes.map((item: any) => <div className=' text-xs py-0.5 px-2 bg-button-gray rounded-xl'>{item.type}</div>)}
                </td>
                <td>
                  <img src={RightArrow} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>}

      {selectedItem && <RightSideDrawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <div className='flex flex-col px-8 2xl:px-14 gap-3 text-sm overflow-y-auto max-h-[82vh] scrollableDiv'>

          <div className="flex items-center gap-1 px-2 py-1.5 bg-input-box-gray rounded-xl">
            <img
              src={selectedItem?.profilePhoto || ProfileIcon}
              alt="Event"
              className="h-6 w-6 rounded-full object-cover"
            />
            <span className="truncate">
            {selectedItem?.firstName +' '+ selectedItem?.lastName}
            </span>
          </div>

          <div className="flex flex-col gap-1.5 px-2 py-1.5 bg-input-box-gray rounded-xl">
            <div className='flex items-center justify-between'>
              <span className='text-text-gray text-sm'>Total tickets purchased</span>
              <span>{selectedItem?.totalTicketsPurchased}x</span>
            </div>
            <div className='flex items-center justify-between'>
              <span className='text-text-gray text-sm'>Recent purchased date</span>
              <div className='text-end'>
                    <span>
                    {moment(selectedItem?.purchasedDate).format("Do MMM YYYY")}
                    </span>
                    <span className=" text-[#F1F1F1B2]">
                      {' '+getTime(selectedItem?.purchasedDate)}
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-1'>
              {selectedItem?.ticketTypes.map((item: any) => (
                <div className="flex items-center justify-between px-4 py-2 bg-[#363636] rounded-xl">
                  <span>{item.type}</span>
                  <span>${item.price}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-1.5 px-2 py-1.5 bg-input-box-gray rounded-xl">
            <div className='flex items-center justify-between'>
              <span className='text-text-gray text-sm'>Amount paid</span>
              <span>${selectedItem?.totalAmount }</span>
            </div>
            {/* <hr className='border-[#474747]' />
            <div className='flex items-center justify-between'>
              <span className='text-text-gray text-sm'>Paid via card</span>
              <span>****3453</span>
            </div> */}
          </div>

          <div className="flex flex-col gap-1.5 px-2 py-1.5 bg-input-box-gray rounded-xl">
            <span className='text-text-gray text-sm'>Ticket redemptions</span>
            {selectedItem?.ticketRedemptions?.map((item: any) =>
              <div className="flex items-center justify-between px-2 py-1.5 bg-[#363636] rounded-xl">
                <span>{item?.total_purchases}x {item?.type}</span>
              <img src={item?.isRedeemed ? Tick : Cross} />
            </div>)}
          </div>

        </div>
      </RightSideDrawer>}

    </div>

  )
}
